// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var label = /* #__PURE__ */ Concur_React_DOM.label(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var checkbox = function (props) {
    return function (checked) {
        return input(append([ Concur_React_Props["_type"]("checkbox"), Concur_React_Props.checked(checked), voidRight(!checked)(Concur_React_Props.onChange) ])(props));
    };
};
var labelCheckbox$prime = function (cls) {
    return function (id) {
        return function (label1) {
            return function (checked) {
                return div([ Concur_React_Props.key(id), Concur_React_Props.className(cls) ])([ checkbox([ Concur_React_Props["_id"](id) ])(checked), label([ Concur_React_Props.htmlFor(id), Concur_React_Props.className("mx-3") ])([ text(label1) ]) ]);
            };
        };
    };
};
var labelCheckbox = /* #__PURE__ */ labelCheckbox$prime("");
export {
    labelCheckbox$prime,
    labelCheckbox,
    checkbox
};
