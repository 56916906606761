// Generated by purs version 0.15.7
import * as Concur_Core from "../Concur.Core/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Control_MultiAlternative from "../Control.MultiAlternative/index.js";
import * as Control_ShiftMap from "../Control.ShiftMap/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var elLeaf = function (dictLiftWidget) {
    var liftWidget = Concur_Core_LiftWidget.liftWidget(dictLiftWidget);
    return function (dictFunctor) {
        var map1 = Data_Functor.map(dictFunctor);
        return function (e) {
            return function (props) {
                return liftWidget(Concur_Core.mkLeafWidget(function (h) {
                    return e(map1(Concur_Core_Props.mkProp(h))(props));
                }));
            };
        };
    };
};
var el = function (dictShiftMap) {
    var shiftMap = Control_ShiftMap.shiftMap(dictShiftMap);
    return function (dictFunctor) {
        var map1 = Data_Functor.map(dictFunctor);
        return function (e) {
            return function (props) {
                return shiftMap(function (f) {
                    return function (w) {
                        return Concur_Core.mkNodeWidget(function (h) {
                            return function (v) {
                                return e(map1((function () {
                                    var $16 = Concur_Core_Props.mkProp(h);
                                    var $17 = map(f);
                                    return function ($18) {
                                        return $16($17($18));
                                    };
                                })())(props))(v);
                            };
                        })(w);
                    };
                });
            };
        };
    };
};
var el$prime = function (dictShiftMap) {
    var el1 = el(dictShiftMap);
    return function (dictMultiAlternative) {
        var orr = Control_MultiAlternative.orr(dictMultiAlternative);
        return function (dictFunctor) {
            var el2 = el1(dictFunctor);
            return function (e) {
                return function (props) {
                    var $19 = el2(e)(props);
                    return function ($20) {
                        return $19(orr($20));
                    };
                };
            };
        };
    };
};
export {
    el,
    elLeaf,
    el$prime
};
