// Generated by purs version 0.15.7
import * as API_SelStudent from "../API.SelStudent/index.js";
import * as API_Signature from "../API.Signature/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Patterns from "../Concur.Core.Patterns/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Array_NonEmpty_Internal from "../Data.Array.NonEmpty.Internal/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as Internal_Luxon from "../Internal.Luxon/index.js";
import * as Internal_Util from "../Internal.Util/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_Checkbox from "../Widgets.Checkbox/index.js";
import * as Widgets_Confirmation from "../Widgets.Confirmation/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
import * as Widgets_Modal from "../Widgets.Modal/index.js";
import * as Widgets_Signature_Types from "../Widgets.Signature.Types/index.js";
var ordRNTY = /* #__PURE__ */ GraphQL_Type.ordRNTY(Data_Ord.ordString);
var fromFoldable = /* #__PURE__ */ Data_Map_Internal.fromFoldable(ordRNTY)(Data_Foldable.foldableArray);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var length = /* #__PURE__ */ Data_Foldable.length(Data_Foldable.foldableArray)(Data_Semiring.semiringInt);
var eqRNTY = /* #__PURE__ */ GraphQL_Type.eqRNTY(Data_Eq.eqString);
var eq = /* #__PURE__ */ Data_Eq.eq(eqRNTY);
var sortWith = /* #__PURE__ */ Data_Array.sortWith(ordRNTY);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var fromFoldable1 = /* #__PURE__ */ Data_Array.fromFoldable(Data_Foldable.foldableArray);
var loopState = /* #__PURE__ */ Concur_Core_Patterns.loopState(Concur_Core_Types.widgetMonad);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var form = /* #__PURE__ */ Concur_React_DOM.form(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var h1 = /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var span = /* #__PURE__ */ Concur_React_DOM.span(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var map2 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var sortWith1 = /* #__PURE__ */ Data_Array.sortWith(Data_Ord.ordString);
var map4 = /* #__PURE__ */ Data_Functor.map(Data_Map_Internal.functorMap);
var all = /* #__PURE__ */ Data_Foldable.all(Data_Map_Internal.foldableMap)(Data_HeytingAlgebra.heytingAlgebraBoolean);
var map5 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var table = /* #__PURE__ */ Concur_React_DOM.table(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var thead$prime = /* #__PURE__ */ Concur_React_DOM["thead$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var td$prime = /* #__PURE__ */ Concur_React_DOM["td$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var tbody$prime = /* #__PURE__ */ Concur_React_DOM["tbody$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var ordRecord = /* #__PURE__ */ Data_Ord.ordRecord()(/* #__PURE__ */ Data_Ord.ordRecordCons(/* #__PURE__ */ Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "sig";
    }
})(ordRNTY))()({
    reflectSymbol: function () {
        return "doc";
    }
})(ordRNTY));
var update = /* #__PURE__ */ Data_Map_Internal.update(ordRecord);
var label = /* #__PURE__ */ Concur_React_DOM.label(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var tr$prime = /* #__PURE__ */ Concur_React_DOM["tr$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var td = /* #__PURE__ */ Concur_React_DOM.td(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var fromFoldable2 = /* #__PURE__ */ Data_Array.fromFoldable(Data_List_Types.foldableList);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var details = /* #__PURE__ */ Concur_React_DOM.details(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var summary$prime = /* #__PURE__ */ Concur_React_DOM["summary$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var abbr = /* #__PURE__ */ Concur_React_DOM.abbr(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var insert = /* #__PURE__ */ Data_Map_Internal.insert(ordRNTY);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var map6 = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(eqRNTY));
var h1$prime = /* #__PURE__ */ Concur_React_DOM["h1$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var b$prime = /* #__PURE__ */ Concur_React_DOM["b$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map7 = /* #__PURE__ */ Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray);
var any = /* #__PURE__ */ Data_Foldable.any(Data_List_Types.foldableList)(Data_HeytingAlgebra.heytingAlgebraBoolean);
var lookup = /* #__PURE__ */ Data_Map_Internal.lookup(ordRNTY);
var any1 = /* #__PURE__ */ Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Maybe.bindMaybe);
var notEq = /* #__PURE__ */ Data_Eq.notEq(/* #__PURE__ */ Data_Maybe.eqMaybe(Data_Eq.eqString));
var find = /* #__PURE__ */ Data_Foldable.find(Data_Foldable.foldableArray);
var fromFoldable3 = /* #__PURE__ */ Data_Map_Internal.fromFoldable(ordRecord)(Data_Foldable.foldableArray);
var update1 = /* #__PURE__ */ Data_Map_Internal.update(ordRNTY);
var bindFlipped1 = /* #__PURE__ */ Control_Bind.bindFlipped(Concur_Core_Types.bindWidget);
var textarea = /* #__PURE__ */ Concur_React_DOM.textarea(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var all1 = /* #__PURE__ */ Data_Foldable.all(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean);
var union = /* #__PURE__ */ Data_Map_Internal.union(ordRNTY);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.bindWidget);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var EditEmail = /* #__PURE__ */ (function () {
    function EditEmail(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    EditEmail.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new EditEmail(value0, value1, value2);
            };
        };
    };
    return EditEmail;
})();
var SendEmails = /* #__PURE__ */ (function () {
    function SendEmails() {

    };
    SendEmails.value = new SendEmails();
    return SendEmails;
})();
var GoBack = /* #__PURE__ */ (function () {
    function GoBack() {

    };
    GoBack.value = new GoBack();
    return GoBack;
})();
var targetsFromUnambig = /* #__PURE__ */ (function () {
    var process = function (f) {
        return new Data_Tuple.Tuple(f.form.id, f.field.key);
    };
    var $220 = map(process);
    return function ($221) {
        return fromFoldable($220($221));
    };
})();
var targetSigReqs = function (docs) {
    return function (rel) {
        var unambiguousFields = function (v) {
            if (length(v) < 2) {
                return Data_Array.head(v);
            };
            return Data_Maybe.Nothing.value;
        };
        var matchingKeys = function (form1) {
            return Data_Array.filter(function (s) {
                return eq(s.relationship)(rel);
            })(form1.signatures);
        };
        var formTypes = Data_Array.nubByEq(function (a) {
            return function (b) {
                return eq(a.id)(b.id);
            };
        })(map(function (v) {
            return v.form_type;
        })(sortWith(function (v) {
            return v.form_type.id;
        })(docs)));
        var matched = map(function (form1) {
            return {
                form: form1,
                fields: matchingKeys(form1)
            };
        })(formTypes);
        var unambiguous = (function () {
            var process = function (f) {
                return map1(function (v) {
                    return {
                        form: f.form,
                        field: v
                    };
                })(unambiguousFields(f.fields));
            };
            return Data_Array.mapMaybe(process)(matched);
        })();
        var targets = targetsFromUnambig(unambiguous);
        var ambiguousFields = function (v) {
            if (length(v) > 1) {
                return Data_Array_NonEmpty.fromArray(v);
            };
            return Data_Maybe.Nothing.value;
        };
        var ambiguous = Data_Array.mapMaybe(function (m) {
            return map1(function (v) {
                return {
                    form: m.form,
                    fields: v
                };
            })(ambiguousFields(m.fields));
        })(matched);
        var choices = fromFoldable1(map(function (v) {
            return {
                opts: v.fields,
                rel: rel,
                form: v.form,
                selected: Data_Maybe.Nothing.value
            };
        })(ambiguous));
        var $168 = length(ambiguous) > 0;
        if ($168) {
            return new Data_Either.Left({
                rel: rel,
                choices: choices,
                others: unambiguous
            });
        };
        return new Data_Either.Right(targets);
    };
};
var runEmailEdit = function (account) {
    return function (entry) {
        return function (initEmail) {
            var runModal = loopState(initEmail)(function (email) {
                return form([ Concur_React_Props.className("signature-email-single-form signature-req-edit-email modal-grid"), Concur_React_Props.action("javascript:void(0);"), voidRight(new Data_Either.Right(email))(Concur_React_Props.onSubmit) ])([ h1([ Concur_React_Props.className("modal-heading") ])([ text("Edit "), text(entry.field.emailLabel), text(" for "), text(API_SelStudent.studentName(account)(entry.doc.student)) ]), div([ Concur_React_Props.className("signature-employer-container") ])(Data_Array.catMaybes([ Internal_Util.justIf(eq(entry.field.relationship)(Widgets_Signature_Types.employerRel))(span([ Concur_React_Props.className("signature-employer") ])((function () {
                    if (entry.doc.contact instanceof Data_Maybe.Just) {
                        return [ text(entry.doc.contact.value0.name), text(" at "), text(Data_Maybe.maybe("no employer selected")(function (v) {
                            return v.company;
                        })(entry.doc.employer)) ];
                    };
                    if (entry.doc.contact instanceof Data_Maybe.Nothing) {
                        return [ text(Translate.lookupTranslation("no-contact-selected")) ];
                    };
                    throw new Error("Failed pattern match at Widgets.Signature.Request (line 443, column 17 - line 449, column 84): " + [ entry.doc.contact.constructor.name ]);
                })())) ])), input([ map2(map3(Data_Either.Left.create)(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onChange), Concur_React_Props.value(email), Concur_React_Props["_id"]("email-input"), Concur_React_Props["_type"]("email"), Concur_React_Props.required(true), Concur_React_Props.placeholder(Translate.lookupTranslation("email-address")), Concur_React_Props.className("widget-input req-email-address") ]), div([ Concur_React_Props.className("btn-group btn-group-email-single modal-buttons") ])([ button([ Concur_React_Props.className("btn btn-primary"), Concur_React_Props["_type"]("submit") ])([ text(Translate.lookupTranslation("save")) ]), button([ Concur_React_Props.className("btn"), Concur_React_Props["_type"]("button"), voidRight(new Data_Either.Right(initEmail))(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("cancel")) ]) ]) ]);
            });
            return bind(Widgets_Modal.modalWidget(text(""))(runModal))(function (v) {
                if (v instanceof Widgets_Modal.ModalClosed) {
                    return pure(initEmail);
                };
                if (v instanceof Widgets_Modal.ModalReturned) {
                    return pure(v.value0);
                };
                throw new Error("Failed pattern match at Widgets.Signature.Request (line 425, column 77 - line 427, column 38): " + [ v.constructor.name ]);
            });
        };
    };
};
var renderReviewGroup = function (state) {
    return function (group) {
        var sortEntries = sortWith1(function ($222) {
            return Data_String_Common.toLower((function (v) {
                return v.doc.student.name;
            })($222));
        });
        var groupId = "sig-review-" + GraphQL_Type.innerId(group.form.id);
        var selectAllCheck = function (alreadySent) {
            var matches = function (v) {
                if (alreadySent) {
                    return Data_Maybe.isJust(v.sentAt);
                };
                return Data_Maybe.isNothing(v.sentAt);
            };
            var id = (function () {
                if (alreadySent) {
                    return "-sent";
                };
                return "-unsent";
            })();
            var doSelectAll = function (sel) {
                return {
                    sigs: map4(function (s) {
                        return {
                            selected: (function () {
                                var $174 = matches(s);
                                if ($174) {
                                    return sel && Data_Maybe.isJust(s.email);
                                };
                                return s.selected;
                            })(),
                            email: s.email,
                            sentAt: s.sentAt,
                            doc: s.doc,
                            field: s.field,
                            key: s.key
                        };
                    })(group.sigs),
                    form: group.form
                };
            };
            var allSelected = all(function (s) {
                return s.selected || (Data_Maybe.isNothing(s.email) || !matches(s));
            })(group.sigs);
            return map5(map3(Data_Either.Left.create)(doSelectAll))(Widgets_Checkbox["labelCheckbox$prime"]("sig-review-check")(groupId + ("-select-all" + id))(Translate.lookupTranslation("student"))(allSelected));
        };
        var entryTable = function (sent) {
            return function (selAll) {
                return function (content) {
                    return table([ Concur_React_Props.className("sig-request-table") ])([ thead$prime(Data_Array.catMaybes([ new Data_Maybe.Just(td$prime([ Data_Maybe.fromMaybe(text(Translate.lookupTranslation("student")))(selAll) ])), Internal_Util.justIf(eq(state.rel)(Widgets_Signature_Types.employerRel))(td$prime([ text(Translate.lookupTranslation("employer")) ])), Internal_Util.justIf(sent)(td$prime([ text(Translate.lookupTranslation("sent")) ])), new Data_Maybe.Just(td$prime([ text(Translate.lookupTranslation("email")) ])) ])), tbody$prime(content) ]);
                };
            };
        };
        var entryCheck = function (entry) {
            var labelWhen = map1(Internal_Luxon.toRelative)(entry.sentAt);
            var labelEmployer = map1(function (v) {
                return v.name;
            })(entry.doc.contact);
            var labelEmail = Data_Maybe.fromMaybe("")(entry.email);
            var editBtn = button([ voidRight(new Data_Either.Right(new EditEmail(group.form.id, entry, Data_Maybe.fromMaybe("")(entry.email))))(Concur_React_Props.onClick), Concur_React_Props.className("edit-btn") ])([ span([ Concur_React_Props.className("image-replace edit-icon") ])([  ]), span([ Concur_React_Props.className("edit-btn-text") ])([ text(Translate.lookupTranslation("edit")) ]) ]);
            var doSelect = function (sel) {
                return {
                    sigs: update(function (s) {
                        return new Data_Maybe.Just({
                            selected: sel,
                            doc: s.doc,
                            email: s.email,
                            field: s.field,
                            key: s.key,
                            sentAt: s.sentAt
                        });
                    })(entry.key)(group.sigs),
                    form: group.form
                };
            };
            var checkId = groupId + ("-sig-" + (GraphQL_Type.innerId(entry.doc.id) + ("-" + GraphQL_Type.innerStr(entry.field.key))));
            var check = div([ Concur_React_Props.className("sig-review-check") ])([ Widgets_Checkbox.checkbox([ Concur_React_Props["_id"](checkId), Concur_React_Props.disabled(Data_Maybe.isNothing(entry.email)) ])(entry.selected), label([ Concur_React_Props.htmlFor(checkId) ])([ text(API_SelStudent.studentName(state.account)(entry.doc.student)) ]) ]);
            return tr$prime(Data_Array.catMaybes([ new Data_Maybe.Just(td$prime([ map5(map3(Data_Either.Left.create)(doSelect))(check) ])), Internal_Util.justIf(eq(entry.field.relationship)(Widgets_Signature_Types.employerRel))(Data_Maybe.maybe(td$prime([  ]))(function (lbl) {
                return td$prime([ text(lbl) ]);
            })(labelEmployer)), map1(function (when) {
                return td$prime([ text(when) ]);
            })(labelWhen), new Data_Maybe.Just(td([ Concur_React_Props.className("flex flex-row") ])([ text(labelEmail), editBtn ])) ]));
        };
        var allEntries = fromFoldable2(Data_Map_Internal.values(group.sigs));
        var alreadySentEntries = sortEntries(Data_Array.filter(function ($223) {
            return Data_Maybe.isJust((function (v) {
                return v.sentAt;
            })($223));
        })(allEntries));
        var alreadySentGroup = (function () {
            if (length(alreadySentEntries) <= 0) {
                return div$prime([  ]);
            };
            return details([ Concur_React_Props.className("sig-request-subgroup") ])([ summary$prime([ text(Translate.lookupTranslation("already-emailed")) ]), entryTable(true)(new Data_Maybe.Just(selectAllCheck(true)))(map(entryCheck)(alreadySentEntries)) ]);
        })();
        var noEmailEntries = sortEntries(Data_Array.filter(function (e) {
            return Data_Maybe.isNothing(e.email) && Data_Maybe.isNothing(e.sentAt);
        })(allEntries));
        var noEmailGroup = (function () {
            if (length(noEmailEntries) <= 0) {
                return div$prime([  ]);
            };
            return details([ Concur_React_Props.className("sig-request-subgroup"), Concur_React_Props.open(true) ])([ summary$prime([ text(Translate.lookupTranslation("no-saved-email")) ]), entryTable(false)(Data_Maybe.Nothing.value)(map(entryCheck)(noEmailEntries)) ]);
        })();
        var sendableEntries = sortEntries(Data_Array.filter(function (e) {
            return Data_Maybe.isNothing(e.sentAt) && Data_Maybe.isJust(e.email);
        })(allEntries));
        var sendableGroup = (function () {
            if (length(sendableEntries) <= 0) {
                return div$prime([  ]);
            };
            return div([ Concur_React_Props.className("sig-request-subgroup") ])([ entryTable(false)(new Data_Maybe.Just(selectAllCheck(false)))(map(entryCheck)(sendableEntries)) ]);
        })();
        return div([ Concur_React_Props.className("sig-request-group") ])([ div([ Concur_React_Props.className("sig-request-group-header") ])([ abbr([ Concur_React_Props.className("form-abbr"), Concur_React_Props.style({
            backgroundColor: group.form.bgColor
        }), Concur_React_Props.title(group.form.label) ])([ text(group.form.abbr) ]) ]), div([ Concur_React_Props.className("sig-request-group-select") ])([ sendableGroup, noEmailGroup, alreadySentGroup ]) ]);
    };
};
var renderReviewList = function (state) {
    var renderGroup = function (group) {
        var updateGroup = function (newGroup) {
            return {
                groups: insert(group.form.id)(newGroup)(state.groups),
                comment: state.comment,
                rel: state.rel,
                rstate: state.rstate,
                account: state.account
            };
        };
        return map5(Data_Either.either(function ($224) {
            return Data_Either.Left.create(updateGroup($224));
        })(function (a) {
            return new Data_Either.Right(a);
        }))(renderReviewGroup(state)(group));
    };
    return div([ Concur_React_Props.className("sig-request-review-form") ])(append1([ h1([ Concur_React_Props.className("modal-heading") ])([ text("Select "), text(Widgets_Signature_Types.sigRelName(state.rel)), text(Translate.lookupTranslation(" signatures to request")) ]) ])(fromFoldable2(map6(renderGroup)(Data_Map_Internal.values(state.groups)))));
};
var renderResolveChoice = function (group) {
    var radioName = "sig-request-resolve-" + GraphQL_Type.innerId(group.form.id);
    var radio = function (choice) {
        var htmlId = radioName + ("-" + GraphQL_Type.innerStr(choice.key));
        return div([ Concur_React_Props.className("sig-request-resolve-option") ])([ input([ Concur_React_Props["_type"]("radio"), Concur_React_Props.name(radioName), Concur_React_Props["_id"](htmlId), Concur_React_Props.checked(eq1(map1(function (v) {
            return v.key;
        })(group.selected))(new Data_Maybe.Just(choice.key))), voidRight({
            selected: new Data_Maybe.Just(choice),
            form: group.form,
            opts: group.opts,
            rel: group.rel
        })(Concur_React_Props.onChange) ]), label([ Concur_React_Props.htmlFor(htmlId) ])([ text(choice.label) ]) ]);
    };
    return div([ Concur_React_Props.className("sig-request-group") ])([ div([ Concur_React_Props.className("sig-request-group-header") ])([ abbr([ Concur_React_Props.className("form-abbr"), Concur_React_Props.style({
        backgroundColor: group.form.bgColor
    }), Concur_React_Props.title(group.form.label) ])([ text(group.form.abbr) ]) ]), div([ Concur_React_Props.className("sig-request-group-select") ])([ h1$prime([ text(Translate.lookupTranslation("resolve-signature-for") + " "), b$prime([ text(group.form.label) ]) ]), div$prime(Data_Array_NonEmpty.toArray(map7(radio)(group.opts))) ]) ]);
};
var renderResolveSingle = function (v) {
    return Internal_ConcurUtil.loopInfinitely(renderResolveChoice({
        form: v.form,
        rel: v.field.relationship,
        opts: Data_Array_NonEmpty.singleton(v.field),
        selected: new Data_Maybe.Just(v.field)
    }));
};
var renderAmbiguityResolve = function (state) {
    var renderGroup = function (idx) {
        return function (group) {
            return map5(function ($$new) {
                return {
                    choices: Data_Maybe.fromMaybe(state.choices)(Data_Array.updateAt(idx)($$new)(state.choices)),
                    others: state.others,
                    rel: state.rel
                };
            })(renderResolveChoice(group));
        };
    };
    return div([ Concur_React_Props.className("sig-request-resolve-form") ])(append1([ h1([ Concur_React_Props.className("modal-heading") ])([ text("Select "), text(Widgets_Signature_Types.sigRelName(state.rel)), text(Translate.lookupTranslation(" signatures to request")) ]) ])(append1(Data_Array.mapWithIndex(renderGroup)(state.choices))(map(renderResolveSingle)(state.others))));
};
var isGroupEmpty = function (group) {
    return Data_Map_Internal.isEmpty(group.sigs);
};
var isEmpty = function (v) {
    return Data_Map_Internal.isEmpty(v.groups) || any(isGroupEmpty)(Data_Map_Internal.values(v.groups));
};
var initSigReqState = function (account) {
    return function (rel) {
        return function (docs) {
            return function (targs) {
                var isTargeted = function (ftype) {
                    return function (field) {
                        return Data_Maybe.maybe(false)(function (v) {
                            return eq(v)(field.key);
                        })(lookup(ftype.id)(targs));
                    };
                };
                var isSigned = function (rf) {
                    return function (field) {
                        return any1(function (s) {
                            return eq(s.key)(field.key);
                        })(rf.signatures);
                    };
                };
                var grouped = Data_Array.groupBy(function (a) {
                    return function (b) {
                        return eq(a.form_type.id)(b.form_type.id);
                    };
                })(sortWith(function (v) {
                    return v.form_type.id;
                })(docs));
                var docEntry = function (v) {
                    return function (v1) {
                        if (isTargeted(v.form_type)(v1) && !isSigned(v)(v1)) {
                            var defEmail = bindFlipped(function (v2) {
                                return v2.default_email;
                            })(Data_Array.find(function (s) {
                                return eq(s.key)(v1.key);
                            })(v.signature_fields));
                            var email$prime = (function () {
                                var $182 = notEq(defEmail)(new Data_Maybe.Just(""));
                                if ($182) {
                                    return defEmail;
                                };
                                return Data_Maybe.Nothing.value;
                            })();
                            var verif = find(function (v2) {
                                return eq(v2.data.key)(v1.key);
                            })(v.verifications);
                            var v2 = (function () {
                                if (verif instanceof Data_Maybe.Just) {
                                    return {
                                        email: new Data_Maybe.Just(verif.value0.data.email),
                                        sentAt: new Data_Maybe.Just(verif.value0.created_at)
                                    };
                                };
                                if (verif instanceof Data_Maybe.Nothing) {
                                    return {
                                        email: email$prime,
                                        sentAt: Data_Maybe.Nothing.value
                                    };
                                };
                                throw new Error("Failed pattern match at Widgets.Signature.Request (line 185, column 33 - line 187, column 62): " + [ verif.constructor.name ]);
                            })();
                            return new Data_Maybe.Just({
                                field: v1,
                                sentAt: v2.sentAt,
                                email: v2.email,
                                selected: Data_Maybe.isNothing(v2.sentAt) && Data_Maybe.isJust(v2.email),
                                key: {
                                    doc: v.id,
                                    sig: v1.key
                                },
                                doc: v
                            });
                        };
                        return Data_Maybe.Nothing.value;
                    };
                };
                var docEntries = function (doc) {
                    return map(function (ent) {
                        return new Data_Tuple.Tuple(ent.key, ent);
                    })(Data_Array.catMaybes(map(function (field) {
                        return docEntry(doc)(field);
                    })(doc.form_type.signatures)));
                };
                var group = function (gdocs) {
                    var form1 = (Data_Array_NonEmpty.head(gdocs)).form_type;
                    var docEntryList = Data_Array.concatMap(docEntries)(Data_Array_NonEmpty.toArray(gdocs));
                    return Internal_Util.justIf(Data_Array.length(docEntryList) > 0)(new Data_Tuple.Tuple(form1.id, {
                        form: form1,
                        sigs: fromFoldable3(docEntryList)
                    }));
                };
                var groups = fromFoldable(Data_Array.catMaybes(map(group)(grouped)));
                return {
                    groups: groups,
                    rel: rel,
                    account: account,
                    comment: "",
                    rstate: Data_Maybe.Nothing.value
                };
            };
        };
    };
};
var getRequests = function (state) {
    var mkRequest = function (v) {
        if (v.email instanceof Data_Maybe.Just && v.selected) {
            return new Data_Maybe.Just({
                docId: v.doc.id,
                key: v.field.key,
                email: v.email.value0,
                comment: state.comment
            });
        };
        return Data_Maybe.Nothing.value;
    };
    var groupReqs = function (group) {
        return Data_List.catMaybes(map6(mkRequest)(Data_Map_Internal.values(group.sigs)));
    };
    return fromFoldable2(Data_List.concatMap(groupReqs)(Data_Map_Internal.values(state.groups)));
};
var anySelected = function (v) {
    var inGroup = function (v1) {
        return any(function (v2) {
            return v2.selected;
        })(Data_Map_Internal.values(v1.sigs));
    };
    return any(inGroup)(Data_Map_Internal.values(v.groups));
};
var runReviewList = function (v) {
    if (isEmpty(v)) {
        return div([ Concur_React_Props.className("modal-col") ])([ div([ Concur_React_Props.className("mt-6") ])([ text(Translate.lookupTranslation("request-all-signed")) ]) ]);
    };
    var handleAction = function (v1) {
        if (v1 instanceof GoBack) {
            return pure(new Data_Either.Left(Data_Unit.unit));
        };
        if (v1 instanceof SendEmails) {
            return pure(new Data_Either.Right(v));
        };
        if (v1 instanceof EditEmail) {
            return bind(runEmailEdit(v.account)(v1.value1)(v1.value2))(function (email) {
                var newEmail = (function () {
                    var $197 = email !== "";
                    if ($197) {
                        return new Data_Maybe.Just(email);
                    };
                    return Data_Maybe.Nothing.value;
                })();
                var updateEntry = function (v2) {
                    return {
                        email: newEmail,
                        selected: Data_Maybe.isJust(newEmail),
                        doc: v2.doc,
                        field: v2.field,
                        key: v2.key,
                        sentAt: v2.sentAt
                    };
                };
                var updateGroup = function (group) {
                    return new Data_Maybe.Just({
                        sigs: update(function ($225) {
                            return Data_Maybe.Just.create(updateEntry($225));
                        })(v1.value1.key)(group.sigs),
                        form: group.form
                    });
                };
                return runReviewList({
                    groups: update1(updateGroup)(v1.value0)(v.groups),
                    comment: v.comment,
                    rel: v.rel,
                    rstate: v.rstate,
                    account: v.account
                });
            });
        };
        throw new Error("Failed pattern match at Widgets.Signature.Request (line 296, column 5 - line 296, column 81): " + [ v1.constructor.name ]);
    };
    var handle = function (v1) {
        if (v1 instanceof Data_Either.Right) {
            return handleAction(v1.value0);
        };
        if (v1 instanceof Data_Either.Left) {
            return runReviewList(v1.value0);
        };
        throw new Error("Failed pattern match at Widgets.Signature.Request (line 292, column 5 - line 292, column 98): " + [ v1.constructor.name ]);
    };
    return bindFlipped1(handle)(div([ Concur_React_Props.className("modal-col modal-wide") ])([ renderReviewList(v), div([ Concur_React_Props.className("signature-comment-container w-full") ])([ label([ Concur_React_Props.htmlFor("comment-input"), Concur_React_Props.className("signature-comment-label") ])([ text(Translate.lookupTranslation("signature-comment")) ]), textarea([ map2(map3(map3(Data_Either.Left.create)(function (v1) {
        return {
            comment: v1,
            account: v.account,
            groups: v.groups,
            rel: v.rel,
            rstate: v.rstate
        };
    }))(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onChange), Concur_React_Props.value(v.comment), Concur_React_Props["_id"]("comment-input"), Concur_React_Props.placeholder(Translate.lookupTranslation("signature-comment-placeholder")), Concur_React_Props.className("widget-input signature-comment") ])([  ]) ]), div([ Concur_React_Props.className("modal-buttons") ])(Data_Array.catMaybes([ Internal_Util.justIf(Data_Maybe.isJust(v.rstate))(button([ Concur_React_Props.className("btn"), voidRight(new Data_Either.Right(GoBack.value))(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("back")) ])), new Data_Maybe.Just(button([ Concur_React_Props.className("btn btn-primary"), Concur_React_Props.disabled(!anySelected(v)), voidRight(new Data_Either.Right(SendEmails.value))(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("send-email")) ])) ])) ]));
};
var allResolved = function (state) {
    return all1(function ($226) {
        return Data_Maybe.isJust((function (v) {
            return v.selected;
        })($226));
    })(state.choices);
};
var chosenTargets = function (state) {
    var selected = fromFoldable(Data_Array.catMaybes(map(function (a) {
        return map1((function () {
            var $227 = Data_Tuple.Tuple.create(a.form.id);
            return function ($228) {
                return $227((function (v) {
                    return v.key;
                })($228));
            };
        })())(a.selected);
    })(state.choices)));
    return Internal_Util.justIf(allResolved(state))(selected);
};
var signatureRequest = function (workQ) {
    return function (docs) {
        return function (rel) {
            var resolveInner = function (state$prime) {
                return loopState(state$prime)(function (state) {
                    return div([ Concur_React_Props.className("modal-col") ])([ map5(Data_Either.Left.create)(renderAmbiguityResolve(state)), button([ Concur_React_Props.className("btn btn-primary"), Concur_React_Props.disabled(Data_Maybe.isNothing(chosenTargets(state))), voidRight(new Data_Either.Right(state))(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("next")) ]) ]);
                });
            };
            var resolve = function (initState) {
                return bind(resolveInner(initState))(function (state) {
                    var v = chosenTargets(state);
                    if (v instanceof Data_Maybe.Just) {
                        return pure({
                            targets: union(v.value0)(targetsFromUnambig(state.others)),
                            rstate: new Data_Maybe.Just(state)
                        });
                    };
                    if (v instanceof Data_Maybe.Nothing) {
                        return resolve(state);
                    };
                    throw new Error("Failed pattern match at Widgets.Signature.Request (line 81, column 8 - line 84, column 36): " + [ v.constructor.name ]);
                });
            };
            var runModal = function (targ) {
                return bind((function () {
                    if (targ instanceof Data_Either.Right) {
                        return pure({
                            targets: targ.value0,
                            rstate: Data_Maybe.Nothing.value
                        });
                    };
                    if (targ instanceof Data_Either.Left) {
                        return resolve(targ.value0);
                    };
                    throw new Error("Failed pattern match at Widgets.Signature.Request (line 53, column 32 - line 55, column 48): " + [ targ.constructor.name ]);
                })())(function (v) {
                    return review((function () {
                        var v1 = initSigReqState(workQ.account)(rel)(docs)(v.targets);
                        return {
                            groups: v1.groups,
                            comment: v1.comment,
                            rel: v1.rel,
                            rstate: v.rstate,
                            account: v1.account
                        };
                    })());
                });
            };
            var review = function (initState) {
                return bind(runReviewList(initState))(function (ret) {
                    if (ret instanceof Data_Either.Left) {
                        if (initState.rstate instanceof Data_Maybe.Just) {
                            return runModal(new Data_Either.Left(initState.rstate.value0));
                        };
                        if (initState.rstate instanceof Data_Maybe.Nothing) {
                            return runModal(targetSigReqs(docs)(rel));
                        };
                        throw new Error("Failed pattern match at Widgets.Signature.Request (line 62, column 23 - line 64, column 61): " + [ initState.rstate.constructor.name ]);
                    };
                    if (ret instanceof Data_Either.Right) {
                        return confirm(ret.value0);
                    };
                    throw new Error("Failed pattern match at Widgets.Signature.Request (line 61, column 9 - line 65, column 41): " + [ ret.constructor.name ]);
                });
            };
            var confirm = function (state) {
                return bind(Widgets_Confirmation.confirmWidget(Translate.lookupTranslation("request-signatures"))(Translate.lookupTranslation("request-signatures-confirm-btn"))(Translate.lookupTranslation("request-signatures-confirm")))(function (conf) {
                    if (conf) {
                        return pure(state);
                    };
                    return review(state);
                });
            };
            return bind(Widgets_Modal.modalWidget(div$prime([  ]))(runModal(targetSigReqs(docs)(rel))))(function (ret) {
                if (ret instanceof Widgets_Modal.ModalClosed) {
                    return pure(false);
                };
                if (ret instanceof Widgets_Modal.ModalReturned) {
                    return discard(liftEffect(Effect_Console.log(getRequests(ret.value0))))(function () {
                        return bind(alt(Widgets_Loading.loadingWidget)(liftAff(runGraphQL(API_Signature.requestSigs(getRequests(ret.value0))))))(function (ret$prime) {
                            return bind($$Error.wrapCritical(ret$prime))(function () {
                                return pure(true);
                            });
                        });
                    });
                };
                throw new Error("Failed pattern match at Widgets.Signature.Request (line 44, column 5 - line 50, column 22): " + [ ret.constructor.name ]);
            });
        };
    };
};
export {
    signatureRequest,
    targetsFromUnambig,
    targetSigReqs,
    initSigReqState,
    isEmpty,
    isGroupEmpty,
    anySelected,
    getRequests,
    EditEmail,
    SendEmails,
    GoBack,
    runReviewList,
    renderReviewList,
    renderReviewGroup,
    runEmailEdit,
    chosenTargets,
    allResolved,
    renderAmbiguityResolve,
    renderResolveChoice,
    renderResolveSingle
};
