// Generated by purs version 0.15.7
var Step1 = /* #__PURE__ */ (function () {
    function Step1() {

    };
    Step1.value = new Step1();
    return Step1;
})();
var Step2A = /* #__PURE__ */ (function () {
    function Step2A() {

    };
    Step2A.value = new Step2A();
    return Step2A;
})();
var Step2B = /* #__PURE__ */ (function () {
    function Step2B() {

    };
    Step2B.value = new Step2B();
    return Step2B;
})();
var Step3 = /* #__PURE__ */ (function () {
    function Step3() {

    };
    Step3.value = new Step3();
    return Step3;
})();
var Step4 = /* #__PURE__ */ (function () {
    function Step4() {

    };
    Step4.value = new Step4();
    return Step4;
})();
var Preview = /* #__PURE__ */ (function () {
    function Preview(value0) {
        this.value0 = value0;
    };
    Preview.create = function (value0) {
        return new Preview(value0);
    };
    return Preview;
})();
var TestForm = /* #__PURE__ */ (function () {
    function TestForm() {

    };
    TestForm.value = new TestForm();
    return TestForm;
})();
var NavNext = /* #__PURE__ */ (function () {
    function NavNext() {

    };
    NavNext.value = new NavNext();
    return NavNext;
})();
var NavCancel = /* #__PURE__ */ (function () {
    function NavCancel() {

    };
    NavCancel.value = new NavCancel();
    return NavCancel;
})();
var NavJump = /* #__PURE__ */ (function () {
    function NavJump(value0) {
        this.value0 = value0;
    };
    NavJump.create = function (value0) {
        return new NavJump(value0);
    };
    return NavJump;
})();
var WidgetResult = /* #__PURE__ */ (function () {
    function WidgetResult(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WidgetResult.create = function (value0) {
        return function (value1) {
            return new WidgetResult(value0, value1);
        };
    };
    return WidgetResult;
})();
var wrFunctor = {
    map: function (f) {
        return function (v) {
            return new WidgetResult(v.value0, f(v.value1));
        };
    }
};
export {
    Step1,
    Step2A,
    Step2B,
    Step3,
    Step4,
    Preview,
    TestForm,
    NavNext,
    NavCancel,
    NavJump,
    WidgetResult,
    wrFunctor
};
