{
    "new-form": "New Form",
    "hide-forms": "Hide forms",
    "show-forms": "Show forms",
    "recent-forms": "Recent Forms",
    "recent-forms-empty": "No forms match the filters or current search criteria. CIick <b>+Create Forms</b> to get started.",
    "export-all-forms": "Export all forms",
    "show-less": "Show less",
    "show-more": "Show more",
    "re-order": "Re-order",
    "choose-a-student": "Choose a student",
    "choose-a-course": "Choose a course",
    "choose-a-teacher": "Choose a teacher",
    "select-students": "Select students",
    "select-forms": "Select forms",
    "fill-forms": "Fill forms",
    "show-all-semesters": "Show all semesters",
    "could-not-find-student-name": "Could not find student name",
    "could-not-find-placement": "Could not find placement",
    "select-all": "Select all",
    "select-all-unsent": "Select all unsent",
    "selected-count": " selected",
    "copy": "Copy",
    "delete": "Delete",
    "student": "Student",
    "profile": "Profile",
    "student-profile-icon-alt-text": "student profile icon",
    "course": "Course",
    "copy": "Copy",
    "delete": "Delete",
    "student": "Student",
    "profile": "Profile",
    "student-profile-icon-alt-text": "student profile icon",
    "tag-imported": "Imported",
    "course": "Course",
    "employer": "Employer",
    "contact": "Contact",
    "position": "Position",
    "select-employer": "Choose an Employer",
    "select-position": "Choose a Position",
    "teacher": "Teacher",
    "search": "Search",
    "search-teacher": "Search by teacher",
    "search-semester": "Search by semester",
    "search-class": "Search by class",
    "search-teacher-all": "All teachers",
    "search-semester-all": "All semesters",
    "search-class-all": "All classes",
    "semester": "Semester",
    "class": "Class",
    "update-semester": "Change Semester",
    "update-teacher": "Change Teacher",
    "update-semester-for": "Change semester for",
    "update-teacher-for": "Change teacher for",
    "forms": "forms",
    "form-details": "Form Details",
    "fewer-fields": "Fewer fields",
    "more-fields": "More fields",
    "form-has-not-been-saved-text": "The form you are working on has not been saved. Do you want to discard the changes?",
    "next": "Next",
    "prev": "Previous",
    "finish": "Finish",
    "cancel": "Cancel",
    "back": "Back",
    "form-save-quit": "Save and exit",
    "form-quit": "Exit",
    "add": "Add",
    "discard-changes": "Discard changes",
    "clear": "Clear",
    "clear-lowercase": "clear",
    "save": "Save",
    "done": "Done",
    "export-pdf": "Save and Export PDF",
    "export": "Export",
    "nothing-here": "There is nothing here!",
    "nothing-here-clearable": "There is nothing here! Try clearing your filters.",
    "clear-filters": "Clear Search Filters",
    "choose-expectations": "Choose expectations",
    "insert-expectation": "Insert expectation",
    "expectation": "Expectation",
    "expectations-all-curriculum": "All Curriculum",
    "expectations-overall": "Overall Expectations",
    "expectations-course-favs": "Course Favorites",
    "expectations-noc": "Occupations (NOC)",
    "expectations-employer-tab": "Employer / Position",
    "expectations-trade": "Trades",
    "employer-choose-expectation": "Choose employer expectations",
    "employer-insert-expectation": "Insert employer expectation",
    "course-choose-expectation": "Choose course expectations",
    "course-insert-expectation": "Insert course expectation",
    "learning-demonstration": "Demonstration of Learning",
    "create-forms": "＋ Create Forms",
    "edit-forms": "Edit Forms",
    "edit": "Edit",
    "course-form-course": "Course",
    "employer-form-employer": "Employer",
    "employer-details": "Employer",
    "employer-student-position": "Student position",
    "employer-contact": "Contact",
    "add-employer": "＋ New Employer",
    "edit-employer": "Edit ",
    "first-name": "First Name",
    "last-name": "Last Name",
    "semester-hidden-select": "Creating form(s) for semester",
    "teacher-hidden-select": "and teacher",
    "must-select-teacher-semester": "Please select a teacher and semester (required).",
    "none": "None",
    "preview": "Preview",
    "preview-and-sign": "Preview & Signatures",
    "no-pdf": "There is no PDF for this form.",
    "save-before-preview": "ℹ️ Please remember to save before previewing.",
    "autofill-form-button": "Re-autofill",
    "email": "Email",
    "email-address": "Email address",
    "email-all": "Email all",
    "signature-comment": "Message",
    "signature-comment-placeholder": "e.g. Please sign this form.",
    "signatures": "Signatures",
    "request-signatures": "Request signatures",
    "request-signatures-confirm-btn": "Send",
    "request-signatures-confirm": "Are you sure you want to send these signature requests?",
    "request-signatures-no-sigs": "Selected forms have no e-signatures",
    "request-select-signatures": "Select signatures to request",
    "request-all-signed": "All signature fields on the selected form(s) have been signed.",
    "no-contact-selected": "Contact has not been selected in the form.",
    "sent": "Sent",
    "edit-email-for": "Edit email for ",
    "rel-student": "student",
    "rel-teacher": "teacher",
    "rel-employer": "employer",
    "rel-parent": "parent/guardian",
    "rel-principal": "principal",
    "rel-coordinator": "coordinator",
    "resolve-signature-for": "Choose a signature to request for",
    "will-req-signature-for": "Will request",
    "save-signature": "Save as my signature",
    "save-send": "Save & Send",
    "send-email": "Send",
    "already-emailed": "Request already sent",
    "no-saved-email": "No saved email",
    "sign": "Sign",
    "sign-here": "Tap or click to sign",
    "no-sign-here": "Not signed yet",
    "signed-at": "Signed at",
    "signature": "Signature",
    "created-at": "Created at",
    "updated-at": "Updated at",
    "form-created-at": "Form created on ",
    "form-updated-at": "Form updated on ",
    "signature-add-email": "+ Email",
    "signature-send-email": "Send Email",
    "signature-resend-email": "Re-send Email",
    "student-signature": "Student Signature",
    "teacher-signature": "Teacher Signature",
    "employer-signature": "Employer Signature",
    "parent-signature": "Parent/Guardian Signature",
    "principal-signature": "Principal Signature",
    "coordinator-signature": "Coordinator Signature",
    "student-rel": "Student",
    "teacher-rel": "Teacher",
    "employer-rel": "Employer",
    "parent-rel": "Parent/Guardian",
    "principal-rel": "Principal",
    "coordinator-rel": "Coordinator",
    "student-email": "Student Email",
    "teacher-email": "Teacher Email",
    "employer-email": "Employer Email",
    "parent-email": "Parent/Guardian Email",
    "principal-email": "Principal Email",
    "coordinator-email": "Coordinator Email",
    "confirm-email-title": "Send Email?",
    "confirm-email": "Are you sure you want to email this signature request to",
    "confirm-email-multiple": "Are you sure you want to email these signature requests?",
    "confirm-delete-forms-title": "Really delete forms?",
    "confirm-delete-forms": "Are you sure you want to delete these forms?",
    "confirm-delete-forms-primary-button": "Delete",
    "export-forms": "Export Forms",
    "delete-forms": "Delete Forms",
    "copy-forms": "Copy Forms",
    "copy-forms-select-students": "Select students to copy forms to",
    "select-choose": "Choose...",
    "select-choose-search": "Choose or search...",
    "yes": "Yes",
    "no": "No",
    "ok": "Okay",
    "error-occurred": "An error has occurred",
    "sign-with-saved": "Sign with saved signature",
    "sig-form-sign-with-saved": "Sign with My Signature",
    "email-to-sign": "Request signature via email",
    "add-email": "Add email",
    "edit-email": "Edit email",
    "delete-signature": "Delete signature",
    "email-sent": "Email sent",
    "email-sent-to": "Email sent to",
    "email-sent-at": "on",
    "email-sent-link": "Copy e-signature link",
    "email-sent-link-copied": "Link copied",
    "signature-form-invalid": "Please fill out the name and email fields.",
    "loading": "Loading...",
    "legacy-form-label": "Imported",
    "comments": "Comments",
    "comment-section": "Comments",
    "comment-form-placeholder": "Post a Comment",
    "comment-edit-header": "Edit Comment",
    "comment-by": "Comment by ",
    "comment-public-check": "Public (the student can view this comment)",
    "comment-public-indicator": "public",
    "comment-private-indicator": "private",
    "comment-created-at": "posted ",
    "comment-updated-at": "edited ",
    "confirm-delete-comment-title": "Really delete comment?",
    "confirm-delete-comment": "Are you sure you want to delete this comment?",
    "confirm-delete-comment-primary-button": "Delete",
    "post-comment": "Post",
    "edit-comment": "Edit",
    "save-comment": "Save",
    "delete-comment": "Delete",
    "cancel-edit-comment": "Cancel",
    "attachments": "Attachments",
    "attachment-section": "Attachments",
    "attachment-form": "Attach File",
    "attachment-delete": "Delete",
    "attachment-upload": "Upload File",
    "confirm-delete-attachment-title": "Really delete attachment?",
    "confirm-delete-attachment": "Are you sure you want to delete this attachment?",
    "confirm-delete-attachment-primary-button": "Delete",
    "error-page-header": "Oops, something went wrong",
    "error-refcode-text": "When reporting this issue to customer service (info@charityrepublic.com), please use this reference code:",
    "error-button-pre-text": "Click the button below to return to the main page.",
    "error-button-text": "Return to Recent Forms",
    "error": "Error",
    "generic-error": "Something went wrong!"
}
