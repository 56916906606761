// Generated by purs version 0.15.7
import * as Concur_Core_FRP from "../Concur.Core.FRP/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Cofree from "../Control.Cofree/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Form_StudentSelect from "../Form.StudentSelect/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_JSUtil from "../Internal.JSUtil/index.js";
import * as Internal_SelectionMap from "../Internal.SelectionMap/index.js";
import * as Step1 from "../Step1/index.js";
import * as Steps_FormPicker from "../Steps.FormPicker/index.js";
import * as Steps_FormPreview from "../Steps.FormPreview/index.js";
import * as Steps_State from "../Steps.State/index.js";
import * as Steps_Step from "../Steps.Step/index.js";
import * as Steps_Step3 from "../Steps.Step3/index.js";
import * as Steps_WorkQueue from "../Steps.WorkQueue/index.js";
import * as Widgets_Form_TestForm from "../Widgets.Form.TestForm/index.js";
var map = /* #__PURE__ */ Data_Functor.map(/* #__PURE__ */ Control_Cofree.functorCofree(Concur_Core_Types.functorWidget));
var loopW = /* #__PURE__ */ Concur_Core_FRP.loopW(Concur_Core_Types.widgetMonad);
var isEmpty = /* #__PURE__ */ Internal_SelectionMap.isEmpty(/* #__PURE__ */ GraphQL_Type.ordRNTY(Data_Ord.ordString));
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var discard1 = /* #__PURE__ */ discard(Concur_Core_Types.bindWidget);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var widgetAlternative = /* #__PURE__ */ Concur_Core_Types.widgetAlternative(Data_Monoid.monoidArray);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Cofree.applicativeCofree(widgetAlternative));
var discard2 = /* #__PURE__ */ discard(/* #__PURE__ */ Control_Cofree.bindCofree(widgetAlternative));
var fireOnce_ = /* #__PURE__ */ Concur_Core_FRP.fireOnce_(Concur_Core_Types.widgetMonad)(/* #__PURE__ */ Concur_Core_Types.widgetPlus(Data_Monoid.monoidArray));
var onRoute = function (v) {
    return function (v1) {
        if (v instanceof Steps_Step.Step1) {
            return map(Data_Either.Left.create)(loopW(v1)(Step1.step1Widget));
        };
        if (v instanceof Steps_Step.Step2A) {
            var checkFormsSelected = function (s) {
                var $25 = Data_Map_Internal.isEmpty(s.workQueue.forms.sel);
                if ($25) {
                    return Steps_Step.Step2B.value;
                };
                return Steps_Step.Step3.value;
            };
            return map(Data_Either.Left.create)(Steps_State.runStepWidget(v1)(Form_StudentSelect.selectStudentStep)(Steps_State.toStepOn(checkFormsSelected)));
        };
        if (v instanceof Steps_Step.Step2B) {
            var checkStudentsSelected = function (s) {
                var $26 = isEmpty(s.workQueue.students);
                if ($26) {
                    return Steps_Step.Step2A.value;
                };
                return Steps_Step.Step3.value;
            };
            return map(Data_Either.Left.create)(Steps_State.runStepWidget(v1)(Steps_FormPicker.step2BWidget)(Steps_State.toStepOn(checkStudentsSelected)));
        };
        if (v instanceof Steps_Step.Step3) {
            var wrapper = function (s) {
                return bind(Steps_Step3.formFillQueueStep(s))(function (r) {
                    return discard1(liftEffect(Internal_JSUtil.allowPageUnload))(function () {
                        return pure(r);
                    });
                });
            };
            return map(Data_Either.Left.create)(Steps_State.runStepWidget(v1)(wrapper)((function () {
                var $28 = Steps_State.toStep(Steps_Step.Step1.value);
                return function ($29) {
                    return (function (s) {
                        return {
                            workQueue: Steps_WorkQueue.resetWorkQueue(s.workQueue),
                            preselectForms: s.preselectForms,
                            pushStateInterface: s.pushStateInterface,
                            step: s.step
                        };
                    })($28($29));
                };
            })()));
        };
        if (v instanceof Steps_Step.Step4) {
            return pure1(new Data_Either.Left(v1));
        };
        if (v instanceof Steps_Step.Preview) {
            return discard2(fireOnce_(Steps_FormPreview.previewWidget(v.value0)))(function () {
                return pure1(new Data_Either.Left(v1));
            });
        };
        if (v instanceof Steps_Step.TestForm) {
            return discard2(fireOnce_(Widgets_Form_TestForm.renderTestForm))(function () {
                return pure1(new Data_Either.Left(v1));
            });
        };
        throw new Error("Failed pattern match at Steps.Route (line 30, column 1 - line 30, column 75): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var routeSignal = function (state) {
    return onRoute(state.step)(state);
};
export {
    routeSignal,
    onRoute
};
