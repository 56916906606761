// Generated by purs version 0.15.7
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_FunctorWithIndex from "../Data.FunctorWithIndex/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as $$Error from "../Error/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Dynamic from "../Widgets.Form.Dynamic/index.js";
var eq = /* #__PURE__ */ Data_Eq.eq(Data_Argonaut_Core.eqJson);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var getId = /* #__PURE__ */ Widgets_Form_Class.getId(Widgets_Form_Dynamic.dynFormWidget);
var setData = /* #__PURE__ */ Widgets_Form_Class.setData(Widgets_Form_Dynamic.dynFormWidget);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray)(Data_Either.applicativeEither);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Widgets_Form_Class.fwrFunctor);
var toWidget = /* #__PURE__ */ Widgets_Form_Class.toWidget(Widgets_Form_Dynamic.dynFormWidget);
var mapWithIndex = /* #__PURE__ */ Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray);
var getData = /* #__PURE__ */ Widgets_Form_Class.getData(Widgets_Form_Dynamic.dynFormWidget);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var fromFoldable = /* #__PURE__ */ Foreign_Object.fromFoldable(Data_Foldable.foldableArray);
var setWMData = function ($copy_v) {
    return function ($copy_v1) {
        return function ($copy_v2) {
            var $tco_var_v = $copy_v;
            var $tco_var_v1 = $copy_v1;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(v, v1, v2) {
                if (eq(v2)(Data_Argonaut_Core.fromArray([  ]))) {
                    $tco_var_v = v;
                    $tco_var_v1 = v1;
                    $copy_v2 = Data_Argonaut_Core.fromObject(Foreign_Object.empty);
                    return;
                };
                $tco_done = true;
                return bind(Data_Either.note(new $$Error.StringError("Form/subform expected object."))(Data_Argonaut_Core.toObject(v2)))(function (obj) {
                    var update = function (w) {
                        var v3 = Foreign_Object.lookup(getId(w))(obj);
                        if (v3 instanceof Data_Maybe.Nothing) {
                            return new Data_Either.Right(w);
                        };
                        if (v3 instanceof Data_Maybe.Just && Data_Argonaut_Core.isNull(v3.value0)) {
                            return new Data_Either.Right(w);
                        };
                        if (v3 instanceof Data_Maybe.Just) {
                            return $$Error.tagWidget(getId(w))($$Error.tagContext(new $$Error.LoadingJson("WidgetMap", v3.value0))(setData(v)(w)(v3.value0)));
                        };
                        throw new Error("Failed pattern match at Widgets.Form.WidgetMap (line 52, column 20 - line 56, column 36): " + [ v3.constructor.name ]);
                    };
                    return traverse(update)(v1);
                });
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v, $tco_var_v1, $copy_v2);
            };
            return $tco_result;
        };
    };
};
var renderWidgets = function (fc) {
    return function (wm) {
        var subWidget = function (i) {
            return function (ws) {
                return map(function (v) {
                    return map1(function (w) {
                        return Data_Maybe.fromMaybe(wm)(Data_Array.updateAt(i)(w)(wm));
                    })(v);
                })(toWidget(fc)(ws));
            };
        };
        return mapWithIndex(subWidget)(wm);
    };
};
var getWMData = function (fc) {
    return function (wm) {
        var field = function (f) {
            return new Data_Tuple.Tuple(getId(f), getData(fc)(f));
        };
        var dataFields = Data_Array.filter(function (v) {
            return !Data_Argonaut_Core.isNull(v.value1);
        })(map2(field)(wm));
        return Data_Argonaut_Core.fromObject(fromFoldable(dataFields));
    };
};
export {
    renderWidgets,
    getWMData,
    setWMData
};
