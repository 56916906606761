// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Data_Function_Uncurried from "../Data.Function.Uncurried/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Nullable from "../Data.Nullable/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Uncurried from "../Effect.Uncurried/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var unmountComponentAtNode = /* #__PURE__ */ Effect_Uncurried.runEffectFn1($foreign.unmountComponentAtNodeImpl);
var renderToString = /* #__PURE__ */ Data_Function_Uncurried.runFn1($foreign.renderToStringImpl);
var renderToStaticMarkup = /* #__PURE__ */ Data_Function_Uncurried.runFn1($foreign.renderToStaticMarkupImpl);
var render = function (rEl) {
    return function (el) {
        return map(Data_Nullable.toMaybe)(function () {
            return $foreign.renderImpl(rEl, el);
        });
    };
};
var hydrate = function (rEl) {
    return function (el) {
        return map(Data_Nullable.toMaybe)(function () {
            return $foreign.hydrateImpl(rEl, el);
        });
    };
};
var findDOMNode = /* #__PURE__ */ Effect_Uncurried.runEffectFn1($foreign.findDOMNodeImpl);
export {
    render,
    hydrate,
    unmountComponentAtNode,
    findDOMNode,
    renderToString,
    renderToStaticMarkup
};
