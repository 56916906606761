// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Common from "../Widgets.Form.Common/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showNumber);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var div = /* #__PURE__ */ Concur_React_DOM.div(/* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray))(Concur_Core_Types.widgetShiftMap);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var map2 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var eq1 = /* #__PURE__ */ Data_Eq.eq(Data_Argonaut_Core.eqJson);
var decodeJsonMaybe = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeJsonMaybe(Data_Argonaut_Decode_Class.decodeJsonNumber);
var NumberInput = function (x) {
    return x;
};
var showNumberInput = /* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "cls";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "label";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "placeholder";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "step";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "value";
    }
})(/* #__PURE__ */ Data_Maybe.showMaybe(Data_Show.showNumber)))(Data_Show.showNumber))(/* #__PURE__ */ Data_Maybe.showMaybe(Data_Show.showString)))(Data_Show.showString))(Data_Show.showString))(Data_Show.showString));
var isIntInput = function (v) {
    return Data_Int.toNumber(Data_Int.floor(v.step)) === v.step;
};
var numberInputFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return true;
    },
    asField: function (v) {
        return new Data_Maybe.Just(new GraphQL_Shape.QScalar(v.id));
    },
    toWidget: function (fc) {
        return function (v) {
            var value = (function () {
                if (isIntInput(v)) {
                    return Data_Maybe.fromMaybe("")(map(function ($70) {
                        return show(Data_Int.floor($70));
                    })(v.value));
                };
                return show1(Data_Maybe.fromMaybe(0.0)(v.value));
            })();
            var id = Widgets_Form_Class.fieldId(fc)(v.id);
            var handleInput = function (newval) {
                var v1 = Data_Number.fromString(newval);
                if (v1 instanceof Data_Maybe.Just) {
                    return {
                        value: new Data_Maybe.Just(v1.value0),
                        cls: v.cls,
                        id: v.id,
                        label: v.label,
                        placeholder: v.placeholder,
                        step: v.step
                    };
                };
                if (v1 instanceof Data_Maybe.Nothing) {
                    return {
                        value: Data_Maybe.Nothing.value,
                        cls: v.cls,
                        id: v.id,
                        label: v.label,
                        placeholder: v.placeholder,
                        step: v.step
                    };
                };
                throw new Error("Failed pattern match at Widgets.Form.NumberInput (line 51, column 13 - line 53, column 54): " + [ v1.constructor.name ]);
            };
            return map1(Widgets_Form_Class.formWidgetState)(div([ Concur_React_Props.key(id), Concur_React_Props.className(Widgets_Form_Class.fieldClass(v)) ])([ Widgets_Form_Common["widgetLabelS$prime"](fc)(v), input([ Concur_React_Props["_type"]("number"), Concur_React_Props["_id"](id), Concur_React_Props.className("widget-input"), Concur_React_Props.placeholder(Data_Maybe.fromMaybe("")(v.placeholder)), Concur_React_Props.value(value), Concur_React_Props.step(show1(v.step)), map2(map3(map3(NumberInput)(handleInput))(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onInput) ]) ]));
        };
    },
    getData: function (v) {
        return function (v1) {
            return Data_Maybe.maybe(Data_Argonaut_Core.jsonNull)(Data_Argonaut_Core.fromNumber)(v1.value);
        };
    },
    setData: function (v) {
        return function (v1) {
            return function (v2) {
                if (eq1(v2)(Data_Argonaut_Core.fromString(""))) {
                    return new Data_Either.Right(v1);
                };
                return Widgets_Form_Class.setFromJson(numberInputFW)(decodeJsonMaybe)(v1)(function (val) {
                    return new Data_Either.Right({
                        id: v1.id,
                        label: v1.label,
                        cls: v1.cls,
                        step: v1.step,
                        value: val,
                        placeholder: v1.placeholder
                    });
                })(v2);
            };
        };
    }
};
export {
    NumberInput,
    isIntInput,
    showNumberInput,
    numberInputFW
};
