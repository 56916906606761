// Generated by purs version 0.15.7
import * as Control_Comonad_Cofree from "../Control.Comonad.Cofree/index.js";
import * as Control_Monad_Rec_Class from "../Control.Monad.Rec.Class/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
var power = /* #__PURE__ */ Data_Monoid.power(Data_Monoid.monoidString);
var map = /* #__PURE__ */ Data_Functor.map(/* #__PURE__ */ Control_Comonad_Cofree.functorCofree(Data_List_Types.functorList));
var map1 = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var setNodeValue = function (a) {
    return function (n) {
        return Control_Comonad_Cofree.mkCofree(a)(Control_Comonad_Cofree.tail(n));
    };
};
var scanTreeAccum = function (f) {
    return function (b) {
        return function (n) {
            var go = function (v) {
                if (v.current instanceof Data_List_Types.Nil) {
                    return new Control_Monad_Rec_Class.Done(v["final"]);
                };
                if (v.current instanceof Data_List_Types.Cons) {
                    var fb$prime = f(Control_Comonad_Cofree.head(v.current.value0))(v.b);
                    return new Control_Monad_Rec_Class.Loop({
                        b: v.b,
                        current: v.current.value1,
                        "final": Data_List.snoc(v["final"])(Control_Comonad_Cofree.mkCofree(fb$prime.value)(Control_Monad_Rec_Class.tailRec(go)({
                            b: fb$prime.accum,
                            current: Control_Comonad_Cofree.tail(v.current.value0),
                            "final": Data_List_Types.Nil.value
                        })))
                    });
                };
                throw new Error("Failed pattern match at Data.Tree (line 87, column 3 - line 87, column 129): " + [ v.constructor.name ]);
            };
            var fb = f(Control_Comonad_Cofree.head(n))(b);
            return Control_Comonad_Cofree.mkCofree(fb.value)(Control_Monad_Rec_Class.tailRec(go)({
                b: fb.accum,
                current: Control_Comonad_Cofree.tail(n),
                "final": Data_List_Types.Nil.value
            }));
        };
    };
};
var scanTree = function (f) {
    return function (b) {
        return function (n) {
            var go = function (v) {
                if (v.current instanceof Data_List_Types.Nil) {
                    return new Control_Monad_Rec_Class.Done(v["final"]);
                };
                if (v.current instanceof Data_List_Types.Cons) {
                    var fb$prime = f(Control_Comonad_Cofree.head(v.current.value0))(v.b);
                    return new Control_Monad_Rec_Class.Loop({
                        b: v.b,
                        current: v.current.value1,
                        "final": Data_List.snoc(v["final"])(Control_Comonad_Cofree.mkCofree(fb$prime)(Control_Monad_Rec_Class.tailRec(go)({
                            b: fb$prime,
                            current: Control_Comonad_Cofree.tail(v.current.value0),
                            "final": Data_List_Types.Nil.value
                        })))
                    });
                };
                throw new Error("Failed pattern match at Data.Tree (line 72, column 3 - line 72, column 129): " + [ v.constructor.name ]);
            };
            var fb = f(Control_Comonad_Cofree.head(n))(b);
            return Control_Comonad_Cofree.mkCofree(fb)(Control_Monad_Rec_Class.tailRec(go)({
                b: fb,
                current: Control_Comonad_Cofree.tail(n),
                "final": Data_List_Types.Nil.value
            }));
        };
    };
};
var modifyNodeValue = function (f) {
    return function (n) {
        return Control_Comonad_Cofree.mkCofree(f(Control_Comonad_Cofree.head(n)))(Control_Comonad_Cofree.tail(n));
    };
};
var mkTree = Control_Comonad_Cofree.mkCofree;
var drawTree$prime = function (level) {
    return function (t) {
        var treeRoot = power("       ")(level) + ("|----> " + (Control_Comonad_Cofree.head(t) + "\x0a"));
        var treeChildren = drawForest$prime(level + 1 | 0)(Control_Comonad_Cofree.tail(t));
        return treeRoot + treeChildren;
    };
};
var drawForest$prime = function (level) {
    return function (forest) {
        var goForest = function (v) {
            if (v.current instanceof Data_List_Types.Nil) {
                return new Control_Monad_Rec_Class.Done(v.drawn);
            };
            if (v.current instanceof Data_List_Types.Cons) {
                var drawnTree = drawTree$prime(v.level)(v.current.value0);
                return new Control_Monad_Rec_Class.Loop({
                    level: v.level,
                    drawn: v.drawn + drawnTree,
                    current: v.current.value1
                });
            };
            throw new Error("Failed pattern match at Data.Tree (line 45, column 3 - line 47, column 79): " + [ v.constructor.name ]);
        };
        return Control_Monad_Rec_Class.tailRec(goForest)({
            level: level,
            drawn: "",
            current: forest
        });
    };
};
var drawTree = /* #__PURE__ */ drawTree$prime(0);
var showTree = function (dictShow) {
    var show = Data_Show.show(dictShow);
    return function (tree) {
        return drawTree(map(show)(tree));
    };
};
var drawForest = /* #__PURE__ */ drawForest$prime(0);
var showForest = function (dictShow) {
    var show = Data_Show.show(dictShow);
    return function (forest) {
        return drawForest(map1(function (tree) {
            return map(show)(tree);
        })(forest));
    };
};
var appendChild = function (c) {
    return function (n) {
        return Control_Comonad_Cofree.mkCofree(Control_Comonad_Cofree.head(n))(Data_List.snoc(Control_Comonad_Cofree.tail(n))(c));
    };
};
export {
    mkTree,
    drawTree,
    drawTree$prime,
    drawForest,
    drawForest$prime,
    showTree,
    showForest,
    scanTree,
    scanTreeAccum,
    setNodeValue,
    modifyNodeValue,
    appendChild
};
