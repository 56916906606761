// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Common from "../Widgets.Form.Common/index.js";
var showRecord = /* #__PURE__ */ Data_Show.showRecord()();
var showRecordFieldsCons = /* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "label";
    }
});
var showRecordFieldsConsNil = /* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "value";
    }
});
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Data_Eq.eqString));
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var decodeJsonMaybe = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeJsonMaybe(Data_Argonaut_Decode_Class.decodeJsonString);
var any = /* #__PURE__ */ Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean);
var Radio = function (x) {
    return x;
};
var showRadio = /* #__PURE__ */ showRecord(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "cls";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(/* #__PURE__ */ showRecordFieldsCons(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "options";
    }
})(/* #__PURE__ */ showRecordFieldsConsNil(/* #__PURE__ */ Data_Maybe.showMaybe(Data_Show.showString)))(/* #__PURE__ */ Data_Show.showArray(/* #__PURE__ */ showRecord(/* #__PURE__ */ showRecordFieldsCons(/* #__PURE__ */ showRecordFieldsConsNil(Data_Show.showString))(Data_Show.showString)))))(Data_Show.showString))(Data_Show.showString))(Data_Show.showString));
var radioOption = function (fc) {
    return function (state) {
        return function (opt) {
            var selected = eq(state.value)(new Data_Maybe.Just(opt.value));
            var id = Widgets_Form_Class.fieldId(fc)(state.id) + ("." + opt.value);
            return div$prime([ input([ Concur_React_Props["_type"]("radio"), Concur_React_Props["_id"](id), Concur_React_Props.name(id), Concur_React_Props.value(opt.value), Concur_React_Props.checked(selected), voidRight(opt.value)(Concur_React_Props.onChange) ]), Widgets_Form_Common["widgetLabel$prime"](id)(opt.label) ]);
        };
    };
};
var radioFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return true;
    },
    asField: function (v) {
        return new Data_Maybe.Just(new GraphQL_Shape.QScalar(v.id));
    },
    toWidget: function (fc) {
        return function (v) {
            return map(map1(map1(Widgets_Form_Class.formWidgetState)(Radio))(function (v1) {
                return {
                    value: new Data_Maybe.Just(v1),
                    cls: v.cls,
                    id: v.id,
                    label: v.label,
                    options: v.options
                };
            }))(div([ Concur_React_Props.key(Widgets_Form_Class.fieldId(fc)(v.id)), Concur_React_Props.className(Widgets_Form_Class.fieldClass(v)) ])(append1([ Widgets_Form_Common["widgetLabelS$prime"](fc)(v) ])(map2(radioOption(fc)(v))(v.options))));
        };
    },
    getData: function (v) {
        return function (v1) {
            return Data_Maybe.maybe(Data_Argonaut_Core.jsonNull)(Data_Argonaut_Core.fromString)(v1.value);
        };
    },
    setData: function (fc) {
        return function (v) {
            return Widgets_Form_Class.setFromJson(radioFW)(decodeJsonMaybe)(v)(function (val$prime) {
                if (val$prime instanceof Data_Maybe.Nothing) {
                    return new Data_Either.Right({
                        id: v.id,
                        label: v.label,
                        cls: v.cls,
                        options: v.options,
                        value: Data_Maybe.Nothing.value
                    });
                };
                if (val$prime instanceof Data_Maybe.Just && any(function (opt) {
                    return opt.value === val$prime.value0;
                })(v.options)) {
                    return new Data_Either.Right({
                        id: v.id,
                        label: v.label,
                        cls: v.cls,
                        options: v.options,
                        value: new Data_Maybe.Just(val$prime.value0)
                    });
                };
                if (val$prime instanceof Data_Maybe.Just) {
                    return new Data_Either.Left(new $$Error.StringError(val$prime.value0 + (" is not an option in radio " + Widgets_Form_Class.fieldId(fc)(v.id))));
                };
                throw new Error("Failed pattern match at Widgets.Form.Radio (line 41, column 9 - line 45, column 106): " + [ val$prime.constructor.name ]);
            });
        };
    }
};
export {
    Radio,
    radioOption,
    showRadio,
    radioFW
};
