// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Date from "../Data.Date/index.js";
import * as Data_Date_Component from "../Data.Date.Component/index.js";
import * as Data_DateTime from "../Data.DateTime/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Time from "../Data.Time/index.js";
import * as Data_Time_Component from "../Data.Time.Component/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as Internal_Debug from "../Internal.Debug/index.js";
import * as Internal_Luxon from "../Internal.Luxon/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Common from "../Widgets.Form.Common/index.js";
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var fromEnum = /* #__PURE__ */ Data_Enum.fromEnum(Data_Time_Component.boundedEnumHour);
var fromEnum1 = /* #__PURE__ */ Data_Enum.fromEnum(Data_Time_Component.boundedEnumMinute);
var showRecord = /* #__PURE__ */ Data_Show.showRecord()();
var showRecordFieldsCons = /* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "cls";
    }
});
var showRecordFieldsCons1 = /* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
});
var showRecordFieldsCons2 = /* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "label";
    }
});
var showRecordFieldsConsNil = /* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "value";
    }
});
var fromEnum2 = /* #__PURE__ */ Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear);
var fromEnum3 = /* #__PURE__ */ Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth);
var fromEnum4 = /* #__PURE__ */ Data_Enum.fromEnum(Data_Date_Component.boundedEnumDay);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray)(Data_Maybe.applicativeMaybe);
var toEnum = /* #__PURE__ */ Data_Enum.toEnum(Data_Time_Component.boundedEnumHour);
var toEnum1 = /* #__PURE__ */ Data_Enum.toEnum(Data_Time_Component.boundedEnumMinute);
var toEnum2 = /* #__PURE__ */ Data_Enum.toEnum(Data_Time_Component.boundedEnumSecond);
var toEnum3 = /* #__PURE__ */ Data_Enum.toEnum(Data_Time_Component.boundedEnumMillisecond);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var toEnum4 = /* #__PURE__ */ Data_Enum.toEnum(Data_Date_Component.boundedEnumYear);
var toEnum5 = /* #__PURE__ */ Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth);
var toEnum6 = /* #__PURE__ */ Data_Enum.toEnum(Data_Date_Component.boundedEnumDay);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Maybe.bindMaybe);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.bindWidget);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var bindFlipped1 = /* #__PURE__ */ Control_Bind.bindFlipped(Concur_Core_Types.bindWidget);
var div = /* #__PURE__ */ Concur_React_DOM.div(/* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray))(Concur_Core_Types.widgetShiftMap);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var DateTimeInput = function (x) {
    return x;
};
var DateInput = function (x) {
    return x;
};
var zeroPad = function (n) {
    return function (str) {
        var padlen = n - Data_String_CodePoints.length(str) | 0;
        var pad = (function () {
            if (padlen > 0) {
                return Data_String_Common.joinWith("")(Data_Array.replicate(padlen)("0"));
            };
            return "";
        })();
        return pad + str;
    };
};
var showTime = function (time) {
    return zeroPad(2)(show(fromEnum(Data_Time.hour(time)))) + (":" + zeroPad(2)(show(fromEnum1(Data_Time.minute(time)))));
};
var showGenDate = function (dict) {
    return dict.showGenDate;
};
var showDateTimeInput = /* #__PURE__ */ showRecord(/* #__PURE__ */ showRecordFieldsCons(/* #__PURE__ */ showRecordFieldsCons1(/* #__PURE__ */ showRecordFieldsCons2(/* #__PURE__ */ showRecordFieldsConsNil(/* #__PURE__ */ Data_Maybe.showMaybe(Internal_Luxon.showLDT)))(Data_Show.showString))(Data_Show.showString))(Data_Show.showString));
var showDateInput = /* #__PURE__ */ showRecord(/* #__PURE__ */ showRecordFieldsCons(/* #__PURE__ */ showRecordFieldsCons1(/* #__PURE__ */ showRecordFieldsCons2(/* #__PURE__ */ showRecordFieldsConsNil(/* #__PURE__ */ Data_Maybe.showMaybe(Data_Date.showDate)))(Data_Show.showString))(Data_Show.showString))(Data_Show.showString));
var showDate = function (date) {
    return zeroPad(4)(show(fromEnum2(Data_Date.year(date)))) + ("-" + (zeroPad(2)(show(fromEnum3(Data_Date.month(date)))) + ("-" + zeroPad(2)(show(fromEnum4(Data_Date.day(date)))))));
};
var showDateTime = function (dt) {
    return showDate(Data_DateTime.date(dt)) + ("T" + showTime(Data_DateTime.time(dt)));
};
var parseTime = function (str) {
    return bind(traverse(Data_Int.fromString)(Data_String_Common.split(":")(str)))(function (parts) {
        if (parts.length === 2) {
            return bind(toEnum(parts[0]))(function (hour) {
                return bind(toEnum1(parts[1]))(function (minute) {
                    return bind(toEnum2(0))(function (second) {
                        return bind(toEnum3(0))(function (ms) {
                            return pure(new Data_Time.Time(hour, minute, second, ms));
                        });
                    });
                });
            });
        };
        return Data_Maybe.Nothing.value;
    });
};
var parseGenDate = function (dict) {
    return dict.parseGenDate;
};
var parseDate = function (str) {
    return bind(traverse(Data_Int.fromString)(Data_String_Common.split("-")(str)))(function (parts) {
        if (parts.length === 3) {
            return bind(toEnum4(parts[0]))(function (year) {
                return bind(toEnum5(parts[1]))(function (month) {
                    return bind(toEnum6(parts[2]))(function (day) {
                        return Data_Date.exactDate(year)(month)(day);
                    });
                });
            });
        };
        return Data_Maybe.Nothing.value;
    });
};
var parseDateTime = function (str) {
    var v = Data_String_Common.split("T")(str);
    if (v.length === 2) {
        return bind(parseDate(v[0]))(function (date) {
            return bind(parseTime(v[1]))(function (time) {
                return pure(new Data_DateTime.DateTime(date, time));
            });
        });
    };
    return Data_Maybe.Nothing.value;
};
var parseInconsistentDate = function (maybeDateTimeOrJustDateIdk) {
    var v = Data_String_Common.split("T")(maybeDateTimeOrJustDateIdk);
    if (v.length === 2) {
        return parseDate(v[0]);
    };
    return parseDate(maybeDateTimeOrJustDateIdk);
};
var genSetData = function (dictGenDate) {
    var parseGenDate1 = parseGenDate(dictGenDate);
    return function (v) {
        return function (v1) {
            if (Data_Argonaut_Core.isNull(v1)) {
                return new Data_Either.Right({
                    value: Data_Maybe.Nothing.value,
                    cls: v.cls,
                    id: v.id,
                    label: v.label
                });
            };
            var v2 = bindFlipped(parseGenDate1)(Data_Argonaut_Core.toString(v1));
            if (v2 instanceof Data_Maybe.Just) {
                return new Data_Either.Right({
                    value: new Data_Maybe.Just(v2.value0),
                    cls: v.cls,
                    id: v.id,
                    label: v.label
                });
            };
            if (v2 instanceof Data_Maybe.Nothing) {
                var v3 = Internal_Debug.debugLog("Failed to parse datetime: " + Data_Argonaut_Core.stringify(v1));
                return new Data_Either.Right({
                    value: Data_Maybe.Nothing.value,
                    cls: v.cls,
                    id: v.id,
                    label: v.label
                });
            };
            throw new Error("Failed pattern match at Widgets.Form.Date (line 103, column 5 - line 107, column 47): " + [ v2.constructor.name ]);
        };
    };
};
var genLDT = {
    showGenDate: Internal_Luxon.toISONoTZ,
    parseGenDate: Internal_Luxon.fromISO
};
var genSetData1 = /* #__PURE__ */ genSetData(genLDT);
var genDT = {
    showGenDate: showDateTime,
    parseGenDate: parseDateTime
};
var genD = {
    showGenDate: showDate,
    parseGenDate: parseInconsistentDate
};
var genSetData2 = /* #__PURE__ */ genSetData(genD);
var dateWidget = function (dictGenDate) {
    var parseGenDate1 = parseGenDate(dictGenDate);
    var showGenDate1 = showGenDate(dictGenDate);
    return function (fc) {
        return function (htmlType) {
            return function (state) {
                var id = Widgets_Form_Class.fieldId(fc)(state.id);
                var handleInput = function (newval) {
                    var v = parseGenDate1(newval);
                    if (v instanceof Data_Maybe.Just) {
                        return pure1({
                            value: new Data_Maybe.Just(v.value0),
                            cls: state.cls,
                            id: state.id,
                            label: state.label
                        });
                    };
                    if (v instanceof Data_Maybe.Nothing) {
                        return discard(liftEffect(Effect_Console.log("Error parsing date " + newval)))(function () {
                            return pure1({
                                value: Data_Maybe.Nothing.value,
                                cls: state.cls,
                                id: state.id,
                                label: state.label
                            });
                        });
                    };
                    throw new Error("Failed pattern match at Widgets.Form.Date (line 93, column 13 - line 97, column 53): " + [ v.constructor.name ]);
                };
                return bindFlipped1(handleInput)(div([ Concur_React_Props.key(id), Concur_React_Props.className(Widgets_Form_Class.fieldClass(state)) ])([ Widgets_Form_Common.widgetLabelS(fc)(state)([ Concur_React_Props.className("block w-full") ]), input([ Concur_React_Props["_type"](htmlType), Concur_React_Props["_id"](id), Concur_React_Props.className("widget-input"), Concur_React_Props.value(Data_Maybe.maybe("")(showGenDate1)(state.value)), map(Concur_React_Props.unsafeTargetValue)(Concur_React_Props.onInput) ]) ]));
            };
        };
    };
};
var dateWidget1 = /* #__PURE__ */ dateWidget(genLDT);
var dateWidget2 = /* #__PURE__ */ dateWidget(genD);
var dateTimeFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return true;
    },
    asField: function (v) {
        return new Data_Maybe.Just(new GraphQL_Shape.QScalar(v.id));
    },
    toWidget: function (fc) {
        return function (v) {
            return map1(map2(Widgets_Form_Class.formWidgetState)(DateTimeInput))(dateWidget1(fc)("datetime-local")(v));
        };
    },
    getData: function (v) {
        return function (v1) {
            return Data_Maybe.maybe(Data_Argonaut_Core.jsonNull)(function ($140) {
                return Data_Argonaut_Core.fromString(Internal_Luxon.toISONoTZ($140));
            })(v1.value);
        };
    },
    setData: function (v) {
        return function (v1) {
            return function (json) {
                return map3(DateTimeInput)(genSetData1(v1)(json));
            };
        };
    }
};
var dateFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return true;
    },
    asField: function (v) {
        return new Data_Maybe.Just(new GraphQL_Shape.QScalar(v.id));
    },
    toWidget: function (fc) {
        return function (v) {
            return map1(map2(Widgets_Form_Class.formWidgetState)(DateInput))(dateWidget2(fc)("date")(v));
        };
    },
    getData: function (v) {
        return function (v1) {
            return Data_Maybe.maybe(Data_Argonaut_Core.jsonNull)(function ($141) {
                return Data_Argonaut_Core.fromString(showDate($141));
            })(v1.value);
        };
    },
    setData: function (v) {
        return function (v1) {
            return function (json) {
                return map3(DateInput)(genSetData2(v1)(json));
            };
        };
    }
};
export {
    parseGenDate,
    showGenDate,
    DateInput,
    DateTimeInput,
    dateWidget,
    genSetData,
    showDateTime,
    showDate,
    showTime,
    zeroPad,
    parseInconsistentDate,
    parseDateTime,
    parseTime,
    parseDate,
    showDateInput,
    dateFW,
    showDateTimeInput,
    dateTimeFW,
    genD,
    genDT,
    genLDT
};
