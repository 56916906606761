// Generated by purs version 0.15.7
import * as API_Pagination from "../API.Pagination/index.js";
import * as API_Search from "../API.Search/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_Luxon from "../Internal.Luxon/index.js";
import * as Translate from "../Translate/index.js";
var gDecodeJsonCons = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString));
var nameIsSymbol = {
    reflectSymbol: function () {
        return "name";
    }
};
var colorIsSymbol = {
    reflectSymbol: function () {
        return "color";
    }
};
var tagsIsSymbol = {
    reflectSymbol: function () {
        return "tags";
    }
};
var preferred_nameIsSymbol = {
    reflectSymbol: function () {
        return "preferred_name";
    }
};
var last_imported_atIsSymbol = {
    reflectSymbol: function () {
        return "last_imported_at";
    }
};
var idIsSymbol = {
    reflectSymbol: function () {
        return "id";
    }
};
var rlhsCons = /* #__PURE__ */ GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsNil);
var maybeShape = /* #__PURE__ */ GraphQL_Shape.maybeShape(GraphQL_Shape.scalarShape);
var buildQuery = /* #__PURE__ */ GraphQL_Query.buildQuery(API_Search.toVarsSearchVars)(/* #__PURE__ */ API_Pagination.pageQR(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ GraphQL_Type.decRNTY(Data_Argonaut_Decode_Class.decodeJsonString)))(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldMaybe(Internal_Luxon.decodeLDT))(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonString))(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons(Data_Argonaut_Decode_Class.gDecodeJsonNil)(nameIsSymbol)()())(colorIsSymbol)()())())))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(tagsIsSymbol)()())(preferred_nameIsSymbol)()())(nameIsSymbol)()())(last_imported_atIsSymbol)()())(idIsSymbol)()())())(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(tagsIsSymbol)(/* #__PURE__ */ GraphQL_Shape.arrayShape(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(nameIsSymbol)(GraphQL_Shape.scalarShape)()())(colorIsSymbol)(GraphQL_Shape.scalarShape)()())()))()())(preferred_nameIsSymbol)(maybeShape)()())(nameIsSymbol)(GraphQL_Shape.scalarShape)()())(last_imported_atIsSymbol)(maybeShape)()())(idIsSymbol)(GraphQL_Shape.scalarShape)()())()));
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ GraphQL_Type.eqRNTY(Data_Eq.eqString));
var tagImported = function (v) {
    if (v.last_imported_at instanceof Data_Maybe.Nothing) {
        return v;
    };
    if (v.last_imported_at instanceof Data_Maybe.Just) {
        var tag = {
            name: Translate.lookupTranslation("tag-imported"),
            color: "rgb(241, 240, 242)"
        };
        return {
            id: v.id,
            name: v.name,
            preferred_name: v.preferred_name,
            tags: Data_Array.cons(tag)(v.tags),
            last_imported_at: v.last_imported_at
        };
    };
    throw new Error("Failed pattern match at API.SelStudent (line 57, column 1 - line 57, column 40): " + [ v.constructor.name ]);
};
var searchStudents = function (q) {
    return buildQuery("students")(API_Search.queryVars(q));
};
var isMe = function (account) {
    return function (id) {
        return Data_Maybe.maybe(false)(function (s) {
            return eq(s.id)(id);
        })(account.student);
    };
};
var studentName_ = function (account) {
    return function (v) {
        return function (simple) {
            return function (paren) {
                if (v.preferred_name instanceof Data_Maybe.Nothing) {
                    return simple(v.name);
                };
                if (v.preferred_name instanceof Data_Maybe.Just && v.preferred_name.value0 === "") {
                    return simple(v.name);
                };
                if (v.preferred_name instanceof Data_Maybe.Just && isMe(account)(v.id)) {
                    return simple(v.preferred_name.value0);
                };
                if (v.preferred_name instanceof Data_Maybe.Just) {
                    return paren({
                        legal: v.name,
                        preferred: v.preferred_name.value0
                    });
                };
                throw new Error("Failed pattern match at API.SelStudent (line 49, column 66 - line 53, column 58): " + [ v.preferred_name.constructor.name ]);
            };
        };
    };
};
var studentName = function (account) {
    return function (student) {
        return studentName_(account)(student)(function (name) {
            return name;
        })(function (v) {
            return v.legal + (" (" + (v.preferred + ")"));
        });
    };
};
export {
    isMe,
    studentName,
    studentName_,
    tagImported,
    searchStudents
};
