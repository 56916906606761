// Generated by purs version 0.15.7
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var loopInfinitely = function (w) {
    return bind(w)(function () {
        return loopInfinitely(w);
    });
};
export {
    loopInfinitely
};
