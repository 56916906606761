// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Date from "../Data.Date/index.js";
import * as Data_Date_Component from "../Data.Date.Component/index.js";
import * as Data_DateTime from "../Data.DateTime/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Time from "../Data.Time/index.js";
import * as Data_Time_Component from "../Data.Time.Component/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Class_Console from "../Effect.Class.Console/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Widgets_Form from "../Widgets.Form/index.js";
import * as Widgets_Form_Checkbox from "../Widgets.Form.Checkbox/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Date from "../Widgets.Form.Date/index.js";
import * as Widgets_Form_Dynamic from "../Widgets.Form.Dynamic/index.js";
import * as Widgets_Form_EmailInput from "../Widgets.Form.EmailInput/index.js";
import * as Widgets_Form_EmployerSelect from "../Widgets.Form.EmployerSelect/index.js";
import * as Widgets_Form_Expectations from "../Widgets.Form.Expectations/index.js";
import * as Widgets_Form_List from "../Widgets.Form.List/index.js";
import * as Widgets_Form_NumberInput from "../Widgets.Form.NumberInput/index.js";
import * as Widgets_Form_PhoneInput from "../Widgets.Form.PhoneInput/index.js";
import * as Widgets_Form_Radio from "../Widgets.Form.Radio/index.js";
import * as Widgets_Form_Select from "../Widgets.Form.Select/index.js";
import * as Widgets_Form_Simple from "../Widgets.Form.Simple/index.js";
import * as Widgets_Form_StudentSubForm from "../Widgets.Form.StudentSubForm/index.js";
import * as Widgets_Form_SubForm from "../Widgets.Form.SubForm/index.js";
import * as Widgets_Form_TextInput from "../Widgets.Form.TextInput/index.js";
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var toEnum = /* #__PURE__ */ Data_Enum.toEnum(Data_Date_Component.boundedEnumYear);
var toEnum1 = /* #__PURE__ */ Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth);
var toEnum2 = /* #__PURE__ */ Data_Enum.toEnum(Data_Date_Component.boundedEnumDay);
var toEnum3 = /* #__PURE__ */ Data_Enum.toEnum(Data_Time_Component.boundedEnumHour);
var toEnum4 = /* #__PURE__ */ Data_Enum.toEnum(Data_Time_Component.boundedEnumMinute);
var toEnum5 = /* #__PURE__ */ Data_Enum.toEnum(Data_Time_Component.boundedEnumSecond);
var toEnum6 = /* #__PURE__ */ Data_Enum.toEnum(Data_Time_Component.boundedEnumMillisecond);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var wrapFormWidget = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_Simple.showLabel)(Widgets_Form_Simple.labelFW);
var wrapFormWidget1 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_TextInput.showTextInput)(Widgets_Form_TextInput.textInputFW);
var wrapFormWidget2 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_Dynamic.dynWidgetShow)(Widgets_Form_Dynamic.dynFormWidget);
var wrapFormWidget3 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_TextInput.showTextArea)(Widgets_Form_TextInput.textAreaFW);
var wrapFormWidget4 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_List.showLW)(Widgets_Form_List.listFW);
var wrapFormWidget5 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_NumberInput.showNumberInput)(Widgets_Form_NumberInput.numberInputFW);
var wrapFormWidget6 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_Date.showDateInput)(Widgets_Form_Date.dateFW);
var wrapFormWidget7 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_Date.showDateTimeInput)(Widgets_Form_Date.dateTimeFW);
var wrapFormWidget8 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_Checkbox.showCheckbox)(Widgets_Form_Checkbox.checkboxFW);
var wrapFormWidget9 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_Radio.showRadio)(Widgets_Form_Radio.radioFW);
var wrapFormWidget10 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_SubForm.showSubForm)(Widgets_Form_SubForm.subFormFW);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.bindWidget);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var log = /* #__PURE__ */ Effect_Class_Console.log(Effect_Class.monadEffectEffect);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Concur_Core_Types.bindWidget);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var Next = /* #__PURE__ */ (function () {
    function Next(value0) {
        this.value0 = value0;
    };
    Next.create = function (value0) {
        return new Next(value0);
    };
    return Next;
})();
var Reset = /* #__PURE__ */ (function () {
    function Reset() {

    };
    Reset.value = new Reset();
    return Reset;
})();
var ShowShape = /* #__PURE__ */ (function () {
    function ShowShape() {

    };
    ShowShape.value = new ShowShape();
    return ShowShape;
})();
var TestLoad = /* #__PURE__ */ (function () {
    function TestLoad() {

    };
    TestLoad.value = new TestLoad();
    return TestLoad;
})();
var testDate = /* #__PURE__ */ fromJust(/* #__PURE__ */ bind(/* #__PURE__ */ toEnum(1984))(function (year) {
    return bind(toEnum1(1))(function (month) {
        return bind(toEnum2(1))(function (day) {
            return Data_Date.exactDate(year)(month)(day);
        });
    });
}));
var testDateTime = /* #__PURE__ */ fromJust(/* #__PURE__ */ bind(/* #__PURE__ */ toEnum3(8))(function (hour) {
    return bind(toEnum4(0))(function (minute) {
        return bind(toEnum5(0))(function (second) {
            return bind(toEnum6(0))(function (ms) {
                var time = new Data_Time.Time(hour, minute, second, ms);
                return pure(new Data_DateTime.DateTime(testDate, time));
            });
        });
    });
}));
var testForm = /* #__PURE__ */ (function () {
    return {
        id: GraphQL_Type.typedId("test"),
        label: "Test",
        fields: [ wrapFormWidget({
            id: "header",
            label: "Test:",
            cls: "text-lg"
        }), Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_EmployerSelect.showESelect)(Widgets_Form_EmployerSelect.employerSelectFW)(Widgets_Form_EmployerSelect.initEmployerSelect({
            id: "employer-select",
            cls: "",
            label: "Employer select",
            contact: true
        })), Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_StudentSubForm.showStudentSubForm)(Widgets_Form_StudentSubForm.stsfFW)(new Widgets_Form_StudentSubForm.StudentSubForm({
            id: "student-subform",
            label: new Data_Maybe.Just("<b>Student Information</b>"),
            labelClass: "",
            cls: "",
            section: true,
            collapsible: true,
            expanded: true,
            fields: [ wrapFormWidget1({
                id: "name",
                label: "Student Name",
                cls: "",
                placeholder: Data_Maybe.Nothing.value,
                value: ""
            }) ]
        })), wrapFormWidget({
            id: "misc",
            label: "<h1>Misc</h1>",
            cls: ""
        }), wrapFormWidget1({
            id: "text",
            label: "<b>Test textbox</b>",
            cls: "",
            placeholder: new Data_Maybe.Just("Placeholder Test"),
            value: "foo"
        }), wrapFormWidget1({
            id: "empty-text",
            label: "Empty textbox",
            cls: "",
            placeholder: new Data_Maybe.Just("Empty"),
            value: ""
        }), wrapFormWidget2(Widgets_Form_Expectations.courseExpectationsWidget({
            id: "subform",
            label: new Data_Maybe.Just("Collapsible sub-form test"),
            labelClass: "",
            section: true,
            collapsible: true,
            expanded: true,
            cls: "",
            fields: [ wrapFormWidget3({
                id: "expectation",
                label: "Expectation",
                cls: "",
                placeholder: new Data_Maybe.Just("Placeholder Test"),
                value: ""
            }), wrapFormWidget3({
                id: "learning",
                label: "Learning",
                cls: "",
                placeholder: new Data_Maybe.Just("Placeholder Test"),
                value: ""
            }) ]
        })({
            id: "course-expectations",
            cls: "",
            label: "Course Expectations"
        })), wrapFormWidget2(Widgets_Form_Expectations.employerExpectationsWidget({
            id: "subform",
            label: new Data_Maybe.Just("Collapsible sub-form test"),
            labelClass: "",
            section: true,
            collapsible: true,
            expanded: true,
            cls: "",
            fields: [ wrapFormWidget3({
                id: "expectation",
                label: "Expectation",
                cls: "",
                placeholder: new Data_Maybe.Just("Placeholder Test"),
                value: ""
            }), wrapFormWidget3({
                id: "learning",
                label: "Learning",
                cls: "",
                placeholder: new Data_Maybe.Just("Placeholder Test"),
                value: ""
            }) ]
        })({
            id: "employer-expectations",
            cls: "",
            label: "Employer Expectations"
        })), wrapFormWidget4(Widgets_Form_List.simpleListWidget({
            id: "list",
            label: "List of Textboxes",
            cls: "",
            template: wrapFormWidget1({
                id: "list-text",
                label: "Empty textbox",
                cls: "",
                placeholder: new Data_Maybe.Just("Empty"),
                value: ""
            })
        })), wrapFormWidget3({
            id: "textarea",
            label: "Test text area",
            cls: "",
            placeholder: new Data_Maybe.Just("Test"),
            value: "testing"
        }), Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_PhoneInput.showPhoneInput)(Widgets_Form_PhoneInput.phoneInputFW)({
            id: "phone",
            label: "Test phone input",
            cls: "",
            placeholder: new Data_Maybe.Just("Phone number"),
            value: "555-555-5555"
        }), Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_EmailInput.showEmailInput)(Widgets_Form_EmailInput.emailInputFW)({
            id: "email",
            label: "Test email input",
            cls: "",
            placeholder: new Data_Maybe.Just("Email"),
            value: "email@example.com"
        }), wrapFormWidget5({
            id: "int",
            label: "<b>Test int input</b>",
            cls: "",
            placeholder: new Data_Maybe.Just("Integer"),
            step: 1.0,
            value: new Data_Maybe.Just(42.0)
        }), wrapFormWidget5({
            id: "nint",
            label: "Null int input",
            cls: "",
            placeholder: new Data_Maybe.Just("Integer"),
            step: 1.0,
            value: Data_Maybe.Nothing.value
        }), wrapFormWidget5({
            id: "float",
            label: "Test float input",
            cls: "",
            placeholder: new Data_Maybe.Just("Decimal"),
            step: 0.1,
            value: new Data_Maybe.Just(3.14)
        }), wrapFormWidget6({
            id: "date",
            label: "Test date input",
            cls: "",
            value: new Data_Maybe.Just(testDate)
        }), wrapFormWidget6({
            id: "null-date",
            label: "Test date input",
            cls: "",
            value: Data_Maybe.Nothing.value
        }), wrapFormWidget7({
            id: "datetime",
            label: "Test datetime input",
            cls: "",
            value: Data_Maybe.Nothing.value
        }), wrapFormWidget7({
            id: "test_datetime",
            label: "Test datetime input",
            cls: "",
            value: Data_Maybe.Nothing.value
        }), wrapFormWidget8({
            id: "check",
            label: "<b>Test checkbox</b>",
            cls: "",
            legacy: false,
            value: false
        }), wrapFormWidget9({
            id: "radio",
            label: "Test radio",
            cls: "",
            options: [ {
                value: "1",
                label: "One"
            }, {
                value: "2",
                label: "Two"
            } ],
            value: new Data_Maybe.Just("1")
        }), wrapFormWidget9({
            id: "radio-null",
            label: "Test radio with nothing selected",
            cls: "",
            options: [ {
                value: "1",
                label: "One"
            }, {
                value: "2",
                label: "Two"
            } ],
            value: Data_Maybe.Nothing.value
        }), Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_Select.showSelect)(Widgets_Form_Select.selectFW)(Widgets_Form_Select.initSelectWidget({
            id: "select",
            label: new Data_Maybe.Just("Test select"),
            cls: "",
            placeholder: new Data_Maybe.Just("Placeholder"),
            options: [ {
                value: Data_Argonaut_Core.fromString("1"),
                label: "Testing a very long label my goodness this is huge totally massive and worst of all no punctuation where we are going we dont need punctuation"
            }, {
                value: Data_Argonaut_Core.fromString("2"),
                label: "Two"
            } ],
            value: new Data_Maybe.Just(Data_Argonaut_Core.fromString("1"))
        })), wrapFormWidget({
            id: "label",
            label: "This is a label.",
            cls: ""
        }), wrapFormWidget({
            id: "label-html",
            label: "This is a label <marquee>with HTML</marquee>.",
            cls: ""
        }), wrapFormWidget({
            id: "label-brokenhtml",
            label: "This HTML is <b>missing a closing tag",
            cls: ""
        }), Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_Simple.showFormError)(Widgets_Form_Simple.formErrFW)({
            id: "test-error",
            err: new $$Error.StringError("This is a test of the erorr system.")
        }), wrapFormWidget10({
            id: "subform",
            label: new Data_Maybe.Just("Collapsible sub-form test"),
            labelClass: "",
            section: true,
            collapsible: true,
            expanded: true,
            cls: "",
            fields: [ wrapFormWidget1({
                id: "subtext",
                label: "Test textbox",
                cls: "",
                placeholder: new Data_Maybe.Just("Placeholder Test"),
                value: ""
            }), wrapFormWidget8({
                id: "subcheck",
                label: "Test checkbox",
                cls: "",
                legacy: false,
                value: false
            }) ]
        }), wrapFormWidget10({
            id: "nocollapse-subform",
            label: new Data_Maybe.Just("Non-collapsible sub-form test"),
            labelClass: "",
            section: true,
            collapsible: false,
            expanded: true,
            cls: "",
            fields: [ wrapFormWidget1({
                id: "subtext",
                label: "Test textbox",
                cls: "",
                placeholder: new Data_Maybe.Just("Placeholder Test"),
                value: ""
            }), wrapFormWidget8({
                id: "subcheck",
                label: "Test checkbox",
                cls: "",
                legacy: false,
                value: false
            }) ]
        }), wrapFormWidget10({
            id: "nolabel-subform",
            label: Data_Maybe.Nothing.value,
            labelClass: "",
            section: true,
            collapsible: false,
            expanded: true,
            cls: "",
            fields: [ wrapFormWidget1({
                id: "subtext",
                label: "Test textbox in subform with no label",
                cls: "",
                placeholder: new Data_Maybe.Just("Placeholder Test"),
                value: ""
            }) ]
        }), wrapFormWidget4(Widgets_Form_List.simpleListWidget({
            id: "subform-list",
            label: "List of SubForms",
            cls: "",
            template: wrapFormWidget10({
                id: "list-subform",
                label: new Data_Maybe.Just("List subform test"),
                labelClass: "",
                section: true,
                collapsible: true,
                expanded: false,
                cls: "",
                fields: [ wrapFormWidget1({
                    id: "subtext",
                    label: "Test textbox",
                    cls: "",
                    placeholder: new Data_Maybe.Just("Placeholder Test"),
                    value: ""
                }), wrapFormWidget8({
                    id: "subcheck",
                    label: "Test checkbox",
                    cls: "",
                    legacy: false,
                    value: false
                }) ]
            })
        })) ]
    };
})();
var renderTestForm = /* #__PURE__ */ (function () {
    var initData = Widgets_Form.formData(testForm);
    var run = function (v) {
        return discard(liftEffect(log(Data_Argonaut_Core.stringify(Widgets_Form.formData(v.formState)))))(function () {
            return bindFlipped(handle(v))(div$prime([ button([ Concur_React_Props.className("btn"), voidRight(Reset.value)(Concur_React_Props.onClick) ])([ text("Reset") ]), button([ Concur_React_Props.className("btn"), voidRight(ShowShape.value)(Concur_React_Props.onClick) ])([ text("Print Query Shape") ]), button([ Concur_React_Props.className("btn"), voidRight(TestLoad.value)(Concur_React_Props.onClick) ])([ text("Simulate Load") ]), map(Next.create)(Widgets_Form.formWidget(Widgets_Form_Class.blankFormContext)(v.formState)) ]));
        });
    };
    var handle = function (v) {
        return function (v1) {
            if (v1 instanceof Next) {
                return run({
                    formState: v1.value0.value0.state
                });
            };
            if (v1 instanceof ShowShape) {
                return discard(liftEffect(log(GraphQL_Shape.shapeToString(Widgets_Form.formShape(v.formState)))))(function () {
                    return run(v);
                });
            };
            if (v1 instanceof Reset) {
                var v2 = Widgets_Form.setFormData(Widgets_Form_Class.blankFormContext)(initData)(v.formState);
                if (v2 instanceof Data_Either.Right) {
                    return run({
                        formState: v2.value0
                    });
                };
                if (v2 instanceof Data_Either.Left) {
                    return $$Error.renderError_(v2.value0);
                };
                throw new Error("Failed pattern match at Widgets.Form.TestForm (line 399, column 9 - line 401, column 42): " + [ v2.constructor.name ]);
            };
            if (v1 instanceof TestLoad) {
                var fdata = Widgets_Form.formData(v.formState);
                return discard(liftEffect(log(fdata)))(function () {
                    var v2 = Widgets_Form.setFormData(Widgets_Form_Class.blankFormContext)(fdata)(testForm);
                    if (v2 instanceof Data_Either.Right) {
                        return run({
                            formState: v2.value0
                        });
                    };
                    if (v2 instanceof Data_Either.Left) {
                        return $$Error.renderError_(v2.value0);
                    };
                    throw new Error("Failed pattern match at Widgets.Form.TestForm (line 406, column 9 - line 408, column 41): " + [ v2.constructor.name ]);
                });
            };
            throw new Error("Failed pattern match at Widgets.Form.TestForm (line 394, column 5 - line 394, column 105): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
    return run({
        formState: testForm
    });
})();
export {
    testDate,
    testDateTime,
    testForm,
    Next,
    Reset,
    ShowShape,
    TestLoad,
    renderTestForm
};
