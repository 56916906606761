// Generated by purs version 0.15.7
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Translate from "../Translate/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var rlhsCons = /* #__PURE__ */ GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsNil);
var splitSignature = function (v) {
    return new Data_Tuple.Tuple(v.signature, {
        first_name: v.first_name,
        last_name: v.last_name,
        email: v.email
    });
};
var signatureFieldFields = /* #__PURE__ */ (function () {
    return GraphQL_Shape.shapeToStringInner(GraphQL_Shape.shapeOf(GraphQL_Shape.recShape(GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsCons(rlhsCons({
        reflectSymbol: function () {
            return "relationship_label";
        }
    })(GraphQL_Shape.scalarShape)()())({
        reflectSymbol: function () {
            return "relationship";
        }
    })(GraphQL_Shape.scalarShape)()())({
        reflectSymbol: function () {
            return "label";
        }
    })(GraphQL_Shape.scalarShape)()())({
        reflectSymbol: function () {
            return "key";
        }
    })(GraphQL_Shape.scalarShape)()())({
        reflectSymbol: function () {
            return "emailLabel";
        }
    })(GraphQL_Shape.scalarShape)()())({
        reflectSymbol: function () {
            return "can";
        }
    })(GraphQL_Shape.recShape(GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsCons(rlhsCons({
        reflectSymbol: function () {
            return "useDefault";
        }
    })(GraphQL_Shape.scalarShape)()())({
        reflectSymbol: function () {
            return "saveDefault";
        }
    })(GraphQL_Shape.scalarShape)()())({
        reflectSymbol: function () {
            return "request";
        }
    })(GraphQL_Shape.scalarShape)()())({
        reflectSymbol: function () {
            return "create";
        }
    })(GraphQL_Shape.scalarShape)()())())()())())(Type_Proxy["Proxy"].value));
})();
var sigRelName = function (v) {
    if (v.value0 === "STUDENT_SIGNATURE") {
        return Translate.lookupTranslation("rel-student");
    };
    if (v.value0 === "TEACHER_SIGNATURE") {
        return Translate.lookupTranslation("rel-teacher");
    };
    if (v.value0 === "EMPLOYER_SIGNATURE") {
        return Translate.lookupTranslation("rel-employer");
    };
    if (v.value0 === "PARENT_SIGNATURE") {
        return Translate.lookupTranslation("rel-parent");
    };
    if (v.value0 === "PRINCIPAL_SIGNATURE") {
        return Translate.lookupTranslation("rel-principal");
    };
    if (v.value0 === "COORDINATOR_SIGNATURE") {
        return Translate.lookupTranslation("rel-coordinator");
    };
    return "";
};
var isSigned = function (v) {
    if (v.signature instanceof Data_Maybe.Nothing) {
        return false;
    };
    return true;
};
var emptySignature = /* #__PURE__ */ (function () {
    return {
        first_name: "",
        last_name: "",
        email: "",
        signature: Data_Maybe.Nothing.value
    };
})();
var emptySigInfo = {
    first_name: "",
    last_name: "",
    email: ""
};
var employerRel = /* #__PURE__ */ (function () {
    return new GraphQL_Type.RenamedType("EMPLOYER_SIGNATURE");
})();
var addSignature = function (v) {
    return function (signature) {
        return {
            first_name: v.first_name,
            last_name: v.last_name,
            email: v.email,
            signature: signature
        };
    };
};
var fromPartSig = function (v) {
    if (v.signature instanceof Data_Maybe.Nothing) {
        return Data_Maybe.Nothing.value;
    };
    if (v.signature instanceof Data_Maybe.Just) {
        return new Data_Maybe.Just(addSignature(v)(v.signature.value0));
    };
    throw new Error("Failed pattern match at Widgets.Signature.Types (line 99, column 1 - line 99, column 55): " + [ v.constructor.name ]);
};
var toPartSig = function (v) {
    return addSignature(v)(new Data_Maybe.Just(v.signature));
};
export {
    signatureFieldFields,
    sigRelName,
    employerRel,
    emptySignature,
    emptySigInfo,
    isSigned,
    addSignature,
    splitSignature,
    fromPartSig,
    toPartSig
};
