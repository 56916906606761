// Generated by purs version 0.15.7
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad from "../Control.Monad/index.js";
import * as Control_Monad_Rec_Class from "../Control.Monad.Rec.Class/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_AVar from "../Effect.AVar/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_AVar from "../Effect.Aff.AVar/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var discard1 = /* #__PURE__ */ discard(Effect_Aff.bindAff);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(Data_List_Types.monoidList);
var $$void = /* #__PURE__ */ Data_Functor["void"](Effect_Aff.functorAff);
var forever = /* #__PURE__ */ Control_Monad_Rec_Class.forever(Effect_Aff.monadRecAff);
var sequence_ = /* #__PURE__ */ Data_Foldable.sequence_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList);
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_List_Types.foldableList);
var unlessM = /* #__PURE__ */ Control_Monad.unlessM(Effect_Aff.monadAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var traverse_ = /* #__PURE__ */ Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList);
var Bus = /* #__PURE__ */ (function () {
    function Bus(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Bus.create = function (value0) {
        return function (value1) {
            return new Bus(value0, value1);
        };
    };
    return Bus;
})();
var write = function (a) {
    return function (v) {
        return Effect_Aff_AVar.put(a)(v.value0);
    };
};
var split = function (v) {
    return new Data_Tuple.Tuple(new Bus(v.value0, v.value1), new Bus(v.value0, v.value1));
};
var read = function (v) {
    return bind(Effect_Aff_AVar.empty)(function (res$prime) {
        return bind(Effect_Aff_AVar.take(v.value1))(function (cs) {
            return discard1(Effect_Aff_AVar.put(new Data_List_Types.Cons(res$prime, cs))(v.value1))(function () {
                return Effect_Aff_AVar.take(res$prime);
            });
        });
    });
};
var make = function (dictMonadEffect) {
    return Effect_Class.liftEffect(dictMonadEffect)(function __do() {
        var cell = Effect_AVar.empty();
        var consumers = Effect_AVar["new"](mempty)();
        Effect_Aff.launchAff_($$void(Effect_Aff.attempt(forever(bind(Effect_Aff_AVar.take(cell))(function (res) {
            return bind(Effect_Aff_AVar.take(consumers))(function (vars) {
                return discard1(Effect_Aff_AVar.put(Data_List_Types.Nil.value)(consumers))(function () {
                    return sequence_(foldl(function (xs) {
                        return function (a) {
                            return new Data_List_Types.Cons(Effect_Aff_AVar.put(res)(a), xs);
                        };
                    })(mempty)(vars));
                });
            });
        })))))();
        return new Bus(cell, consumers);
    });
};
var kill = function (err) {
    return function (v) {
        return unlessM(liftEffect(map(Effect_AVar.isKilled)(Effect_AVar.status(v.value0))))(discard1(Effect_Aff_AVar.kill(err)(v.value0))(function () {
            return bind(Effect_Aff_AVar.take(v.value1))(function (vars) {
                return discard1(traverse_(Effect_Aff_AVar.kill(err))(vars))(function () {
                    return Effect_Aff_AVar.kill(err)(v.value1);
                });
            });
        }));
    };
};
var isKilled = function (dictMonadEffect) {
    var liftEffect1 = Effect_Class.liftEffect(dictMonadEffect);
    return function (v) {
        return liftEffect1(map(Effect_AVar.isKilled)(Effect_AVar.status(v.value0)));
    };
};
export {
    make,
    read,
    write,
    split,
    kill,
    isKilled
};
