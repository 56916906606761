// Generated by purs version 0.15.7
import * as API_Pagination from "../API.Pagination/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_Luxon from "../Internal.Luxon/index.js";
import * as Widgets_BetterSelect from "../Widgets.BetterSelect/index.js";
var map = /* #__PURE__ */ Data_Functor.map(GraphQL_Query.queryFunctor);
var rltvCons = /* #__PURE__ */ GraphQL_Query.rltvCons(GraphQL_Query.rltvNil);
var student_idIsSymbol = {
    reflectSymbol: function () {
        return "student_id";
    }
};
var rntyType = /* #__PURE__ */ GraphQL_Type.rntyType({
    reflectSymbol: function () {
        return "ID";
    }
});
var encRNTY = /* #__PURE__ */ GraphQL_Type.encRNTY(Data_Argonaut_Encode_Class.encodeJsonJString);
var rltvCons1 = /* #__PURE__ */ GraphQL_Query.rltvCons(/* #__PURE__ */ rltvCons(student_idIsSymbol)(rntyType)(encRNTY)()());
var idIsSymbol = {
    reflectSymbol: function () {
        return "id";
    }
};
var form_idIsSymbol = {
    reflectSymbol: function () {
        return "form_id";
    }
};
var rltvfConsOther = /* #__PURE__ */ GraphQL_Query.rltvfConsOther(GraphQL_Query.rltvfNil);
var rltvfConsOther1 = /* #__PURE__ */ GraphQL_Query.rltvfConsOther(/* #__PURE__ */ rltvfConsOther(student_idIsSymbol)());
var buildQuery = /* #__PURE__ */ GraphQL_Query.buildQuery(/* #__PURE__ */ GraphQL_Query.rlTVars(/* #__PURE__ */ GraphQL_Query.rltvCons(/* #__PURE__ */ rltvCons1(idIsSymbol)(rntyType)(encRNTY)()())(form_idIsSymbol)(rntyType)(encRNTY)()())(/* #__PURE__ */ GraphQL_Query.rltvfConsOther(/* #__PURE__ */ rltvfConsOther1(idIsSymbol)())(form_idIsSymbol)())());
var gDecodeJsonCons = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ GraphQL_Type.decRNTY(Data_Argonaut_Decode_Class.decodeJsonString)));
var gDecodeJsonCons1 = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString));
var gDecodeJsonCons2 = /* #__PURE__ */ gDecodeJsonCons1(Data_Argonaut_Decode_Class.gDecodeJsonNil);
var labelIsSymbol = {
    reflectSymbol: function () {
        return "label";
    }
};
var positionsIsSymbol = {
    reflectSymbol: function () {
        return "positions";
    }
};
var gDecodeJsonCons3 = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons2(labelIsSymbol)()())(idIsSymbol)()())())))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(positionsIsSymbol)()();
var rlhsCons = /* #__PURE__ */ GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsNil);
var rlhsCons1 = /* #__PURE__ */ rlhsCons(positionsIsSymbol)(/* #__PURE__ */ GraphQL_Shape.arrayShape(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(labelIsSymbol)(GraphQL_Shape.scalarShape)()())(idIsSymbol)(GraphQL_Shape.scalarShape)()())()))()();
var buildQuery1 = /* #__PURE__ */ buildQuery(/* #__PURE__ */ GraphQL_Query.jsonQR(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(gDecodeJsonCons3)())(/* #__PURE__ */ GraphQL_Shape.recShape(rlhsCons1)()));
var searchIsSymbol = {
    reflectSymbol: function () {
        return "search";
    }
};
var buildQuery2 = /* #__PURE__ */ GraphQL_Query.buildQuery(/* #__PURE__ */ GraphQL_Query.rlTVars(/* #__PURE__ */ GraphQL_Query.rltvCons(/* #__PURE__ */ rltvCons1(searchIsSymbol)(GraphQL_Type.hqtStr)(Data_Argonaut_Encode_Class.encodeJsonJString)()())(form_idIsSymbol)(rntyType)(encRNTY)()())(/* #__PURE__ */ GraphQL_Query.rltvfConsOther(/* #__PURE__ */ rltvfConsOther1(searchIsSymbol)())(form_idIsSymbol)())());
var companyIsSymbol = {
    reflectSymbol: function () {
        return "company";
    }
};
var buildQuery3 = /* #__PURE__ */ buildQuery2(/* #__PURE__ */ API_Pagination.unpagedQR(/* #__PURE__ */ GraphQL_Query.arrayQR(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons1(/* #__PURE__ */ gDecodeJsonCons(Data_Argonaut_Decode_Class.gDecodeJsonNil)(idIsSymbol)()())(companyIsSymbol)()())()))(/* #__PURE__ */ GraphQL_Shape.arrayShape(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(idIsSymbol)(GraphQL_Shape.scalarShape)()())(companyIsSymbol)(GraphQL_Shape.scalarShape)()())()))));
var gDecodeJsonCons4 = /* #__PURE__ */ gDecodeJsonCons(gDecodeJsonCons3)(idIsSymbol)()();
var rlhsCons2 = /* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(rlhsCons1)(idIsSymbol)(GraphQL_Shape.scalarShape)()());
var buildQuery4 = /* #__PURE__ */ GraphQL_Query.buildQuery(/* #__PURE__ */ GraphQL_Query.rlTVars(/* #__PURE__ */ rltvCons(searchIsSymbol)(GraphQL_Type.hqtStr)(Data_Argonaut_Encode_Class.encodeJsonJString)()())(/* #__PURE__ */ rltvfConsOther(searchIsSymbol)())())(/* #__PURE__ */ API_Pagination.unpagedQR(/* #__PURE__ */ GraphQL_Query.arrayQR(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons1(gDecodeJsonCons4)(companyIsSymbol)()())()))(/* #__PURE__ */ GraphQL_Shape.arrayShape(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ rlhsCons2(companyIsSymbol)(GraphQL_Shape.scalarShape)()())()))));
var nameIsSymbol = {
    reflectSymbol: function () {
        return "name";
    }
};
var gDecodeJsonCons5 = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons2(nameIsSymbol)()())(idIsSymbol)()())())));
var deleted_atIsSymbol = {
    reflectSymbol: function () {
        return "deleted_at";
    }
};
var contactsIsSymbol = {
    reflectSymbol: function () {
        return "contacts";
    }
};
var arrayShape = /* #__PURE__ */ GraphQL_Shape.arrayShape(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(nameIsSymbol)(GraphQL_Shape.scalarShape)()())(idIsSymbol)(GraphQL_Shape.scalarShape)()())());
var buildQuery5 = /* #__PURE__ */ buildQuery2(/* #__PURE__ */ API_Pagination.unpagedQR(/* #__PURE__ */ GraphQL_Query.arrayQR(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons1(/* #__PURE__ */ gDecodeJsonCons5(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldMaybe(Internal_Luxon.decodeLDT))(gDecodeJsonCons4)(deleted_atIsSymbol)()())(contactsIsSymbol)()())(companyIsSymbol)()())()))(/* #__PURE__ */ GraphQL_Shape.arrayShape(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons2(deleted_atIsSymbol)(/* #__PURE__ */ GraphQL_Shape.maybeShape(GraphQL_Shape.scalarShape))()())(contactsIsSymbol)(arrayShape)()())(companyIsSymbol)(GraphQL_Shape.scalarShape)()())()))));
var buildQuery6 = /* #__PURE__ */ buildQuery(/* #__PURE__ */ GraphQL_Query.jsonQR(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons5(Data_Argonaut_Decode_Class.gDecodeJsonNil)(contactsIsSymbol)()())())(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ rlhsCons(contactsIsSymbol)(arrayShape)()())()));
var positionList = function (form_id) {
    return function (student_id) {
        return function (employer) {
            var process = function (v) {
                return v.positions;
            };
            return GraphQL_Query.nameQuery("EmployerPositions")(map(process)(buildQuery1("employer")({
                form_id: form_id,
                student_id: student_id,
                id: employer
            })));
        };
    };
};
var positionSelAff = function (form) {
    return function (student) {
        return function (employer) {
            return Widgets_BetterSelect.simpleFrontendSearch(function (v) {
                return v.label;
            })(Widgets_BetterSelect.wrapSelectOption(function (v) {
                return v.label;
            })(positionList(form)(student)(employer)));
        };
    };
};
var positionFields = "id, label";
var getEmployer = function (dictQueryResult) {
    var buildQuery7 = buildQuery(dictQueryResult);
    return function (id) {
        return function (form_id) {
            return function (student_id) {
                return GraphQL_Query.nameQuery("GetEmployer")(buildQuery7("employer")({
                    id: id,
                    student_id: student_id,
                    form_id: form_id
                }));
            };
        };
    };
};
var employerSearch = function (form_id) {
    return function (student_id) {
        return function (search) {
            return GraphQL_Query.nameQuery("EmployerSearch")(API_Pagination.unpaged(buildQuery3("employers")({
                form_id: form_id,
                student_id: student_id,
                search: search
            })));
        };
    };
};
var employerSearchSelAff = function (form) {
    return function (student) {
        return new Widgets_BetterSelect.BackendSearch(function (search) {
            return Widgets_BetterSelect.wrapSelectOption(function (v) {
                return v.company;
            })(employerSearch(form)(student)(search));
        });
    };
};
var employerPosSearch = function (search) {
    return GraphQL_Query.nameQuery("EmployerPositionsSearch")(API_Pagination.unpaged(buildQuery4("employers")({
        search: search
    })));
};
var employerPosSearchSelAff = /* #__PURE__ */ (function () {
    return new Widgets_BetterSelect.BackendSearch(function (search) {
        return Widgets_BetterSelect.wrapSelectOption(function (v) {
            return v.company;
        })(employerPosSearch(search));
    });
})();
var employerPosConSearch = function (form_id) {
    return function (student_id) {
        return function (search) {
            return GraphQL_Query.nameQuery("EmployerPositionsContacts")(API_Pagination.unpaged(buildQuery5("employers")({
                form_id: form_id,
                student_id: student_id,
                search: search
            })));
        };
    };
};
var employerPosConSearchSelAff = function (fid) {
    return function (sid) {
        return new Widgets_BetterSelect.BackendSearch(function (search) {
            return Widgets_BetterSelect.wrapSelectOption(function (v) {
                return v.company;
            })(employerPosConSearch(fid)(sid)(search));
        });
    };
};
var employerFields = "id, company";
var contactList = function (form_id) {
    return function (student_id) {
        return function (employer) {
            var process = function (v) {
                return v.contacts;
            };
            return GraphQL_Query.nameQuery("EmployerContacts")(map(process)(buildQuery6("employer")({
                form_id: form_id,
                student_id: student_id,
                id: employer
            })));
        };
    };
};
var contactSelAff = function (form) {
    return function (student) {
        return function (employer) {
            return Widgets_BetterSelect.simpleFrontendSearch(function (v) {
                return v.label;
            })(Widgets_BetterSelect.wrapSelectOption(function (v) {
                return v.name;
            })(contactList(form)(student)(employer)));
        };
    };
};
var contactFields = "id, name";
export {
    employerFields,
    positionFields,
    contactFields,
    employerSearch,
    employerSearchSelAff,
    employerPosSearchSelAff,
    employerPosConSearchSelAff,
    positionSelAff,
    contactSelAff,
    getEmployer
};
