// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_Except from "../Control.Monad.Except/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Exception from "../Effect.Exception/index.js";
import * as Foreign from "../Foreign/index.js";
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Effect.functorEffect);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(Effect_Aff.monoidCanceler);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity));
var unsafeReadTagged = /* #__PURE__ */ Foreign.unsafeReadTagged(Data_Identity.monadIdentity);
var map = /* #__PURE__ */ Data_Functor.map(/* #__PURE__ */ Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity));
var readString = /* #__PURE__ */ Foreign.readString(Data_Identity.monadIdentity);
var bind = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var toAff$prime = function (customCoerce) {
    return function (p) {
        return Effect_Aff.makeAff(function (cb) {
            return voidRight(mempty)($foreign.thenImpl(p)(function ($14) {
                return cb(Data_Either.Left.create(customCoerce($14)))();
            })(function ($15) {
                return cb(Data_Either.Right.create($15))();
            }));
        });
    };
};
var fromAff = function (aff) {
    return $foreign.promise(function (succ) {
        return function (err) {
            return Effect_Aff.runAff_(Data_Either.either(err)(succ))(aff);
        };
    });
};
var coerce = function (fn) {
    return Data_Either.either(function (v) {
        return Effect_Exception.error("Promise failed, couldn't extract JS Error or String");
    })(identity)(Control_Monad_Except.runExcept(alt(unsafeReadTagged("Error")(fn))(map(Effect_Exception.error)(readString(fn)))));
};
var toAff = /* #__PURE__ */ toAff$prime(coerce);
var toAffE = function (f) {
    return bind(liftEffect(f))(toAff);
};
export {
    fromAff,
    toAff,
    toAff$prime,
    toAffE
};
