// Generated by purs version 0.15.7
import * as Concur_React_Run from "../Concur.React.Run/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Logger_Class from "../Control.Monad.Logger.Class/index.js";
import * as Control_Monad_Logger_Trans from "../Control.Monad.Logger.Trans/index.js";
import * as Data_Log_Formatter_JSON from "../Data.Log.Formatter.JSON/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL from "../GraphQL/index.js";
import * as Steps from "../Steps/index.js";
import * as Steps_Url from "../Steps.Url/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var discard2 = /* #__PURE__ */ discard(/* #__PURE__ */ Control_Monad_Logger_Trans.bindLoggerT(Effect.monadEffect));
var debug = /* #__PURE__ */ Control_Monad_Logger_Class.debug(/* #__PURE__ */ Control_Monad_Logger_Trans.monadLoggerLoggerT(Effect_Class.monadEffectEffect));
var composeKleisliFlipped = /* #__PURE__ */ Control_Bind.composeKleisliFlipped(Effect.bindEffect);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Class.monadEffectEffect);
var main = function __do() {
    Effect_Console.log("Application Started")();
    var envLogger = discard2(debug(Data_Map_Internal.empty)("GraphQL endpoint: " + GraphQL.graphqlEndpoint))(function () {
        return discard2(debug(Data_Map_Internal.empty)("Sentry is " + (function () {
            if ($$Error.sentryFlag) {
                return "enabled.";
            };
            return "disabled.";
        })()))(function () {
            return debug(Data_Map_Internal.empty)("Url prefix is '" + (Steps_Url.hrUrlPrefix + "'"));
        });
    });
    Control_Monad_Logger_Trans.runLoggerT(envLogger)(composeKleisliFlipped(Effect_Console.log)(function ($14) {
        return pure(Data_Log_Formatter_JSON.jsonFormatter($14));
    }))();
    liftEffect((function () {
        if ($$Error.sentryFlag) {
            return $$Error.initSentry;
        };
        return pure(Data_Unit.unit);
    })())();
    return Concur_React_Run.runWidgetInDom("forms-spa")(Steps.stepWidget)();
};
export {
    main
};
