// Generated by purs version 0.15.7
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Cont_Trans from "../Control.Monad.Cont.Trans/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Monad_List_Trans from "../Control.Monad.List.Trans/index.js";
import * as Control_Monad_Maybe_Trans from "../Control.Monad.Maybe.Trans/index.js";
import * as Control_Monad_RWS_Trans from "../Control.Monad.RWS.Trans/index.js";
import * as Control_Monad_Reader_Trans from "../Control.Monad.Reader.Trans/index.js";
import * as Control_Monad_State_Trans from "../Control.Monad.State.Trans/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Control_Monad_Writer_Trans from "../Control.Monad.Writer.Trans/index.js";
import * as Data_JSDate from "../Data.JSDate/index.js";
import * as Data_Log_Level from "../Data.Log.Level/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Cont_Trans.monadTransContT);
var lift1 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT);
var lift2 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_List_Trans.monadTransListT);
var lift3 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Maybe_Trans.monadTransMaybeT);
var lift4 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT);
var lift5 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_State_Trans.monadTransStateT);
var log = function (dict) {
    return dict.log;
};
var log$prime = function (dictMonadLogger) {
    var MonadEffect0 = dictMonadLogger.MonadEffect0();
    var bind = Control_Bind.bind((MonadEffect0.Monad0()).Bind1());
    var liftEffect = Effect_Class.liftEffect(MonadEffect0);
    var log1 = log(dictMonadLogger);
    return function (level) {
        return function (tags) {
            return function (message) {
                return bind(liftEffect(Data_JSDate.now))(function ($92) {
                    return log1((function (v) {
                        return {
                            level: level,
                            message: message,
                            tags: tags,
                            timestamp: v
                        };
                    })($92));
                });
            };
        };
    };
};
var trace = function (dictMonadLogger) {
    return log$prime(dictMonadLogger)(Data_Log_Level.Trace.value);
};
var trace$prime = function (dictMonadLogger) {
    return trace(dictMonadLogger)(Data_Map_Internal.empty);
};
var warn = function (dictMonadLogger) {
    return log$prime(dictMonadLogger)(Data_Log_Level.Warn.value);
};
var warn$prime = function (dictMonadLogger) {
    return warn(dictMonadLogger)(Data_Map_Internal.empty);
};
var monadLoggerContT = function (dictMonadLogger) {
    var MonadEffect0 = dictMonadLogger.MonadEffect0();
    var monadEffectContT = Control_Monad_Cont_Trans.monadEffectContT(MonadEffect0);
    return {
        log: (function () {
            var $93 = lift(MonadEffect0.Monad0());
            var $94 = log(dictMonadLogger);
            return function ($95) {
                return $93($94($95));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectContT;
        }
    };
};
var monadLoggerExceptT = function (dictMonadLogger) {
    var MonadEffect0 = dictMonadLogger.MonadEffect0();
    var monadEffectExceptT = Control_Monad_Except_Trans.monadEffectExceptT(MonadEffect0);
    return {
        log: (function () {
            var $96 = lift1(MonadEffect0.Monad0());
            var $97 = log(dictMonadLogger);
            return function ($98) {
                return $96($97($98));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectExceptT;
        }
    };
};
var monadLoggerListT = function (dictMonadLogger) {
    var MonadEffect0 = dictMonadLogger.MonadEffect0();
    var monadEffectListT = Control_Monad_List_Trans.monadEffectListT(MonadEffect0);
    return {
        log: (function () {
            var $99 = lift2(MonadEffect0.Monad0());
            var $100 = log(dictMonadLogger);
            return function ($101) {
                return $99($100($101));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectListT;
        }
    };
};
var monadLoggerMaybeT = function (dictMonadLogger) {
    var MonadEffect0 = dictMonadLogger.MonadEffect0();
    var monadEffectMaybe = Control_Monad_Maybe_Trans.monadEffectMaybe(MonadEffect0);
    return {
        log: (function () {
            var $102 = lift3(MonadEffect0.Monad0());
            var $103 = log(dictMonadLogger);
            return function ($104) {
                return $102($103($104));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectMaybe;
        }
    };
};
var monadLoggerRWST = function (dictMonoid) {
    var lift6 = Control_Monad_Trans_Class.lift(Control_Monad_RWS_Trans.monadTransRWST(dictMonoid));
    var monadEffectRWS = Control_Monad_RWS_Trans.monadEffectRWS(dictMonoid);
    return function (dictMonadLogger) {
        var MonadEffect0 = dictMonadLogger.MonadEffect0();
        var monadEffectRWS1 = monadEffectRWS(MonadEffect0);
        return {
            log: (function () {
                var $105 = lift6(MonadEffect0.Monad0());
                var $106 = log(dictMonadLogger);
                return function ($107) {
                    return $105($106($107));
                };
            })(),
            MonadEffect0: function () {
                return monadEffectRWS1;
            }
        };
    };
};
var monadLoggerReaderT = function (dictMonadLogger) {
    var MonadEffect0 = dictMonadLogger.MonadEffect0();
    var monadEffectReader = Control_Monad_Reader_Trans.monadEffectReader(MonadEffect0);
    return {
        log: (function () {
            var $108 = lift4(MonadEffect0.Monad0());
            var $109 = log(dictMonadLogger);
            return function ($110) {
                return $108($109($110));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectReader;
        }
    };
};
var monadLoggerStateT = function (dictMonadLogger) {
    var MonadEffect0 = dictMonadLogger.MonadEffect0();
    var monadEffectState = Control_Monad_State_Trans.monadEffectState(MonadEffect0);
    return {
        log: (function () {
            var $111 = lift5(MonadEffect0.Monad0());
            var $112 = log(dictMonadLogger);
            return function ($113) {
                return $111($112($113));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectState;
        }
    };
};
var monadLoggerWriterT = function (dictMonoid) {
    var lift6 = Control_Monad_Trans_Class.lift(Control_Monad_Writer_Trans.monadTransWriterT(dictMonoid));
    var monadEffectWriter = Control_Monad_Writer_Trans.monadEffectWriter(dictMonoid);
    return function (dictMonadLogger) {
        var MonadEffect0 = dictMonadLogger.MonadEffect0();
        var monadEffectWriter1 = monadEffectWriter(MonadEffect0);
        return {
            log: (function () {
                var $114 = lift6(MonadEffect0.Monad0());
                var $115 = log(dictMonadLogger);
                return function ($116) {
                    return $114($115($116));
                };
            })(),
            MonadEffect0: function () {
                return monadEffectWriter1;
            }
        };
    };
};
var info = function (dictMonadLogger) {
    return log$prime(dictMonadLogger)(Data_Log_Level.Info.value);
};
var info$prime = function (dictMonadLogger) {
    return info(dictMonadLogger)(Data_Map_Internal.empty);
};
var error = function (dictMonadLogger) {
    return log$prime(dictMonadLogger)(Data_Log_Level["Error"].value);
};
var error$prime = function (dictMonadLogger) {
    return error(dictMonadLogger)(Data_Map_Internal.empty);
};
var debug = function (dictMonadLogger) {
    return log$prime(dictMonadLogger)(Data_Log_Level.Debug.value);
};
var debug$prime = function (dictMonadLogger) {
    return debug(dictMonadLogger)(Data_Map_Internal.empty);
};
export {
    log,
    trace,
    debug,
    info,
    warn,
    error,
    monadLoggerContT,
    monadLoggerExceptT,
    monadLoggerListT,
    monadLoggerMaybeT,
    monadLoggerRWST,
    monadLoggerReaderT,
    monadLoggerStateT,
    monadLoggerWriterT
};
