// Generated by purs version 0.15.7
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as Widgets_Checkbox from "../Widgets.Checkbox/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var label = /* #__PURE__ */ Concur_React_DOM.label(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var Checkbox = function (x) {
    return x;
};
var showCheckbox = /* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "cls";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "label";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "legacy";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "value";
    }
})(Data_Show.showBoolean))(Data_Show.showBoolean))(Data_Show.showString))(Data_Show.showString))(Data_Show.showString));
var checkboxFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return true;
    },
    asField: function (v) {
        return new Data_Maybe.Just(new GraphQL_Shape.QScalar(v.id));
    },
    toWidget: function (fc) {
        return function (v) {
            var id = Widgets_Form_Class.fieldId(fc)(v.id);
            var fieldClass$prime = (function () {
                var $52 = v.cls === "";
                if ($52) {
                    return "flex col-span-12 items-center";
                };
                return "flex items-center " + v.cls;
            })();
            return map(map1(map1(Widgets_Form_Class.formWidgetState)(Checkbox))(function (v1) {
                return {
                    value: v1,
                    cls: v.cls,
                    id: v.id,
                    label: v.label,
                    legacy: v.legacy
                };
            }))(div([ Concur_React_Props.key(id), Concur_React_Props.className(fieldClass$prime) ])([ Widgets_Checkbox.checkbox([ Concur_React_Props["_id"](id) ])(v.value), label([ Concur_React_Props.htmlFor(id), Concur_React_Props.className("mx-3"), Concur_React_Props.dangerouslySetInnerHTML({
                "__html": v.label
            }) ])([  ]) ]));
        };
    },
    getData: function (v) {
        return function (v1) {
            if (v1.legacy && !v1.value) {
                return Data_Argonaut_Core.fromString("NO");
            };
            if (v1.legacy && v1.value) {
                return Data_Argonaut_Core.fromString("YES");
            };
            return Data_Argonaut_Core.fromBoolean(v1.value);
        };
    },
    setData: function (v) {
        return function (v1) {
            return function (json) {
                var setYesNo = Widgets_Form_Class.setFromJson(checkboxFW)(Data_Argonaut_Decode_Class.decodeJsonJson)(v1)(function (val) {
                    var v2 = Data_Argonaut_Core.toString(val);
                    if (v2 instanceof Data_Maybe.Just && v2.value0 === "YES") {
                        return new Data_Either.Right({
                            id: v1.id,
                            label: v1.label,
                            cls: v1.cls,
                            legacy: v1.legacy,
                            value: true
                        });
                    };
                    if (v2 instanceof Data_Maybe.Just && v2.value0 === "NO") {
                        return new Data_Either.Right({
                            id: v1.id,
                            label: v1.label,
                            cls: v1.cls,
                            legacy: v1.legacy,
                            value: false
                        });
                    };
                    return new Data_Either.Left(new $$Error.StringError("Invalid checkbox value: " + Data_Argonaut_Core.stringify(val)));
                });
                var setBool = Widgets_Form_Class.setFromJson(checkboxFW)(Data_Argonaut_Decode_Class.decodeJsonJson)(v1)(function (val) {
                    var v2 = Data_Argonaut_Core.toBoolean(val);
                    if (v2 instanceof Data_Maybe.Just) {
                        return new Data_Either.Right({
                            id: v1.id,
                            label: v1.label,
                            cls: v1.cls,
                            legacy: v1.legacy,
                            value: v2.value0
                        });
                    };
                    if (v2 instanceof Data_Maybe.Nothing) {
                        return new Data_Either.Left(new $$Error.StringError("Invalid checkbox value: " + Data_Argonaut_Core.stringify(val)));
                    };
                    throw new Error("Failed pattern match at Widgets.Form.Checkbox (line 56, column 44 - line 58, column 90): " + [ v2.constructor.name ]);
                });
                var $68 = Data_Argonaut_Core.isBoolean(json);
                if ($68) {
                    return setBool(json);
                };
                return setYesNo(json);
            };
        };
    }
};
export {
    Checkbox,
    showCheckbox,
    checkboxFW
};
