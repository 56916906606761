// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Effect_Uncurried from "../Effect.Uncurried/index.js";
import * as React_DOM_Props from "../React.DOM.Props/index.js";
import * as React_SyntheticEvent from "../React.SyntheticEvent/index.js";
var y = function ($10) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.y($10));
};
var x = function ($11) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.x($11));
};
var wrap = function ($12) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.wrap($12));
};
var wmode = function ($13) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.wmode($13));
};
var width = function ($14) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.width($14));
};
var vocab = function ($15) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.vocab($15));
};
var viewBox = function ($16) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.viewBox($16));
};
var valueArray = function ($17) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.valueArray($17));
};
var value = function ($18) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.value($18));
};
var useMap = function ($19) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.useMap($19));
};
var unselectable = function ($20) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.unselectable($20));
};
var unsafeTargetValue = function (e) {
    return e.target.value;
};
var unsafeMkPropHandler = function (s) {
    return new Concur_Core_Props.Handler(function (f) {
        return React_DOM_Props.unsafeMkProps(s)(Effect_Uncurried.mkEffectFn1(f));
    });
};
var unsafeMkProp = function (s) {
    return function (v) {
        return new Concur_Core_Props.PrimProp(React_DOM_Props.unsafeMkProps(s)(v));
    };
};
var $$typeof = function ($21) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props["typeof"]($21));
};
var title = function ($22) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.title($22));
};
var target = function ($23) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.target($23));
};
var tabIndex = function ($24) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.tabIndex($24));
};
var suppressContentEditableWarning = function ($25) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.suppressContentEditableWarning($25));
};
var summary = function ($26) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.summary($26));
};
var style = function ($27) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.style($27));
};
var strokeWidth = function ($28) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.strokeWidth($28));
};
var stroke = function ($29) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.stroke($29));
};
var step = function ($30) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.step($30));
};
var start = function ($31) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.start($31));
};
var srcSet = function ($32) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.srcSet($32));
};
var srcLang = function ($33) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.srcLang($33));
};
var srcDoc = function ($34) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.srcDoc($34));
};
var src = function ($35) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.src($35));
};
var spellCheck = function ($36) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.spellCheck($36));
};
var span = function ($37) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.span($37));
};
var sizes = function ($38) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.sizes($38));
};
var size = function ($39) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.size($39));
};
var shape = function ($40) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.shape($40));
};
var selected = function ($41) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.selected($41));
};
var security = function ($42) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.security($42));
};
var seamless = function ($43) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.seamless($43));
};
var scrolling = function ($44) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.scrolling($44));
};
var scoped = function ($45) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.scoped($45));
};
var scope = function ($46) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.scope($46));
};
var sandbox = function ($47) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.sandbox($47));
};
var rows = function ($48) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.rows($48));
};
var rowSpan = function ($49) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.rowSpan($49));
};
var role = function ($50) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.role($50));
};
var reversed = function ($51) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.reversed($51));
};
var results = function ($52) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.results($52));
};
var resource = function ($53) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.resource($53));
};
var required = function ($54) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.required($54));
};
var rel = function ($55) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.rel($55));
};
var ref = function ($56) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.ref($56));
};
var readOnly = function ($57) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.readOnly($57));
};
var radioGroup = function ($58) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.radioGroup($58));
};
var r = function ($59) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.r($59));
};
var property = function ($60) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.property($60));
};
var profile = function ($61) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.profile($61));
};
var preload = function ($62) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.preload($62));
};
var prefix = function ($63) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.prefix($63));
};
var poster = function ($64) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.poster($64));
};
var points = function ($65) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.points($65));
};
var placeholder = function ($66) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.placeholder($66));
};
var persist = function (v) {
    if (v instanceof Concur_Core_Props.Handler) {
        return new Concur_Core_Props.Handler(function (hdlr) {
            return v.value0(function (e) {
                return function __do() {
                    var v1 = React_SyntheticEvent.persist(e)();
                    return hdlr(e)();
                };
            });
        });
    };
    if (v instanceof Concur_Core_Props.PrimProp) {
        return new Concur_Core_Props.PrimProp(v.value0);
    };
    throw new Error("Failed pattern match at Concur.React.Props (line 80, column 1 - line 82, column 33): " + [ v.constructor.name ]);
};
var pattern = function ($67) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.pattern($67));
};
var optimum = function ($68) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.optimum($68));
};
var open = function ($69) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.open($69));
};
var opacity = function ($70) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.opacity($70));
};
var onWheelCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onWheelCapture);
})();
var onWheel = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onWheel);
})();
var onWaitingCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onWaitingCapture);
})();
var onWaiting = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onWaiting);
})();
var onVolumeChangeCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onVolumeChangeCapture);
})();
var onVolumeChange = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onVolumeChange);
})();
var onTransitionEndCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTransitionEndCapture);
})();
var onTransitionEnd = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTransitionEnd);
})();
var onTouchStartCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTouchStartCapture);
})();
var onTouchStart = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTouchStart);
})();
var onTouchMoveCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTouchMoveCapture);
})();
var onTouchMove = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTouchMove);
})();
var onTouchEndCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTouchEndCapture);
})();
var onTouchEnd = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTouchEnd);
})();
var onTouchCancelCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTouchCancelCapture);
})();
var onTouchCancel = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTouchCancel);
})();
var onToggleCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onToggleCapture);
})();
var onToggle = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onToggle);
})();
var onTimeUpdateCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTimeUpdateCapture);
})();
var onTimeUpdate = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onTimeUpdate);
})();
var onSuspendCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onSuspendCapture);
})();
var onSuspend = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onSuspend);
})();
var onSubmitCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onSubmitCapture);
})();
var onSubmit = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onSubmit);
})();
var onStalledCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onStalledCapture);
})();
var onStalled = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onStalled);
})();
var onSelectCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onSelectCapture);
})();
var onSelect = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onSelect);
})();
var onSeekingCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onSeekingCapture);
})();
var onSeeking = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onSeeking);
})();
var onSeekedCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onSeekedCapture);
})();
var onSeeked = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onSeeked);
})();
var onScrollCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onScrollCapture);
})();
var onScroll = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onScroll);
})();
var onRateChangeCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onRateChangeCapture);
})();
var onRateChange = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onRateChange);
})();
var onProgressCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onProgressCapture);
})();
var onProgress = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onProgress);
})();
var onPlayingCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onPlayingCapture);
})();
var onPlaying = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onPlaying);
})();
var onPlayCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onPlayCapture);
})();
var onPlay = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onPlay);
})();
var onPauseCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onPauseCapture);
})();
var onPause = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onPause);
})();
var onPasteCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onPasteCapture);
})();
var onPaste = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onPaste);
})();
var onMouseUpCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseUpCapture);
})();
var onMouseUp = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseUp);
})();
var onMouseOverCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseOverCapture);
})();
var onMouseOver = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseOver);
})();
var onMouseOutCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseOutCapture);
})();
var onMouseOut = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseOut);
})();
var onMouseMoveCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseMoveCapture);
})();
var onMouseMove = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseMove);
})();
var onMouseLeaveCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseLeaveCapture);
})();
var onMouseLeave = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseLeave);
})();
var onMouseEnterCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseEnterCapture);
})();
var onMouseEnter = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseEnter);
})();
var onMouseDownCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseDownCapture);
})();
var onMouseDown = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onMouseDown);
})();
var onLoadedMetadataCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onLoadedMetadataCapture);
})();
var onLoadedMetadata = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onLoadedMetadata);
})();
var onLoadedDataCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onLoadedDataCapture);
})();
var onLoadedData = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onLoadedData);
})();
var onLoadStartCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onLoadStartCapture);
})();
var onLoadStart = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onLoadStart);
})();
var onLoadCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onLoadCapture);
})();
var onLoad = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onLoad);
})();
var onKeyUpCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onKeyUpCapture);
})();
var onKeyUp = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onKeyUp);
})();
var onKeyPressCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onKeyPressCapture);
})();
var onKeyPress = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onKeyPress);
})();
var onKeyDownCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onKeyDownCapture);
})();
var onKeyDown = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onKeyDown);
})();
var onInvalidCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onInvalidCapture);
})();
var onInvalid = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onInvalid);
})();
var onInputCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onInputCapture);
})();
var onInput = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onInput);
})();
var onFocusCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onFocusCapture);
})();
var onFocus = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onFocus);
})();
var onErrorCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onErrorCapture);
})();
var onError = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onError);
})();
var onEndedCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onEndedCapture);
})();
var onEnded = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onEnded);
})();
var onEncryptedCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onEncryptedCapture);
})();
var onEncrypted = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onEncrypted);
})();
var onEmptiedCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onEmptiedCapture);
})();
var onEmptied = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onEmptied);
})();
var onDurationChangeCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDurationChangeCapture);
})();
var onDurationChange = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDurationChange);
})();
var onDropCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDropCapture);
})();
var onDrop = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDrop);
})();
var onDragStartCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragStartCapture);
})();
var onDragStart = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragStart);
})();
var onDragOverCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragOverCapture);
})();
var onDragOver = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragOver);
})();
var onDragLeaveCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragLeaveCapture);
})();
var onDragLeave = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragLeave);
})();
var onDragExitCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragExitCapture);
})();
var onDragExit = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragExit);
})();
var onDragEnterCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragEnterCapture);
})();
var onDragEnter = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragEnter);
})();
var onDragEndCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragEndCapture);
})();
var onDragEnd = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragEnd);
})();
var onDragCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDragCapture);
})();
var onDrag = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDrag);
})();
var onDoubleClickCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDoubleClickCapture);
})();
var onDoubleClick = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onDoubleClick);
})();
var onCutCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCutCapture);
})();
var onCut = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCut);
})();
var onCopyCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCopyCapture);
})();
var onCopy = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCopy);
})();
var onContextMenuCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onContextMenuCapture);
})();
var onContextMenu = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onContextMenu);
})();
var onCompositionUpdateCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCompositionUpdateCapture);
})();
var onCompositionUpdate = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCompositionUpdate);
})();
var onCompositionStartCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCompositionStartCapture);
})();
var onCompositionStart = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCompositionStart);
})();
var onCompositionEndCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCompositionEndCapture);
})();
var onCompositionEnd = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCompositionEnd);
})();
var onClickCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onClickCapture);
})();
var onClick = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onClick);
})();
var onChangeCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onChangeCapture);
})();
var onChange = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onChange);
})();
var onCanPlayThroughCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCanPlayThroughCapture);
})();
var onCanPlayThrough = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCanPlayThrough);
})();
var onCanPlayCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCanPlayCapture);
})();
var onCanPlay = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onCanPlay);
})();
var onBlurCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onBlurCapture);
})();
var onBlur = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onBlur);
})();
var onAnimationStartCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onAnimationStartCapture);
})();
var onAnimationStart = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onAnimationStart);
})();
var onAnimationIterationCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onAnimationIterationCapture);
})();
var onAnimationIteration = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onAnimationIteration);
})();
var onAnimationEndCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onAnimationEndCapture);
})();
var onAnimationEnd = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onAnimationEnd);
})();
var onAbortCapture = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onAbortCapture);
})();
var onAbort = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(React_DOM_Props.onAbort);
})();
var nonce = function ($71) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.nonce($71));
};
var noValidate = function ($72) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.noValidate($72));
};
var name = function ($73) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.name($73));
};
var muted = function ($74) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.muted($74));
};
var multiple = function ($75) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.multiple($75));
};
var minLength = function ($76) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.minLength($76));
};
var min = function ($77) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.min($77));
};
var method = function ($78) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.method($78));
};
var mediaGroup = function ($79) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.mediaGroup($79));
};
var media = function ($80) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.media($80));
};
var maxLength = function ($81) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.maxLength($81));
};
var max = function ($82) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.max($82));
};
var marginWidth = function ($83) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.marginWidth($83));
};
var marginHeight = function ($84) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.marginHeight($84));
};
var manifest = function ($85) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.manifest($85));
};
var low = function ($86) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.low($86));
};
var loop = function ($87) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.loop($87));
};
var list = function ($88) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.list($88));
};
var lang = function ($89) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.lang($89));
};
var label = function ($90) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.label($90));
};
var kind = function ($91) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.kind($91));
};
var keyType = function ($92) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.keyType($92));
};
var keyParams = function ($93) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.keyParams($93));
};
var key = function ($94) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.key($94));
};
var itemType = function ($95) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.itemType($95));
};
var itemScope = function ($96) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.itemScope($96));
};
var itemRef = function ($97) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.itemRef($97));
};
var itemProp = function ($98) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.itemProp($98));
};
var itemID = function ($99) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.itemID($99));
};
var isEnterEvent = function (e) {
    return e.which === 13 || e.keyCode === 13;
};
var onKeyEnter = /* #__PURE__ */ Concur_Core_Props.filterProp(isEnterEvent)(onKeyDown);
var is = function ($100) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.is($100));
};
var integrity = function ($101) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.integrity($101));
};
var inputMode = function ($102) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.inputMode($102));
};
var inlist = function ($103) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.inlist($103));
};
var icon = function ($104) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.icon($104));
};
var httpEquiv = function ($105) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.httpEquiv($105));
};
var htmlFor = function ($106) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.htmlFor($106));
};
var hrefLang = function ($107) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.hrefLang($107));
};
var href = function ($108) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.href($108));
};
var high = function ($109) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.high($109));
};
var hidden = function ($110) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.hidden($110));
};
var height = function ($111) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.height($111));
};
var headers = function ($112) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.headers($112));
};
var frameBorder = function ($113) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.frameBorder($113));
};
var formTarget = function ($114) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.formTarget($114));
};
var formNoValidate = function ($115) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.formNoValidate($115));
};
var formMethod = function ($116) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.formMethod($116));
};
var formEncType = function ($117) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.formEncType($117));
};
var formAction = function ($118) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.formAction($118));
};
var form = function ($119) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.form($119));
};
var fillOpacity = function ($120) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.fillOpacity($120));
};
var fill = function ($121) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.fill($121));
};
var encType = function ($122) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.encType($122));
};
var emptyProp = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.PrimProp($foreign.emptyProp_);
})();
var draggable = function ($123) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.draggable($123));
};
var download = function ($124) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.download($124));
};
var disabled = function ($125) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.disabled($125));
};
var dir = function ($126) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.dir($126));
};
var defer = function ($127) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.defer($127));
};
var defaultValue = function ($128) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.defaultValue($128));
};
var defaultChecked = function ($129) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.defaultChecked($129));
};
var $$default = function ($130) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props["default"]($130));
};
var dateTime = function ($131) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.dateTime($131));
};
var datatype = function ($132) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.datatype($132));
};
var dangerouslySetInnerHTML = function ($133) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.dangerouslySetInnerHTML($133));
};
var d = function ($134) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.d($134));
};
var cy = function ($135) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cy($135));
};
var cx = function ($136) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cx($136));
};
var crossOrigin = function ($137) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.crossOrigin($137));
};
var coords = function ($138) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.coords($138));
};
var controls = function ($139) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.controls($139));
};
var contextMenu = function ($140) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.contextMenu($140));
};
var contentEditable = function ($141) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.contentEditable($141));
};
var content = function ($142) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.content($142));
};
var cols = function ($143) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cols($143));
};
var color = function ($144) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.color($144));
};
var colSpan = function ($145) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.colSpan($145));
};
var className = function ($146) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.className($146));
};
var classList = /* #__PURE__ */ (function () {
    var $147 = Data_Array.intercalate(Data_Monoid.monoidString)(" ");
    var $148 = Data_Array.concatMap(Data_Maybe.maybe([  ])(function (s) {
        return [ s ];
    }));
    return function ($149) {
        return className($147($148($149)));
    };
})();
var classID = function ($150) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.classID($150));
};
var cite = function ($151) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cite($151));
};
var checked = function ($152) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.checked($152));
};
var charSet = function ($153) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.charSet($153));
};
var challenge = function ($154) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.challenge($154));
};
var cellSpacing = function ($155) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cellSpacing($155));
};
var cellPadding = function ($156) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cellPadding($156));
};
var capture = function ($157) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.capture($157));
};
var autoSave = function ($158) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoSave($158));
};
var autoPlay = function ($159) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoPlay($159));
};
var autoFocus = function ($160) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoFocus($160));
};
var autoCorrect = function ($161) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoCorrect($161));
};
var autoComplete = function ($162) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoComplete($162));
};
var autoCapitalize = function ($163) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoCapitalize($163));
};
var async = function ($164) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.async($164));
};
var aria = function ($165) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.aria($165));
};
var alt = function ($166) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.alt($166));
};
var allowTransparency = function ($167) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.allowTransparency($167));
};
var allowFullScreen = function ($168) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.allowFullScreen($168));
};
var action = function ($169) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.action($169));
};
var accessKey = function ($170) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.accessKey($170));
};
var acceptCharset = function ($171) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.acceptCharset($171));
};
var accept = function ($172) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.accept($172));
};
var about = function ($173) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.about($173));
};
var _type = function ($174) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props["_type"]($174));
};
var _id = function ($175) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props["_id"]($175));
};
var _data = function ($176) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props["_data"]($176));
};
export {
    emptyProp_,
    resetTargetValue
} from "./foreign.js";
export {
    emptyProp,
    unsafeMkPropHandler,
    unsafeMkProp,
    classList,
    unsafeTargetValue,
    isEnterEvent,
    persist,
    aria,
    _data,
    style,
    dangerouslySetInnerHTML,
    accept,
    acceptCharset,
    accessKey,
    action,
    allowFullScreen,
    allowTransparency,
    alt,
    async,
    autoComplete,
    autoFocus,
    autoPlay,
    capture,
    cellPadding,
    cellSpacing,
    charSet,
    challenge,
    checked,
    cite,
    classID,
    className,
    cols,
    colSpan,
    content,
    contentEditable,
    contextMenu,
    controls,
    coords,
    crossOrigin,
    dateTime,
    $$default as default,
    defaultChecked,
    defaultValue,
    defer,
    dir,
    disabled,
    download,
    draggable,
    encType,
    form,
    formAction,
    formEncType,
    formMethod,
    formNoValidate,
    formTarget,
    frameBorder,
    headers,
    height,
    hidden,
    high,
    href,
    hrefLang,
    htmlFor,
    httpEquiv,
    icon,
    _id,
    inputMode,
    integrity,
    is,
    key,
    keyParams,
    keyType,
    kind,
    label,
    lang,
    list,
    loop,
    low,
    manifest,
    marginHeight,
    marginWidth,
    max,
    maxLength,
    media,
    mediaGroup,
    method,
    min,
    minLength,
    multiple,
    muted,
    name,
    nonce,
    noValidate,
    open,
    optimum,
    pattern,
    placeholder,
    poster,
    preload,
    profile,
    radioGroup,
    readOnly,
    rel,
    required,
    reversed,
    role,
    rows,
    rowSpan,
    sandbox,
    scope,
    scoped,
    scrolling,
    seamless,
    selected,
    shape,
    size,
    sizes,
    span,
    spellCheck,
    src,
    srcDoc,
    srcLang,
    srcSet,
    start,
    step,
    summary,
    tabIndex,
    target,
    title,
    _type,
    useMap,
    value,
    valueArray,
    width,
    wmode,
    wrap,
    about,
    datatype,
    inlist,
    prefix,
    property,
    resource,
    $$typeof as typeof,
    vocab,
    autoCapitalize,
    autoCorrect,
    autoSave,
    color,
    itemProp,
    itemScope,
    itemType,
    itemID,
    itemRef,
    results,
    security,
    unselectable,
    onAnimationStart,
    onAnimationEnd,
    onAnimationIteration,
    onTransitionEnd,
    onToggle,
    onError,
    onLoad,
    onAbort,
    onCanPlay,
    onCanPlayThrough,
    onDurationChange,
    onEmptied,
    onEncrypted,
    onEnded,
    onLoadedData,
    onLoadedMetadata,
    onLoadStart,
    onPause,
    onPlay,
    onPlaying,
    onProgress,
    onRateChange,
    onSeeked,
    onSeeking,
    onStalled,
    onSuspend,
    onTimeUpdate,
    onVolumeChange,
    onWaiting,
    onCopy,
    onCut,
    onPaste,
    onCompositionEnd,
    onCompositionStart,
    onCompositionUpdate,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onKeyEnter,
    onFocus,
    onBlur,
    onChange,
    onInput,
    onInvalid,
    onSubmit,
    onClick,
    onContextMenu,
    onDoubleClick,
    onDrag,
    onDragEnd,
    onDragEnter,
    onDragExit,
    onDragLeave,
    onDragOver,
    onDragStart,
    onDrop,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    onMouseOut,
    onMouseOver,
    onMouseUp,
    onSelect,
    onTouchCancel,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    onScroll,
    onWheel,
    onAnimationStartCapture,
    onAnimationEndCapture,
    onAnimationIterationCapture,
    onTransitionEndCapture,
    onToggleCapture,
    onErrorCapture,
    onLoadCapture,
    onAbortCapture,
    onCanPlayCapture,
    onCanPlayThroughCapture,
    onDurationChangeCapture,
    onEmptiedCapture,
    onEncryptedCapture,
    onEndedCapture,
    onLoadedDataCapture,
    onLoadedMetadataCapture,
    onLoadStartCapture,
    onPauseCapture,
    onPlayCapture,
    onPlayingCapture,
    onProgressCapture,
    onRateChangeCapture,
    onSeekedCapture,
    onSeekingCapture,
    onStalledCapture,
    onSuspendCapture,
    onTimeUpdateCapture,
    onVolumeChangeCapture,
    onWaitingCapture,
    onCopyCapture,
    onCutCapture,
    onPasteCapture,
    onCompositionEndCapture,
    onCompositionStartCapture,
    onCompositionUpdateCapture,
    onKeyDownCapture,
    onKeyPressCapture,
    onKeyUpCapture,
    onFocusCapture,
    onBlurCapture,
    onChangeCapture,
    onInputCapture,
    onInvalidCapture,
    onSubmitCapture,
    onClickCapture,
    onContextMenuCapture,
    onDoubleClickCapture,
    onDragCapture,
    onDragEndCapture,
    onDragEnterCapture,
    onDragExitCapture,
    onDragLeaveCapture,
    onDragOverCapture,
    onDragStartCapture,
    onDropCapture,
    onMouseDownCapture,
    onMouseEnterCapture,
    onMouseLeaveCapture,
    onMouseMoveCapture,
    onMouseOutCapture,
    onMouseOverCapture,
    onMouseUpCapture,
    onSelectCapture,
    onTouchCancelCapture,
    onTouchEndCapture,
    onTouchMoveCapture,
    onTouchStartCapture,
    onScrollCapture,
    onWheelCapture,
    ref,
    suppressContentEditableWarning,
    x,
    y,
    cx,
    cy,
    r,
    fill,
    opacity,
    fillOpacity,
    stroke,
    strokeWidth,
    points,
    d,
    viewBox
};
