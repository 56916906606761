// Generated by purs version 0.15.7
import * as API_Attachments from "../API.Attachments/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as Internal_Luxon from "../Internal.Luxon/index.js";
import * as Internal_Util from "../Internal.Util/index.js";
import * as Translate from "../Translate/index.js";
import * as Web_File_File from "../Web.File.File/index.js";
import * as Widgets_Confirmation from "../Widgets.Confirmation/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
import * as Widgets_Upload from "../Widgets.Upload/index.js";
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var p = /* #__PURE__ */ Concur_React_DOM.p(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var details = /* #__PURE__ */ Concur_React_DOM.details(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var length = /* #__PURE__ */ Data_Foldable.length(Data_Foldable.foldableArray)(Data_Semiring.semiringInt);
var summary$prime = /* #__PURE__ */ Concur_React_DOM["summary$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h1 = /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Concur_Core_Types.bindWidget);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.bindWidget);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var UploadAttachment = /* #__PURE__ */ (function () {
    function UploadAttachment() {

    };
    UploadAttachment.value = new UploadAttachment();
    return UploadAttachment;
})();
var DeleteAttachment = /* #__PURE__ */ (function () {
    function DeleteAttachment(value0) {
        this.value0 = value0;
    };
    DeleteAttachment.create = function (value0) {
        return new DeleteAttachment(value0);
    };
    return DeleteAttachment;
})();
var StateChange = /* #__PURE__ */ (function () {
    function StateChange(value0) {
        this.value0 = value0;
    };
    StateChange.create = function (value0) {
        return new StateChange(value0);
    };
    return StateChange;
})();
var uploadId = function (state) {
    return state.id + "-attachment-upload";
};
var renderAttachment = function (state) {
    return function (att) {
        return div([ Concur_React_Props.className("attachment") ])([ a([ Concur_React_Props.className("attachment-label"), Concur_React_Props.href(att.url), Concur_React_Props.target("_blank") ])([ text((function () {
            var $44 = att.description === "";
            if ($44) {
                return att.path;
            };
            return att.description;
        })()) ]), div([ Concur_React_Props.className("attachment-meta") ])([ div([ Concur_React_Props.className("attachment-by") ])([ text(att.user.name) ]), div([ Concur_React_Props.className("attachment-time") ])([ text(Internal_Luxon.toRelative(att.created_at)) ]) ]), div([ Concur_React_Props.className("attachment-buttons") ])(Data_Array.catMaybes([ Internal_Util.justIf(att["can"]["delete"])(button([ Concur_React_Props.className("attachment-delete-button btn"), voidRight(new DeleteAttachment(att.id))(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("attachment-delete")) ])) ])) ]);
    };
};
var initAttachments = function (v) {
    return {
        id: v.id,
        formId: v.formId,
        attachments: v.attachments,
        description: ""
    };
};
var attachmentForm = function (state) {
    return div([ Concur_React_Props.className("attachment-form") ])([ p([ Concur_React_Props.className("attachment-form-header") ])([ text(Translate.lookupTranslation("attachment-form")) ]), input([ Concur_React_Props["_type"]("file"), Concur_React_Props["_id"](uploadId(state)), voidRight(UploadAttachment.value)(Concur_React_Props.onChange) ]) ]);
};
var renderAttachments = function (v) {
    return details([ Concur_React_Props.key(v.id), Concur_React_Props["_id"](v.id), Concur_React_Props.open(length(v.attachments) > 0) ])([ summary$prime([ h1([ Concur_React_Props.className("attachment-section-heading") ])([ text(Translate.lookupTranslation("attachment-section")) ]) ]), div([ Concur_React_Props.className("attachment-section") ])([ div([ Concur_React_Props.className("attachment-list") ])(map(renderAttachment(v))(v.attachments)), attachmentForm(v) ]) ]);
};
var renderLoading = function (state) {
    return alt(Widgets_Loading.loadingWidgetOverlay)(Internal_ConcurUtil.loopInfinitely(renderAttachments(state)));
};
var handleResult = function (v) {
    return function (v1) {
        if (v1 instanceof StateChange) {
            return pure(v1.value0);
        };
        if (v1 instanceof DeleteAttachment) {
            return bind(Widgets_Confirmation.confirmModal(renderAttachments(v))(Translate.lookupTranslation("confirm-delete-attachment-title"))(Translate.lookupTranslation("confirm-delete-attachment-primary-button"))(Translate.lookupTranslation("confirm-delete-attachment")))(function (conf) {
                if (conf) {
                    return bind(bindFlipped($$Error.wrapCritical)(alt(renderLoading(v))(liftAff(runGraphQL(API_Attachments.deleteAttachment(v1.value0))))))(function (att) {
                        return pure({
                            attachments: Data_Array.filter(function (a1) {
                                return a1.id !== att.id;
                            })(v.attachments),
                            description: v.description,
                            formId: v.formId,
                            id: v.id
                        });
                    });
                };
                return pure(v);
            });
        };
        if (v1 instanceof UploadAttachment) {
            return bind(liftEffect(Widgets_Upload.getUploadFile(uploadId(v))))(function (file) {
                return discard(liftEffect(Effect_Console.log(file)))(function () {
                    var name = (function () {
                        var $57 = v.description === "";
                        if ($57) {
                            return Web_File_File.name(file);
                        };
                        return v.description;
                    })();
                    return bind(bindFlipped($$Error.wrapCritical)(alt(renderLoading(v))(liftAff(runGraphQL(API_Attachments.postAttachment(new API_Attachments.FormAttachment(v.formId))({
                        description: name,
                        file: file
                    }))))))(function (attach) {
                        return pure({
                            attachments: append1(v.attachments)([ attach ]),
                            description: v.description,
                            formId: v.formId,
                            id: v.id
                        });
                    });
                });
            });
        };
        throw new Error("Failed pattern match at Widgets.Attachments (line 45, column 1 - line 45, column 79): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var runAttachments = function (state) {
    return bindFlipped(handleResult(state))(renderAttachments(state));
};
export {
    UploadAttachment,
    DeleteAttachment,
    StateChange,
    initAttachments,
    runAttachments,
    handleResult,
    renderAttachments,
    renderAttachment,
    attachmentForm,
    renderLoading,
    uploadId
};
