// Generated by purs version 0.15.7
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad from "../Control.Monad/index.js";
import * as Control_Monad_Rec_Class from "../Control.Monad.Rec.Class/index.js";
import * as Control_MultiAlternative from "../Control.MultiAlternative/index.js";
import * as Control_Plus from "../Control.Plus/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Effect_Exception from "../Effect.Exception/index.js";
import * as Effect_Ref from "../Effect.Ref/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var $$void = /* #__PURE__ */ Data_Functor["void"](Effect.functorEffect);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_Foldable.foldableArray);
var sequence = /* #__PURE__ */ Data_Traversable.sequence(Data_Traversable.traversableArray)(Effect.applicativeEffect);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var traverse_ = /* #__PURE__ */ Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableArray);
var join = /* #__PURE__ */ Control_Bind.join(Effect.bindEffect);
var voidLeft = /* #__PURE__ */ Data_Functor.voidLeft(Effect.functorEffect);
var View = /* #__PURE__ */ (function () {
    function View(value0) {
        this.value0 = value0;
    };
    View.create = function (value0) {
        return new View(value0);
    };
    return View;
})();
var Completed = /* #__PURE__ */ (function () {
    function Completed(value0) {
        this.value0 = value0;
    };
    Completed.create = function (value0) {
        return new Completed(value0);
    };
    return Completed;
})();
var Callback = function (x) {
    return x;
};
var Widget = function (x) {
    return x;
};
var widgetShiftMap = {
    shiftMap: function (f) {
        return f(identity);
    }
};
var unWid = function (v) {
    return v;
};
var runWidget = function (v) {
    return v;
};
var runCallback = function (v) {
    return v;
};
var newtypeWidget = {
    Coercible0: function () {
        return undefined;
    }
};
var mkWidget = function (e) {
    return e;
};
var mkCallback = Callback;
var $lazy_never = /* #__PURE__ */ $runtime_lazy("never", "Concur.Core.Types", function () {
    return mkCallback(function (_cb) {
        return pure(pure($lazy_never(51)));
    });
});
var never = /* #__PURE__ */ $lazy_never(50);
var silence = function (w) {
    return mkWidget(function (_cb) {
        return runWidget(w)(function (_res) {
            return $$void(pure(never));
        });
    });
};
var killAff = function (v) {
    return function (f) {
        var handler = function (_cb) {
            return function (v1) {
                return pure(Data_Unit.unit);
            };
        };
        return mkCallback(function (cb) {
            return function __do() {
                cb(new View(v))();
                var aff = Effect_Aff.killFiber(Effect_Exception.error("cancelling aff"))(f);
                Effect_Aff.runAff_(handler(cb))(aff)();
                return pure(never);
            };
        });
    };
};
var functorResult = {
    map: function (v) {
        return function (v1) {
            if (v1 instanceof View) {
                return new View(v1.value0);
            };
            if (v1 instanceof Completed) {
                return new Completed(v(v1.value0));
            };
            throw new Error("Failed pattern match at Concur.Core.Types (line 36, column 1 - line 38, column 40): " + [ v.constructor.name, v1.constructor.name ]);
        };
    }
};
var map2 = /* #__PURE__ */ Data_Functor.map(functorResult);
var functorCallback = {
    map: function (f) {
        return function (g) {
            return mkCallback(function (cb) {
                return map(map(Data_Functor.map(functorCallback)(f)))(runCallback(g)(function ($98) {
                    return cb(f($98));
                }));
            });
        };
    }
};
var map3 = /* #__PURE__ */ Data_Functor.map(functorCallback);
var functorWidget = {
    map: function (f) {
        return function (m) {
            return map3(map2(f))(m);
        };
    }
};
var foldStep = function (dictMonoid) {
    var append = Data_Semigroup.append(dictMonoid.Semigroup0());
    return function (callback) {
        return function (lastE) {
            return function (next) {
                return function __do() {
                    var last = lastE();
                    if (last instanceof Data_Maybe.Just) {
                        var n = Effect_Ref.read(next)();
                        if (n instanceof View) {
                            return new Data_Maybe.Just(append(last.value0)(n.value0));
                        };
                        if (n instanceof Completed) {
                            callback(new Completed(n.value0))();
                            return Data_Maybe.Nothing.value;
                        };
                        throw new Error("Failed pattern match at Concur.Core.Types (line 170, column 7 - line 174, column 23): " + [ n.constructor.name ]);
                    };
                    if (last instanceof Data_Maybe.Nothing) {
                        return Data_Maybe.Nothing.value;
                    };
                    throw new Error("Failed pattern match at Concur.Core.Types (line 167, column 3 - line 175, column 28): " + [ last.constructor.name ]);
                };
            };
        };
    };
};
var step = function (dictMonoid) {
    var foldStep1 = foldStep(dictMonoid);
    var mempty = Data_Monoid.mempty(dictMonoid);
    return function (dictSemigroup) {
        return function (callback) {
            return function (resultRefs) {
                return function __do() {
                    var maybeView = foldl(foldStep1(callback))(pure(new Data_Maybe.Just(mempty)))(resultRefs)();
                    if (maybeView instanceof Data_Maybe.Just) {
                        return callback(new View(maybeView.value0))();
                    };
                    if (maybeView instanceof Data_Maybe.Nothing) {
                        return Data_Unit.unit;
                    };
                    throw new Error("Failed pattern match at Concur.Core.Types (line 186, column 3 - line 188, column 25): " + [ maybeView.constructor.name ]);
                };
            };
        };
    };
};
var subscribe = function (dictSemigroup) {
    return function (dictMonoid) {
        var step1 = step(dictMonoid)(dictMonoid.Semigroup0());
        return function (ss) {
            return function (callback) {
                return function (results) {
                    return function (wrTpl) {
                        var refs = Data_Tuple.snd(wrTpl);
                        return function __do() {
                            var canceler = runWidget(Data_Tuple.fst(wrTpl))(function (res) {
                                return function __do() {
                                    Effect_Ref.write(res)(Data_Tuple.fst(refs))();
                                    var subs = Effect_Ref.read(ss)();
                                    if (subs) {
                                        return step1(callback)(results)();
                                    };
                                    if (!subs) {
                                        return Data_Unit.unit;
                                    };
                                    throw new Error("Failed pattern match at Concur.Core.Types (line 204, column 5 - line 206, column 25): " + [ subs.constructor.name ]);
                                };
                            })();
                            var inner = canceler();
                            return Effect_Ref.write(inner)(Data_Tuple.snd(refs))();
                        };
                    };
                };
            };
        };
    };
};
var flipEither = function (v) {
    if (v instanceof Data_Either.Left) {
        return new Data_Either.Right(v.value0);
    };
    if (v instanceof Data_Either.Right) {
        return new Data_Either.Left(v.value0);
    };
    throw new Error("Failed pattern match at Concur.Core.Types (line 125, column 1 - line 128, column 13): " + [ v.constructor.name ]);
};
var fixCancelerRef = function (f) {
    return function __do() {
        var ref = Effect_Ref["new"](pure(never))();
        var cancel = f(ref)();
        Effect_Ref.write(cancel)(ref)();
        return ref;
    };
};
var effAction = function (a) {
    return mkWidget(function (cb) {
        return function __do() {
            var inner = a();
            cb(new Completed(inner))();
            return pure(never);
        };
    });
};
var pulse = function (dictMonoid) {
    return effAction(pure(Data_Unit.unit));
};
var display = function (v) {
    return mkWidget(function (cb) {
        return function __do() {
            cb(new View(v))();
            return pure(unWid(display(v)));
        };
    });
};
var widgetSemigroup = function (dictMonoid) {
    return {
        append: function (w1) {
            return function (w2) {
                return Control_MultiAlternative.orr(widgetMultiAlternative(dictMonoid))([ w1, w2 ]);
            };
        }
    };
};
var widgetPlus = function (dictMonoid) {
    return {
        empty: display(Data_Monoid.mempty(dictMonoid)),
        Alt0: function () {
            return widgetAlt(dictMonoid);
        }
    };
};
var widgetMultiAlternative = function (dictMonoid) {
    return {
        orr: (function (dictMonoid1) {
            var mempty = Data_Monoid.mempty(dictMonoid1);
            var Semigroup0 = dictMonoid1.Semigroup0();
            var subscribe1 = subscribe(Semigroup0)(dictMonoid1);
            var step1 = step(dictMonoid1)(Semigroup0);
            return function (widgets) {
                return mkWidget(function (cb) {
                    return function __do() {
                        var wcRefs = sequence(Data_Array.replicate(Data_Array.length(widgets))(function __do() {
                            var l = Effect_Ref["new"](new View(mempty))();
                            var r = Effect_Ref["new"](never)();
                            return new Data_Tuple.Tuple(l, r);
                        }))();
                        var subscribed = Effect_Ref["new"](false)();
                        var results = map1(Data_Tuple.fst)(wcRefs);
                        traverse_(subscribe1(subscribed)(cb)(results))(Data_Array.zip(widgets)(wcRefs))();
                        Effect_Ref.write(true)(subscribed)();
                        var cancelers = map1(function ($99) {
                            return Effect_Ref.read(Data_Tuple.snd($99));
                        })(wcRefs);
                        var wi = sequence(cancelers)();
                        step1(cb)(results)();
                        return pure(unWid(Control_MultiAlternative.orr(widgetMultiAlternative(dictMonoid1))(map1(Widget)(wi))));
                    };
                });
            };
        })(dictMonoid),
        Plus0: function () {
            return widgetPlus(dictMonoid);
        }
    };
};
var widgetAlt = function (dictMonoid) {
    return {
        alt: Data_Semigroup.append(widgetSemigroup(dictMonoid)),
        Functor0: function () {
            return functorWidget;
        }
    };
};
var widgetMonoid = function (dictMonoid) {
    var widgetSemigroup1 = widgetSemigroup(dictMonoid);
    return {
        mempty: Control_Plus.empty(widgetPlus(dictMonoid)),
        Semigroup0: function () {
            return widgetSemigroup1;
        }
    };
};
var widgetMonad = {
    Applicative0: function () {
        return applicativeWidget;
    },
    Bind1: function () {
        return bindWidget;
    }
};
var bindWidget = {
    bind: function (m) {
        return function (f) {
            return mkWidget(function (cb) {
                return function __do() {
                    var cancelerRef = fixCancelerRef(function (cancelerRef) {
                        return function __do() {
                            var cancelerA = runWidget(m)(function (res) {
                                if (res instanceof View) {
                                    return cb(new View(res.value0));
                                };
                                if (res instanceof Completed) {
                                    return function __do() {
                                        var cA = join(Effect_Ref.read(cancelerRef))();
                                        $$void(runCallback(cA)(function (v) {
                                            return pure(Data_Unit.unit);
                                        }))();
                                        var cancelerB = runWidget(f(res.value0))(cb)();
                                        return Effect_Ref.write(cancelerB)(cancelerRef)();
                                    };
                                };
                                throw new Error("Failed pattern match at Concur.Core.Types (line 99, column 9 - line 105, column 44): " + [ res.constructor.name ]);
                            })();
                            return function __do() {
                                var c = cancelerA();
                                return unWid(Control_Bind.bind(bindWidget)(c)(f));
                            };
                        };
                    })();
                    return join(Effect_Ref.read(cancelerRef));
                };
            });
        };
    },
    Apply0: function () {
        return $lazy_applyWidget(0);
    }
};
var applicativeWidget = {
    pure: function (a) {
        return mkWidget(function (cb) {
            return voidLeft(cb(new Completed(a)))(pure(never));
        });
    },
    Apply0: function () {
        return $lazy_applyWidget(0);
    }
};
var $lazy_applyWidget = /* #__PURE__ */ $runtime_lazy("applyWidget", "Concur.Core.Types", function () {
    return {
        apply: Control_Monad.ap(widgetMonad),
        Functor0: function () {
            return functorWidget;
        }
    };
});
var applyWidget = /* #__PURE__ */ $lazy_applyWidget(74);
var bind1 = /* #__PURE__ */ Control_Bind.bind(bindWidget);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(applicativeWidget);
var widgetAlternative = function (dictMonoid) {
    var widgetPlus1 = widgetPlus(dictMonoid);
    return {
        Applicative0: function () {
            return applicativeWidget;
        },
        Plus1: function () {
            return widgetPlus1;
        }
    };
};
var monadRecWidget = {
    tailRecM: function (k) {
        return function (a) {
            return bind1(k(a))(function (v) {
                if (v instanceof Control_Monad_Rec_Class.Loop) {
                    return Control_Monad_Rec_Class.tailRecM(monadRecWidget)(k)(v.value0);
                };
                if (v instanceof Control_Monad_Rec_Class.Done) {
                    return pure1(v.value0);
                };
                throw new Error("Failed pattern match at Concur.Core.Types (line 83, column 26 - line 85, column 21): " + [ v.constructor.name ]);
            });
        };
    },
    Monad0: function () {
        return widgetMonad;
    }
};
var widgetMonadEff = function (dictMonoid) {
    return {
        liftEffect: effAction,
        Monad0: function () {
            return widgetMonad;
        }
    };
};
var affAction = function (v) {
    return function (aff) {
        var handler = function (v1) {
            return function (v2) {
                if (v2 instanceof Data_Either.Right) {
                    return v1(new Completed(v2.value0));
                };
                if (v2 instanceof Data_Either.Left) {
                    return Effect_Console.log("error calling aff");
                };
                throw new Error("Failed pattern match at Concur.Core.Types (line 271, column 5 - line 271, column 44): " + [ v1.constructor.name, v2.constructor.name ]);
            };
        };
        return mkWidget(function (cb) {
            return function __do() {
                cb(new View(v))();
                var fiber = Effect_Aff.runAff(handler(cb))(aff)();
                return pure(killAff(v)(fiber));
            };
        });
    };
};
var widgetMonadAff = function (dictMonoid) {
    var widgetMonadEff1 = widgetMonadEff(dictMonoid);
    return {
        liftAff: affAction(Data_Monoid.mempty(dictMonoid)),
        MonadEffect0: function () {
            return widgetMonadEff1;
        }
    };
};
export {
    Callback,
    View,
    Completed,
    mkCallback,
    runCallback,
    never,
    Widget,
    unWid,
    runWidget,
    mkWidget,
    fixCancelerRef,
    display,
    silence,
    flipEither,
    foldStep,
    step,
    subscribe,
    pulse,
    effAction,
    killAff,
    affAction,
    functorResult,
    functorCallback,
    widgetShiftMap,
    functorWidget,
    newtypeWidget,
    applyWidget,
    widgetMonad,
    applicativeWidget,
    monadRecWidget,
    bindWidget,
    widgetMultiAlternative,
    widgetSemigroup,
    widgetMonoid,
    widgetAlt,
    widgetPlus,
    widgetAlternative,
    widgetMonadEff,
    widgetMonadAff
};
