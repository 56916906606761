// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as React from "../React/index.js";
import * as React_DOM_Props from "../React.DOM.Props/index.js";
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var renderDropdownItem = function (v) {
    return a([ voidRight(v.value)(Concur_React_Props.onClick), Concur_React_Props.className("whitespace-nowrap") ])([ text(v.label) ]);
};
var dropdownDiv = function (dictShiftMap) {
    var el$prime = Concur_React_DOM["el$prime"](dictShiftMap);
    return function (dictMultiAlternative) {
        var el$prime1 = el$prime(dictMultiAlternative);
        return function (props) {
            return el$prime1((function () {
                var $26 = React.unsafeCreateElement($foreign.classDropdownDiv);
                return function ($27) {
                    return $26(React_DOM_Props.unsafeFromPropsArray($27));
                };
            })())(props);
        };
    };
};
var dropdownDiv1 = /* #__PURE__ */ dropdownDiv(Concur_Core_Types.widgetShiftMap)(widgetMultiAlternative);
var dropdownMenu_ = function (widgets) {
    return dropdownDiv1([ Concur_React_Props.className("dropdown-menu"), voidRight(Data_Maybe.Nothing.value)(Concur_React_Props.onBlur) ])(map(function (v) {
        return map1(Data_Maybe.Just.create)(v);
    })(widgets));
};
var dropdownMenu = function (items) {
    return dropdownMenu_(map(renderDropdownItem)(items));
};
var dropdownContainer = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap)([ /* #__PURE__ */ Concur_React_Props.className("dropdown-container") ]);
var dropdownButton_ = function (btn) {
    return function (widgets) {
        return bind(dropdownContainer([ btn ]))(function () {
            return bind(dropdownContainer([ Internal_ConcurUtil.loopInfinitely(btn), dropdownMenu_(widgets) ]))(function (v) {
                if (v instanceof Data_Maybe.Just) {
                    return pure(v.value0);
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return dropdownButton_(btn)(widgets);
                };
                throw new Error("Failed pattern match at Widgets.Dropdown (line 38, column 75 - line 42, column 47): " + [ v.constructor.name ]);
            });
        });
    };
};
var dropdownButton = function (btn) {
    return function (items) {
        return dropdownButton_(btn)(map(renderDropdownItem)(items));
    };
};
export {
    classDropdownDiv
} from "./foreign.js";
export {
    renderDropdownItem,
    dropdownButton,
    dropdownButton_,
    dropdownMenu,
    dropdownMenu_,
    dropdownContainer,
    dropdownDiv
};
