// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Routing_Match from "../Routing.Match/index.js";
import * as Steps_Step from "../Steps.Step/index.js";
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Routing_Match.matchApply);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Routing_Match.matchFunctor);
var litWithPrefix = function (str) {
    if ($foreign.hrUrlPrefix === "") {
        return Routing_Match.lit(str);
    };
    return applySecond(Routing_Match.lit($foreign.hrUrlPrefix))(Routing_Match.lit(str));
};
var matchStep = /* #__PURE__ */ (function () {
    return applySecond(Routing_Match.root)(Data_Foldable.oneOf(Data_Foldable.foldableArray)(Routing_Match.matchPlus)([ voidRight(Steps_Step.Step2A.value)(litWithPrefix("step2")), Data_Functor.map(Routing_Match.matchFunctor)(Steps_Step.Preview.create)(applySecond(litWithPrefix("preview"))(Routing_Match["int"])), voidRight(Steps_Step.TestForm.value)(litWithPrefix("test-form")) ]));
})();
export {
    hrUrlPrefix
} from "./foreign.js";
export {
    litWithPrefix,
    matchStep
};
