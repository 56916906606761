// Generated by purs version 0.15.7
var unsecond = function (dict) {
    return dict.unsecond;
};
var unfirst = function (dict) {
    return dict.unfirst;
};
export {
    unfirst,
    unsecond
};
