// Generated by purs version 0.15.7
import * as API_Expectations from "../API.Expectations/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_BetterSelect from "../Widgets.BetterSelect/index.js";
import * as Widgets_ExpectationPicker from "../Widgets.ExpectationPicker/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Dynamic from "../Widgets.Form.Dynamic/index.js";
import * as Widgets_Form_List from "../Widgets.Form.List/index.js";
import * as Widgets_Form_Simple from "../Widgets.Form.Simple/index.js";
import * as Widgets_Form_SubForm from "../Widgets.Form.SubForm/index.js";
var setData = /* #__PURE__ */ Widgets_Form_Class.setData(Widgets_Form_SubForm.subFormFW);
var gEncodeJsonCons = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString);
var learningIsSymbol = {
    reflectSymbol: function () {
        return "learning";
    }
};
var expectationIsSymbol = {
    reflectSymbol: function () {
        return "expectation";
    }
};
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons(Data_Argonaut_Encode_Class.gEncodeJsonNil)(learningIsSymbol)())(expectationIsSymbol)())());
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var wrapFormWidget = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_SubForm.showSubForm)(Widgets_Form_SubForm.subFormFW);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var wrapFormWidget1 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_Simple.showFormError)(Widgets_Form_Simple.formErrFW);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var gDecodeJsonCons = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonString));
var gDecodeJsonCons1 = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString));
var idIsSymbol = {
    reflectSymbol: function () {
        return "id";
    }
};
var expectationPicker = /* #__PURE__ */ Widgets_ExpectationPicker.expectationPicker(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons1(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ GraphQL_Type.decRNTY(Data_Argonaut_Decode_Class.decodeJsonString)))(/* #__PURE__ */ gDecodeJsonCons(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
    reflectSymbol: function () {
        return "sub_category";
    }
})()())(idIsSymbol)()())({
    reflectSymbol: function () {
        return "description";
    }
})()())({
    reflectSymbol: function () {
        return "category";
    }
})()())());
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var label = /* #__PURE__ */ Concur_React_DOM.label(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var textarea = /* #__PURE__ */ Concur_React_DOM.textarea(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var map2 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var details = /* #__PURE__ */ Concur_React_DOM.details(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var summary$prime = /* #__PURE__ */ Concur_React_DOM["summary$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var bind2 = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var decodeJson = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeJson(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons1(/* #__PURE__ */ gDecodeJsonCons1(/* #__PURE__ */ gDecodeJsonCons1(Data_Argonaut_Decode_Class.gDecodeJsonNil)(learningIsSymbol)()())(idIsSymbol)()())(expectationIsSymbol)()())());
var map4 = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var wrapFormWidget2 = /* #__PURE__ */ Widgets_Form_Dynamic.wrapFormWidget(Widgets_Form_List.showLW)(Widgets_Form_List.listFW);
var fromFoldable = /* #__PURE__ */ Data_Array.fromFoldable(Data_List_Types.foldableList);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray)(Concur_Core_Types.applicativeWidget);
var ExpectationWidget = function (x) {
    return x;
};
var widgetFromExpectationData = function (fc) {
    return function (subform) {
        return function (json) {
            return setData(fc)(subform)(json);
        };
    };
};
var widgetFromExpectation = function (fc) {
    return function (subform) {
        return function (v) {
            var v1 = widgetFromExpectationData(fc)(subform)(encodeJson({
                expectation: v.description,
                learning: ""
            }));
            if (v1 instanceof Data_Either.Right) {
                return pure(wrapFormWidget(v1.value0));
            };
            if (v1 instanceof Data_Either.Left) {
                var err$prime = new $$Error.WithContext(new $$Error.DuringAction("while loading chosen expectation into widget"), v1.value0);
                return bind(liftAff($$Error.reportError(err$prime)))(function () {
                    return pure(wrapFormWidget1({
                        id: "expectation-error",
                        err: new $$Error.StringError("Failed to load pre-defined expectation data into the expectation entry. " + "This is likely caused by the subform in the form design not matching the expected layout.")
                    }));
                });
            };
            throw new Error("Failed pattern match at Widgets.Form.Expectations (line 139, column 5 - line 152, column 18): " + [ v1.constructor.name ]);
        };
    };
};
var showEW = /* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons(expectationIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil(learningIsSymbol)(Data_Show.showString))(Data_Show.showString));
var occuListExpSelAff = function (occ) {
    var toFE = function (v) {
        return {
            id: v.id
        };
    };
    return Widgets_BetterSelect.mapSearchAff(toFE)(Widgets_ExpectationPicker.rankFilterEntry)(API_Expectations.occupationListSelAff(occ));
};
var runEmployerExpectationChooser = function (fc) {
    var preselectEmployer = function (state) {
        return Widgets_BetterSelect.setSelected(state)(bind1(bind1(fc.doc)(function (v) {
            return v.employer;
        }))(function (emp) {
            return bind1(bind1(fc.doc)(function (v) {
                return v.position;
            }))(function (pos) {
                return pure1({
                    label: emp.company + (" - " + pos.label),
                    value: {
                        id: GraphQL_Type.innerId(pos.id)
                    }
                });
            });
        }));
    };
    var tabs = [ {
        label: Translate.lookupTranslation("expectations-noc"),
        query: function ($163) {
            return runGraphQL(API_Expectations.nocExpectationsQuery($163));
        },
        select: Widgets_BetterSelect["initSelect$prime"]("noc")(occuListExpSelAff("NOC"))({
            placeholder: new Data_Maybe.Just(Translate.lookupTranslation("select-choose-search")),
            deselectLabel: Widgets_BetterSelect.defSelectConfig.deselectLabel,
            allowDeselect: Widgets_BetterSelect.defSelectConfig.allowDeselect
        })
    }, {
        label: Translate.lookupTranslation("expectations-employer-tab"),
        query: function ($164) {
            return runGraphQL(API_Expectations.positionExpectationsQuery($164));
        },
        select: preselectEmployer(Widgets_BetterSelect["initSelect$prime"]("employer-pos")(API_Expectations.employerPosSearchSelAff(GraphQL_Type.maybeId(map(function (v) {
            return v.id;
        })(fc.doc))))({
            placeholder: new Data_Maybe.Just(Translate.lookupTranslation("select-choose-search")),
            deselectLabel: Widgets_BetterSelect.defSelectConfig.deselectLabel,
            allowDeselect: Widgets_BetterSelect.defSelectConfig.allowDeselect
        }))
    }, {
        label: Translate.lookupTranslation("expectations-trade"),
        query: function ($165) {
            return runGraphQL(API_Expectations.tradeExpectationsQuery($165));
        },
        select: Widgets_BetterSelect["initSelect$prime"]("trade")(occuListExpSelAff("TRADE"))({
            placeholder: new Data_Maybe.Just(Translate.lookupTranslation("select-choose-search")),
            deselectLabel: Widgets_BetterSelect.defSelectConfig.deselectLabel,
            allowDeselect: Widgets_BetterSelect.defSelectConfig.allowDeselect
        })
    } ];
    var state = Widgets_ExpectationPicker.initExpectationPicker({
        tabs: tabs
    });
    return bind(expectationPicker(state))(function (endState) {
        return pure(Widgets_ExpectationPicker.selectedExpectations(endState));
    });
};
var expectFW = {
    getId: function (v) {
        return "expectation";
    },
    isLeaf: function (v) {
        return false;
    },
    asField: function (v) {
        return Data_Maybe.Nothing.value;
    },
    toWidget: function (fc) {
        return function (v) {
            var labelledTextArea = function (id) {
                return function (value) {
                    return function (label1) {
                        var qualId = Widgets_Form_Class.fieldId(fc)(id);
                        return div$prime([ label([ Concur_React_Props.htmlFor(qualId) ])([ text(label1) ]), textarea([ Concur_React_Props.className("widget-input"), Concur_React_Props.value(value), Concur_React_Props["_id"](qualId), map1(Concur_React_Props.unsafeTargetValue)(Concur_React_Props.onInput) ])([  ]) ]);
                    };
                };
            };
            var learningWidget = map2(function (v1) {
                return {
                    learning: v1,
                    expectation: v.expectation
                };
            })(labelledTextArea("learning")(v.learning)(Translate.lookupTranslation("learning-demonstration")));
            var expWidget = new Data_Maybe.Just(map2(function (v1) {
                return {
                    expectation: v1,
                    learning: v.learning
                };
            })(labelledTextArea("expectation")(v.expectation)(Translate.lookupTranslation("expectation"))));
            var body = div([ Concur_React_Props.className("expectation-body") ])(Data_Array.catMaybes([ expWidget, new Data_Maybe.Just(learningWidget) ]));
            return map2(map3(Widgets_Form_Class.formWidgetState)(ExpectationWidget))(details([ Concur_React_Props.className("expectation"), Concur_React_Props.key(Widgets_Form_Class.fieldId(fc)("expectation-entry")), Concur_React_Props["_id"](Widgets_Form_Class.fieldId(fc)("expectation-entry")), Concur_React_Props.open(true) ])([ summary$prime([ text(v.expectation) ]), body ]));
        };
    },
    getData: function (v) {
        return function (v1) {
            return encodeJson({
                expectation: v1.expectation,
                learning: v1.learning
            });
        };
    },
    setData: function (v) {
        return function (v1) {
            return function (json) {
                return bind2($$Error["wrapJsonError$prime"](json)(decodeJson(json)))(function (v2) {
                    return new Data_Either.Right({
                        expectation: v2.expectation,
                        learning: v2.learning
                    });
                });
            };
        };
    }
};
var employerExpectationsWidget = function (subform) {
    return function (v) {
        var load = function (fc) {
            return function (json) {
                return map4(wrapFormWidget)(widgetFromExpectationData(fc)(subform)(json));
            };
        };
        return wrapFormWidget2(Widgets_Form_List.modalListWidget({
            id: v.id,
            label: v.label,
            cls: v.cls,
            addBtns: [ {
                label: Translate.lookupTranslation("employer-choose-expectation"),
                newEntries: function (fc) {
                    return bind(map2(fromFoldable)(runEmployerExpectationChooser(fc)))(function (exps) {
                        return traverse(widgetFromExpectation(fc)(subform))(exps);
                    });
                }
            }, {
                label: Translate.lookupTranslation("employer-insert-expectation"),
                newEntries: function (v1) {
                    return pure([ wrapFormWidget(subform) ]);
                }
            } ],
            load: load,
            loadExist: function (fc) {
                return function (v1) {
                    return function (json) {
                        return load(fc)(json);
                    };
                };
            }
        }));
    };
};
var courseListExpSelAff = function (student) {
    var toFE = function (v) {
        return {
            id: GraphQL_Type.innerId(v.id)
        };
    };
    return Widgets_BetterSelect.mapSearchAff(toFE)(Widgets_ExpectationPicker.rankFilterEntry)(API_Expectations.courseListSelAff(student));
};
var runCourseExpectationChooser = function (fc) {
    var select = Widgets_BetterSelect["initSelect$prime"]("course")(courseListExpSelAff(Data_Maybe.maybe(GraphQL_Type.dummyId)(function (v) {
        return v.student.id;
    })(fc.doc)))({
        placeholder: new Data_Maybe.Just(Translate.lookupTranslation("select-choose-search")),
        deselectLabel: Widgets_BetterSelect.defSelectConfig.deselectLabel,
        allowDeselect: Widgets_BetterSelect.defSelectConfig.allowDeselect
    });
    var tabs = [ {
        label: Translate.lookupTranslation("expectations-all-curriculum"),
        query: (function () {
            var $166 = API_Expectations.courseExpectationsQuery([  ]);
            return function ($167) {
                return runGraphQL($166(GraphQL_Type.typedId($167)));
            };
        })(),
        select: select
    }, {
        label: Translate.lookupTranslation("expectations-overall"),
        query: (function () {
            var $168 = API_Expectations.courseExpectationsQuery([ API_Expectations.Overall.value ]);
            return function ($169) {
                return runGraphQL($168(GraphQL_Type.typedId($169)));
            };
        })(),
        select: select
    } ];
    var state = Widgets_ExpectationPicker.initExpectationPicker({
        tabs: tabs
    });
    return bind(expectationPicker(state))(function (endState) {
        return pure(Widgets_ExpectationPicker.selectedExpectations(endState));
    });
};
var courseExpectationsWidget = function (subform) {
    return function (v) {
        var load = function (fc) {
            return function (json) {
                return map4(wrapFormWidget)(widgetFromExpectationData(fc)(subform)(json));
            };
        };
        return wrapFormWidget2(Widgets_Form_List.modalListWidget({
            id: v.id,
            label: v.label,
            cls: v.cls,
            addBtns: [ {
                label: Translate.lookupTranslation("course-choose-expectation"),
                newEntries: function (fc) {
                    return bind(map2(fromFoldable)(runCourseExpectationChooser(fc)))(function (exps) {
                        return traverse(widgetFromExpectation(fc)(subform))(exps);
                    });
                }
            }, {
                label: Translate.lookupTranslation("course-insert-expectation"),
                newEntries: function (v1) {
                    return pure([ wrapFormWidget(subform) ]);
                }
            } ],
            load: load,
            loadExist: function (fc) {
                return function (v1) {
                    return function (json) {
                        return load(fc)(json);
                    };
                };
            }
        }));
    };
};
export {
    courseExpectationsWidget,
    runCourseExpectationChooser,
    courseListExpSelAff,
    occuListExpSelAff,
    employerExpectationsWidget,
    runEmployerExpectationChooser,
    widgetFromExpectation,
    widgetFromExpectationData,
    ExpectationWidget,
    showEW,
    expectFW
};
