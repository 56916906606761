// Generated by purs version 0.15.7
import * as Data_Ordering from "../Data.Ordering/index.js";
var Trace = /* #__PURE__ */ (function () {
    function Trace() {

    };
    Trace.value = new Trace();
    return Trace;
})();
var Debug = /* #__PURE__ */ (function () {
    function Debug() {

    };
    Debug.value = new Debug();
    return Debug;
})();
var Info = /* #__PURE__ */ (function () {
    function Info() {

    };
    Info.value = new Info();
    return Info;
})();
var Warn = /* #__PURE__ */ (function () {
    function Warn() {

    };
    Warn.value = new Warn();
    return Warn;
})();
var $$Error = /* #__PURE__ */ (function () {
    function $$Error() {

    };
    $$Error.value = new $$Error();
    return $$Error;
})();
var eqLogLevel = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Trace && y instanceof Trace) {
                return true;
            };
            if (x instanceof Debug && y instanceof Debug) {
                return true;
            };
            if (x instanceof Info && y instanceof Info) {
                return true;
            };
            if (x instanceof Warn && y instanceof Warn) {
                return true;
            };
            if (x instanceof $$Error && y instanceof $$Error) {
                return true;
            };
            return false;
        };
    }
};
var ordLogLevel = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Trace && y instanceof Trace) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Trace) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Trace) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Debug && y instanceof Debug) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Debug) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Debug) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Info && y instanceof Info) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Info) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Info) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Warn && y instanceof Warn) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Warn) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Warn) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof $$Error && y instanceof $$Error) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Data.Log.Level (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqLogLevel;
    }
};
export {
    Trace,
    Debug,
    Info,
    Warn,
    $$Error as Error,
    eqLogLevel,
    ordLogLevel
};
