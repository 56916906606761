// Generated by purs version 0.15.7
import * as API_FormType from "../API.FormType/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Class_Console from "../Effect.Class.Console/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Widgets_Form from "../Widgets.Form/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var div = /* #__PURE__ */ Concur_React_DOM.div(/* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray))(Concur_Core_Types.widgetShiftMap);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.bindWidget);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var log = /* #__PURE__ */ Effect_Class_Console.log(Effect_Class.monadEffectEffect);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var previewWidget = function (id) {
    var ignoreAction = function (v) {
        return v.value0.state;
    };
    var displayFormWidget = function (ft) {
        return bind(div([ Concur_React_Props.className("step-container") ])([ div([ Concur_React_Props.className("step-preview") ])([ map(ignoreAction)(Widgets_Form.formWidget(Widgets_Form_Class.blankFormContext)(ft)) ]) ]))(function (newft) {
            return displayFormWidget(newft);
        });
    };
    var parseForm = function (v) {
        if (v instanceof Data_Either.Left) {
            return $$Error.handleCritical(new $$Error.WithContext(new $$Error.InModule("Steps.FormPreview.previewWidget"), new $$Error.WithContext(new $$Error.DuringAction("parsing FormType from API"), v.value0)));
        };
        if (v instanceof Data_Either.Right) {
            return displayFormWidget(Widgets_Form.fromFormType(v.value0));
        };
        throw new Error("Failed pattern match at Steps.FormPreview (line 38, column 9 - line 41, column 16): " + [ v.constructor.name ]);
    };
    return discard(liftEffect(log("Previewing form: " + show(id))))(function () {
        return bind(liftAff(API_FormType.formQuery(GraphQL_Type.typedId(show(id)))))(function (form) {
            return parseForm(form);
        });
    });
};
export {
    previewWidget
};
