"use strict";

export const runEmployerModal = function(eid) {
    return function(_, onSuccess) {
        window.addEventListener("closedEmployerModal", function (ev) {
            if (ev.detail.fromCancel) {
                onSuccess(null);
            } else {
                onSuccess(ev.detail.clientId.toString());
            }
        });
        Livewire.emit('openModal', 'employers.create-employer', {mode: 1, clientId: eid});
        return function(_a, _b, onCancelled) {
            onCancelled();
        };
    };
}

