// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
var debugLog$prime = function (msg) {
    return function (val) {
        return $foreign.debug_log_msg(msg, val);
    };
};
var debugTrace$prime = function (msg) {
    return function (val) {
        var x = debugLog$prime(msg)(val);
        return val;
    };
};
var debugLog = function (val) {
    return $foreign.debug_log(val);
};
var debugTrace = function (val) {
    var x = debugLog(val);
    return val;
};
export {
    debug_log,
    debug_log_msg
} from "./foreign.js";
export {
    debugLog,
    debugLog$prime,
    debugTrace,
    debugTrace$prime
};
