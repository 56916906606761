// Generated by purs version 0.15.7
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_List_Types.semigroupList);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_List_Types.bindList);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_List_Types.applicativeList);
var sum = /* #__PURE__ */ Data_Foldable.sum(Data_List_Types.foldableList);
var map = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var product = /* #__PURE__ */ Data_Foldable.product(Data_List_Types.foldableList);
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_List_Types.foldableList);
var foldr = /* #__PURE__ */ Data_Foldable.foldr(Data_List_Types.foldableList);
var fold = /* #__PURE__ */ Data_Foldable.fold(Data_List_Types.foldableList);
var foldMap = /* #__PURE__ */ Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_List_Types.monoidList);
var sequence = /* #__PURE__ */ Data_Traversable.sequence(Data_List_Types.traversableList);
var apply = /* #__PURE__ */ Control_Apply.apply(Data_List_Types.applyList);
var Free = function (x) {
    return x;
};
var showFree = function (dictShow) {
    var show = Data_Show.show(Data_List_Types.showList(Data_List_Types.showList(dictShow)));
    return {
        show: function (v) {
            return "(Free " + (show(v) + ")");
        }
    };
};
var semiringFree = /* #__PURE__ */ (function () {
    return {
        add: function (v) {
            return function (v1) {
                return append1(v)(v1);
            };
        },
        zero: Data_List_Types.Nil.value,
        mul: function (v) {
            return function (v1) {
                return bind(v)(function (xs) {
                    return bind(v1)(function (ys) {
                        return pure(append1(xs)(ys));
                    });
                });
            };
        },
        one: Data_List.singleton(Data_List_Types.Nil.value)
    };
})();
var newtypeFree = {
    Coercible0: function () {
        return undefined;
    }
};
var liftFree = function (dictSemiring) {
    var sum1 = sum(dictSemiring);
    var product1 = product(dictSemiring);
    return function (f) {
        return function (v) {
            return sum1(map((function () {
                var $83 = map(f);
                return function ($84) {
                    return product1($83($84));
                };
            })())(v));
        };
    };
};
var functorFree = {
    map: function (fn) {
        return function (v) {
            return map(map(fn))(v);
        };
    }
};
var map1 = /* #__PURE__ */ Data_Functor.map(functorFree);
var free = function (a) {
    return Data_List.singleton(Data_List.singleton(a));
};
var lowerFree = function (dictSemiring) {
    return function (f) {
        return function (a) {
            return f(free(a));
        };
    };
};
var foldableFree = {
    foldl: function (fn) {
        return function (accum) {
            return function (v) {
                return foldl(foldl(fn))(accum)(v);
            };
        };
    },
    foldr: function (fn) {
        return function (accum) {
            return function (v) {
                return foldr(Data_Function.flip(foldr(fn)))(accum)(v);
            };
        };
    },
    foldMap: function (dictMonoid) {
        var fold1 = fold(dictMonoid);
        return function (fn) {
            return function (v) {
                return fold1(foldMap(map(fn))(v));
            };
        };
    }
};
var traversableFree = {
    sequence: function (dictApplicative) {
        var map2 = Data_Functor.map((dictApplicative.Apply0()).Functor0());
        var sequence1 = sequence(dictApplicative);
        return function (v) {
            return map2(Free)(sequence1(map(sequence1)(v)));
        };
    },
    traverse: function (dictApplicative) {
        return function (fn) {
            return function (freeA) {
                return Data_Traversable.sequence(traversableFree)(dictApplicative)(map1(fn)(freeA));
            };
        };
    },
    Functor0: function () {
        return functorFree;
    },
    Foldable1: function () {
        return foldableFree;
    }
};
var eqFree = function (dictEq) {
    var eq = Data_Eq.eq(Data_List_Types.eqList(Data_List_Types.eqList(dictEq)));
    return {
        eq: function (v) {
            return function (v1) {
                return eq(v)(v1);
            };
        }
    };
};
var ordFree = function (dictOrd) {
    var compare = Data_Ord.compare(Data_List_Types.ordList(Data_List_Types.ordList(dictOrd)));
    var eqFree1 = eqFree(dictOrd.Eq0());
    return {
        compare: function (v) {
            return function (v1) {
                return compare(v)(v1);
            };
        },
        Eq0: function () {
            return eqFree1;
        }
    };
};
var applyFree = {
    apply: function (v) {
        return function (v1) {
            return bind(v)(function (fns) {
                return bind(v1)(function (xs) {
                    return pure(apply(fns)(xs));
                });
            });
        };
    },
    Functor0: function () {
        return functorFree;
    }
};
var applicativeFree = {
    pure: free,
    Apply0: function () {
        return applyFree;
    }
};
export {
    Free,
    free,
    liftFree,
    lowerFree,
    newtypeFree,
    showFree,
    eqFree,
    ordFree,
    semiringFree,
    functorFree,
    applyFree,
    applicativeFree,
    foldableFree,
    traversableFree
};
