"use strict";

export const blockPageUnload = function() {
    window.onbeforeunload = function() {
        return "Are you sure you want to leave this page? Any unsaved changes will be lost.";
    }
}

export const allowPageUnload = function() {
    window.onbeforeunload = null;
}

// Triggers a save prompt using `confirm` if page unload is currently blocked.
// Returns true if unload is not blocked, or if the user clicked ok in the dialog.
export const manualSavePrompt = function() {
    if (window.onbeforeunload == null) {
        return true;
    } else {
        return confirm("Are you sure you want to leave this page? Any unsaved changes will be lost.")
    }
}
