// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Nullable from "../Data.Nullable/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Aff_Compat from "../Effect.Aff.Compat/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var employerCreateModal = function (id) {
    return map(function (v) {
        return map1(GraphQL_Type.typedId)(v);
    })(map(Data_Nullable.toMaybe)(liftAff(Effect_Aff_Compat.fromEffectFnAff($foreign.runEmployerModal(Data_Nullable.toNullable(map1(GraphQL_Type.innerId)(id)))))));
};
export {
    runEmployerModal
} from "./foreign.js";
export {
    employerCreateModal
};
