// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Affjax from "../Affjax/index.js";
import * as Affjax_RequestBody from "../Affjax.RequestBody/index.js";
import * as Affjax_ResponseFormat from "../Affjax.ResponseFormat/index.js";
import * as Affjax_Web from "../Affjax.Web/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Combinators from "../Data.Argonaut.Decode.Combinators/index.js";
import * as Data_Argonaut_Decode_Decoders from "../Data.Argonaut.Decode.Decoders/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HTTP_Method from "../Data.HTTP.Method/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as $$Error from "../Error/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
import * as Web_XHR_FormData from "../Web.XHR.FormData/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var getField = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonJson));
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var gEncodeJsonCons = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString);
var variablesIsSymbol = {
    reflectSymbol: function () {
        return "variables";
    }
};
var queryIsSymbol = {
    reflectSymbol: function () {
        return "query";
    }
};
var bind1 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var map = /* #__PURE__ */ Data_Functor.map(Effect_Aff.functorAff);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Either.bindEither);
var queryStringParams = function (dictFoldable) {
    var intercalate = Data_Foldable.intercalate(dictFoldable)(Data_Monoid.monoidString);
    return function (params) {
        return "(" + (intercalate(",")(params) + ")");
    };
};
var getResultData = function (dictDecodeJson) {
    var getField1 = Data_Argonaut_Decode_Combinators.getField(dictDecodeJson);
    return function (json) {
        return $$Error.wrapJsonError(bind(Data_Argonaut_Decode_Decoders.decodeJObject(json))(function (obj) {
            return getField1(obj)("data");
        }));
    };
};
var extractErrors = function (obj) {
    var collapse = function (v) {
        if (v instanceof Data_Either.Right) {
            return v.value0;
        };
        if (v instanceof Data_Either.Left) {
            return new $$Error.WithContext(new $$Error.DuringAction("gathering information about another error"), v.value0);
        };
        throw new Error("Failed pattern match at GraphQL (line 71, column 5 - line 71, column 65): " + [ v.constructor.name ]);
    };
    return collapse($$Error["wrapJsonError$prime"](Data_Argonaut_Core.fromObject(obj))(bind(getField(obj)("errors"))(function (v) {
        return new Data_Either.Right(new $$Error.QueryError(v));
    })));
};
var handleResult = function (result) {
    return pure((function () {
        if (result instanceof Data_Either.Left) {
            return new Data_Either.Left(new $$Error.AffJaxError(result.value0));
        };
        if (result instanceof Data_Either.Right) {
            var v = Data_Argonaut_Decode_Decoders.decodeJObject(result.value0.body);
            if (v instanceof Data_Either.Right && Foreign_Object.member("errors")(v.value0)) {
                return new Data_Either.Left(extractErrors(v.value0));
            };
            return new Data_Either.Right(result.value0.body);
        };
        throw new Error("Failed pattern match at GraphQL (line 58, column 28 - line 63, column 32): " + [ result.constructor.name ]);
    })());
};
var fileQuery = function (dictEncodeJson) {
    var encodeJson = Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(gEncodeJsonCons(Data_Argonaut_Encode_Class.gEncodeJsonCons(dictEncodeJson)(Data_Argonaut_Encode_Class.gEncodeJsonNil)(variablesIsSymbol)())(queryIsSymbol)())());
    return function (qstr) {
        return function (vars) {
            return function (vname) {
                return function (fname) {
                    return function (blob) {
                        var content = encodeJson({
                            query: qstr,
                            variables: vars
                        });
                        return bind1(liftEffect(function __do() {
                            var fd = Web_XHR_FormData["new"]();
                            Web_XHR_FormData.append("operations")(Data_Argonaut_Core.stringify(content))(fd)();
                            Web_XHR_FormData.append("map")("{ \"0\": [ \"variables." + (vname + "\" ] }"))(fd)();
                            Web_XHR_FormData.appendBlob("0")(blob)(fname)(fd)();
                            return fd;
                        }))(function (formData) {
                            return bind1(Affjax_Web.request({
                                method: new Data_Either.Left(Data_HTTP_Method.POST.value),
                                url: $foreign.graphqlEndpoint,
                                headers: Affjax.defaultRequest.headers,
                                content: new Data_Maybe.Just(new Affjax_RequestBody.FormData(formData)),
                                username: Affjax.defaultRequest.username,
                                password: Affjax.defaultRequest.password,
                                withCredentials: true,
                                responseFormat: Affjax_ResponseFormat.json,
                                timeout: Affjax.defaultRequest.timeout
                            }))(function (res) {
                                return handleResult(res);
                            });
                        });
                    };
                };
            };
        };
    };
};
var query = function (dictEncodeJson) {
    var encodeJson = Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson);
    var encodeJson1 = Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(gEncodeJsonCons(Data_Argonaut_Encode_Class.gEncodeJsonCons(dictEncodeJson)(Data_Argonaut_Encode_Class.gEncodeJsonNil)(variablesIsSymbol)())(queryIsSymbol)())());
    return function (qstr) {
        return function (vars) {
            var queryCtx = new $$Error.InQuery({
                query: qstr,
                vars: encodeJson(vars)
            });
            return map($$Error.tagContext(queryCtx))((function () {
                var content = encodeJson1({
                    query: qstr,
                    variables: vars
                });
                return bind1(Affjax_Web.request({
                    method: new Data_Either.Left(Data_HTTP_Method.POST.value),
                    url: $foreign.graphqlEndpoint,
                    headers: Affjax.defaultRequest.headers,
                    content: new Data_Maybe.Just(new Affjax_RequestBody.Json(content)),
                    username: Affjax.defaultRequest.username,
                    password: Affjax.defaultRequest.password,
                    withCredentials: true,
                    responseFormat: Affjax_ResponseFormat.json,
                    timeout: Affjax.defaultRequest.timeout
                }))(function (res) {
                    return handleResult(res);
                });
            })());
        };
    };
};
var queryData = function (dictEncodeJson) {
    var query1 = query(dictEncodeJson);
    return function (dictDecodeJson) {
        var getResultData1 = getResultData(dictDecodeJson);
        return function (qstr) {
            return function (vars) {
                return bind1(query1(qstr)(vars))(function (res) {
                    return pure(bindFlipped(getResultData1)(res));
                });
            };
        };
    };
};
export {
    graphqlEndpoint
} from "./foreign.js";
export {
    query,
    queryData,
    handleResult,
    extractErrors,
    getResultData,
    queryStringParams,
    fileQuery
};
