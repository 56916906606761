// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
var div = /* #__PURE__ */ Concur_React_DOM.div(/* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray))(Concur_Core_Types.widgetShiftMap);
var loadingWidget = /* #__PURE__ */ div([ /* #__PURE__ */ Concur_React_Props.className("loading-spinner") ])([ /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget)("Loading") ]);
var loadingWidgetOverlay = /* #__PURE__ */ div([ /* #__PURE__ */ Concur_React_Props.className("loading-overlay") ])([ loadingWidget ]);
export {
    loadingWidget,
    loadingWidgetOverlay
};
