"use strict";

import * as SignaturePad from '../../SignatureES6.js';

export const classSignaturePad = SignaturePad.default;

export const getCanvasBlobFromId = SignaturePad.getCanvasBlobFromId;

export const signatureDataUrl = function(blob) {
    return URL.createObjectURL(blob)
}
