// Generated by purs version 0.15.7
import * as API_SelStudent from "../API.SelStudent/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_SubForm from "../Widgets.Form.SubForm/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Widgets_Form_Class.fwrFunctor);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h2$prime = /* #__PURE__ */ Concur_React_DOM["h2$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var toWidget = /* #__PURE__ */ Widgets_Form_Class.toWidget(Widgets_Form_SubForm.subFormFW);
var getData = /* #__PURE__ */ Widgets_Form_Class.getData(Widgets_Form_SubForm.subFormFW);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var setData = /* #__PURE__ */ Widgets_Form_Class.setData(Widgets_Form_SubForm.subFormFW);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Either.applicativeEither);
var show = /* #__PURE__ */ Data_Show.show(Widgets_Form_SubForm.showSubForm);
var StudentSubForm = /* #__PURE__ */ (function () {
    function StudentSubForm(value0) {
        this.value0 = value0;
    };
    StudentSubForm.create = function (value0) {
        return new StudentSubForm(value0);
    };
    return StudentSubForm;
})();
var stsfFW = {
    getId: function (v) {
        return v.value0.id;
    },
    asField: function (v) {
        return Data_Maybe.Nothing.value;
    },
    isLeaf: function (v) {
        return false;
    },
    toWidget: function (fc) {
        return function (v) {
            var wrapResult = function (r) {
                return map(StudentSubForm.create)(r);
            };
            var name = Data_Maybe.maybe("NO DOCUMENT SET - STUDENT NAME UNKNOWN")((function () {
                var $38 = API_SelStudent.studentName(fc.account);
                return function ($39) {
                    return $38((function (v1) {
                        return v1.student;
                    })($39));
                };
            })())(fc.doc);
            return map1(wrapResult)(div([ Concur_React_Props.className("student-subform") ])([ h2$prime([ text(name) ]), toWidget(fc)(v.value0) ]));
        };
    },
    getData: function (fc) {
        return function (v) {
            return getData(fc)(v.value0);
        };
    },
    setData: function (fc) {
        return function (v) {
            return function (json) {
                return bind(setData(fc)(v.value0)(json))(function (newsf) {
                    return pure(new StudentSubForm(newsf));
                });
            };
        };
    }
};
var showStudentSubForm = {
    show: function (v) {
        return show(v.value0);
    }
};
export {
    StudentSubForm,
    showStudentSubForm,
    stsfFW
};
