// Generated by purs version 0.15.7
import * as API_SelEmployer from "../API.SelEmployer/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as Internal_Util from "../Internal.Util/index.js";
import * as Record from "../Record/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_BetterSelect from "../Widgets.BetterSelect/index.js";
import * as Widgets_EmployerModal from "../Widgets.EmployerModal/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Common from "../Widgets.Form.Common/index.js";
var intercalate = /* #__PURE__ */ Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString);
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Concur_Core_Types.bindWidget);
var bind = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Concur_Core_Types.widgetMonad));
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var gDecodeJsonCons = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString));
var gDecodeJsonCons1 = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ GraphQL_Type.decRNTY(Data_Argonaut_Decode_Class.decodeJsonString)));
var idIsSymbol = {
    reflectSymbol: function () {
        return "id";
    }
};
var companyIsSymbol = {
    reflectSymbol: function () {
        return "company";
    }
};
var decodeRecord = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons1(Data_Argonaut_Decode_Class.gDecodeJsonNil)(idIsSymbol)()())(companyIsSymbol)()())();
var getEmployer = /* #__PURE__ */ API_SelEmployer.getEmployer(/* #__PURE__ */ GraphQL_Query.jsonQR(decodeRecord)(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsNil)(idIsSymbol)(GraphQL_Shape.scalarShape)()())(companyIsSymbol)(GraphQL_Shape.scalarShape)()())()));
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Concur_Core_Types.widgetMonad));
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var label = /* #__PURE__ */ Concur_React_DOM.label(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var gEncodeJsonCons = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString);
var gEncodeJsonCons1 = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ GraphQL_Type.encRNTY(Data_Argonaut_Encode_Class.encodeJsonJString));
var nameIsSymbol = {
    reflectSymbol: function () {
        return "name";
    }
};
var gEncodeJsonCons2 = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonMaybe(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons1(/* #__PURE__ */ gEncodeJsonCons(Data_Argonaut_Encode_Class.gEncodeJsonNil)(nameIsSymbol)())(idIsSymbol)())()));
var positionIsSymbol = {
    reflectSymbol: function () {
        return "position";
    }
};
var employerIsSymbol = {
    reflectSymbol: function () {
        return "employer";
    }
};
var gEncodeJsonCons3 = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonMaybe(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons1(Data_Argonaut_Encode_Class.gEncodeJsonNil)(idIsSymbol)())(companyIsSymbol)())()))(/* #__PURE__ */ gEncodeJsonCons2(Data_Argonaut_Encode_Class.gEncodeJsonNil)(positionIsSymbol)())(employerIsSymbol)();
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(gEncodeJsonCons3)());
var contactIsSymbol = {
    reflectSymbol: function () {
        return "contact";
    }
};
var encodeJson1 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons2(gEncodeJsonCons3)(contactIsSymbol)())());
var merge = /* #__PURE__ */ Record.merge()();
var gDecodeJsonCons2 = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldMaybe(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons1(/* #__PURE__ */ gDecodeJsonCons(Data_Argonaut_Decode_Class.gDecodeJsonNil)(nameIsSymbol)()())(idIsSymbol)()())()));
var decodeRecord1 = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons2(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldMaybe(decodeRecord))(/* #__PURE__ */ gDecodeJsonCons2(Data_Argonaut_Decode_Class.gDecodeJsonNil)(positionIsSymbol)()())(employerIsSymbol)()())(contactIsSymbol)()())();
var EmployerSelect = function (x) {
    return x;
};
var toFormPos = function (v) {
    return {
        id: v.id,
        name: v.label
    };
};
var toFormCont = function (v) {
    return {
        id: v.id,
        name: v.name
    };
};
var showESelect = {
    show: function (v) {
        return "EmployerSelect " + v.id;
    }
};
var initEmployerSelect = function (v) {
    return {
        id: v.id,
        cls: v.cls,
        label: v.label,
        employer: Widgets_BetterSelect["initSelect$prime"](v.id + "-employer")(API_SelEmployer.employerSearchSelAff(GraphQL_Type.dummyId)(GraphQL_Type.dummyId))({
            placeholder: new Data_Maybe.Just(Translate.lookupTranslation("select-choose-search")),
            deselectLabel: Widgets_BetterSelect.defSelectConfig.deselectLabel,
            allowDeselect: Widgets_BetterSelect.defSelectConfig.allowDeselect
        }),
        position: Widgets_BetterSelect["initSimpleSelect$prime"](v.id + "-position")([  ])({
            placeholder: new Data_Maybe.Just(Translate.lookupTranslation("select-choose-search")),
            deselectLabel: Widgets_BetterSelect.defSelectConfig.deselectLabel,
            allowDeselect: Widgets_BetterSelect.defSelectConfig.allowDeselect
        }),
        contact: Internal_Util.justIf(v.contact)(Widgets_BetterSelect["initSimpleSelect$prime"](v.id + "-contact")([  ])({
            placeholder: new Data_Maybe.Just(Translate.lookupTranslation("select-choose-search")),
            deselectLabel: Widgets_BetterSelect.defSelectConfig.deselectLabel,
            allowDeselect: Widgets_BetterSelect.defSelectConfig.allowDeselect
        })),
        loadSelection: Data_Maybe.Nothing.value
    };
};
var fromFormPos = function (v) {
    return {
        id: v.id,
        label: v.name
    };
};
var fromFormCont = function (v) {
    return {
        id: v.id,
        name: v.name
    };
};
var employerSelectFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return true;
    },
    asField: function (v) {
        return new Data_Maybe.Just(new GraphQL_Shape.QScalar(v.id));
    },
    toWidget: function (fc) {
        return function (v) {
            var truncName = function (inp) {
                var parts = Data_String_Common.split(" ")(inp);
                var $188 = Data_Array.length(parts) > 2;
                if ($188) {
                    return intercalate(" ")(parts) + "...";
                };
                return inp;
            };
            var studentId = Data_Maybe.maybe(GraphQL_Type.dummyId)(function (v1) {
                return v1.student.id;
            })(fc.doc);
            var docId = Data_Maybe.maybe(GraphQL_Type.dummyId)(function (v1) {
                return v1.id;
            })(fc.doc);
            var state = {
                employer: Widgets_BetterSelect.setSearchAff(v.employer)(API_SelEmployer.employerSearchSelAff(docId)(studentId)),
                cls: v.cls,
                contact: v.contact,
                id: v.id,
                label: v.label,
                loadSelection: v.loadSelection,
                position: v.position
            };
            var employerSelID = Widgets_Form_Class.fieldId(fc)(state.employer.id);
            var onSelectedEmployer = function (empSel) {
                return {
                    employer: empSel,
                    position: Data_Function.flip(Widgets_BetterSelect.setSelected)(Data_Maybe.Nothing.value)(Widgets_BetterSelect.setSearchAff(state.position)(Data_Maybe.maybe(Widgets_BetterSelect.emptySearchAff)(function (e) {
                        return API_SelEmployer.positionSelAff(docId)(studentId)(e.value.id);
                    })(Widgets_BetterSelect.selectedItem(empSel)))),
                    contact: Data_Function.flip(map)(state.contact)(function (contact) {
                        return Data_Function.flip(Widgets_BetterSelect.setSelected)(Data_Maybe.Nothing.value)(Widgets_BetterSelect.setSearchAff(contact)(Data_Maybe.maybe(Widgets_BetterSelect.emptySearchAff)(function (e) {
                            return API_SelEmployer.contactSelAff(docId)(studentId)(e.value.id);
                        })(Widgets_BetterSelect.selectedItem(empSel))));
                    }),
                    cls: state.cls,
                    id: state.id,
                    label: state.label,
                    loadSelection: state.loadSelection
                };
            };
            var positionSelID = Widgets_Form_Class.fieldId(fc)(state.position.id);
            var setCreatedEmployer = function (id) {
                return bindFlipped($$Error.wrapCritical)(Control_Monad_Except_Trans.runExceptT(bind(liftAff(runGraphQL(getEmployer(id)(GraphQL_Type.maybeId(map(function (v1) {
                    return v1.id;
                })(fc.doc)))(GraphQL_Type.maybeId(map(function (v1) {
                    return v1.student.id;
                })(fc.doc))))))(function (emp) {
                    var item = {
                        label: emp.company,
                        value: emp
                    };
                    return pure({
                        employer: Widgets_BetterSelect.setSelected(state.employer)(new Data_Maybe.Just(item)),
                        position: Widgets_BetterSelect.setSearchAff(state.position)(API_SelEmployer.positionSelAff(docId)(studentId)(emp.id)),
                        contact: Data_Function.flip(map)(state.contact)(function (contact) {
                            return Widgets_BetterSelect.setSearchAff(contact)(API_SelEmployer.contactSelAff(docId)(studentId)(emp.id));
                        }),
                        cls: state.cls,
                        id: state.id,
                        label: state.label,
                        loadSelection: state.loadSelection
                    });
                })));
            };
            var editBtn = function (emp) {
                var btn = button([ Concur_React_Props.className("btn employer-add-btn"), Concur_React_Props.onClick ])([ text(Translate.lookupTranslation("edit-employer") + truncName(emp.company)) ]);
                return bind1(btn)(function () {
                    return bind1(alt(Widgets_EmployerModal.employerCreateModal(new Data_Maybe.Just(emp.id)))(Internal_ConcurUtil.loopInfinitely(btn)))(function (eid) {
                        if (eid instanceof Data_Maybe.Just) {
                            return setCreatedEmployer(eid.value0);
                        };
                        if (eid instanceof Data_Maybe.Nothing) {
                            return pure1(state);
                        };
                        throw new Error("Failed pattern match at Widgets.Form.EmployerSelect (line 131, column 13 - line 133, column 38): " + [ eid.constructor.name ]);
                    });
                });
            };
            var contactSelId = function (contact) {
                return Widgets_Form_Class.fieldId(fc)(contact.id);
            };
            var canCreateEmployers = Data_Maybe.maybe(false)(function (v1) {
                return v1.can.create_employers;
            })(fc.doc);
            var addBtn = (function () {
                var btn = button([ Concur_React_Props.className("btn employer-add-btn"), Concur_React_Props.onClick ])([ text(Translate.lookupTranslation("add-employer")) ]);
                return bind1(btn)(function () {
                    return bind1(alt(Widgets_EmployerModal.employerCreateModal(Data_Maybe.Nothing.value))(Internal_ConcurUtil.loopInfinitely(btn)))(function (eid) {
                        if (eid instanceof Data_Maybe.Just) {
                            return setCreatedEmployer(eid.value0);
                        };
                        if (eid instanceof Data_Maybe.Nothing) {
                            return pure1(state);
                        };
                        throw new Error("Failed pattern match at Widgets.Form.EmployerSelect (line 119, column 13 - line 121, column 38): " + [ eid.constructor.name ]);
                    });
                });
            })();
            return map1(map2(Widgets_Form_Class.formWidgetState)(EmployerSelect))(div([ Concur_React_Props.className(Widgets_Form_Class.fieldClass(state)), Concur_React_Props.key(state.id) ])([ Widgets_Form_Common["widgetLabelS$prime"](fc)(state), div([ Concur_React_Props.className("search-subform-select-wrapper w-full") ])(Data_Array.catMaybes([ new Data_Maybe.Just(label([ Concur_React_Props.htmlFor(employerSelID) ])([ text(Translate.lookupTranslation("employer")) ])), new Data_Maybe.Just(map1(onSelectedEmployer)(Widgets_BetterSelect.selectWidgetWithId(employerSelID)(state.employer))), Internal_Util.justIf(canCreateEmployers)(div([ Concur_React_Props.className("employer-edit-buttons") ])(Data_Array.catMaybes([ new Data_Maybe.Just(addBtn), map(editBtn)(map(function (v1) {
                return v1.value;
            })(Widgets_BetterSelect.selectedItem(state.employer))) ]))), new Data_Maybe.Just(label([ Concur_React_Props.htmlFor(employerSelID) ])([ text(Translate.lookupTranslation("position")) ])), new Data_Maybe.Just(map1(function (v1) {
                return {
                    position: v1,
                    cls: state.cls,
                    contact: state.contact,
                    employer: state.employer,
                    id: state.id,
                    label: state.label,
                    loadSelection: state.loadSelection
                };
            })(Widgets_BetterSelect.selectWidgetWithId(positionSelID)(state.position))), Data_Function.flip(map)(state.contact)(function (contact) {
                return label([ Concur_React_Props.htmlFor(contactSelId(contact)) ])([ text(Translate.lookupTranslation("contact")) ]);
            }), Data_Function.flip(map)(state.contact)(function (contact) {
                return map1(map2(function (v1) {
                    return {
                        contact: v1,
                        cls: state.cls,
                        employer: state.employer,
                        id: state.id,
                        label: state.label,
                        loadSelection: state.loadSelection,
                        position: state.position
                    };
                })(Data_Maybe.Just.create))(Widgets_BetterSelect.selectWidgetWithId(contactSelId(contact))(contact));
            }) ])) ]));
        };
    },
    getData: function (v) {
        return function (v1) {
            var always = {
                employer: map(function (e) {
                    return {
                        id: e.value.id,
                        company: e.value.company
                    };
                })(Widgets_BetterSelect.selectedItem(v1.employer)),
                position: map(map2(toFormPos)(function (v2) {
                    return v2.value;
                }))(Widgets_BetterSelect.selectedItem(v1.position))
            };
            if (v1.contact instanceof Data_Maybe.Nothing) {
                return encodeJson(always);
            };
            if (v1.contact instanceof Data_Maybe.Just) {
                return encodeJson1(merge(always)({
                    contact: map(map2(toFormCont)(function (v2) {
                        return v2.value;
                    }))(Widgets_BetterSelect.selectedItem(v1.contact.value0))
                }));
            };
            throw new Error("Failed pattern match at Widgets.Form.EmployerSelect (line 155, column 41 - line 158, column 73): " + [ v1.contact.constructor.name ]);
        };
    },
    setData: function (fc) {
        return function (v) {
            var studentId = Data_Maybe.maybe(GraphQL_Type.dummyId)(function (v1) {
                return v1.student.id;
            })(fc.doc);
            var docId = Data_Maybe.maybe(GraphQL_Type.dummyId)(function (v1) {
                return v1.id;
            })(fc.doc);
            return Widgets_Form_Class.setFromJson(employerSelectFW)(decodeRecord1)(v)(function (v1) {
                return new Data_Either.Right({
                    employer: Widgets_BetterSelect.setSelected(v.employer)(map(function (e) {
                        return {
                            label: e.company,
                            value: e
                        };
                    })(v1.employer)),
                    position: Data_Function.flip(Widgets_BetterSelect.setSelected)(map(function (p) {
                        return {
                            label: p.label,
                            value: p
                        };
                    })(map(fromFormPos)(v1.position)))(Widgets_BetterSelect.setSearchAff(v.position)(Data_Maybe.maybe(Widgets_BetterSelect.emptySearchAff)(function (e) {
                        return API_SelEmployer.positionSelAff(docId)(studentId)(e.id);
                    })(v1.employer))),
                    contact: Data_Function.flip(map)(v.contact)(function (contact) {
                        return Data_Function.flip(Widgets_BetterSelect.setSelected)(map(function (p) {
                            return {
                                label: p.name,
                                value: p
                            };
                        })(map(fromFormCont)(v1.contact)))(Widgets_BetterSelect.setSearchAff(contact)(Data_Maybe.maybe(Widgets_BetterSelect.emptySearchAff)(function (e) {
                            return API_SelEmployer.contactSelAff(docId)(studentId)(e.id);
                        })(v1.employer)));
                    }),
                    loadSelection: v.loadSelection,
                    id: v.id,
                    cls: v.cls,
                    label: v.label
                });
            });
        };
    }
};
export {
    EmployerSelect,
    initEmployerSelect,
    fromFormPos,
    toFormPos,
    fromFormCont,
    toFormCont,
    showESelect,
    employerSelectFW
};
