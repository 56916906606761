// Generated by purs version 0.15.7
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Combinators from "../Data.Argonaut.Decode.Combinators/index.js";
import * as Data_Argonaut_Decode_Decoders from "../Data.Argonaut.Decode.Decoders/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Symbol from "../Data.Symbol/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as $$Error from "../Error/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
import * as GraphQL from "../GraphQL/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Record from "../Record/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
import * as Web_File_File from "../Web.File.File/index.js";
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var $$null = /* #__PURE__ */ Data_Foldable["null"](Data_Foldable.foldableArray);
var intercalate = /* #__PURE__ */ Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var getField = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeForeignObject(Data_Argonaut_Decode_Class.decodeJsonJson));
var getField1 = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(Data_Argonaut_Decode_Class.decodeJsonJson);
var bind1 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var encodeForeignObject = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeForeignObject(Data_Argonaut_Encode_Class.encodeJsonJson);
var query = /* #__PURE__ */ GraphQL.query(encodeForeignObject);
var except = /* #__PURE__ */ Control_Monad_Except_Trans.except(Effect_Aff.applicativeAff);
var fileQuery = /* #__PURE__ */ GraphQL.fileQuery(encodeForeignObject);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var merge = /* #__PURE__ */ Record.merge()();
var QueryVar = /* #__PURE__ */ (function () {
    function QueryVar(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    QueryVar.create = function (value0) {
        return function (value1) {
            return new QueryVar(value0, value1);
        };
    };
    return QueryVar;
})();
var FileUpload = /* #__PURE__ */ (function () {
    function FileUpload(value0) {
        this.value0 = value0;
    };
    FileUpload.create = function (value0) {
        return new FileUpload(value0);
    };
    return FileUpload;
})();
var Query = /* #__PURE__ */ (function () {
    function Query(value0) {
        this.value0 = value0;
    };
    Query.create = function (value0) {
        return new Query(value0);
    };
    return Query;
})();
var varToString = function (v) {
    return "$" + (v.value0 + (": " + GraphQL_Type.typeToString(v.value1)));
};
var varFiles = function (dict) {
    return dict.varFiles;
};
var unitQR = {
    queryResultDecode: function (v) {
        return function (v1) {
            return new Data_Either.Right(Data_Unit.unit);
        };
    },
    queryResultShape: function (v) {
        return [  ];
    },
    queryResultArgs: function (v) {
        return [  ];
    }
};
var toVars = function (dict) {
    return dict.toVars;
};
var rltvfNil = {
    rlFiles: function (v) {
        return function (v1) {
            return function (v2) {
                return [  ];
            };
        };
    }
};
var rltvNil = {
    rlToVars: function (v) {
        return function (v1) {
            return [  ];
        };
    },
    rlEncodeVars: function (v) {
        return function (v1) {
            return Foreign_Object.empty;
        };
    }
};
var rlToVars = function (dict) {
    return dict.rlToVars;
};
var rlFiles = function (dict) {
    return dict.rlFiles;
};
var rltvfConsFU = function (dictRLTVFiles) {
    var rlFiles1 = rlFiles(dictRLTVFiles);
    return function (dictIsSymbol) {
        var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
        var get = Record.get(dictIsSymbol)();
        return function () {
            return {
                rlFiles: function (pfx) {
                    return function (v) {
                        return function (rec) {
                            var thisFile = new Data_Tuple.Tuple(pfx + reflectSymbol(Type_Proxy["Proxy"].value), get(Type_Proxy["Proxy"].value)(rec));
                            return Data_Array.cons(thisFile)(rlFiles1(pfx)(Type_Proxy["Proxy"].value)(rec));
                        };
                    };
                }
            };
        };
    };
};
var rltvfConsOther = function (dictRLTVFiles) {
    var rlFiles1 = rlFiles(dictRLTVFiles);
    return function (dictIsSymbol) {
        return function () {
            return {
                rlFiles: function (pfx) {
                    return function (v) {
                        return function (rec) {
                            return rlFiles1(pfx)(Type_Proxy["Proxy"].value)(rec);
                        };
                    };
                }
            };
        };
    };
};
var rltvfConsRec = function (dictRLTVFiles) {
    var rlFiles1 = rlFiles(dictRLTVFiles);
    return function (dictIsSymbol) {
        var get = Record.get(dictIsSymbol)();
        var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
        return function () {
            return function () {
                return function (dictRLTVFiles1) {
                    var rlFiles2 = rlFiles(dictRLTVFiles1);
                    return {
                        rlFiles: function (pfx) {
                            return function (v) {
                                return function (rec) {
                                    var irec = get(Type_Proxy["Proxy"].value)(rec);
                                    var thisRec = rlFiles2(pfx + (reflectSymbol(Type_Proxy["Proxy"].value) + "."))(Type_Proxy["Proxy"].value)(irec);
                                    return append1(thisRec)(rlFiles1(pfx)(Type_Proxy["Proxy"].value)(rec));
                                };
                            };
                        }
                    };
                };
            };
        };
    };
};
var rltvfNamedTypeRec = function (dictRLTVFiles) {
    var rlFiles1 = rlFiles(dictRLTVFiles);
    return function (dictIsSymbol) {
        var get = Record.get(dictIsSymbol)();
        var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
        return function () {
            return function () {
                return function (dictRLTVFiles1) {
                    var rlFiles2 = rlFiles(dictRLTVFiles1);
                    return {
                        rlFiles: function (pfx) {
                            return function (v) {
                                return function (rec) {
                                    var v1 = get(Type_Proxy["Proxy"].value)(rec);
                                    var thisRec = rlFiles2(pfx + (reflectSymbol(Type_Proxy["Proxy"].value) + "."))(Type_Proxy["Proxy"].value)(v1.value0);
                                    return append1(thisRec)(rlFiles1(pfx)(Type_Proxy["Proxy"].value)(rec));
                                };
                            };
                        }
                    };
                };
            };
        };
    };
};
var rlEncodeVars = function (dict) {
    return dict.rlEncodeVars;
};
var rlTVars = function (dictRLToVars) {
    var rlToVars1 = rlToVars(dictRLToVars);
    var rlEncodeVars1 = rlEncodeVars(dictRLToVars);
    return function (dictRLTVFiles) {
        var rlFiles1 = rlFiles(dictRLTVFiles);
        return function () {
            return {
                toVars: function (rec) {
                    return rlToVars1(Type_Proxy["Proxy"].value)(rec);
                },
                encodeVars: rlEncodeVars1(Type_Proxy["Proxy"].value),
                varFiles: rlFiles1("")(Type_Proxy["Proxy"].value)
            };
        };
    };
};
var rltvCons = function (dictRLToVars) {
    var rlToVars1 = rlToVars(dictRLToVars);
    var rlEncodeVars1 = rlEncodeVars(dictRLToVars);
    return function (dictIsSymbol) {
        var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
        var $$delete = Record["delete"](dictIsSymbol)()();
        var get = Record.get(dictIsSymbol)();
        return function (dictHasQType) {
            var gqlType = GraphQL_Type.gqlType(dictHasQType);
            return function (dictEncodeJson) {
                var encodeJson = Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson);
                return function () {
                    return function () {
                        return {
                            rlToVars: function (v) {
                                return function (rec) {
                                    var ty = gqlType(Type_Proxy["Proxy"].value);
                                    var name = reflectSymbol(Type_Proxy["Proxy"].value);
                                    var thisVar = new QueryVar(name, ty);
                                    return Data_Array.cons(thisVar)(rlToVars1(Type_Proxy["Proxy"].value)($$delete(Type_Proxy["Proxy"].value)(rec)));
                                };
                            },
                            rlEncodeVars: function (v) {
                                return function (r) {
                                    var name = reflectSymbol(Type_Proxy["Proxy"].value);
                                    var val = encodeJson(get(Type_Proxy["Proxy"].value)(r));
                                    return Foreign_Object.insert(name)(val)(rlEncodeVars1(Type_Proxy["Proxy"].value)($$delete(Type_Proxy["Proxy"].value)(r)));
                                };
                            }
                        };
                    };
                };
            };
        };
    };
};
var rltvDyTyCons = function (dictRLToVars) {
    var rlToVars1 = rlToVars(dictRLToVars);
    var rlEncodeVars1 = rlEncodeVars(dictRLToVars);
    return function (dictIsSymbol) {
        var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
        var get = Record.get(dictIsSymbol)();
        var $$delete = Record["delete"](dictIsSymbol)()();
        return function (dictEncodeJson) {
            var encodeJson = Data_Argonaut_Encode_Class.encodeJson(GraphQL_Type.encDYNT(dictEncodeJson));
            return function () {
                return function () {
                    return {
                        rlToVars: function (v) {
                            return function (rec) {
                                var name = reflectSymbol(Type_Proxy["Proxy"].value);
                                var v1 = get(Type_Proxy["Proxy"].value)(rec);
                                var thisVar = new QueryVar(name, new GraphQL_Type.QType(v1.value0));
                                return Data_Array.cons(thisVar)(rlToVars1(Type_Proxy["Proxy"].value)($$delete(Type_Proxy["Proxy"].value)(rec)));
                            };
                        },
                        rlEncodeVars: function (v) {
                            return function (r) {
                                var name = reflectSymbol(Type_Proxy["Proxy"].value);
                                var val = encodeJson(get(Type_Proxy["Proxy"].value)(r));
                                return Foreign_Object.insert(name)(val)(rlEncodeVars1(Type_Proxy["Proxy"].value)($$delete(Type_Proxy["Proxy"].value)(r)));
                            };
                        }
                    };
                };
            };
        };
    };
};
var renameUpload = function (name) {
    return function (v) {
        return new FileUpload({
            name: new Data_Maybe.Just(name),
            file: v.value0.file
        });
    };
};
var queryToString = function (v) {
    var varstr = (function () {
        if ($$null(v.args)) {
            return "";
        };
        return "(" + (intercalate(", ")(map(varToString)(v.args)) + ")");
    })();
    var shapestr = (function () {
        if ($$null(v.shape)) {
            return "";
        };
        return GraphQL_Shape.shapeToString(v.shape);
    })();
    var qormut = (function () {
        if (v.mutation) {
            return "mutation";
        };
        return "query";
    })();
    return qormut + (" " + (v.name + (varstr + (" " + shapestr))));
};
var queryThen = function (v) {
    return function (f) {
        return new Query({
            name: v.value0.name,
            args: v.value0.args,
            files: v.value0.files,
            shape: v.value0.shape,
            mutation: v.value0.mutation,
            decode: function (r) {
                return bind(v.value0.decode(r))(f);
            },
            vars: v.value0.vars,
            keyName: v.value0.keyName
        });
    };
};
var queryResultShape = function (dict) {
    return dict.queryResultShape;
};
var queryResultDecode = function (dict) {
    return dict.queryResultDecode;
};
var queryResultArgs = function (dict) {
    return dict.queryResultArgs;
};
var queryFunctor = {
    map: function (f) {
        return function (v) {
            return new Query({
                name: v.value0.name,
                args: v.value0.args,
                files: v.value0.files,
                shape: v.value0.shape,
                mutation: v.value0.mutation,
                decode: (function () {
                    var $209 = map1(f);
                    return function ($210) {
                        return $209(v.value0.decode($210));
                    };
                })(),
                vars: v.value0.vars,
                keyName: v.value0.keyName
            });
        };
    }
};
var nameQuery = function (name) {
    return function (v) {
        return new Query({
            name: name,
            args: v.value0.args,
            files: v.value0.files,
            shape: v.value0.shape,
            mutation: v.value0.mutation,
            decode: v.value0.decode,
            vars: v.value0.vars,
            keyName: v.value0.keyName
        });
    };
};
var jsonQR = function (dictDecodeJson) {
    var decodeJson = Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson);
    return function (dictHasShape) {
        var shapeOf = GraphQL_Shape.shapeOf(dictHasShape);
        return {
            queryResultDecode: function (v) {
                return function (json) {
                    return $$Error["wrapJsonError$prime"](json)(decodeJson(json));
                };
            },
            queryResultShape: function (p) {
                return shapeOf(p);
            },
            queryResultArgs: function (v) {
                return [  ];
            }
        };
    };
};
var joinGenQ = {
    getQuery: function (v) {
        return {
            query: queryToString(v.value0),
            variables: Data_Argonaut_Core.fromObject(v.value0.vars)
        };
    },
    getDecode: function (v) {
        return function (json) {
            return bind($$Error["wrapJsonError$prime"](json)(bind(bind(Data_Argonaut_Decode_Decoders.decodeJObject(json))(function (v1) {
                return getField(v1)("data");
            }))(function (v1) {
                return getField1(v1)(v.value0.keyName);
            })))(function (inner) {
                return v.value0.decode(inner);
            });
        };
    }
};
var getDecode = /* #__PURE__ */ GraphQL_Join.getDecode(joinGenQ);
var qgql = {
    runGraphQL: function (v) {
        if (v.value0.files.length === 0) {
            return Control_Monad_Except_Trans.runExceptT(bind1(query(queryToString(v.value0))(v.value0.vars))(function (ret) {
                return except(getDecode(v)(ret));
            }));
        };
        if (v.value0.files.length === 1) {
            return Control_Monad_Except_Trans.runExceptT(bind1(fileQuery(queryToString(v.value0))(v.value0.vars)(v["value0"]["files"][0].value0)(v["value0"]["files"][0].value1.value0.name)(v["value0"]["files"][0].value1.value0.file))(function (ret) {
                return except(getDecode(v)(ret));
            }));
        };
        return pure(new Data_Either.Left(new $$Error.StringError("Multiple file uploads are not currently supported")));
    }
};
var fuEnc = {
    encodeJson: function (v) {
        return Data_Argonaut_Core.jsonNull;
    }
};
var fromBaseQuery = function (bq) {
    return function (vars) {
        return function (decode) {
            return merge({
                vars: vars,
                decode: decode,
                keyName: bq.name
            })(bq);
        };
    };
};
var fileUpload = function (file) {
    return new FileUpload({
        name: new Data_Maybe.Just(Web_File_File.name(file)),
        file: Web_File_File.toBlob(file)
    });
};
var encodeVars = function (dict) {
    return dict.encodeVars;
};
var buildQuery_ = function (dictToVars) {
    var encodeVars1 = encodeVars(dictToVars);
    var varFiles1 = varFiles(dictToVars);
    var toVars1 = toVars(dictToVars);
    return function (dictQueryResult) {
        var queryResultArgs1 = queryResultArgs(dictQueryResult);
        var queryResultShape1 = queryResultShape(dictQueryResult);
        var queryResultDecode1 = queryResultDecode(dictQueryResult);
        return function (mutation) {
            return function (name) {
                return function (vars$prime) {
                    var vars = encodeVars1(vars$prime);
                    var files = varFiles1(vars$prime);
                    var args = append1(toVars1(vars$prime))(map(function (v) {
                        return v.value0;
                    })(queryResultArgs1(Type_Proxy["Proxy"].value)));
                    var callArgs = map(function (v) {
                        return new GraphQL_Shape.QueryArg(v.value0, v.value0);
                    })(args);
                    var shape = [ new GraphQL_Shape.QCall(name, callArgs, queryResultShape1(Type_Proxy["Proxy"].value)) ];
                    var query1 = {
                        name: name,
                        args: args,
                        files: files,
                        shape: shape,
                        mutation: mutation
                    };
                    var decode = queryResultDecode1({
                        query: query1,
                        qstr: queryToString(query1),
                        vars: Data_Argonaut_Core.fromObject(vars)
                    });
                    return fromBaseQuery(query1)(vars)(decode);
                };
            };
        };
    };
};
var buildQuery = function (dictToVars) {
    var buildQuery_1 = buildQuery_(dictToVars);
    return function (dictQueryResult) {
        var buildQuery_2 = buildQuery_1(dictQueryResult);
        return function (name) {
            return function (vars) {
                return new Query(buildQuery_2(false)(name)(vars));
            };
        };
    };
};
var buildMutation = function (dictToVars) {
    var buildQuery_1 = buildQuery_(dictToVars);
    return function (dictQueryResult) {
        var buildQuery_2 = buildQuery_1(dictQueryResult);
        return function (name) {
            return function (vars) {
                return new Query(buildQuery_2(true)(name)(vars));
            };
        };
    };
};
var blobUpload = function (file) {
    return new FileUpload({
        name: Data_Maybe.Nothing.value,
        file: file
    });
};
var arrayQR = function (dictDecodeJson) {
    var decodeJson = Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson);
    return function (dictHasShape) {
        var shapeOf = GraphQL_Shape.shapeOf(dictHasShape);
        return {
            queryResultDecode: function (v) {
                return function (json) {
                    return $$Error["wrapJsonError$prime"](json)(decodeJson(json));
                };
            },
            queryResultShape: function (p) {
                return shapeOf(p);
            },
            queryResultArgs: function (v) {
                return [  ];
            }
        };
    };
};
export {
    encodeVars,
    queryResultArgs,
    queryResultDecode,
    queryResultShape,
    rlEncodeVars,
    rlFiles,
    rlToVars,
    toVars,
    varFiles,
    Query,
    buildQuery,
    buildMutation,
    nameQuery,
    buildQuery_,
    queryThen,
    fromBaseQuery,
    queryToString,
    QueryVar,
    varToString,
    FileUpload,
    blobUpload,
    fileUpload,
    renameUpload,
    qgql,
    joinGenQ,
    queryFunctor,
    jsonQR,
    arrayQR,
    unitQR,
    rlTVars,
    rltvDyTyCons,
    rltvCons,
    rltvNil,
    fuEnc,
    rltvfConsFU,
    rltvfConsRec,
    rltvfNamedTypeRec,
    rltvfConsOther,
    rltvfNil
};
