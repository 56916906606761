// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Translate from "../Translate/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var find = /* #__PURE__ */ Data_Foldable.find(Data_Foldable.foldableArray);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var iframe = /* #__PURE__ */ Concur_React_DOM.iframe(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var details = /* #__PURE__ */ Concur_React_DOM.details(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var summary$prime = /* #__PURE__ */ Concur_React_DOM["summary$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h1 = /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var initDocPreview = function (doc) {
    return {
        loaded: false,
        doc: doc
    };
};
var docPdfUrl = function (doc) {
    return map(function (v) {
        return v.url;
    })(find(function (r) {
        return r.format === "PDF";
    })(doc.exports));
};
var previewIFrame = function (state) {
    var v = docPdfUrl(state.doc);
    if (v instanceof Data_Maybe.Just) {
        return div$prime([ iframe([ Concur_React_Props.src(v.value0) ])([  ]) ]);
    };
    if (v instanceof Data_Maybe.Nothing) {
        return div$prime([ text(Translate.lookupTranslation("no-pdf")) ]);
    };
    throw new Error("Failed pattern match at Widgets.FormPreview (line 51, column 23 - line 53, column 62): " + [ v.constructor.name ]);
};
var renderPreview = function (v) {
    if (!v.loaded) {
        return bind(details([ Concur_React_Props.onToggle, Concur_React_Props.open(false), Concur_React_Props.className("form-submission-preview") ])([ summary$prime([ h1([ Concur_React_Props.className("preview-heading") ])([ text(Translate.lookupTranslation("preview")) ]) ]) ]))(function () {
            return pure({
                loaded: true,
                doc: v.doc
            });
        });
    };
    return details([ Concur_React_Props.open(false), Concur_React_Props.className("form-submission-preview") ])([ summary$prime([ h1([ Concur_React_Props.className("preview-heading") ])([ text(Translate.lookupTranslation("preview")) ]) ]), previewIFrame(v) ]);
};
export {
    docPdfUrl,
    initDocPreview,
    renderPreview,
    previewIFrame
};
