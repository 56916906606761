// Generated by purs version 0.15.7
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Rec_Class from "../Control.Monad.Rec.Class/index.js";
import * as Control_Plus from "../Control.Plus/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Lens_Getter from "../Data.Lens.Getter/index.js";
import * as Data_Lens_Internal_Forget from "../Data.Lens.Internal.Forget/index.js";
import * as Data_Lens_Setter from "../Data.Lens.Setter/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Profunctor_Strong from "../Data.Profunctor.Strong/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Effect_AVar from "../Effect.AVar/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_AVar from "../Effect.Aff.AVar/index.js";
import * as Effect_Aff_Bus from "../Effect.Aff.Bus/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
var un = /* #__PURE__ */ Data_Newtype.un();
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Effect_Aff.applyAff);
var Wire = function (x) {
    return x;
};
var tea = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    return function (s) {
        return function (render) {
            return function (update) {
                var go = function (st) {
                    return bind(render(st))((function () {
                        var $128 = Data_Function.flip(update)(st);
                        return function ($129) {
                            return go($128($129));
                        };
                    })());
                };
                return go(s);
            };
        };
    };
};
var retryUntilLoop = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    return function (p) {
        return function (w) {
            return function (a) {
                return bind(w(a))(function (a$prime) {
                    var $117 = p(a$prime);
                    if ($117) {
                        return pure(a$prime);
                    };
                    return retryUntilLoop(dictMonad)(p)(w)(a$prime);
                });
            };
        };
    };
};
var retryUntil = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    return function (p) {
        return function (w) {
            return bind(w)(function (a) {
                var $118 = p(a);
                if ($118) {
                    return pure(a);
                };
                return retryUntil(dictMonad)(p)(w);
            });
        };
    };
};
var remoteWidget = function (dictMonadEffect) {
    var Monad0 = dictMonadEffect.Monad0();
    var bind = Control_Bind.bind(Monad0.Bind1());
    var liftEffect = Effect_Class.liftEffect(dictMonadEffect);
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    return function (dictMonadAff) {
        var liftAff = Effect_Aff_Class.liftAff(dictMonadAff);
        var MonadEffect0 = dictMonadAff.MonadEffect0();
        var bind1 = Control_Bind.bind((MonadEffect0.Monad0()).Bind1());
        var liftEffect1 = Effect_Class.liftEffect(MonadEffect0);
        return function (dictMonadEffect1) {
            return function (dictPlus) {
                var empty = Control_Plus.empty(dictPlus);
                return function (axn) {
                    return bind(liftEffect(Effect_AVar.empty))(function ($$var) {
                        return pure(new Data_Tuple.Tuple(liftAff(Effect_Aff_AVar.take($$var)), bind1(axn)(function (f) {
                            return bind1(liftEffect1(Effect_AVar.tryPut(f)($$var)))(function () {
                                return empty;
                            });
                        })));
                    });
                };
            };
        };
    };
};
var newtypeWire = {
    Coercible0: function () {
        return undefined;
    }
};
var send = function (dictMonadEffect) {
    return function (dictMonadAff) {
        return function (dictPlus) {
            return function (w) {
                return (un(Wire)(w)).send;
            };
        };
    };
};
var $$with = function (dictMonadEffect) {
    return function (dictMonadAff) {
        var Monad0 = (dictMonadAff.MonadEffect0()).Monad0();
        var Bind1 = Monad0.Bind1();
        var bind = Control_Bind.bind(Bind1);
        var map = Data_Functor.map((Bind1.Apply0()).Functor0());
        var pure = Control_Applicative.pure(Monad0.Applicative0());
        return function (dictPlus) {
            var alt = Control_Alt.alt(dictPlus.Alt0());
            return function (wire) {
                return function (f) {
                    var go = function (a) {
                        return bind(alt(map(Data_Either.Left.create)(f(a)))(map(Data_Either.Right.create)((un(Wire)(wire)).receive)))(function (res) {
                            return Data_Either.either(pure)(go)(res);
                        });
                    };
                    return bind((un(Wire)(wire)).value)(function (val) {
                        return go(val);
                    });
                };
            };
        };
    };
};
var mapWire = function (dictAlt) {
    var map = Data_Functor.map(dictAlt.Functor0());
    return function (dictMonadEffect) {
        return function (dictMonadAff) {
            var bind = Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1());
            return function (dictPlus) {
                return function (lens) {
                    var lens1 = lens(Data_Lens_Internal_Forget.strongForget);
                    var lens2 = lens(Data_Profunctor_Strong.strongFn);
                    return function (wire) {
                        var wirerec = un(Wire)(wire);
                        return {
                            value: map(Data_Lens_Getter.view(lens1))(wirerec.value),
                            send: function (a) {
                                return bind(wirerec.value)((function () {
                                    var $130 = Data_Lens_Setter.set(lens2)(a);
                                    return function ($131) {
                                        return wirerec.send($130($131));
                                    };
                                })());
                            },
                            receive: map(Data_Lens_Getter.view(lens1))(wirerec.receive)
                        };
                    };
                };
            };
        };
    };
};
var loopState = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    return function (s) {
        return function (f) {
            return bind(f(s))(function (v) {
                if (v instanceof Data_Either.Left) {
                    return loopState(dictMonad)(v.value0)(f);
                };
                if (v instanceof Data_Either.Right) {
                    return pure(v.value0);
                };
                throw new Error("Failed pattern match at Concur.Core.Patterns (line 39, column 25 - line 41, column 20): " + [ v.constructor.name ]);
            });
        };
    };
};
var forkAction = function (dictMonadEffect) {
    return function (dictMonadAff) {
        var MonadEffect0 = dictMonadAff.MonadEffect0();
        var bind = Control_Bind.bind((MonadEffect0.Monad0()).Bind1());
        var remoteWidget1 = remoteWidget(MonadEffect0)(dictMonadAff)(MonadEffect0);
        return function (dictPlus) {
            var remoteWidget2 = remoteWidget1(dictPlus);
            var alt = Control_Alt.alt(dictPlus.Alt0());
            return function (axn) {
                return function (rest) {
                    return bind(remoteWidget2(axn))(function (v) {
                        return alt(v.value1)(rest(v.value0));
                    });
                };
            };
        };
    };
};
var forkActionState = function (dictPlus) {
    var alt = Control_Alt.alt(dictPlus.Alt0());
    return function (dictMonadAff) {
        var MonadEffect0 = dictMonadAff.MonadEffect0();
        var Bind1 = (MonadEffect0.Monad0()).Bind1();
        var bind = Control_Bind.bind(Bind1);
        var map = Data_Functor.map((Bind1.Apply0()).Functor0());
        var forkAction1 = forkAction(MonadEffect0)(dictMonadAff)(dictPlus);
        return function (axn) {
            return function (render) {
                return function (st) {
                    var go = function (st$prime) {
                        return function (axn$prime) {
                            return bind(alt(map(Data_Either.Left.create)(render(st$prime)))(map(Data_Either.Right.create)(axn$prime)))(function (e) {
                                if (e instanceof Data_Either.Left) {
                                    return go(e.value0)(axn$prime);
                                };
                                if (e instanceof Data_Either.Right) {
                                    return render(e.value0(st$prime));
                                };
                                throw new Error("Failed pattern match at Concur.Core.Patterns (line 109, column 5 - line 111, column 32): " + [ e.constructor.name ]);
                            });
                        };
                    };
                    return forkAction1(axn)(go(st));
                };
            };
        };
    };
};
var forcePut = function (dictMonadAff) {
    var liftAff = Effect_Aff_Class.liftAff(dictMonadAff);
    return function (a) {
        return function ($$var) {
            return liftAff(applySecond(Effect_Aff_AVar.tryTake($$var))(Effect_Aff_AVar.put(a)($$var)));
        };
    };
};
var forcePut1 = /* #__PURE__ */ forcePut(Effect_Aff_Class.monadAffAff);
var local = function (dictAlt) {
    var alt = Control_Alt.alt(dictAlt);
    return function (dictMonadRec) {
        var forever = Control_Monad_Rec_Class.forever(dictMonadRec);
        return function (dictMonadEffect) {
            return function (dictMonadAff) {
                var MonadEffect0 = dictMonadAff.MonadEffect0();
                var Bind1 = (MonadEffect0.Monad0()).Bind1();
                var bind = Control_Bind.bind(Bind1);
                var liftEffect = Effect_Class.liftEffect(MonadEffect0);
                var make = Effect_Aff_Bus.make(MonadEffect0);
                var liftAff = Effect_Aff_Class.liftAff(dictMonadAff);
                var applySecond1 = Control_Apply.applySecond(Bind1.Apply0());
                return function (dictPlus) {
                    var empty = Control_Plus.empty(dictPlus);
                    return function (ainit) {
                        return function (f) {
                            return bind(liftEffect(Effect_AVar["new"](ainit)))(function (currentVal) {
                                return bind(make)(function (toChildBus) {
                                    return bind(liftEffect(Effect_AVar.empty))(function (fromChild) {
                                        var wire = {
                                            value: liftAff(Effect_Aff_AVar.read(currentVal)),
                                            send: function (a) {
                                                return applySecond1(liftAff(Effect_Aff_AVar.put(a)(fromChild)))(empty);
                                            },
                                            receive: liftAff(Effect_Aff_Bus.read(toChildBus))
                                        };
                                        var sendToChildren = function (a) {
                                            return liftAff(applySecond(forcePut1(a)(currentVal))(Effect_Aff_Bus.write(a)(toChildBus)));
                                        };
                                        var getFromAnyChild = liftAff(Effect_Aff_AVar.take(fromChild));
                                        return alt(f(wire))(forever(bind(getFromAnyChild)(sendToChildren)));
                                    });
                                });
                            });
                        };
                    };
                };
            };
        };
    };
};
export {
    loopState,
    retryUntil,
    retryUntilLoop,
    tea,
    remoteWidget,
    forkAction,
    forkActionState,
    Wire,
    mapWire,
    local,
    $$with as with,
    send,
    newtypeWire
};
