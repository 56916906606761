// Generated by purs version 0.15.7
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Ref from "../Effect.Ref/index.js";
import * as Effect_Timer from "../Effect.Timer/index.js";
import * as Effect_Unsafe from "../Effect.Unsafe/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var PrimProp = /* #__PURE__ */ (function () {
    function PrimProp(value0) {
        this.value0 = value0;
    };
    PrimProp.create = function (value0) {
        return new PrimProp(value0);
    };
    return PrimProp;
})();
var Handler = /* #__PURE__ */ (function () {
    function Handler(value0) {
        this.value0 = value0;
    };
    Handler.create = function (value0) {
        return new Handler(value0);
    };
    return Handler;
})();
var mkProp = function (v) {
    return function (v1) {
        if (v1 instanceof PrimProp) {
            return v1.value0;
        };
        if (v1 instanceof Handler) {
            return v1.value0(v);
        };
        throw new Error("Failed pattern match at Concur.Core.Props (line 21, column 1 - line 25, column 7): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var handleProp = function (v) {
    return function (v1) {
        if (v1 instanceof PrimProp) {
            return new PrimProp(v1.value0);
        };
        if (v1 instanceof Handler) {
            return new PrimProp(v1.value0(v));
        };
        throw new Error("Failed pattern match at Concur.Core.Props (line 30, column 1 - line 34, column 15): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var functorProps = {
    map: function (v) {
        return function (v1) {
            if (v1 instanceof PrimProp) {
                return new PrimProp(v1.value0);
            };
            if (v1 instanceof Handler) {
                return new Handler(function (k) {
                    return v1.value0(function ($35) {
                        return k(v($35));
                    });
                });
            };
            throw new Error("Failed pattern match at Concur.Core.Props (line 16, column 1 - line 18, column 48): " + [ v.constructor.name, v1.constructor.name ]);
        };
    }
};
var filterProp = function (v) {
    return function (v1) {
        if (v1 instanceof PrimProp) {
            return v1;
        };
        if (v1 instanceof Handler) {
            return new Handler(function (h) {
                return v1.value0(function (a) {
                    var $28 = v(a);
                    if ($28) {
                        return h(a);
                    };
                    return pure(Data_Unit.unit);
                });
            });
        };
        throw new Error("Failed pattern match at Concur.Core.Props (line 40, column 1 - line 44, column 12): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var debounceInner = function (time) {
    return function (ref) {
        return function (callback) {
            return function (a) {
                var schedule = function (v) {
                    return function __do() {
                        var tid = Effect_Timer.setTimeout(time)(callback(v))();
                        return Effect_Ref.write(new Data_Maybe.Just(tid))(ref)();
                    };
                };
                return function __do() {
                    var id = Effect_Ref.read(ref)();
                    if (id instanceof Data_Maybe.Nothing) {
                        return schedule(a)();
                    };
                    if (id instanceof Data_Maybe.Just) {
                        Effect_Timer.clearTimeout(id.value0)();
                        return schedule(a)();
                    };
                    throw new Error("Failed pattern match at Concur.Core.Props (line 70, column 3 - line 74, column 17): " + [ id.constructor.name ]);
                };
            };
        };
    };
};
var debounce = function (t) {
    return function (p) {
        var ref = Effect_Unsafe.unsafePerformEffect(Effect_Ref["new"](Data_Maybe.Nothing.value));
        if (p instanceof PrimProp) {
            return new PrimProp(p.value0);
        };
        if (p instanceof Handler) {
            return new Handler(function (cb) {
                return p.value0(debounceInner(t)(ref)(cb));
            });
        };
        throw new Error("Failed pattern match at Concur.Core.Props (line 57, column 3 - line 59, column 65): " + [ p.constructor.name ]);
    };
};
export {
    PrimProp,
    Handler,
    mkProp,
    handleProp,
    filterProp,
    debounce,
    debounceInner,
    functorProps
};
