// Generated by purs version 0.15.7
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Map_Internal.eqMap(Data_Eq.eqString)(Data_Eq.eqString));
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var compare1 = /* #__PURE__ */ Data_Ord.compare(/* #__PURE__ */ Data_Map_Internal.ordMap(Data_Ord.ordString)(Data_Ord.ordString));
var Path = /* #__PURE__ */ (function () {
    function Path(value0) {
        this.value0 = value0;
    };
    Path.create = function (value0) {
        return new Path(value0);
    };
    return Path;
})();
var Query = /* #__PURE__ */ (function () {
    function Query(value0) {
        this.value0 = value0;
    };
    Query.create = function (value0) {
        return new Query(value0);
    };
    return Query;
})();
var eqRoutePart = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Path && y instanceof Path) {
                return x.value0 === y.value0;
            };
            if (x instanceof Query && y instanceof Query) {
                return eq1(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var ordRoutePart = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Path && y instanceof Path) {
                return compare(x.value0)(y.value0);
            };
            if (x instanceof Path) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Path) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Query && y instanceof Query) {
                return compare1(x.value0)(y.value0);
            };
            throw new Error("Failed pattern match at Routing.Types (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqRoutePart;
    }
};
export {
    Path,
    Query,
    eqRoutePart,
    ordRoutePart
};
