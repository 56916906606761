// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Data_Function_Uncurried from "../Data.Function.Uncurried/index.js";
var unsafeUnion = /* #__PURE__ */ Data_Function_Uncurried.runFn2($foreign.unsafeUnionFn);
export {
    unsafeUnionFn
} from "./foreign.js";
export {
    unsafeUnion
};
