var scrollTarget = "";

const scrollAttempt = function (id, attempt) {
    const element = document.getElementById(id);
    const idMatches = element && element.id === scrollTarget;

    if (idMatches) {
        element.scrollIntoView();
    } else {
        // Only attempt three times. it'd be nice if we could cancel on actions,
        // but the FFI doesn't really operate nicely that way without a whole
        // lot of indirection.
        if (idMatches && attempt < 2) {
            console.debug ("Couldn't scroll to element: " + id);
            setTimeout(scrollAttempt, 200, id, attempt + 1);
        }

        if (element === null) {
            console.error("Element " + id + " does not exist.");
        }
    }
}

export const jsScrollIntoView = function (id) {
    scrollTarget = id;

    return function() {
        setTimeout(scrollAttempt, 200, id, 0); // Give concur some time to load the element.
    };
}