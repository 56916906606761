// Generated by purs version 0.15.7
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Dynamic from "../Widgets.Form.Dynamic/index.js";
import * as Widgets_Form_WidgetMap from "../Widgets.Form.WidgetMap/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Either.applicativeEither);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Widgets_Form_Class.fwrFunctor);
var div = /* #__PURE__ */ Concur_React_DOM.div(/* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray))(Concur_Core_Types.widgetShiftMap);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var asField = /* #__PURE__ */ Widgets_Form_Class.asField(Widgets_Form_Dynamic.dynFormWidget);
var setFormData = function (fc) {
    return function (json) {
        return function (state) {
            var jsonCtx = new $$Error.LoadingJson("form data", json);
            return $$Error.tagAction("loading data into form")($$Error.tagContext(jsonCtx)(bind(Widgets_Form_WidgetMap.setWMData(fc)(state.fields)(json))(function (fields) {
                return pure({
                    fields: fields,
                    id: state.id,
                    label: state.label
                });
            })));
        };
    };
};
var fromFormType = function (v) {
    return {
        id: v.id,
        label: v.label,
        fields: v.fields
    };
};
var fromFormBasic = function (v) {
    return {
        id: v.id,
        label: v.label,
        fields: [  ]
    };
};
var formWidget = function (fc) {
    return function (state) {
        return map(function (v) {
            return map1(function (v1) {
                return {
                    fields: v1,
                    id: state.id,
                    label: state.label
                };
            })(v);
        })(div([ Concur_React_Props["_id"](GraphQL_Type.innerId(state.id)), Concur_React_Props.key(GraphQL_Type.innerId(state.id)) ])([ div([ Concur_React_Props.className("step-3-form") ])(Widgets_Form_WidgetMap.renderWidgets(fc)(state.fields)) ]));
    };
};
var formShape = function (state) {
    return Data_Array.catMaybes(map2(asField)(state.fields));
};
var formData = function (state) {
    return Widgets_Form_WidgetMap.getWMData(Widgets_Form_Class.blankFormContext)(state.fields);
};
export {
    formWidget,
    formData,
    formShape,
    setFormData,
    fromFormType,
    fromFormBasic
};
