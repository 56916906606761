// Generated by purs version 0.15.7
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var label = /* #__PURE__ */ Concur_React_DOM.label(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var widgetLabel = function ($$for) {
    return function (label1) {
        return function (extraProps) {
            return label(append([ Concur_React_Props.htmlFor($$for), Concur_React_Props.dangerouslySetInnerHTML({
                "__html": label1
            }) ])(extraProps))([  ]);
        };
    };
};
var widgetLabel$prime = function ($$for) {
    return function (label1) {
        return widgetLabel($$for)(label1)([  ]);
    };
};
var widgetLabelS$prime = function (fc) {
    return function (v) {
        return widgetLabel$prime(Widgets_Form_Class.fieldId(fc)(v.id))(v.label);
    };
};
var widgetLabelM = function (v) {
    return function (v1) {
        return function (v2) {
            if (v1.label instanceof Data_Maybe.Just) {
                return widgetLabel(Widgets_Form_Class.fieldId(v)(v1.id))(v1.label.value0)(v2);
            };
            if (v1.label instanceof Data_Maybe.Nothing) {
                return div$prime([  ]);
            };
            throw new Error("Failed pattern match at Widgets.Form.Common (line 44, column 1 - line 44, column 101): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var widgetLabelM$prime = function (fc) {
    return function (state) {
        return widgetLabelM(fc)(state)([  ]);
    };
};
var widgetLabelS = function (fc) {
    return function (v) {
        return function (props) {
            return widgetLabel(Widgets_Form_Class.fieldId(fc)(v.id))(v.label)(props);
        };
    };
};
export {
    widgetLabel,
    widgetLabel$prime,
    widgetLabelS,
    widgetLabelS$prime,
    widgetLabelM,
    widgetLabelM$prime
};
