// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Common from "../Widgets.Form.Common/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showNumber);
var textarea = /* #__PURE__ */ Concur_React_DOM.textarea(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var showRecord = /* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "cls";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "label";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "placeholder";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "value";
    }
})(Data_Show.showString))(/* #__PURE__ */ Data_Maybe.showMaybe(Data_Show.showString)))(Data_Show.showString))(Data_Show.showString))(Data_Show.showString));
var TextInput = function (x) {
    return x;
};
var TextArea = function (x) {
    return x;
};
var textInputFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return true;
    },
    asField: function (v) {
        return new Data_Maybe.Just(new GraphQL_Shape.QScalar(v.id));
    },
    toWidget: function (fc) {
        return function (v) {
            var id = Widgets_Form_Class.fieldId(fc)(v.id);
            return map(Widgets_Form_Class.formWidgetState)(div([ Concur_React_Props.key(id), Concur_React_Props.className(Widgets_Form_Class.fieldClass(v)) ])([ Widgets_Form_Common.widgetLabelS(fc)(v)([ Concur_React_Props.className("block w-full") ]), input([ Concur_React_Props["_type"]("text"), Concur_React_Props["_id"](id), Concur_React_Props.value(v.value), Concur_React_Props.className("widget-input"), Concur_React_Props.placeholder(Data_Maybe.fromMaybe("")(v.placeholder)), map1(map2(map2(TextInput)(function (v1) {
                return {
                    value: v1,
                    cls: v.cls,
                    id: v.id,
                    label: v.label,
                    placeholder: v.placeholder
                };
            }))(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onInput) ]) ]));
        };
    },
    getData: function (v) {
        return function (v1) {
            return Data_Argonaut_Core.fromString(v1.value);
        };
    },
    setData: function (v) {
        return function (v1) {
            return function (v2) {
                if (Data_Argonaut_Core.isNumber(v2)) {
                    return Widgets_Form_Class.setFromJson(textInputFW)(Data_Argonaut_Decode_Class.decodeJsonNumber)(v1)(function (v3) {
                        var $75 = Data_Int.toNumber(Data_Int.floor(v3)) === v3;
                        if ($75) {
                            return new Data_Either.Right({
                                id: v1.id,
                                label: v1.label,
                                cls: v1.cls,
                                placeholder: v1.placeholder,
                                value: show(Data_Int.floor(v3))
                            });
                        };
                        return new Data_Either.Right({
                            id: v1.id,
                            label: v1.label,
                            cls: v1.cls,
                            placeholder: v1.placeholder,
                            value: show1(v3)
                        });
                    })(v2);
                };
                return Widgets_Form_Class.setFromJson(textInputFW)(Data_Argonaut_Decode_Class.decodeJsonString)(v1)(function (val) {
                    return new Data_Either.Right({
                        id: v1.id,
                        label: v1.label,
                        cls: v1.cls,
                        placeholder: v1.placeholder,
                        value: val
                    });
                })(v2);
            };
        };
    }
};
var textAreaFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return true;
    },
    asField: function (v) {
        return new Data_Maybe.Just(new GraphQL_Shape.QScalar(v.id));
    },
    toWidget: function (fc) {
        return function (v) {
            var id = Widgets_Form_Class.fieldId(fc)(v.id);
            return map(Widgets_Form_Class.formWidgetState)(div([ Concur_React_Props.key(id), Concur_React_Props.className(Widgets_Form_Class.fieldClass(v)) ])([ Widgets_Form_Common.widgetLabelS(fc)(v)([ Concur_React_Props.className("block w-full") ]), textarea([ Concur_React_Props["_id"](id), Concur_React_Props.className("widget-input"), Concur_React_Props.placeholder(Data_Maybe.fromMaybe("")(v.placeholder)), map1(map2(map2(TextArea)(function (v1) {
                return {
                    value: v1,
                    cls: v.cls,
                    id: v.id,
                    label: v.label,
                    placeholder: v.placeholder
                };
            }))(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onInput), Concur_React_Props.value(v.value) ])([  ]) ]));
        };
    },
    getData: function (v) {
        return function (v1) {
            return Data_Argonaut_Core.fromString(v1.value);
        };
    },
    setData: function (v) {
        return function (v1) {
            return function (v2) {
                if (Data_Argonaut_Core.isNumber(v2)) {
                    return Widgets_Form_Class.setFromJson(textAreaFW)(Data_Argonaut_Decode_Class.decodeJsonNumber)(v1)(function (v3) {
                        var $86 = Data_Int.toNumber(Data_Int.floor(v3)) === v3;
                        if ($86) {
                            return new Data_Either.Right({
                                id: v1.id,
                                label: v1.label,
                                cls: v1.cls,
                                placeholder: v1.placeholder,
                                value: show(Data_Int.floor(v3))
                            });
                        };
                        return new Data_Either.Right({
                            id: v1.id,
                            label: v1.label,
                            cls: v1.cls,
                            placeholder: v1.placeholder,
                            value: show1(v3)
                        });
                    })(v2);
                };
                return Widgets_Form_Class.setFromJson(textAreaFW)(Data_Argonaut_Decode_Class.decodeJsonString)(v1)(function (val) {
                    return new Data_Either.Right({
                        id: v1.id,
                        label: v1.label,
                        cls: v1.cls,
                        placeholder: v1.placeholder,
                        value: val
                    });
                })(v2);
            };
        };
    }
};
var showTextInput = showRecord;
var showTextArea = showRecord;
export {
    TextInput,
    TextArea,
    showTextInput,
    textInputFW,
    showTextArea,
    textAreaFW
};
