// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_Util from "../Internal.Util/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_Modal from "../Widgets.Modal/index.js";
import * as Widgets_Signature from "../Widgets.Signature/index.js";
import * as Widgets_Signature_Email from "../Widgets.Signature.Email/index.js";
var find = /* #__PURE__ */ Data_Foldable.find(Data_Foldable.foldableArray);
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ GraphQL_Type.eqRNTY(Data_Eq.eqString));
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var any = /* #__PURE__ */ Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var EmailAll = /* #__PURE__ */ (function () {
    function EmailAll() {

    };
    EmailAll.value = new EmailAll();
    return EmailAll;
})();
var updateDefSignatures = function (sgs) {
    return function (defs) {
        var findKey = function (arr) {
            return function (key) {
                return find(function (r) {
                    return eq(r.key)(key);
                })(arr);
            };
        };
        var updateSig = function (ssig) {
            var v = findKey(defs)(ssig.field.key);
            if (v instanceof Data_Maybe.Just) {
                return Widgets_Signature.loadDefaults(ssig)(v.value0);
            };
            if (v instanceof Data_Maybe.Nothing) {
                return ssig;
            };
            throw new Error("Failed pattern match at Widgets.Signature.Group (line 76, column 22 - line 78, column 24): " + [ v.constructor.name ]);
        };
        return {
            id: sgs.id,
            docId: sgs.docId,
            sigs: map(updateSig)(sgs.sigs),
            workQ: sgs.workQ
        };
    };
};
var renderSigGroup = function (state) {
    var sigWidget = function (idx) {
        return function (sig) {
            var newSigs = function (sig$prime) {
                return Data_Maybe.fromMaybe(state.sigs)(Data_Array.updateAt(idx)(sig$prime)(state.sigs));
            };
            return map1(map2(Data_Either.Left.create)(function (sig$prime) {
                return {
                    sigs: newSigs(sig$prime),
                    docId: state.docId,
                    id: state.id,
                    workQ: state.workQ
                };
            }))(Widgets_Signature.signatureWidget(sig));
        };
    };
    var sigWidgets = Data_Array.mapWithIndex(sigWidget)(state.sigs);
    var actionBtns = div([ Concur_React_Props.className("btn-group btn-group-email") ])(Data_Array.catMaybes([ Internal_Util.justIf(any(function (v) {
        return v.field.can.request;
    })(state.sigs))(button([ Concur_React_Props.className("btn email-all"), voidRight(new Data_Either.Right(EmailAll.value))(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("email-all")) ])) ]));
    return div([ Concur_React_Props.className("signature-group") ])(append(sigWidgets)([ actionBtns ]));
};
var loadSignatures = function (v) {
    return function (state) {
        var findVerif = function (key) {
            return find(function (r) {
                return eq(r.data.key)(key);
            })(v.verifications);
        };
        var findKey = function (arr) {
            return function (key) {
                return find(function (r) {
                    return eq(r.key)(key);
                })(arr);
            };
        };
        var loadDefs = function (ssig) {
            var v1 = findKey(v.sig_defaults)(ssig.field.key);
            if (v1 instanceof Data_Maybe.Just) {
                return Widgets_Signature.loadDefaults(ssig)(v1.value0);
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return ssig;
            };
            throw new Error("Failed pattern match at Widgets.Signature.Group (line 65, column 21 - line 67, column 24): " + [ v1.constructor.name ]);
        };
        var loadVerif = function (ssig) {
            var v1 = findVerif(ssig.field.key);
            if (v1 instanceof Data_Maybe.Just) {
                return Widgets_Signature.loadVerification(ssig)(v1.value0);
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return loadDefs(ssig);
            };
            throw new Error("Failed pattern match at Widgets.Signature.Group (line 60, column 22 - line 62, column 33): " + [ v1.constructor.name ]);
        };
        var loadSig = function (ssig) {
            var v1 = findKey(v.signatures)(ssig.field.key);
            if (v1 instanceof Data_Maybe.Just) {
                return Widgets_Signature.loadSignature(ssig)(v1.value0);
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return loadVerif(ssig);
            };
            throw new Error("Failed pattern match at Widgets.Signature.Group (line 55, column 20 - line 57, column 34): " + [ v1.constructor.name ]);
        };
        return {
            id: state.id,
            docId: state.docId,
            sigs: map(loadSig)(state.sigs),
            workQ: state.workQ
        };
    };
};
var loadVerifications = function (verifications) {
    return loadSignatures({
        signatures: [  ],
        sig_defaults: [  ],
        verifications: verifications
    });
};
var initSigGroup = function (workQ) {
    return function (id) {
        return function (docId) {
            return function (sigs) {
                return {
                    id: id,
                    docId: docId,
                    sigs: sigs,
                    workQ: workQ
                };
            };
        };
    };
};
var handleSGAction = function (state) {
    return function (v) {
        var toEmailEntry = function (v1) {
            return {
                field: v1.field,
                email: v1.info.email,
                selected: false,
                doc: Data_Maybe.Nothing.value
            };
        };
        return bind(Widgets_Modal.modalWidget(renderSigGroup(state))(Widgets_Signature_Email.emailAllForm(state.workQ)(state.docId)(map(toEmailEntry)(Data_Array.filter(function (v1) {
            return v1.field.can.request;
        })(state.sigs)))))(function (res) {
            if (res instanceof Widgets_Modal.ModalClosed) {
                return pure(state);
            };
            if (res instanceof Widgets_Modal.ModalReturned && res.value0 instanceof Data_Either.Right) {
                return pure(loadVerifications(res.value0.value0)(state));
            };
            if (res instanceof Widgets_Modal.ModalReturned && res.value0 instanceof Data_Either.Left) {
                return $$Error.handleCritical(new $$Error.WithContext(new $$Error.InModule("signature group"), res.value0.value0));
            };
            throw new Error("Failed pattern match at Widgets.Signature.Group (line 91, column 5 - line 94, column 98): " + [ res.constructor.name ]);
        });
    };
};
var signatureGroup = function (state) {
    return bind(renderSigGroup(state))(function (v) {
        if (v instanceof Data_Either.Left) {
            return pure(v.value0);
        };
        if (v instanceof Data_Either.Right) {
            return handleSGAction(state)(v.value0);
        };
        throw new Error("Failed pattern match at Widgets.Signature.Group (line 83, column 49 - line 85, column 42): " + [ v.constructor.name ]);
    });
};
export {
    EmailAll,
    initSigGroup,
    loadVerifications,
    loadSignatures,
    updateDefSignatures,
    signatureGroup,
    handleSGAction,
    renderSigGroup
};
