// Generated by purs version 0.15.7
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Map from "../Data.Map/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
var StringTag = /* #__PURE__ */ (function () {
    function StringTag(value0) {
        this.value0 = value0;
    };
    StringTag.create = function (value0) {
        return new StringTag(value0);
    };
    return StringTag;
})();
var NumberTag = /* #__PURE__ */ (function () {
    function NumberTag(value0) {
        this.value0 = value0;
    };
    NumberTag.create = function (value0) {
        return new NumberTag(value0);
    };
    return NumberTag;
})();
var IntTag = /* #__PURE__ */ (function () {
    function IntTag(value0) {
        this.value0 = value0;
    };
    IntTag.create = function (value0) {
        return new IntTag(value0);
    };
    return IntTag;
})();
var BooleanTag = /* #__PURE__ */ (function () {
    function BooleanTag(value0) {
        this.value0 = value0;
    };
    BooleanTag.create = function (value0) {
        return new BooleanTag(value0);
    };
    return BooleanTag;
})();
var JSDateTag = /* #__PURE__ */ (function () {
    function JSDateTag(value0) {
        this.value0 = value0;
    };
    JSDateTag.create = function (value0) {
        return new JSDateTag(value0);
    };
    return JSDateTag;
})();
var TagSetTag = /* #__PURE__ */ (function () {
    function TagSetTag(value0) {
        this.value0 = value0;
    };
    TagSetTag.create = function (value0) {
        return new TagSetTag(value0);
    };
    return TagSetTag;
})();
var mkTagType = function (tagger) {
    return function (name) {
        var $3 = Data_Map_Internal.singleton(name);
        return function ($4) {
            return $3(tagger($4));
        };
    };
};
var numberTag = /* #__PURE__ */ (function () {
    return mkTagType(NumberTag.create);
})();
var tag = /* #__PURE__ */ (function () {
    return mkTagType(StringTag.create);
})();
var tagSetTag = /* #__PURE__ */ (function () {
    return mkTagType(TagSetTag.create);
})();
var jsDateTag = /* #__PURE__ */ (function () {
    return mkTagType(JSDateTag.create);
})();
var intTag = /* #__PURE__ */ (function () {
    return mkTagType(IntTag.create);
})();
var fromArray = /* #__PURE__ */ Data_Map_Internal.unions(Data_Ord.ordString)(Data_Foldable.foldableArray);
var booleanTag = /* #__PURE__ */ (function () {
    return mkTagType(BooleanTag.create);
})();
export {
    StringTag,
    NumberTag,
    IntTag,
    BooleanTag,
    JSDateTag,
    TagSetTag,
    tag,
    intTag,
    numberTag,
    booleanTag,
    jsDateTag,
    tagSetTag,
    fromArray
};
export {
    empty,
    singleton,
    unions
} from "../Data.Map/index.js";
