// Generated by purs version 0.15.7
import * as Data_Maybe from "../Data.Maybe/index.js";
var justIf = function (v) {
    return function (v1) {
        if (v) {
            return new Data_Maybe.Just(v1);
        };
        if (!v) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at Internal.Util (line 5, column 1 - line 5, column 44): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
export {
    justIf
};
