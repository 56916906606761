import * as Main from '../output/Main';

// Taken from https://github.com/purescript/spago#get-started-from-scratch-with-parcel-frontend-projects
function main() {
    console.log('Welcome to PureScript');

    Main.main();
}

// HMR setup. For more info see: https://parceljs.org/hmr.html
if (module.hot) {
    module.hot.accept(function () {
        console.log('Reloaded, running main again');
        main();
    });
}

main();