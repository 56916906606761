// Generated by purs version 0.15.7
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as $$Error from "../Error/index.js";
import * as Steps_WorkQueue from "../Steps.WorkQueue/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var map = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var $$void = /* #__PURE__ */ Data_Functor["void"](Effect.functorEffect);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.bindWidget);
var exportAllWidget = function (workQ) {
    return bind(alt(liftAff(Steps_WorkQueue.createAllForms(workQ)))(Steps_WorkQueue.loadingWidgetWQ(workQ)))(function (res) {
        var v = map(Data_Array.head)(res);
        if (v instanceof Data_Either.Right && v.value0 instanceof Data_Maybe.Just) {
            return bind(liftEffect(function __do() {
                var win = Web_HTML.window();
                return $$void(Web_HTML_Window.open(v.value0.value0.url)("_blank")("")(win))();
            }))(function () {
                return pure(Data_Unit.unit);
            });
        };
        if (v instanceof Data_Either.Right) {
            return discard(liftEffect(Effect_Console.log("Exected at least one report")))(function () {
                return pure(Data_Unit.unit);
            });
        };
        if (v instanceof Data_Either.Left) {
            return $$Error.handleCritical(v.value0);
        };
        throw new Error("Failed pattern match at Widgets.Export (line 18, column 5 - line 27, column 39): " + [ v.constructor.name ]);
    });
};
export {
    exportAllWidget
};
