// Generated by purs version 0.15.7
import * as Concur_Core_FRP from "../Concur.Core.FRP/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Cofree from "../Control.Cofree/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Routing from "../Routing/index.js";
import * as Routing_PushState from "../Routing.PushState/index.js";
import * as Steps_Step from "../Steps.Step/index.js";
import * as Steps_Url from "../Steps.Url/index.js";
import * as Steps_WorkQueue from "../Steps.WorkQueue/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
var widgetAlternative = /* #__PURE__ */ Concur_Core_Types.widgetAlternative(Data_Monoid.monoidArray);
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Cofree.applicativeCofree(widgetAlternative));
var justWait = /* #__PURE__ */ Concur_Core_FRP.justWait(Concur_Core_Types.widgetMonad)(widgetAlternative);
var fireOnce = /* #__PURE__ */ Concur_Core_FRP.fireOnce(Concur_Core_Types.widgetMonad)(/* #__PURE__ */ Concur_Core_Types.widgetPlus(Data_Monoid.monoidArray));
var bind = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT)(Effect_Aff.monadAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var toStep = function (step) {
    return function (state) {
        return {
            step: step,
            workQueue: state.workQueue,
            pushStateInterface: state.pushStateInterface,
            preselectForms: state.preselectForms
        };
    };
};
var toStepOn = function (fn) {
    return function (state) {
        return toStep(fn(state))(state);
    };
};
var runStepWidget = function (state) {
    return function (stepW) {
        return function (fin) {
            var wrapResult = function (v) {
                if (v.value0 instanceof Steps_Step.NavNext) {
                    return pure(fin(v.value1));
                };
                if (v.value0 instanceof Steps_Step.NavCancel) {
                    return pure(toStep(Steps_Step.Step1.value)({
                        step: v.value1.step,
                        workQueue: Steps_WorkQueue.resetWorkQueue(v.value1.workQueue),
                        pushStateInterface: v.value1.pushStateInterface,
                        preselectForms: v.value1.preselectForms
                    }));
                };
                if (v.value0 instanceof Steps_Step.NavJump) {
                    return pure(toStep(v.value0.value0)(v.value1));
                };
                throw new Error("Failed pattern match at Steps.State (line 40, column 5 - line 40, column 69): " + [ v.constructor.name ]);
            };
            return justWait(state)(fireOnce(stepW(state)))(wrapResult);
        };
    };
};
var loadStepState = /* #__PURE__ */ Control_Monad_Except_Trans.runExceptT(/* #__PURE__ */ bind(/* #__PURE__ */ lift(/* #__PURE__ */ liftEffect(Routing_PushState.makeInterface)))(function (pushStateInterface) {
    return bind(lift(liftEffect(pushStateInterface.locationState)))(function (locationState) {
        return bind(Steps_WorkQueue.loadWorkQueue(Steps_WorkQueue.initialWorkQueue))(function (workQueue) {
            var step = (function () {
                var v = Routing.match(Steps_Url.matchStep)(locationState.path);
                if (v instanceof Data_Either.Left) {
                    return Steps_Step.Step1.value;
                };
                if (v instanceof Data_Either.Right) {
                    return v.value0;
                };
                throw new Error("Failed pattern match at Steps.State (line 56, column 16 - line 58, column 25): " + [ v.constructor.name ]);
            })();
            return pure1({
                step: step,
                workQueue: workQueue,
                preselectForms: [  ],
                pushStateInterface: pushStateInterface
            });
        });
    });
}));
var loadStepStateWidget = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray))(Widgets_Loading.loadingWidget)(/* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray))(loadStepState));
export {
    runStepWidget,
    loadStepStateWidget,
    loadStepState,
    toStep,
    toStepOn
};
