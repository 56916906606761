// Generated by purs version 0.15.7
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_JSDate from "../Data.JSDate/index.js";
import * as Data_Log_Level from "../Data.Log.Level/index.js";
import * as Data_Log_Tag from "../Data.Log.Tag/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Unfoldable from "../Data.Unfoldable/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
var fromFoldable = /* #__PURE__ */ Foreign_Object.fromFoldable(Data_Foldable.foldableArray);
var toUnfoldable = /* #__PURE__ */ Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray);
var map = /* #__PURE__ */ Data_Functor.map(Data_Map_Internal.functorMap);
var levelCode = function (v) {
    if (v instanceof Data_Log_Level.Trace) {
        return 0;
    };
    if (v instanceof Data_Log_Level.Debug) {
        return 1;
    };
    if (v instanceof Data_Log_Level.Info) {
        return 2;
    };
    if (v instanceof Data_Log_Level.Warn) {
        return 3;
    };
    if (v instanceof Data_Log_Level["Error"]) {
        return 4;
    };
    throw new Error("Failed pattern match at Data.Log.Formatter.JSON (line 58, column 1 - line 58, column 29): " + [ v.constructor.name ]);
};
var toJson = function (set) {
    return Data_Argonaut_Core.fromObject(fromFoldable(jsonify(set)));
};
var jsonify = function (set) {
    return toUnfoldable(map(fieldToJson)(set));
};
var fieldToJson = function (v) {
    if (v instanceof Data_Log_Tag.StringTag) {
        return Data_Argonaut_Core.fromString(v.value0);
    };
    if (v instanceof Data_Log_Tag.IntTag) {
        return Data_Argonaut_Core.fromNumber(Data_Int.toNumber(v.value0));
    };
    if (v instanceof Data_Log_Tag.NumberTag) {
        return Data_Argonaut_Core.fromNumber(v.value0);
    };
    if (v instanceof Data_Log_Tag.BooleanTag) {
        return Data_Argonaut_Core.fromBoolean(v.value0);
    };
    if (v instanceof Data_Log_Tag.JSDateTag) {
        return Data_Argonaut_Core.fromNumber(Data_JSDate.getTime(v.value0));
    };
    if (v instanceof Data_Log_Tag.TagSetTag) {
        return toJson(v.value0);
    };
    throw new Error("Failed pattern match at Data.Log.Formatter.JSON (line 50, column 1 - line 50, column 27): " + [ v.constructor.name ]);
};
var buildPayload = function (v) {
    return Data_Log_Tag.fromArray([ Data_Log_Tag.intTag("level")(levelCode(v.level)), Data_Log_Tag.jsDateTag("ts")(v.timestamp), Data_Log_Tag.tag("message")(v.message), (function () {
        var $16 = Data_Map_Internal.isEmpty(v.tags);
        if ($16) {
            return Data_Map_Internal.empty;
        };
        return Data_Log_Tag.tagSetTag("tags")(v.tags);
    })() ]);
};
var jsonFormatter = function ($21) {
    return Data_Argonaut_Core.stringify(toJson(buildPayload($21)));
};
export {
    jsonFormatter
};
