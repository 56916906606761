// Generated by purs version 0.15.7
import * as API_Account from "../API.Account/index.js";
import * as API_Document from "../API.Document/index.js";
import * as API_FormType from "../API.FormType/index.js";
import * as API_Search from "../API.Search/index.js";
import * as API_SelStudent from "../API.SelStudent/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Comonad_Cofree from "../Control.Comonad.Cofree/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Set from "../Data.Set/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unfoldable from "../Data.Unfoldable/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Compound from "../GraphQL.Compound/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as Internal_SelectionMap from "../Internal.SelectionMap/index.js";
import * as Steps_WorkQueue_DocumentMap from "../Steps.WorkQueue.DocumentMap/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_BetterSelect from "../Widgets.BetterSelect/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
import * as Widgets_Search from "../Widgets.Search/index.js";
var show = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_Show.showArray(Data_Show.showString));
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var fromFoldable = /* #__PURE__ */ Data_Array.fromFoldable(Data_List_Types.foldableList);
var eqRNTY = /* #__PURE__ */ GraphQL_Type.eqRNTY(Data_Eq.eqString);
var eq = /* #__PURE__ */ Data_Eq.eq(eqRNTY);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var li = /* #__PURE__ */ Concur_React_DOM.li(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var li$prime = /* #__PURE__ */ Concur_React_DOM["li$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map2 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var ul$prime = /* #__PURE__ */ Concur_React_DOM["ul$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var toUnfoldable = /* #__PURE__ */ Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray);
var ul = /* #__PURE__ */ Concur_React_DOM.ul(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(/* #__PURE__ */ Data_Eq.eqRec()(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "studentId";
    }
})(eqRNTY))()({
    reflectSymbol: function () {
        return "formId";
    }
})(eqRNTY))));
var ordRNTY = /* #__PURE__ */ GraphQL_Type.ordRNTY(Data_Ord.ordString);
var lookup = /* #__PURE__ */ Data_Map_Internal.lookup(ordRNTY);
var length = /* #__PURE__ */ Data_Foldable.length(Data_Foldable.foldableArray)(Data_Semiring.semiringInt);
var details = /* #__PURE__ */ Concur_React_DOM.details(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var summary = /* #__PURE__ */ Concur_React_DOM.summary(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var selectAll$prime = /* #__PURE__ */ Internal_SelectionMap["selectAll$prime"](ordRNTY)(Data_Functor.functorArray)(Data_Foldable.foldableArray);
var bind = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var multiQuery_ = /* #__PURE__ */ GraphQL_Join.multiQuery_()(/* #__PURE__ */ GraphQL_Join.jqCons(/* #__PURE__ */ GraphQL_Join.jqCons(GraphQL_Join.jqNil)({
    reflectSymbol: function () {
        return "searchData";
    }
})()()()()(GraphQL_Compound.cqJoin))({
    reflectSymbol: function () {
        return "account";
    }
})()()()()(GraphQL_Query.joinGenQ));
var applicativeExceptT = /* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff);
var pure = /* #__PURE__ */ Control_Applicative.pure(applicativeExceptT);
var fromFoldable1 = /* #__PURE__ */ Data_Set.fromFoldable(Data_List_Types.foldableList)(ordRNTY);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var fromFoldable2 = /* #__PURE__ */ Data_Set.fromFoldable(Data_Foldable.foldableArray)(ordRNTY);
var fromFoldable3 = /* #__PURE__ */ Data_Array.fromFoldable(Data_Set.foldableSet);
var union = /* #__PURE__ */ Data_Set.union(ordRNTY);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray)(applicativeExceptT);
var foldr = /* #__PURE__ */ Data_Foldable.foldr(Data_Foldable.foldableArray);
var insert = /* #__PURE__ */ Data_Map_Internal.insert(ordRNTY);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var isEmpty = /* #__PURE__ */ Internal_SelectionMap.isEmpty(ordRNTY);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var header = /* #__PURE__ */ Concur_React_DOM.header(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var runGraphQL_ = /* #__PURE__ */ GraphQL_Join.runGraphQL_(GraphQL_Query.qgql);
var eq2 = /* #__PURE__ */ Data_Eq.eq(API_Account.eqAccType);
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var StateChange = /* #__PURE__ */ (function () {
    function StateChange(value0) {
        this.value0 = value0;
    };
    StateChange.create = function (value0) {
        return new StateChange(value0);
    };
    return StateChange;
})();
var JumpToForm = /* #__PURE__ */ (function () {
    function JumpToForm(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    JumpToForm.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new JumpToForm(value0, value1, value2, value3);
                };
            };
        };
    };
    return JumpToForm;
})();
var showFQE = function (v) {
    if (v.form instanceof Data_Either.Right) {
        return "{ student: " + (GraphQL_Type.innerId(v.student) + (", form: " + (GraphQL_Type.innerId(v.form.value0) + " }")));
    };
    if (v.form instanceof Data_Either.Left) {
        return "{ student: " + (GraphQL_Type.innerId(v.student) + (", form: " + (GraphQL_Type.innerId(v.form.value0.form_type.id) + " }")));
    };
    throw new Error("Failed pattern match at Steps.WorkQueue (line 279, column 1 - line 279, column 36): " + [ v.constructor.name ]);
};
var showFillQueue = function (fq) {
    return show(map(showFQE)(fq));
};
var setTeacherAndSemester = function (workQ) {
    return function (teacher) {
        return function (semester) {
            return {
                forms: workQ.forms,
                formCache: workQ.formCache,
                students: workQ.students,
                documents: workQ.documents,
                editDocs: workQ.editDocs,
                currentDoc: workQ.currentDoc,
                expandNext: workQ.expandNext,
                search: workQ.search,
                searchData: workQ.searchData,
                semester: Widgets_BetterSelect.setSelected(workQ.semester)(new Data_Maybe.Just({
                    label: semester.name,
                    value: semester
                })),
                teacher: Widgets_BetterSelect.setSelected(workQ.teacher)(map1(function (t) {
                    return {
                        label: t.name,
                        value: t
                    };
                })(teacher)),
                defTeacher: workQ.defTeacher,
                defSemester: workQ.defSemester,
                account: workQ.account,
                loaded: workQ.loaded
            };
        };
    };
};
var setCurrentDoc = function (workQ) {
    return function (doc) {
        return {
            forms: workQ.forms,
            formCache: workQ.formCache,
            students: workQ.students,
            documents: workQ.documents,
            editDocs: workQ.editDocs,
            currentDoc: doc,
            expandNext: Data_Maybe.Nothing.value,
            search: workQ.search,
            searchData: workQ.searchData,
            semester: workQ.semester,
            teacher: workQ.teacher,
            defTeacher: workQ.defTeacher,
            defSemester: workQ.defSemester,
            account: workQ.account,
            loaded: workQ.loaded
        };
    };
};
var resetWorkQueue = function (workQ) {
    return {
        forms: API_FormType.emptyFormPickerMap,
        formCache: workQ.formCache,
        students: Internal_SelectionMap.empty,
        documents: Steps_WorkQueue_DocumentMap.emptyDocMap,
        editDocs: [  ],
        currentDoc: Data_Maybe.Nothing.value,
        expandNext: Data_Maybe.Nothing.value,
        search: workQ.search,
        searchData: workQ.searchData,
        semester: Widgets_BetterSelect.setSelected(workQ.semester)(workQ.defSemester),
        teacher: Widgets_BetterSelect.setSelected(workQ.teacher)(workQ.defTeacher),
        defTeacher: workQ.defTeacher,
        defSemester: workQ.defSemester,
        account: workQ.account,
        loaded: workQ.loaded
    };
};
var renderStudentDetails = function (workQ) {
    return function (student) {
        var studentForms = (function () {
            var $$new = fromFoldable(Internal_SelectionMap.listSelected(workQ.forms));
            var edit = map(function (v) {
                return v.form_type;
            })(Data_Array.filter(function (d) {
                return eq(d.student.id)(student.id);
            })(workQ.editDocs));
            return append1($$new)(edit);
        })();
        var shouldOpen = Data_Maybe.maybe(false)(function (c) {
            return eq(c.studentId)(student.id);
        })(workQ.currentDoc);
        var renderStudentNoForms = li([ Concur_React_Props.className("workqueue-student no-forms") ])([ text(API_SelStudent.studentName(workQ.account)(student)) ]);
        var renderStudentForm = function (form) {
            var lookup1 = {
                formId: form.id,
                studentId: student.id
            };
            var renderSection = function (tree) {
                var node = Control_Comonad_Cofree.head(tree);
                return li$prime([ a([ map2(JumpToForm.create(workQ)(lookup1)(new Data_Maybe.Just(node.id)))(Concur_React_Props.onClick), Concur_React_Props.href("#" + node.id) ])([ text(node.workQueueLabel) ]), ul$prime(map(renderSection)(toUnfoldable(Control_Comonad_Cofree.tail(tree)))) ]);
            };
            var renderSections = function (form$prime) {
                return ul([ Concur_React_Props.className("student-form-section") ])(map(renderSection)(form$prime.sections));
            };
            var isCurrent = eq1(workQ.currentDoc)(new Data_Maybe.Just(lookup1));
            var formAnchor = a([ map2(JumpToForm.create(workQ)(lookup1)(Data_Maybe.Nothing.value))(Concur_React_Props.onClick), Concur_React_Props.className((function () {
                if (isCurrent) {
                    return "workqueue-selected";
                };
                return "";
            })()) ])([ text(form.label) ]);
            var cachedFormMaybe = lookup(form.id)(workQ.formCache);
            var liAttrs = (function () {
                if (Data_Maybe.isNothing(cachedFormMaybe)) {
                    return [ Concur_React_Props.className("workqueue-form-no-sections") ];
                };
                if (Data_Boolean.otherwise) {
                    return [  ];
                };
                throw new Error("Failed pattern match at Steps.WorkQueue (line 385, column 9 - line 385, column 91): " + [  ]);
            })();
            return li(liAttrs)([ (function () {
                if (cachedFormMaybe instanceof Data_Maybe.Just) {
                    var $140 = length(cachedFormMaybe.value0.sections) > 0;
                    if ($140) {
                        return details([ Concur_React_Props.open(isCurrent) ])([ summary([ Concur_React_Props.className("summary-form") ])([ formAnchor ]), renderSections(cachedFormMaybe.value0) ]);
                    };
                    return formAnchor;
                };
                if (cachedFormMaybe instanceof Data_Maybe.Nothing) {
                    return formAnchor;
                };
                throw new Error("Failed pattern match at Steps.WorkQueue (line 363, column 11 - line 378, column 34): " + [ cachedFormMaybe.constructor.name ]);
            })() ]);
        };
        var renderStudentForms = ul([ Concur_React_Props.className("workqueue-student-forms") ])(map(renderStudentForm)(studentForms));
        var openProp = function (v) {
            if (v instanceof Data_Maybe.Just) {
                return [ Concur_React_Props.open(v.value0) ];
            };
            if (v instanceof Data_Maybe.Nothing && shouldOpen) {
                return [ Concur_React_Props.open(shouldOpen) ];
            };
            if (v instanceof Data_Maybe.Nothing) {
                return [  ];
            };
            throw new Error("Failed pattern match at Steps.WorkQueue (line 357, column 5 - line 357, column 39): " + [ v.constructor.name ]);
        };
        var renderStudentAndForms = li([ Concur_React_Props.className("workqueue-student") ])([ details(openProp(workQ.expandNext))([ summary([ Concur_React_Props.href("#"), Concur_React_Props.className("summary-student") ])([ text(API_SelStudent.studentName(workQ.account)(student)) ]), renderStudentForms ]) ]);
        var $144 = length(studentForms) <= 0;
        if ($144) {
            return renderStudentNoForms;
        };
        return renderStudentAndForms;
    };
};
var renderStudentList = function (v) {
    return div([ Concur_React_Props.className("workqueue-students") ])(map(renderStudentDetails(v))(fromFoldable(Internal_SelectionMap.listSelected(v.students))));
};
var removeStudents = function (workQ) {
    return function (students) {
        return {
            forms: workQ.forms,
            formCache: workQ.formCache,
            students: selectAll$prime(students)(workQ.students)(false),
            documents: workQ.documents,
            editDocs: workQ.editDocs,
            currentDoc: workQ.currentDoc,
            expandNext: workQ.expandNext,
            search: workQ.search,
            searchData: workQ.searchData,
            semester: workQ.semester,
            teacher: workQ.teacher,
            defTeacher: workQ.defTeacher,
            defSemester: workQ.defSemester,
            account: workQ.account,
            loaded: workQ.loaded
        };
    };
};
var loadWorkQueue = function (workQ) {
    return Control_Monad_Except_Trans.runExceptT(bind(multiQuery_({
        account: API_Account.queryAccountInfo,
        searchData: API_Search.searchDataQuery
    }))(function (v) {
        var defTeacher = map1(function (v1) {
            return {
                label: v1.name,
                value: v1
            };
        })(v.searchData.defaults.teacher);
        var defSemester = map1(function (v1) {
            return {
                label: v1.name,
                value: v1
            };
        })(v.searchData.defaults.semester);
        return pure({
            teacher: Widgets_BetterSelect.setSelected(workQ.teacher)(defTeacher),
            defTeacher: defTeacher,
            semester: Widgets_BetterSelect.setSelected(workQ.semester)(defSemester),
            defSemester: defSemester,
            account: v.account,
            searchData: v.searchData,
            search: Widgets_Search.loadSearchState(v.searchData),
            currentDoc: workQ.currentDoc,
            documents: workQ.documents,
            editDocs: workQ.editDocs,
            expandNext: workQ.expandNext,
            formCache: workQ.formCache,
            forms: workQ.forms,
            loaded: workQ.loaded,
            students: workQ.students
        });
    }));
};
var loadFormTypes = function (workQ) {
    var ftypesSel = fromFoldable1(map3(function (v) {
        return v.id;
    })(Internal_SelectionMap.listSelected(workQ.forms)));
    var ftypesEdit = fromFoldable2(map(function (v) {
        return v.form_type.id;
    })(workQ.editDocs));
    var ftypeIds = fromFoldable3(union(ftypesSel)(ftypesEdit));
    return bind(traverse(function (id) {
        return API_FormType.formQuery(id);
    })(ftypeIds))(function (ftypes) {
        var formCache = foldr(function (f) {
            return function (m) {
                return insert(f.id)(f)(m);
            };
        })(workQ.formCache)(ftypes);
        return pure({
            formCache: formCache,
            account: workQ.account,
            currentDoc: workQ.currentDoc,
            defSemester: workQ.defSemester,
            defTeacher: workQ.defTeacher,
            documents: workQ.documents,
            editDocs: workQ.editDocs,
            expandNext: workQ.expandNext,
            forms: workQ.forms,
            loaded: workQ.loaded,
            search: workQ.search,
            searchData: workQ.searchData,
            semester: workQ.semester,
            students: workQ.students,
            teacher: workQ.teacher
        });
    });
};
var lazyLoadWorkQueue = function (workQ) {
    if (workQ.loaded) {
        return pure1(new Data_Either.Right(workQ));
    };
    return loadWorkQueue(workQ);
};
var isWorkQueueEmpty = function (v) {
    return (isEmpty(v.forms) || isEmpty(v.students)) && Data_Array.length(v.editDocs) <= 0;
};
var workQueueWidget = function (workQ) {
    var expandBtns = div([ Concur_React_Props.className("expand-collapse-buttons") ])([ button([ voidRight(new StateChange({
        forms: workQ.forms,
        formCache: workQ.formCache,
        students: workQ.students,
        documents: workQ.documents,
        editDocs: workQ.editDocs,
        currentDoc: workQ.currentDoc,
        expandNext: new Data_Maybe.Just(true),
        search: workQ.search,
        searchData: workQ.searchData,
        semester: workQ.semester,
        teacher: workQ.teacher,
        defTeacher: workQ.defTeacher,
        defSemester: workQ.defSemester,
        account: workQ.account,
        loaded: workQ.loaded
    }))(Concur_React_Props.onClick), Concur_React_Props.className("expand-all") ])([ text("Expand all") ]), button([ voidRight(new StateChange({
        forms: workQ.forms,
        formCache: workQ.formCache,
        students: workQ.students,
        documents: workQ.documents,
        editDocs: workQ.editDocs,
        currentDoc: workQ.currentDoc,
        expandNext: new Data_Maybe.Just(false),
        search: workQ.search,
        searchData: workQ.searchData,
        semester: workQ.semester,
        teacher: workQ.teacher,
        defTeacher: workQ.defTeacher,
        defSemester: workQ.defSemester,
        account: workQ.account,
        loaded: workQ.loaded
    }))(Concur_React_Props.onClick), Concur_React_Props.className("collapse-all") ])([ text("Collapse all") ]) ]);
    return div([ Concur_React_Props.className("workqueue") ])(append1([ renderStudentList(workQ) ])((function () {
        var $155 = isWorkQueueEmpty(workQ);
        if ($155) {
            return [  ];
        };
        return [ expandBtns ];
    })()));
};
var loadingWidgetWQ = function (workQ) {
    return div([ Concur_React_Props.className("step-container") ])([ div([ Concur_React_Props.className("page-header") ])([ header([ Concur_React_Props.className("page-heading") ])([ text(Translate.lookupTranslation("loading")) ]) ]), Internal_ConcurUtil.loopInfinitely(workQueueWidget(workQ)), Widgets_Loading.loadingWidget ]);
};
var insertDocument = function (doc) {
    return function (workQ) {
        return {
            forms: workQ.forms,
            formCache: workQ.formCache,
            students: workQ.students,
            documents: Steps_WorkQueue_DocumentMap.insertDoc(doc)(workQ.documents),
            editDocs: workQ.editDocs,
            currentDoc: workQ.currentDoc,
            expandNext: workQ.expandNext,
            search: workQ.search,
            searchData: workQ.searchData,
            semester: workQ.semester,
            teacher: workQ.teacher,
            defTeacher: workQ.defTeacher,
            defSemester: workQ.defSemester,
            account: workQ.account,
            loaded: workQ.loaded
        };
    };
};
var newDocument = function (workQ) {
    return function (ftype) {
        return function (args) {
            return bind(runGraphQL_(API_Document.createDocument(ftype)(args)))(function (doc) {
                var newWorkQ = insertDocument(doc)(workQ);
                return pure(new Data_Tuple.Tuple(newWorkQ, doc));
            });
        };
    };
};
var initialWorkQueue = /* #__PURE__ */ (function () {
    var teacherSel = {
        placeholder: new Data_Maybe.Just(Translate.lookupTranslation("search-teacher")),
        allowDeselect: false,
        deselectLabel: Widgets_BetterSelect.defSelectConfig.deselectLabel
    };
    var semesterSel = {
        placeholder: new Data_Maybe.Just(Translate.lookupTranslation("search-semester")),
        allowDeselect: false,
        deselectLabel: Widgets_BetterSelect.defSelectConfig.deselectLabel
    };
    return {
        forms: API_FormType.emptyFormPickerMap,
        formCache: Data_Map_Internal.empty,
        students: Internal_SelectionMap.empty,
        documents: Steps_WorkQueue_DocumentMap.emptyDocMap,
        editDocs: [  ],
        currentDoc: Data_Maybe.Nothing.value,
        expandNext: Data_Maybe.Nothing.value,
        search: Widgets_Search.initSearchState,
        searchData: API_Search.emptySearchData,
        teacher: Widgets_BetterSelect["initSelect$prime"]("teacher")(API_Search.teacherSearchAff)(teacherSel),
        semester: Widgets_BetterSelect["initSelect$prime"]("semester")(API_Search.semesterSearchAff)(semesterSel),
        defTeacher: Data_Maybe.Nothing.value,
        defSemester: Data_Maybe.Nothing.value,
        account: {
            type: API_Account.TeacherAccount.value,
            student: Data_Maybe.Nothing.value
        },
        loaded: false
    };
})();
var getTeacher = function (workQ) {
    return map1(function (v) {
        return v.value;
    })(Widgets_BetterSelect.selectedItem(workQ.teacher));
};
var getTeacherOrErr = function (workQ) {
    var err = new $$Error.StringError("No teacher selected to fill forms for.");
    return Data_Either.note(err)(getTeacher(workQ));
};
var getSemester = function (workQ) {
    return map1(function (v) {
        return v.value;
    })(Widgets_BetterSelect.selectedItem(workQ.semester));
};
var getSemesterOrErr = function (workQ) {
    var err = new $$Error.StringError("No semester selected to fill forms for.");
    return Data_Either.note(err)(getSemester(workQ));
};
var getFormType = function (workQ) {
    return function (formId) {
        return lookup(formId)(workQ.formCache);
    };
};
var getForm = function (workQ) {
    return function (id) {
        return API_FormType.getSelectedForm(id)(workQ.forms);
    };
};
var getFillQueue = function (workQ) {
    var students = fromFoldable(map3(function (v) {
        return v.id;
    })(Internal_SelectionMap.listSelected(workQ.students)));
    var forms = fromFoldable(map3(function (v) {
        return v.id;
    })(Internal_SelectionMap.listSelected(workQ.forms)));
    var studentForms = function (student) {
        var docs = map(Data_Either.Left.create)(Data_Array.filter(function (f) {
            return eq(f.student.id)(student);
        })(workQ.editDocs));
        return map(function (f) {
            return {
                student: student,
                form: f
            };
        })(append1(docs)(map(Data_Either.Right.create)(forms)));
    };
    return Data_Array.concat(map(studentForms)(students));
};
var getDocument = function (workQ) {
    return function (ftype) {
        return function (docId) {
            return bind(runGraphQL_(API_Document.fetchDocumentQuery(ftype)(docId)))(function (doc) {
                var newWorkQ = insertDocument(doc)(workQ);
                return pure(new Data_Tuple.Tuple(newWorkQ, doc));
            });
        };
    };
};
var getDocByStudent = function (workQ) {
    return function (dl) {
        return Steps_WorkQueue_DocumentMap.getDocByLookup(dl)(workQ.documents);
    };
};
var getDoc = function (workQ) {
    return function (id) {
        return Steps_WorkQueue_DocumentMap.getDocById(id)(workQ.documents);
    };
};
var formForSelected$prime = function (v) {
    return Data_Maybe.isJust(Widgets_BetterSelect.selectedItem(v.teacher)) && Data_Maybe.isJust(Widgets_BetterSelect.selectedItem(v.semester));
};
var formForSelected = function (v) {
    if (eq2(v.account.type)(API_Account.BoardAccount.value)) {
        return Data_Maybe.isJust(Widgets_BetterSelect.selectedItem(v.semester));
    };
    return formForSelected$prime(v);
};
var fillQueueToCreateReq = function (v) {
    return function (v1) {
        if (v1.form instanceof Data_Either.Right) {
            return new Data_Maybe.Just({
                student_id: v1.student,
                type_id: v1.form.value0,
                teacher_id: map1(function (v2) {
                    return v2.value.id;
                })(v.defTeacher),
                semester: map1(function (v2) {
                    return v2.value.semester;
                })(v.defSemester)
            });
        };
        if (v1.form instanceof Data_Either.Left) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at Steps.WorkQueue (line 267, column 1 - line 267, column 79): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var editDocsWorkQueue = function (workQ) {
    return function (docs) {
        return {
            forms: workQ.forms,
            formCache: workQ.formCache,
            students: selectAll$prime(map(function (v1) {
                return v1.student;
            })(docs))(workQ.students)(true),
            documents: workQ.documents,
            editDocs: docs,
            currentDoc: workQ.currentDoc,
            expandNext: workQ.expandNext,
            search: workQ.search,
            searchData: workQ.searchData,
            semester: workQ.semester,
            teacher: workQ.teacher,
            defTeacher: workQ.defTeacher,
            defSemester: workQ.defSemester,
            account: workQ.account,
            loaded: workQ.loaded
        };
    };
};
var createAllForms = function (workQ) {
    return runGraphQL(API_Document.createAndExport(Data_Array.catMaybes(map(fillQueueToCreateReq(workQ))(getFillQueue(workQ)))));
};
var addStudents = function (workQ) {
    return function (students) {
        return {
            forms: workQ.forms,
            formCache: workQ.formCache,
            students: selectAll$prime(students)(workQ.students)(true),
            documents: workQ.documents,
            editDocs: workQ.editDocs,
            currentDoc: workQ.currentDoc,
            expandNext: workQ.expandNext,
            search: workQ.search,
            searchData: workQ.searchData,
            semester: workQ.semester,
            teacher: workQ.teacher,
            defTeacher: workQ.defTeacher,
            defSemester: workQ.defSemester,
            account: workQ.account,
            loaded: workQ.loaded
        };
    };
};
export {
    StateChange,
    JumpToForm,
    initialWorkQueue,
    loadWorkQueue,
    lazyLoadWorkQueue,
    resetWorkQueue,
    formForSelected,
    formForSelected$prime,
    isWorkQueueEmpty,
    getForm,
    getTeacher,
    getTeacherOrErr,
    getSemester,
    getSemesterOrErr,
    setTeacherAndSemester,
    loadFormTypes,
    getFormType,
    addStudents,
    removeStudents,
    getDoc,
    getDocByStudent,
    newDocument,
    getDocument,
    insertDocument,
    setCurrentDoc,
    editDocsWorkQueue,
    fillQueueToCreateReq,
    showFillQueue,
    showFQE,
    getFillQueue,
    createAllForms,
    workQueueWidget,
    renderStudentList,
    renderStudentDetails,
    loadingWidgetWQ
};
