// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as API_Account from "../API.Account/index.js";
import * as API_Autofill from "../API.Autofill/index.js";
import * as API_Document from "../API.Document/index.js";
import * as API_SelStudent from "../API.SelStudent/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Set from "../Data.Set/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as Internal_JSUtil from "../Internal.JSUtil/index.js";
import * as Internal_Luxon from "../Internal.Luxon/index.js";
import * as Internal_Util from "../Internal.Util/index.js";
import * as React_SyntheticEvent from "../React.SyntheticEvent/index.js";
import * as Steps_Step from "../Steps.Step/index.js";
import * as Steps_WorkQueue from "../Steps.WorkQueue/index.js";
import * as Steps_WorkQueue_DocumentMap from "../Steps.WorkQueue.DocumentMap/index.js";
import * as Translate from "../Translate/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
import * as Widgets_Attachments from "../Widgets.Attachments/index.js";
import * as Widgets_Comments from "../Widgets.Comments/index.js";
import * as Widgets_Dropdown from "../Widgets.Dropdown/index.js";
import * as Widgets_Form from "../Widgets.Form/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_FormPreview from "../Widgets.FormPreview/index.js";
import * as Widgets_HiddenSelect from "../Widgets.HiddenSelect/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
import * as Widgets_Signature from "../Widgets.Signature/index.js";
import * as Widgets_Signature_Group from "../Widgets.Signature.Group/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.bindWidget);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var bind = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var except = /* #__PURE__ */ Control_Monad_Except_Trans.except(Effect_Aff.applicativeAff);
var runGraphQL_ = /* #__PURE__ */ GraphQL_Join.runGraphQL_(GraphQL_Query.qgql);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var length = /* #__PURE__ */ Data_Foldable.length(Data_Foldable.foldableArray)(Data_Semiring.semiringInt);
var header = /* #__PURE__ */ Concur_React_DOM.header(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h1 = /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h2 = /* #__PURE__ */ Concur_React_DOM.h2(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var notEq = /* #__PURE__ */ Data_Eq.notEq(API_Account.eqAccType);
var map2 = /* #__PURE__ */ Data_Functor.map(/* #__PURE__ */ Control_Monad_Except_Trans.functorExceptT(Effect_Aff.functorAff));
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var p$prime = /* #__PURE__ */ Concur_React_DOM["p$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map4 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var bind1 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Concur_Core_Types.widgetMonad));
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var except1 = /* #__PURE__ */ Control_Monad_Except_Trans.except(Concur_Core_Types.applicativeWidget);
var pure2 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Concur_Core_Types.widgetMonad));
var bind2 = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ GraphQL_Type.eqRNTY(Data_Eq.eqString));
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Concur_Core_Types.bindWidget);
var $$void = /* #__PURE__ */ Data_Functor["void"](Effect.functorEffect);
var show1 = /* #__PURE__ */ Data_Show.show($$Error.showErrorWrapper);
var ordRNTY = /* #__PURE__ */ GraphQL_Type.ordRNTY(Data_Ord.ordString);
var fromFoldable = /* #__PURE__ */ Data_Set.fromFoldable(Data_Foldable.foldableArray)(ordRNTY);
var fromFoldable1 = /* #__PURE__ */ Data_Set.fromFoldable(Data_List_Types.foldableList)(ordRNTY);
var map5 = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var fromFoldable2 = /* #__PURE__ */ Data_Array.fromFoldable(Data_Set.foldableSet);
var union = /* #__PURE__ */ Data_Set.union(ordRNTY);
var map6 = /* #__PURE__ */ Data_Functor.map(Steps_Step.wrFunctor);
var JumpIdxF = /* #__PURE__ */ (function () {
    function JumpIdxF(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    JumpIdxF.create = function (value0) {
        return function (value1) {
            return new JumpIdxF(value0, value1);
        };
    };
    return JumpIdxF;
})();
var JumpF = /* #__PURE__ */ (function () {
    function JumpF(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    JumpF.create = function (value0) {
        return function (value1) {
            return new JumpF(value0, value1);
        };
    };
    return JumpF;
})();
var NextF = /* #__PURE__ */ (function () {
    function NextF() {

    };
    NextF.value = new NextF();
    return NextF;
})();
var PrevF = /* #__PURE__ */ (function () {
    function PrevF() {

    };
    PrevF.value = new PrevF();
    return PrevF;
})();
var CancelF = /* #__PURE__ */ (function () {
    function CancelF() {

    };
    CancelF.value = new CancelF();
    return CancelF;
})();
var ExportF = /* #__PURE__ */ (function () {
    function ExportF(value0) {
        this.value0 = value0;
    };
    ExportF.create = function (value0) {
        return new ExportF(value0);
    };
    return ExportF;
})();
var SaveCancelF = /* #__PURE__ */ (function () {
    function SaveCancelF() {

    };
    SaveCancelF.value = new SaveCancelF();
    return SaveCancelF;
})();
var SaveF = /* #__PURE__ */ (function () {
    function SaveF() {

    };
    SaveF.value = new SaveF();
    return SaveF;
})();
var AutofillF = /* #__PURE__ */ (function () {
    function AutofillF() {

    };
    AutofillF.value = new AutofillF();
    return AutofillF;
})();
var StateF = /* #__PURE__ */ (function () {
    function StateF(value0) {
        this.value0 = value0;
    };
    StateF.create = function (value0) {
        return new StateF(value0);
    };
    return StateF;
})();
var WorkQF = /* #__PURE__ */ (function () {
    function WorkQF(value0) {
        this.value0 = value0;
    };
    WorkQF.create = function (value0) {
        return new WorkQF(value0);
    };
    return WorkQF;
})();
var ActionF = /* #__PURE__ */ (function () {
    function ActionF(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ActionF.create = function (value0) {
        return function (value1) {
            return new ActionF(value0, value1);
        };
    };
    return ActionF;
})();
var ScrollF = /* #__PURE__ */ (function () {
    function ScrollF() {

    };
    ScrollF.value = new ScrollF();
    return ScrollF;
})();
var workQChangeF = function (v) {
    return function (wq) {
        return new WorkQF(new Steps_WorkQueue.StateChange(wq));
    };
};
var sigStateChangeF = function (state) {
    return function (sstate) {
        return new StateF({
            form: state.form,
            doc: state.doc,
            workQ: state.workQ,
            comments: state.comments,
            attachments: state.attachments,
            preview: state.preview,
            signatures: sstate
        });
    };
};
var setFillState = function (v) {
    return function (fstate) {
        return {
            queue: v.queue,
            position: v.position,
            anchor: v.anchor,
            fillState: fstate,
            workQ: fstate.workQ
        };
    };
};
var scrollIntoView = function (state) {
    return function (id) {
        return discard(liftEffect($foreign.jsScrollIntoView(id)))(function () {
            return pure(state);
        });
    };
};
var saveDocument = function (v) {
    return Control_Monad_Except_Trans.runExceptT((function () {
        var json = Widgets_Form.formData(v.fillState.form);
        return bind(except(Steps_WorkQueue.getTeacherOrErr(v.fillState.workQ)))(function (teacher) {
            return bind(except(Steps_WorkQueue.getSemesterOrErr(v.fillState.workQ)))(function (semester) {
                return runGraphQL_(API_Document.updateDocument(v.fillState.doc)(json)({
                    teacher: teacher.id,
                    semester: semester.semester
                }));
            });
        });
    })());
};
var renderExportDropdown = function (v) {
    if (v.fillState.doc.exports.length === 0) {
        return div$prime([  ]);
    };
    if (v.fillState.doc.exports.length === 1) {
        return button([ voidRight(new ExportF(v["fillState"]["doc"]["exports"][0]))(Concur_React_Props.onClick), Concur_React_Props.className("btn btn-export") ])([ text(Translate.lookupTranslation("export")) ]);
    };
    var mkItem = function ($$export) {
        return {
            label: $$export.label,
            value: $$export
        };
    };
    var items = map(mkItem)(v.fillState.doc.exports);
    var disableSave = !Steps_WorkQueue["formForSelected$prime"](v.workQ);
    var button1 = button([ Concur_React_Props.onClick, Concur_React_Props.disabled(disableSave), Concur_React_Props.className("btn btn-export") ])([ text(Translate.lookupTranslation("export")) ]);
    return map1(ExportF.create)(Widgets_Dropdown.dropdownButton(button1)(items));
};
var previewStateChangeF = function (state) {
    return function (pstate) {
        return new StateF({
            form: state.form,
            doc: state.doc,
            workQ: state.workQ,
            comments: state.comments,
            attachments: state.attachments,
            preview: pstate,
            signatures: state.signatures
        });
    };
};
var onLastItem = function (state) {
    return state.position > (length(state.queue) - 2 | 0);
};
var onFirstItem = function (state) {
    return state.position <= 0;
};
var renderHeader = function (state) {
    var dropBtn = button([ Concur_React_Props.className("dropdown-button"), Concur_React_Props.onClick ])([ text("\u2807") ]);
    var disableSave = !Steps_WorkQueue["formForSelected$prime"](state.workQ);
    var renderHeader$prime = function (v) {
        return header([ Concur_React_Props.className("page-header") ])([ div([ Concur_React_Props.className("page-headings") ])([ h1([ Concur_React_Props.className("page-heading") ])([ text(API_SelStudent.studentName(v.workQ.account)(v.doc.student)) ]), h2([ Concur_React_Props.className("page-subheading") ])([ text(v.form.label) ]) ]), div([ Concur_React_Props.className("btn-group") ])(Data_Array.catMaybes([ new Data_Maybe.Just(button([ voidRight(CancelF.value)(Concur_React_Props.onClick), Concur_React_Props.className("btn btn-cancel") ])([ text(Translate.lookupTranslation("cancel")) ])), new Data_Maybe.Just(renderExportDropdown(state)), new Data_Maybe.Just(button([ voidRight(SaveF.value)(Concur_React_Props.onClick), Concur_React_Props.className("btn btn-save btn-primary"), Concur_React_Props.disabled(disableSave) ])([ text(Translate.lookupTranslation("save")) ])), Internal_Util.justIf(!onFirstItem(state))(button([ voidRight(PrevF.value)(Concur_React_Props.onClick), Concur_React_Props.className("btn btn-previous") ])([ text(Translate.lookupTranslation("prev")) ])), new Data_Maybe.Just(button([ voidRight(NextF.value)(Concur_React_Props.onClick), Concur_React_Props.className("btn btn-next btn-primary"), Concur_React_Props.disabled(disableSave) ])([ text((function () {
            var $139 = onLastItem(state);
            if ($139) {
                return Translate.lookupTranslation("finish");
            };
            return Translate.lookupTranslation("next");
        })()) ])), new Data_Maybe.Just(Widgets_Dropdown.dropdownButton(dropBtn)([ {
            label: Translate.lookupTranslation("autofill-form-button"),
            value: AutofillF.value
        } ])) ])) ]);
    };
    return renderHeader$prime(state.fillState);
};
var initFormSigs = function (workQ) {
    return function (ft) {
        return function (docId) {
            return Widgets_Signature_Group.initSigGroup(workQ)("form-signatures")(docId)(map(Widgets_Signature.fromSigField(docId))(ft.signatures));
        };
    };
};
var loadFormFillLayout = function (v) {
    return function (fbasic) {
        return function (doc) {
            return function (workQ) {
                return bind(except(Data_Either.note(new $$Error.StringError("FormType " + (GraphQL_Type.innerId(fbasic.id) + " wasn't loaded")))(Steps_WorkQueue.getFormType(workQ)(fbasic.id))))(function (ftype) {
                    return pure1({
                        form: Widgets_Form.fromFormType(ftype),
                        preview: Widgets_FormPreview.initDocPreview(doc),
                        signatures: Widgets_Signature_Group.loadSignatures(doc)(initFormSigs(workQ)(ftype)(doc.id)),
                        comments: Widgets_Comments.initComments({
                            id: "form-comments",
                            formId: doc.id,
                            comments: doc.comments,
                            can: doc.can
                        }),
                        attachments: Widgets_Attachments.initAttachments({
                            id: "form-attachments",
                            formId: doc.id,
                            attachments: doc.attachments
                        }),
                        workQ: workQ,
                        doc: doc
                    });
                });
            };
        };
    };
};
var loadExistingFillState = function (state) {
    return function (v) {
        return function (workQ) {
            return bind(Steps_WorkQueue.getDocument(workQ)(v.form_type)(v.id))(function (v1) {
                var newWQ = Steps_WorkQueue.setTeacherAndSemester(v1.value0)(v1.value1.teacher)(v1.value1.semester);
                return bind(loadFormFillLayout(state)(v.form_type)(v1.value1)(Steps_WorkQueue.setCurrentDoc(newWQ)(new Data_Maybe.Just({
                    formId: v.form_type.id,
                    studentId: v1.value1.student.id
                }))))(function (fillState) {
                    var formCtx = Widgets_Form_Class.initFormContext(workQ.account)(v1.value1);
                    return bind(except(Widgets_Form.setFormData(formCtx)(v1.value1.json)(fillState.form)))(function (formData) {
                        return pure1(setFillState(state)({
                            form: formData,
                            doc: fillState.doc,
                            workQ: fillState.workQ,
                            comments: fillState.comments,
                            attachments: fillState.attachments,
                            preview: fillState.preview,
                            signatures: fillState.signatures
                        }));
                    });
                });
            });
        };
    };
};
var loadPreviousFillState = function (state) {
    return function (doc) {
        return function (formId) {
            return function (workQ) {
                return bind(except(Data_Either.note(new $$Error.StringError("Unknown formtype " + GraphQL_Type.innerId(formId)))(Steps_WorkQueue.getForm(workQ)(formId))))(function (ftype) {
                    return bind(loadFormFillLayout(state)(ftype)(doc)(workQ))(function (fillState$prime) {
                        var formCtx = Widgets_Form_Class.initFormContext(workQ.account)(doc);
                        return bind(except(Widgets_Form.setFormData(formCtx)(doc.json)(fillState$prime.form)))(function (dataForm) {
                            var fillState = {
                                form: dataForm,
                                workQ: Steps_WorkQueue.setCurrentDoc(fillState$prime.workQ)(new Data_Maybe.Just({
                                    formId: formId,
                                    studentId: doc.student.id
                                })),
                                attachments: fillState$prime.attachments,
                                comments: fillState$prime.comments,
                                doc: fillState$prime.doc,
                                preview: fillState$prime.preview,
                                signatures: fillState$prime.signatures
                            };
                            return pure1(setFillState(state)(fillState));
                        });
                    });
                });
            };
        };
    };
};
var formResultF = function (v) {
    return function (v1) {
        if (v1.value0.action instanceof Data_Maybe.Nothing) {
            return new StateF({
                form: v1.value0.state,
                doc: v.doc,
                workQ: v.workQ,
                comments: v.comments,
                attachments: v.attachments,
                preview: v.preview,
                signatures: v.signatures
            });
        };
        if (v1.value0.action instanceof Data_Maybe.Just) {
            return new ActionF(v1.value0.action.value0, {
                form: v1.value0.state,
                doc: v.doc,
                workQ: v.workQ,
                comments: v.comments,
                attachments: v.attachments,
                preview: v.preview,
                signatures: v.signatures
            });
        };
        throw new Error("Failed pattern match at Steps.Step3 (line 435, column 1 - line 435, column 77): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var formContext = function (state) {
    return Widgets_Form_Class.initFormContext(state.workQ.account)(state.doc);
};
var currentFillEntry = function (state) {
    return Data_Array.index(state.queue)(state.position);
};
var createDocFormFillState = function (state) {
    return function (formId) {
        return function (student) {
            return bind(except(Data_Either.note(new $$Error.StringError("Unknown formtype " + GraphQL_Type.innerId(formId)))(Steps_WorkQueue.getForm(state.workQ)(formId))))(function (ftype) {
                return bind(except(Steps_WorkQueue.getSemesterOrErr(state.workQ)))(function (semester) {
                    return bind((function () {
                        var $158 = notEq(state.workQ.account.type)(API_Account.BoardAccount.value);
                        if ($158) {
                            return map2(Data_Maybe.Just.create)(except(Steps_WorkQueue.getTeacherOrErr(state.workQ)));
                        };
                        return pure1(Data_Maybe.Nothing.value);
                    })())(function (teacher) {
                        return bind(Steps_WorkQueue.newDocument(state.workQ)(ftype)({
                            student_id: student,
                            semester: semester.semester,
                            teacher_id: map3(function (v) {
                                return v.id;
                            })(teacher)
                        }))(function (v) {
                            return bind(loadFormFillLayout(state)(ftype)(v.value1)(Steps_WorkQueue.setCurrentDoc(v.value0)(new Data_Maybe.Just({
                                formId: formId,
                                studentId: v.value1.student.id
                            }))))(function (fillState) {
                                var formCtx = (function () {
                                    var v1 = Widgets_Form_Class.initFormContext(state.workQ.account)(v.value1);
                                    return {
                                        isAutofilling: true,
                                        account: v1.account,
                                        autofillArgs: v1.autofillArgs,
                                        curIndex: v1.curIndex,
                                        doc: v1.doc,
                                        parentIds: v1.parentIds
                                    };
                                })();
                                return bind(except(Widgets_Form.setFormData(formCtx)(v.value1.json)(fillState.form)))(function (formData) {
                                    return pure1(setFillState(state)({
                                        form: formData,
                                        doc: fillState.doc,
                                        workQ: fillState.workQ,
                                        comments: fillState.comments,
                                        attachments: fillState.attachments,
                                        preview: fillState.preview,
                                        signatures: fillState.signatures
                                    }));
                                });
                            });
                        });
                    });
                });
            });
        };
    };
};
var loadFillState = function (state) {
    return Control_Monad_Except_Trans.runExceptT(bind(except(Data_Either.note(new $$Error.StringError("Position in fill queue out of bounds. Index: " + (show(state.position) + (" Queue: " + Steps_WorkQueue.showFillQueue(state.queue)))))(currentFillEntry(state))))(function (entry) {
        if (entry.form instanceof Data_Either.Left) {
            return loadExistingFillState(state)(entry.form.value0)(state.workQ);
        };
        if (entry.form instanceof Data_Either.Right) {
            var docRef = {
                formId: entry.form.value0,
                studentId: entry.student
            };
            var v = Steps_WorkQueue.getDocByStudent(state.workQ)(docRef);
            if (v instanceof Data_Maybe.Just) {
                return loadPreviousFillState(state)(v.value0)(entry.form.value0)(state.workQ);
            };
            if (v instanceof Data_Maybe.Nothing) {
                return createDocFormFillState(state)(entry.form.value0)(entry.student);
            };
            throw new Error("Failed pattern match at Steps.Step3 (line 311, column 13 - line 314, column 77): " + [ v.constructor.name ]);
        };
        throw new Error("Failed pattern match at Steps.Step3 (line 304, column 5 - line 314, column 77): " + [ entry.form.constructor.name ]);
    }));
};
var loadFillStateWidget = function (state) {
    return alt(Steps_WorkQueue.loadingWidgetWQ(state.workQ))(liftAff(loadFillState(state)));
};
var loadInitFillQueue = function (workQ) {
    return Control_Monad_Except_Trans.runExceptT((function () {
        var queue = Steps_WorkQueue.getFillQueue(workQ);
        return loadFillState({
            queue: queue,
            workQ: workQ,
            position: 0,
            anchor: Data_Maybe.Nothing.value,
            teacher: workQ.searchData.defaults.teacher,
            semester: workQ.searchData.defaults.semester
        });
    })());
};
var commentStateChangeF = function (state) {
    return function (cstate) {
        return new StateF({
            form: state.form,
            doc: state.doc,
            workQ: state.workQ,
            comments: cstate,
            attachments: state.attachments,
            preview: state.preview,
            signatures: state.signatures
        });
    };
};
var attachmentStateChangeF = function (state) {
    return function (astate) {
        return new StateF({
            form: state.form,
            doc: state.doc,
            workQ: state.workQ,
            comments: state.comments,
            attachments: astate,
            preview: state.preview,
            signatures: state.signatures
        });
    };
};
var renderFormFill = function (state) {
    return div([ Concur_React_Props.className("step-3-right form-submission") ])([ (function () {
        var $167 = Steps_WorkQueue["formForSelected$prime"](state.workQ);
        if ($167) {
            return div$prime([  ]);
        };
        return div([ Concur_React_Props.className("error") ])([ text(Translate.lookupTranslation("must-select-teacher-semester")) ]);
    })(), div([ Concur_React_Props.className("teacher-semester-selections") ])([ p$prime([ text(Translate.lookupTranslation("semester-hidden-select")) ]), map1(map4(map4(WorkQF.create)(Steps_WorkQueue.StateChange.create))(function (v) {
        return {
            semester: v,
            account: state.workQ.account,
            currentDoc: state.workQ.currentDoc,
            defSemester: state.workQ.defSemester,
            defTeacher: state.workQ.defTeacher,
            documents: state.workQ.documents,
            editDocs: state.workQ.editDocs,
            expandNext: state.workQ.expandNext,
            formCache: state.workQ.formCache,
            forms: state.workQ.forms,
            loaded: state.workQ.loaded,
            search: state.workQ.search,
            searchData: state.workQ.searchData,
            students: state.workQ.students,
            teacher: state.workQ.teacher
        };
    }))(Widgets_HiddenSelect.hiddenSelect(state.workQ.semester)), p$prime([ text(Translate.lookupTranslation("teacher-hidden-select")) ]), map1(map4(map4(WorkQF.create)(Steps_WorkQueue.StateChange.create))(function (v) {
        return {
            teacher: v,
            account: state.workQ.account,
            currentDoc: state.workQ.currentDoc,
            defSemester: state.workQ.defSemester,
            defTeacher: state.workQ.defTeacher,
            documents: state.workQ.documents,
            editDocs: state.workQ.editDocs,
            expandNext: state.workQ.expandNext,
            formCache: state.workQ.formCache,
            forms: state.workQ.forms,
            loaded: state.workQ.loaded,
            search: state.workQ.search,
            searchData: state.workQ.searchData,
            semester: state.workQ.semester,
            students: state.workQ.students
        };
    }))(Widgets_HiddenSelect.hiddenSelect(state.workQ.teacher)) ]), map1(formResultF(state))(Widgets_Form.formWidget(formContext(state))(state.form)), p$prime([ text(Translate.lookupTranslation("save-before-preview")) ]), a([ Concur_React_Props["_id"]("signature-anchor") ])([  ]), map1(previewStateChangeF(state))(Widgets_FormPreview.renderPreview(state.preview)), div([ Concur_React_Props.className("signatures") ])((function () {
        var $168 = length(state.signatures.sigs) > 0;
        if ($168) {
            return [ h1([ Concur_React_Props.className("signatures-heading") ])([ text(Translate.lookupTranslation("sign")) ]), map1(sigStateChangeF(state))(Widgets_Signature_Group.signatureGroup(state.signatures)) ];
        };
        return [  ];
    })()), map1(commentStateChangeF(state))(Widgets_Comments.runComments(state.comments)), map1(attachmentStateChangeF(state))(Widgets_Attachments.runAttachments(state.attachments)), div([ Concur_React_Props.className("form-updated-times") ])([ p$prime([ text(Translate.lookupTranslation("form-created-at") + (" " + Internal_Luxon.prettyPrint(state.doc.created_at))) ]), p$prime([ text(Translate.lookupTranslation("form-updated-at") + (" " + Internal_Luxon.prettyPrint(state.doc.updated_at))) ]) ]) ]);
};
var formFillWidget = function (state) {
    return renderFormFill(state);
};
var renderFillQueue = function (state) {
    return div([ Concur_React_Props.className("step-3-scroll overflow-y-auto") ])([ div([ Concur_React_Props.className("step-container step-3"), Concur_React_Props.key("step-3-container"), Concur_React_Props["_id"]("step-3-container") ])(Data_Array.catMaybes([ new Data_Maybe.Just(renderHeader(state)), new Data_Maybe.Just(map1(WorkQF.create)(Steps_WorkQueue.workQueueWidget(state.workQ))), new Data_Maybe.Just(formFillWidget(state.fillState)), map3(scrollIntoView(ScrollF.value))(state.anchor) ])) ]);
};
var renderLoading = function (state) {
    return alt(Widgets_Loading.loadingWidgetOverlay)(Internal_ConcurUtil.loopInfinitely(renderFillQueue(state)));
};
var autofillLoadWidget = function (state) {
    return function (args) {
        return bind1(alt(liftAff(runGraphQL(API_Autofill.autofillData(args))))(renderLoading(state)))(function (res) {
            var formCtx = (function () {
                var v = formContext(state.fillState);
                return {
                    isAutofilling: true,
                    account: v.account,
                    autofillArgs: v.autofillArgs,
                    curIndex: v.curIndex,
                    doc: v.doc,
                    parentIds: v.parentIds
                };
            })();
            var newDoc = {
                employer: res.employer,
                attachments: state.fillState.doc.attachments,
                can: state.fillState.doc.can,
                comments: state.fillState.doc.comments,
                contact: state.fillState.doc.contact,
                courses: state.fillState.doc.courses,
                created_at: state.fillState.doc.created_at,
                exports: state.fillState.doc.exports,
                formId: state.fillState.doc.formId,
                formName: state.fillState.doc.formName,
                id: state.fillState.doc.id,
                json: state.fillState.doc.json,
                mutations: state.fillState.doc.mutations,
                position: state.fillState.doc.position,
                semester: state.fillState.doc.semester,
                sig_defaults: state.fillState.doc.sig_defaults,
                signatures: state.fillState.doc.signatures,
                student: state.fillState.doc.student,
                teacher: state.fillState.doc.teacher,
                updated_at: state.fillState.doc.updated_at,
                verifications: state.fillState.doc.verifications
            };
            return bind1(except1(Widgets_Form.setFormData(formCtx)(res.data)(state.fillState.form)))(function (newFormState) {
                return pure2({
                    fillState: {
                        form: newFormState,
                        doc: newDoc,
                        signatures: Widgets_Signature_Group.updateDefSignatures(state.fillState.signatures)(res.signature_fields),
                        attachments: state.fillState.attachments,
                        comments: state.fillState.comments,
                        preview: state.fillState.preview,
                        workQ: state.fillState.workQ
                    },
                    anchor: state.anchor,
                    position: state.position,
                    queue: state.queue,
                    workQ: state.workQ
                });
            });
        });
    };
};
var saveDocWidget = function (state) {
    var tag = (function () {
        var $206 = $$Error.tagContext(new $$Error.InForm(state.fillState.form.id, state.fillState.form.label));
        var $207 = $$Error.tagAction("saving form");
        return function ($208) {
            return $206($207($208));
        };
    })();
    return map1(tag)(Control_Monad_Except_Trans.runExceptT(bind1(alt(liftAff(saveDocument(state)))(renderLoading(state)))(function (doc) {
        return pure2({
            workQ: Steps_WorkQueue.insertDocument(doc)(state.workQ),
            fillState: {
                signatures: Widgets_Signature_Group.loadSignatures(doc)(state.fillState.signatures),
                attachments: state.fillState.attachments,
                comments: state.fillState.comments,
                doc: state.fillState.doc,
                form: state.fillState.form,
                preview: state.fillState.preview,
                workQ: state.fillState.workQ
            },
            anchor: state.anchor,
            position: state.position,
            queue: state.queue
        });
    })));
};
var handleFill = function (v) {
    return function (v1) {
        var state = v;
        if (v1 instanceof WorkQF && v1.value0 instanceof Steps_WorkQueue.StateChange) {
            return fillQueue({
                queue: state.queue,
                position: state.position,
                anchor: state.anchor,
                workQ: v1.value0.value0,
                fillState: {
                    form: state.fillState.form,
                    doc: state.fillState.doc,
                    workQ: v1.value0.value0,
                    comments: state.fillState.comments,
                    attachments: state.fillState.attachments,
                    preview: state.fillState.preview,
                    signatures: state.fillState.signatures
                }
            });
        };
        var state = v;
        if (v1 instanceof WorkQF && v1.value0 instanceof Steps_WorkQueue.JumpToForm) {
            return bind2(liftEffect(React_SyntheticEvent.preventDefault(v1.value0.value3)))(function () {
                var newState = {
                    fillState: {
                        workQ: v1.value0.value0,
                        attachments: state.fillState.attachments,
                        comments: state.fillState.comments,
                        doc: state.fillState.doc,
                        form: state.fillState.form,
                        preview: state.fillState.preview,
                        signatures: state.fillState.signatures
                    },
                    workQ: v1.value0.value0,
                    anchor: state.anchor,
                    position: state.position,
                    queue: state.queue
                };
                return handleFill(newState)(new JumpF(v1.value0.value1, v1.value0.value2));
            });
        };
        var state = v;
        if (v1 instanceof StateF) {
            return discard(liftEffect(Internal_JSUtil.blockPageUnload))(function () {
                return fillQueue({
                    queue: state.queue,
                    position: state.position,
                    anchor: state.anchor,
                    workQ: v1.value0.workQ,
                    fillState: v1.value0
                });
            });
        };
        var state = v;
        if (v1 instanceof JumpF) {
            var match = function (v2) {
                if (v2.form instanceof Data_Either.Right) {
                    return eq(v1.value0.studentId)(v2.student) && eq(v1.value0.formId)(v2.form.value0);
                };
                if (v2.form instanceof Data_Either.Left) {
                    return eq(v1.value0.studentId)(v2.student) && eq(v1.value0.formId)(v2.form.value0.form_type.id);
                };
                throw new Error("Failed pattern match at Steps.Step3 (line 176, column 9 - line 176, column 83): " + [ v2.constructor.name ]);
            };
            var idxM = Data_Array.findIndex(match)(state.queue);
            if (idxM instanceof Data_Maybe.Just) {
                var $187 = state.position === idxM.value0;
                if ($187) {
                    return discard(Data_Maybe.maybe(pure(Data_Unit.unit))(scrollIntoView(Data_Unit.unit))(v1.value1))(function () {
                        return fillQueue(state);
                    });
                };
                return handleFill(state)(new JumpIdxF(idxM.value0, v1.value1));
            };
            if (idxM instanceof Data_Maybe.Nothing) {
                return fillQueue(state);
            };
            throw new Error("Failed pattern match at Steps.Step3 (line 180, column 5 - line 186, column 35): " + [ idxM.constructor.name ]);
        };
        var state = v;
        if (v1 instanceof JumpIdxF && (v1.value0 < 0 || v1.value0 > (length(state.queue) - 1 | 0))) {
            return fillQueue(state);
        };
        var state = v;
        if (v1 instanceof JumpIdxF) {
            return bind2(bindFlipped($$Error.wrapCritical)(saveDocWidget(state)))(function (state$prime) {
                return fillQueueLoadState({
                    queue: state$prime.queue,
                    position: v1.value0,
                    anchor: v1.value1,
                    workQ: state$prime.workQ,
                    fillState: state$prime.fillState
                });
            });
        };
        var state = v;
        if (v1 instanceof NextF && onLastItem(state)) {
            return bind2(bindFlipped($$Error.wrapCritical)(saveDocWidget(state)))(function () {
                return pure(new Steps_Step.WidgetResult(Steps_Step.NavNext.value, state.workQ));
            });
        };
        var state = v;
        if (v1 instanceof NextF) {
            return handleFill(state)(new JumpIdxF(state.position + 1 | 0, Data_Maybe.Nothing.value));
        };
        var state = v;
        if (v1 instanceof PrevF && state.position <= 0) {
            return handleFill(state)(new JumpIdxF(length(state.queue) - 1 | 0, Data_Maybe.Nothing.value));
        };
        var state = v;
        if (v1 instanceof PrevF) {
            return handleFill(state)(new JumpIdxF(state.position - 1 | 0, Data_Maybe.Nothing.value));
        };
        var state = v;
        if (v1 instanceof SaveF) {
            return bind2(bindFlipped($$Error.wrapCritical)(saveDocWidget(state)))(function (state$prime) {
                return discard(liftEffect(Internal_JSUtil.allowPageUnload))(function () {
                    return fillQueue(state$prime);
                });
            });
        };
        var state = v;
        if (v1 instanceof ExportF) {
            return bind2(bindFlipped($$Error.wrapCritical)(saveDocWidget(state)))(function (state$prime) {
                return discard(liftEffect(function __do() {
                    var win = Web_HTML.window();
                    return $$void(Web_HTML_Window.open(v1.value0.url)("_blank")("")(win))();
                }))(function () {
                    return fillQueue(state$prime);
                });
            });
        };
        var state = v;
        if (v1 instanceof CancelF) {
            return bind2(liftEffect(Internal_JSUtil.manualSavePrompt))(function (res) {
                if (res) {
                    return pure(new Steps_Step.WidgetResult(Steps_Step.NavCancel.value, state.workQ));
                };
                return fillQueue(state);
            });
        };
        var state = v;
        if (v1 instanceof SaveCancelF) {
            return bind2(bindFlipped($$Error.wrapCritical)(saveDocWidget(state)))(function (state$prime) {
                return pure(new Steps_Step.WidgetResult(Steps_Step.NavCancel.value, state$prime.workQ));
            });
        };
        var state = v;
        if (v1 instanceof AutofillF) {
            return bindFlipped(fillQueue)(bindFlipped($$Error.wrapCritical)(Control_Monad_Except_Trans.runExceptT(autofillLoadWidget(state)(API_Autofill.initAutofillArgs(state.fillState.doc.formId)(state.fillState.doc.student.id)))));
        };
        if (v1 instanceof ActionF) {
            var state = {
                fillState: v1.value1,
                workQ: v1.value1.workQ,
                anchor: v.anchor,
                position: v.position,
                queue: v.queue
            };
            return bindFlipped(fillQueue)(bindFlipped($$Error.wrapCritical)(Control_Monad_Except_Trans.runExceptT(autofillLoadWidget(state)(v1.value0.value0))));
        };
        if (v1 instanceof ScrollF) {
            return fillQueue({
                queue: v.queue,
                position: v.position,
                anchor: Data_Maybe.Nothing.value,
                workQ: v.workQ,
                fillState: v.fillState
            });
        };
        throw new Error("Failed pattern match at Steps.Step3 (line 158, column 1 - line 158, column 78): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var fillQueueLoadState = function (state) {
    return bindFlipped(fillQueue)(bindFlipped($$Error.wrapCritical)(map1($$Error.tagContext(new $$Error.InForm(state.fillState.form.id, state.fillState.form.label)))(loadFillStateWidget(state))));
};
var fillQueue = function (state) {
    return bind2(renderFillQueue(state))(handleFill(state));
};
var runFillQueue = function (workQ) {
    return bindFlipped(fillQueue)(bindFlipped($$Error.wrapCritical)(alt(liftAff(loadInitFillQueue(workQ)))(Steps_WorkQueue.loadingWidgetWQ(workQ))));
};
var startFillQueue = function (workQ$prime) {
    var load = liftAff(Control_Monad_Except_Trans.runExceptT(Steps_WorkQueue.loadFormTypes(workQ$prime)));
    return bind2(alt(Steps_WorkQueue.loadingWidgetWQ(workQ$prime))(load))(function (workQ$prime$prime) {
        if (workQ$prime$prime instanceof Data_Either.Right) {
            return runFillQueue(workQ$prime$prime.value0);
        };
        if (workQ$prime$prime instanceof Data_Either.Left) {
            return text(show1(workQ$prime$prime.value0));
        };
        throw new Error("Failed pattern match at Steps.Step3 (line 101, column 5 - line 103, column 38): " + [ workQ$prime$prime.constructor.name ]);
    });
};
var formFillQueueStep = function (stepState) {
    var selectedDocs = function (workQ) {
        return fromFoldable(map(function (v) {
            return v.id;
        })(workQ.editDocs));
    };
    var editedDocs = function (workQ) {
        return fromFoldable1(map5(function (v) {
            return v.id;
        })(Steps_WorkQueue_DocumentMap.listDocs(workQ.documents)));
    };
    var wrapResult = function (workQ) {
        return {
            step: stepState.step,
            workQueue: workQ,
            pushStateInterface: stepState.pushStateInterface,
            preselectForms: fromFoldable2(union(selectedDocs(workQ))(editedDocs(workQ)))
        };
    };
    return map1(map6(wrapResult))(startFillQueue(stepState.workQueue));
};
export {
    jsScrollIntoView
} from "./foreign.js";
export {
    formFillQueueStep,
    onFirstItem,
    onLastItem,
    setFillState,
    currentFillEntry,
    startFillQueue,
    runFillQueue,
    fillQueue,
    renderFillQueue,
    scrollIntoView,
    renderLoading,
    loadInitFillQueue,
    fillQueueLoadState,
    handleFill,
    autofillLoadWidget,
    saveDocWidget,
    saveDocument,
    loadFillStateWidget,
    loadFillState,
    loadPreviousFillState,
    createDocFormFillState,
    loadExistingFillState,
    loadFormFillLayout,
    initFormSigs,
    formContext,
    JumpIdxF,
    JumpF,
    NextF,
    PrevF,
    CancelF,
    ExportF,
    SaveCancelF,
    SaveF,
    AutofillF,
    StateF,
    WorkQF,
    ActionF,
    ScrollF,
    formFillWidget,
    formResultF,
    workQChangeF,
    previewStateChangeF,
    commentStateChangeF,
    attachmentStateChangeF,
    sigStateChangeF,
    renderHeader,
    renderExportDropdown,
    renderFormFill
};
