// Generated by purs version 0.15.7
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Combinators from "../Data.Argonaut.Decode.Combinators/index.js";
import * as Data_Argonaut_Decode_Decoders from "../Data.Argonaut.Decode.Decoders/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as $$Error from "../Error/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
import * as GraphQL from "../GraphQL/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var getField = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(Data_Argonaut_Decode_Class.decodeJsonJson);
var map = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var getField1 = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeForeignObject(Data_Argonaut_Decode_Class.decodeJsonJson));
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Either.bindEither);
var gDecodeJsonCons = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt));
var lastPageIsSymbol = {
    reflectSymbol: function () {
        return "lastPage";
    }
};
var currentPageIsSymbol = {
    reflectSymbol: function () {
        return "currentPage";
    }
};
var decodeJson = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeJson(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons(Data_Argonaut_Decode_Class.gDecodeJsonNil)(lastPageIsSymbol)()())(currentPageIsSymbol)()())());
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Either.applicativeEither);
var shapeOf = /* #__PURE__ */ GraphQL_Shape.shapeOf(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsNil)(lastPageIsSymbol)(GraphQL_Shape.scalarShape)()())(currentPageIsSymbol)(GraphQL_Shape.scalarShape)()())());
var map1 = /* #__PURE__ */ Data_Functor.map(Effect_Aff.functorAff);
var query = /* #__PURE__ */ GraphQL.query(Data_Argonaut_Encode_Class.encodeJsonJson);
var Unpaged = /* #__PURE__ */ (function () {
    function Unpaged(value0) {
        this.value0 = value0;
    };
    Unpaged.create = function (value0) {
        return new Unpaged(value0);
    };
    return Unpaged;
})();
var Page = /* #__PURE__ */ (function () {
    function Page(value0) {
        this.value0 = value0;
    };
    Page.create = function (value0) {
        return new Page(value0);
    };
    return Page;
})();
var withPageNo = function (page) {
    return function (json) {
        var v = Data_Argonaut_Core.toObject(json);
        if (v instanceof Data_Maybe.Just) {
            return Data_Argonaut_Core.fromObject(Foreign_Object.insert("page")(Data_Argonaut_Core.fromNumber(Data_Int.toNumber(page)))(v.value0));
        };
        if (v instanceof Data_Maybe.Nothing) {
            return json;
        };
        throw new Error("Failed pattern match at API.Pagination (line 179, column 5 - line 181, column 25): " + [ v.constructor.name ]);
    };
};
var unpagedQR = function (dictQueryResult) {
    var queryResultDecode = GraphQL_Query.queryResultDecode(dictQueryResult);
    var queryResultShape = GraphQL_Query.queryResultShape(dictQueryResult);
    return {
        queryResultDecode: function (qrc) {
            return function (json) {
                return bind($$Error["wrapJsonError$prime"](json)(bind(Data_Argonaut_Decode_Decoders.decodeJObject(json))(function (v) {
                    return getField(v)("data");
                })))(function (inner) {
                    return map(Unpaged.create)(queryResultDecode(qrc)(inner));
                });
            };
        },
        queryResultShape: function (v) {
            return [ new GraphQL_Shape.QObject("data", queryResultShape(Type_Proxy["Proxy"].value)) ];
        },
        queryResultArgs: function (v) {
            return [  ];
        }
    };
};
var unpaged = /* #__PURE__ */ Data_Functor.map(GraphQL_Query.queryFunctor)(function (v) {
    return v.value0;
});
var pageRec = function (v) {
    return v.value0;
};
var pageNumber = function (v) {
    return v.value0.number;
};
var pageLast = function (v) {
    return v.value0.lastPage;
};
var pageItems = function (v) {
    return v.value0.items;
};
var pageInfoFields = "\x0a    paginatorInfo {\x0a        currentPage\x0a        lastPage\x0a    }\x0a";
var nameExtractor = function (name) {
    return function (json) {
        return $$Error["wrapJsonError$prime"](json)(bind(Data_Argonaut_Decode_Decoders.decodeJObject(json))(function (obj) {
            return bind(getField1(obj)("data"))(function (v) {
                return getField(v)(name);
            });
        }));
    };
};
var fromTestPage = function (items) {
    return new Page({
        items: items,
        number: 3,
        lastPage: 10,
        query: {
            qstr: "",
            vars: Data_Argonaut_Core.jsonNull,
            extract: function (v) {
                return new Data_Either.Left(new $$Error.StringError("TEST PAGE -- no query"));
            }
        }
    });
};
var decodePage_ = function (dictDecodeJson) {
    var decodeJson1 = Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeArray(dictDecodeJson));
    return function (pq) {
        return function (json) {
            return $$Error["wrapJsonError$prime"](json)(bind(Data_Argonaut_Decode_Decoders.decodeJObject(json))(function (obj) {
                return bind(bindFlipped(decodeJson)(getField(obj)("paginatorInfo")))(function (v) {
                    return bind(bindFlipped(decodeJson1)(getField(obj)("data")))(function (items) {
                        return pure(new Page({
                            items: items,
                            number: v.currentPage,
                            lastPage: v.lastPage,
                            query: pq
                        }));
                    });
                });
            }));
        };
    };
};
var pageQR = function (dictDecodeJson) {
    var decodePage_1 = decodePage_(dictDecodeJson);
    return function (dictHasShape) {
        var shapeOf1 = GraphQL_Shape.shapeOf(dictHasShape);
        return {
            queryResultDecode: function (v) {
                var pq = {
                    extract: nameExtractor(v.query.name),
                    qstr: v.qstr,
                    vars: v.vars
                };
                return decodePage_1(pq);
            },
            queryResultShape: function (v) {
                return [ new GraphQL_Shape.QObject("paginatorInfo", shapeOf(Type_Proxy["Proxy"].value)), new GraphQL_Shape.QObject("data", shapeOf1(Type_Proxy["Proxy"].value)) ];
            },
            queryResultArgs: function (v) {
                return [ new Data_Tuple.Tuple(new GraphQL_Query.QueryVar("page", new GraphQL_Type.Nullable(new GraphQL_Type.QType("Int"))), Data_Argonaut_Core.jsonNull) ];
            }
        };
    };
};
var decodePage = function (dictDecodeJson) {
    var decodePage_1 = decodePage_(dictDecodeJson);
    return function (pq) {
        return function (json) {
            return bind(pq.extract(json))(decodePage_1(pq));
        };
    };
};
var pageQuery$prime = function (dictDecodeJson) {
    var decodePage1 = decodePage(dictDecodeJson);
    return function (dictEncodeJson) {
        var encodeJson = Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson);
        return function (extract) {
            return function (qstr) {
                return function (vars$prime) {
                    var vars = encodeJson(vars$prime);
                    var pq = {
                        extract: extract,
                        qstr: qstr,
                        vars: vars
                    };
                    return map1(function (v) {
                        return bindFlipped(decodePage1(pq))(v);
                    })(query(qstr)(withPageNo(1)(vars)));
                };
            };
        };
    };
};
var pageQuery = function (dictDecodeJson) {
    var pageQuery$prime1 = pageQuery$prime(dictDecodeJson);
    return function (dictEncodeJson) {
        var pageQuery$prime2 = pageQuery$prime1(dictEncodeJson);
        return function (qname) {
            return pageQuery$prime2(nameExtractor(qname));
        };
    };
};
var changePage = function (dictDecodeJson) {
    var decodePage1 = decodePage(dictDecodeJson);
    return function (pageNo) {
        return function (v) {
            return map1(function (v1) {
                return bindFlipped(decodePage1(v.value0.query))(v1);
            })(query(v.value0.query.qstr)(withPageNo(pageNo)(v.value0.query.vars)));
        };
    };
};
export {
    pageRec,
    pageItems,
    pageNumber,
    pageLast,
    pageQuery,
    pageQuery$prime,
    changePage,
    pageInfoFields,
    unpaged,
    fromTestPage,
    pageQR,
    unpagedQR
};
