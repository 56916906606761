// Generated by purs version 0.15.7
var unright = function (dict) {
    return dict.unright;
};
var unleft = function (dict) {
    return dict.unleft;
};
export {
    unleft,
    unright
};
