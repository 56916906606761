// Generated by purs version 0.15.7
import * as Affjax from "../Affjax/index.js";
import * as Affjax_RequestBody from "../Affjax.RequestBody/index.js";
import * as Affjax_ResponseFormat from "../Affjax.ResponseFormat/index.js";
import * as Affjax_Web from "../Affjax.Web/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Combinators from "../Data.Argonaut.Decode.Combinators/index.js";
import * as Data_Argonaut_Decode_Decoders from "../Data.Argonaut.Decode.Decoders/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_HTTP_Method from "../Data.HTTP.Method/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL from "../GraphQL/index.js";
import * as Record from "../Record/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var query = /* #__PURE__ */ GraphQL.query(Data_Argonaut_Encode_Class.encodeJsonJson);
var except = /* #__PURE__ */ Control_Monad_Except_Trans.except(Effect_Aff.applicativeAff);
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonArray(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJson)(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
    reflectSymbol: function () {
        return "variables";
    }
})())({
    reflectSymbol: function () {
        return "query";
    }
})())()));
var bind1 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var bind2 = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var decodeJson = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeJson(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonJson));
var getField = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(Data_Argonaut_Decode_Class.decodeJsonJson);
var getField1 = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeForeignObject(Data_Argonaut_Decode_Class.decodeJsonJson));
var StringQuery = /* #__PURE__ */ (function () {
    function StringQuery(value0) {
        this.value0 = value0;
    };
    StringQuery.create = function (value0) {
        return new StringQuery(value0);
    };
    return StringQuery;
})();
var stringGQL = {
    runGraphQL: function (v) {
        return Control_Monad_Except_Trans.runExceptT(bind(query(v.value0.query.query)(v.value0.query.variables))(function (ret) {
            return except(v.value0.decode(ret));
        }));
    }
};
var singleJoinable = {
    getQuery: function (v) {
        return v.value0.query;
    },
    getDecode: function (v) {
        return v.value0.decode;
    }
};
var showSQ = {
    show: function (v) {
        return "Query: " + v.value0.query.query;
    }
};
var runMultiQuery = function (queries) {
    var content = encodeJson(queries);
    return bind1(Affjax_Web.request({
        method: new Data_Either.Left(Data_HTTP_Method.POST.value),
        url: GraphQL.graphqlEndpoint,
        headers: Affjax.defaultRequest.headers,
        content: new Data_Maybe.Just(new Affjax_RequestBody.Json(content)),
        username: Affjax.defaultRequest.username,
        password: Affjax.defaultRequest.password,
        withCredentials: true,
        responseFormat: Affjax_ResponseFormat.json,
        timeout: Affjax.defaultRequest.timeout
    }))(function (res) {
        return bind1(GraphQL.handleResult(res))(function (resE) {
            return pure(bind2(resE)(function (j) {
                return $$Error.wrapJsonError(decodeJson(j));
            }));
        });
    });
};
var runGraphQL = function (dict) {
    return dict.runGraphQL;
};
var runGraphQL_ = function (dictGraphQL) {
    var $102 = runGraphQL(dictGraphQL);
    return function ($103) {
        return Control_Monad_Except_Trans.ExceptT($102($103));
    };
};
var jqQueries = function (dict) {
    return dict.jqQueries;
};
var jqNil = {
    jqQueries: function (v) {
        return function (v1) {
            return [  ];
        };
    },
    jqDecode: function (v) {
        return function (v1) {
            return function (v2) {
                return function (v3) {
                    return new Data_Either.Right({});
                };
            };
        };
    }
};
var jqDecode = function (dict) {
    return dict.jqDecode;
};
var multiQuery_ = function () {
    return function (dictJoinQueries) {
        var jqQueries1 = jqQueries(dictJoinQueries);
        var jqDecode1 = jqDecode(dictJoinQueries);
        return function (queries) {
            return bind(runMultiQuery(jqQueries1(Type_Proxy["Proxy"].value)(queries)))(function (ret) {
                return except(jqDecode1(Type_Proxy["Proxy"].value)(queries)(0)(ret));
            });
        };
    };
};
var multiQuery_1 = /* #__PURE__ */ multiQuery_();
var multiQuery = function () {
    return function (dictJoinQueries) {
        var multiQuery_2 = multiQuery_1(dictJoinQueries);
        return function (queries) {
            return Control_Monad_Except_Trans.runExceptT(multiQuery_2(queries));
        };
    };
};
var gqlQuery$prime = function (qstr) {
    return function (vars) {
        return function (decode) {
            return new StringQuery({
                query: {
                    query: qstr,
                    variables: vars
                },
                decode: decode
            });
        };
    };
};
var gqlQuery = function (dictEncodeJson) {
    var encodeJson1 = Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson);
    return function (qstr) {
        return function (vars) {
            return function (decode) {
                return gqlQuery$prime(qstr)(encodeJson1(vars))(decode);
            };
        };
    };
};
var getQuery = function (dict) {
    return dict.getQuery;
};
var getDecode = function (dict) {
    return dict.getDecode;
};
var jqCons = function (dictJoinQueries) {
    var jqQueries1 = jqQueries(dictJoinQueries);
    var jqDecode1 = jqDecode(dictJoinQueries);
    return function (dictIsSymbol) {
        var get = Record.get(dictIsSymbol)();
        var $$delete = Record["delete"](dictIsSymbol)()();
        var insert = Record.insert(dictIsSymbol)()();
        return function () {
            return function () {
                return function () {
                    return function () {
                        return function (dictJoinableQuery) {
                            var getQuery1 = getQuery(dictJoinableQuery);
                            var getDecode1 = getDecode(dictJoinableQuery);
                            return {
                                jqQueries: function (v) {
                                    return function (queries) {
                                        var query1 = getQuery1(get(Type_Proxy["Proxy"].value)(queries));
                                        var rest = jqQueries1(Type_Proxy["Proxy"].value)($$delete(Type_Proxy["Proxy"].value)(queries));
                                        return Data_Array.cons(query1)(rest);
                                    };
                                },
                                jqDecode: function (v) {
                                    return function (queries) {
                                        return function (idx) {
                                            return function (arr) {
                                                return bind2(Data_Either.note(new $$Error.StringError("Missing response for multi-query"))(Data_Array.index(arr)(idx)))(function (json) {
                                                    var query1 = get(Type_Proxy["Proxy"].value)(queries);
                                                    return bind2(getDecode1(query1)(json))(function (self) {
                                                        return bind2(jqDecode1(Type_Proxy["Proxy"].value)($$delete(Type_Proxy["Proxy"].value)(queries))(idx + 1 | 0)(arr))(function (rest) {
                                                            return new Data_Either.Right(insert(Type_Proxy["Proxy"].value)(self)(rest));
                                                        });
                                                    });
                                                });
                                            };
                                        };
                                    };
                                }
                            };
                        };
                    };
                };
            };
        };
    };
};
var decodeQuery_ = function (dictDecodeJson) {
    var decodeJson1 = Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson);
    return function (dictEncodeJson) {
        var gqlQuery1 = gqlQuery(dictEncodeJson);
        return function (qstr) {
            return function (vars) {
                return function (decode$prime) {
                    var decode = function (json) {
                        return bind2(decode$prime(json))(function (json$prime) {
                            return $$Error["wrapJsonError$prime"](json$prime)(decodeJson1(json$prime));
                        });
                    };
                    return gqlQuery1(qstr)(vars)(decode);
                };
            };
        };
    };
};
var decodeQueryData = function (dictDecodeJson) {
    var decodeQuery_1 = decodeQuery_(dictDecodeJson);
    return function (dictEncodeJson) {
        var decodeQuery_2 = decodeQuery_1(dictEncodeJson);
        return function (qstr) {
            return function (vars) {
                return decodeQuery_2(qstr)(vars)(function (j) {
                    return $$Error["wrapJsonError$prime"](j)(bind2(Data_Argonaut_Decode_Decoders.decodeJObject(j))(function (v) {
                        return getField(v)("data");
                    }));
                });
            };
        };
    };
};
var decodeQuery = function (dictDecodeJson) {
    var decodeQuery_1 = decodeQuery_(dictDecodeJson);
    return function (dictEncodeJson) {
        var decodeQuery_2 = decodeQuery_1(dictEncodeJson);
        return function (qname) {
            return function (qstr) {
                return function (vars) {
                    return decodeQuery_2(qstr)(vars)(function (j) {
                        return $$Error["wrapJsonError$prime"](j)(bind2(bind2(Data_Argonaut_Decode_Decoders.decodeJObject(j))(function (v) {
                            return getField1(v)("data");
                        }))(function (v) {
                            return getField(v)(qname);
                        }));
                    });
                };
            };
        };
    };
};
export {
    getDecode,
    getQuery,
    jqDecode,
    jqQueries,
    runGraphQL,
    runGraphQL_,
    StringQuery,
    gqlQuery$prime,
    gqlQuery,
    decodeQuery,
    decodeQueryData,
    decodeQuery_,
    multiQuery,
    multiQuery_,
    runMultiQuery,
    showSQ,
    stringGQL,
    singleJoinable,
    jqCons,
    jqNil
};
