// Generated by purs version 0.15.7
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as Widgets_BetterSelect from "../Widgets.BetterSelect/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Common from "../Widgets.Form.Common/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var find = /* #__PURE__ */ Data_Foldable.find(Data_Foldable.foldableArray);
var eq = /* #__PURE__ */ Data_Eq.eq(Data_Argonaut_Core.eqJson);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var div = /* #__PURE__ */ Concur_React_DOM.div(/* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray))(Concur_Core_Types.widgetShiftMap);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var SelectWidget = function (x) {
    return x;
};
var showSelect = {
    show: function (v) {
        return "Select " + v.id;
    }
};
var setSelected$prime = function (entry) {
    return function (v) {
        var opt = bind(entry)(function (json) {
            return bind(find(function (o) {
                return eq(o.value)(json);
            })(v.options))(function (option) {
                return pure({
                    label: option.label,
                    value: json
                });
            });
        });
        return {
            id: v.id,
            label: v.label,
            cls: v.cls,
            options: v.options,
            placeholder: v.placeholder,
            sel: Widgets_BetterSelect.setSelected(v.sel)(opt)
        };
    };
};
var selectFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return true;
    },
    asField: function (v) {
        return new Data_Maybe.Just(new GraphQL_Shape.QScalar(v.id));
    },
    toWidget: function (fc) {
        return function (v) {
            return map(map1(map1(Widgets_Form_Class.formWidgetState)(SelectWidget))(function (v1) {
                return {
                    sel: v1,
                    cls: v.cls,
                    id: v.id,
                    label: v.label,
                    options: v.options,
                    placeholder: v.placeholder
                };
            }))(div([ Concur_React_Props.className(Widgets_Form_Class.fieldClass(v)), Concur_React_Props.key(v.id) ])([ Widgets_Form_Common.widgetLabelM(fc)(v)([ Concur_React_Props.className("block w-full") ]), Widgets_BetterSelect.selectWidget({
                id: Widgets_Form_Class.fieldId(fc)(v.id),
                search: v.sel.search,
                searchAff: v.sel.searchAff,
                groupFunc: v.sel.groupFunc,
                config: v.sel.config,
                selected: v.sel.selected,
                highlighted: v.sel.highlighted,
                expanded: v.sel.expanded,
                hovering: v.sel.hovering,
                loadNext: v.sel.loadNext,
                options: v.sel.options,
                state: v.sel.state
            }) ]));
        };
    },
    getData: function (v) {
        return function (v1) {
            return Data_Maybe.fromMaybe(Data_Argonaut_Core.jsonNull)(map2(function (v2) {
                return v2.value;
            })(Widgets_BetterSelect.selectedItem(v1.sel)));
        };
    },
    setData: function (v) {
        return function (v1) {
            return function (v2) {
                if (Data_Argonaut_Core.isNull(v2)) {
                    return new Data_Either.Right(setSelected$prime(Data_Maybe.Nothing.value)(v1));
                };
                return new Data_Either.Right(setSelected$prime(new Data_Maybe.Just(v2))(v1));
            };
        };
    }
};
var initSelectWidget = function (v) {
    return setSelected$prime(v.value)({
        id: v.id,
        label: v.label,
        options: v.options,
        cls: v.cls,
        placeholder: v.placeholder,
        sel: (function () {
            var v1 = Widgets_BetterSelect.initSimpleSelect(v.id)(v.options);
            return {
                id: v1.id,
                search: v1.search,
                searchAff: v1.searchAff,
                groupFunc: v1.groupFunc,
                config: {
                    placeholder: v.placeholder,
                    deselectLabel: v1.config.deselectLabel,
                    allowDeselect: v1.config.allowDeselect
                },
                selected: v1.selected,
                highlighted: v1.highlighted,
                expanded: v1.expanded,
                hovering: v1.hovering,
                loadNext: v1.loadNext,
                options: v1.options,
                state: v1.state
            };
        })()
    });
};
export {
    SelectWidget,
    initSelectWidget,
    setSelected$prime,
    showSelect,
    selectFW
};
