// Generated by purs version 0.15.7
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Combinators from "../Data.Argonaut.Decode.Combinators/index.js";
import * as Data_Argonaut_Decode_Decoders from "../Data.Argonaut.Decode.Decoders/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Symbol from "../Data.Symbol/index.js";
import * as Data_Variant from "../Data.Variant/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var $$null = /* #__PURE__ */ Data_Foldable["null"](Data_Foldable.foldableArray);
var prj = /* #__PURE__ */ Data_Variant.prj();
var map = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var inj = /* #__PURE__ */ Data_Variant.inj();
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var getField = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(Data_Argonaut_Decode_Class.decodeJsonString);
var intercalate = /* #__PURE__ */ Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var Union = /* #__PURE__ */ (function () {
    function Union(value0) {
        this.value0 = value0;
    };
    Union.create = function (value0) {
        return new Union(value0);
    };
    return Union;
})();
var UNoMatch = /* #__PURE__ */ (function () {
    function UNoMatch() {

    };
    UNoMatch.value = new UNoMatch();
    return UNoMatch;
})();
var QueryArg = /* #__PURE__ */ (function () {
    function QueryArg(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    QueryArg.create = function (value0) {
        return function (value1) {
            return new QueryArg(value0, value1);
        };
    };
    return QueryArg;
})();
var QScalar = /* #__PURE__ */ (function () {
    function QScalar(value0) {
        this.value0 = value0;
    };
    QScalar.create = function (value0) {
        return new QScalar(value0);
    };
    return QScalar;
})();
var QObject = /* #__PURE__ */ (function () {
    function QObject(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    QObject.create = function (value0) {
        return function (value1) {
            return new QObject(value0, value1);
        };
    };
    return QObject;
})();
var QCall = /* #__PURE__ */ (function () {
    function QCall(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    QCall.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new QCall(value0, value1, value2);
            };
        };
    };
    return QCall;
})();
var QAlias = /* #__PURE__ */ (function () {
    function QAlias(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    QAlias.create = function (value0) {
        return function (value1) {
            return new QAlias(value0, value1);
        };
    };
    return QAlias;
})();
var QUnion = /* #__PURE__ */ (function () {
    function QUnion(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    QUnion.create = function (value0) {
        return function (value1) {
            return new QUnion(value0, value1);
        };
    };
    return QUnion;
})();
var varhsNil = {
    varShapeOf: function (v) {
        return function (v1) {
            return [  ];
        };
    }
};
var varShapeOf = function (dict) {
    return dict.varShapeOf;
};
var unionShape = function (dictVarHasShape) {
    var varShapeOf1 = varShapeOf(dictVarHasShape);
    return function () {
        return {
            shapeOf: function (v) {
                var typename = new QScalar("__typename");
                return Data_Array.cons(typename)(varShapeOf1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value));
            }
        };
    };
};
var unDecNil = {
    decodeUnion: function (v) {
        return function (v1) {
            return function (v2) {
                return Data_Maybe.Nothing.value;
            };
        };
    }
};
var shapeOf = function (dict) {
    return dict.shapeOf;
};
var varhsCons = function (dictVarHasShape) {
    var varShapeOf1 = varShapeOf(dictVarHasShape);
    return function (dictIsSymbol) {
        var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
        return function (dictHasShape) {
            var shapeOf1 = shapeOf(dictHasShape);
            return function () {
                return function () {
                    return {
                        varShapeOf: function (v) {
                            return function (v1) {
                                var shape = shapeOf1(Type_Proxy["Proxy"].value);
                                var name = reflectSymbol(Type_Proxy["Proxy"].value);
                                var thisVar = new QUnion(name, shape);
                                return Data_Array.cons(thisVar)(varShapeOf1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value));
                            };
                        }
                    };
                };
            };
        };
    };
};
var scalarShape = {
    shapeOf: function (v) {
        return [  ];
    }
};
var rlhsNil = {
    rlShapeOf: function (v) {
        return function (v1) {
            return [  ];
        };
    }
};
var rlShapeOf = function (dict) {
    return dict.rlShapeOf;
};
var rlhsCons = function (dictRLHasShape) {
    var rlShapeOf1 = rlShapeOf(dictRLHasShape);
    return function (dictIsSymbol) {
        var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
        return function (dictHasShape) {
            var shapeOf1 = shapeOf(dictHasShape);
            return function () {
                return function () {
                    return {
                        rlShapeOf: function (v) {
                            return function (v1) {
                                var shape = shapeOf1(Type_Proxy["Proxy"].value);
                                var name = reflectSymbol(Type_Proxy["Proxy"].value);
                                var thisField = (function () {
                                    var $75 = $$null(shape);
                                    if ($75) {
                                        return new QScalar(name);
                                    };
                                    return new QObject(name, shape);
                                })();
                                return Data_Array.cons(thisField)(rlShapeOf1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value));
                            };
                        }
                    };
                };
            };
        };
    };
};
var recShape = function (dictRLHasShape) {
    var rlShapeOf1 = rlShapeOf(dictRLHasShape);
    return function () {
        return {
            shapeOf: function (v) {
                return rlShapeOf1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value);
            }
        };
    };
};
var nullishShape = function (dictHasShape) {
    var shapeOf1 = shapeOf(dictHasShape);
    return {
        shapeOf: function (v) {
            return shapeOf1(Type_Proxy["Proxy"].value);
        }
    };
};
var maybeShape = function (dictHasShape) {
    var shapeOf1 = shapeOf(dictHasShape);
    return {
        shapeOf: function (v) {
            return shapeOf1(Type_Proxy["Proxy"].value);
        }
    };
};
var fromUnionTag = function (dictIsSymbol) {
    var prj1 = prj(dictIsSymbol)(Data_Maybe.alternativeMaybe);
    return function () {
        return function (v) {
            return function (v1) {
                if (v1 instanceof Union) {
                    return prj1(v)(v1.value0);
                };
                if (v1 instanceof UNoMatch) {
                    return Data_Maybe.Nothing.value;
                };
                throw new Error("Failed pattern match at GraphQL.Shape (line 124, column 1 - line 124, column 112): " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
    };
};
var fromUnion = function (v) {
    if (v instanceof Union) {
        return new Data_Maybe.Just(v.value0);
    };
    if (v instanceof UNoMatch) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at GraphQL.Shape (line 119, column 1 - line 119, column 52): " + [ v.constructor.name ]);
};
var decodeUnion = function (dict) {
    return dict.decodeUnion;
};
var unDecCons = function (dictUnionDecode) {
    var decodeUnion1 = decodeUnion(dictUnionDecode);
    return function (dictIsSymbol) {
        var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
        var inj1 = inj(dictIsSymbol);
        return function (dictDecodeJson) {
            var decodeJson = Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson);
            return function () {
                return function () {
                    return {
                        decodeUnion: function (v) {
                            return function (v1) {
                                return function (v2) {
                                    if (v2 === reflectSymbol(Type_Proxy["Proxy"].value)) {
                                        return new Data_Maybe.Just(function (j) {
                                            return map(inj1(Type_Proxy["Proxy"].value))(decodeJson(j));
                                        });
                                    };
                                    return decodeUnion1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value)(v2);
                                };
                            };
                        }
                    };
                };
            };
        };
    };
};
var unionDecode = function (dictUnionDecode) {
    var decodeUnion1 = decodeUnion(dictUnionDecode);
    return function () {
        return {
            decodeJson: function (json) {
                return bind(bind(Data_Argonaut_Decode_Decoders.decodeJObject(json))(function (v) {
                    return getField(v)("__typename");
                }))(function (v) {
                    var decode = decodeUnion1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value)(v);
                    if (decode instanceof Data_Maybe.Just) {
                        return map(Union.create)(decode.value0(json));
                    };
                    if (decode instanceof Data_Maybe.Nothing) {
                        return new Data_Either.Right(UNoMatch.value);
                    };
                    throw new Error("Failed pattern match at GraphQL.Shape (line 168, column 8 - line 170, column 37): " + [ decode.constructor.name ]);
                });
            }
        };
    };
};
var arrayShape = function (dictHasShape) {
    var shapeOf1 = shapeOf(dictHasShape);
    return {
        shapeOf: function (v) {
            return shapeOf1(Type_Proxy["Proxy"].value);
        }
    };
};
var argToString = function (v) {
    return v.value0 + (": $" + v.value1);
};
var argsToString = function (args) {
    return intercalate(", ")(map1(argToString)(args));
};
var shapeToStringInner = function (fields) {
    var fieldStrs = map1(fieldToString)(fields);
    return intercalate(", ")(fieldStrs);
};
var shapeToString = function (fields) {
    return "{ " + (shapeToStringInner(fields) + " }");
};
var fieldToString = function (v) {
    if (v instanceof QScalar) {
        return v.value0;
    };
    if (v instanceof QObject) {
        return v.value0 + (" " + shapeToString(v.value1));
    };
    if (v instanceof QCall && (v.value1.length === 0 && v.value2.length === 0)) {
        return v.value0;
    };
    if (v instanceof QCall && v.value1.length === 0) {
        return v.value0 + (" " + shapeToString(v.value2));
    };
    if (v instanceof QCall && v.value2.length === 0) {
        return v.value0 + ("(" + (argsToString(v.value1) + ")"));
    };
    if (v instanceof QCall) {
        return v.value0 + ("(" + (argsToString(v.value1) + (") " + shapeToString(v.value2))));
    };
    if (v instanceof QUnion) {
        return "... on " + (v.value0 + (" " + shapeToString(v.value1)));
    };
    if (v instanceof QAlias && v.value1 instanceof QAlias) {
        return fieldToString(v.value1);
    };
    if (v instanceof QAlias) {
        return v.value0 + (": " + fieldToString(v.value1));
    };
    throw new Error("Failed pattern match at GraphQL.Shape (line 46, column 1 - line 46, column 38): " + [ v.constructor.name ]);
};
var showQF = {
    show: fieldToString
};
export {
    decodeUnion,
    rlShapeOf,
    shapeOf,
    varShapeOf,
    QueryArg,
    QScalar,
    QObject,
    QCall,
    QAlias,
    QUnion,
    shapeToString,
    shapeToStringInner,
    fieldToString,
    argsToString,
    argToString,
    Union,
    UNoMatch,
    fromUnion,
    fromUnionTag,
    showQF,
    arrayShape,
    maybeShape,
    nullishShape,
    recShape,
    unionShape,
    scalarShape,
    rlhsCons,
    rlhsNil,
    varhsCons,
    varhsNil,
    unDecCons,
    unDecNil,
    unionDecode
};
