// Generated by purs version 0.15.7
import * as API_Account from "../API.Account/index.js";
import * as API_Autofill from "../API.Autofill/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var FWAutoFill = /* #__PURE__ */ (function () {
    function FWAutoFill(value0) {
        this.value0 = value0;
    };
    FWAutoFill.create = function (value0) {
        return new FWAutoFill(value0);
    };
    return FWAutoFill;
})();
var FormWidgetResult = /* #__PURE__ */ (function () {
    function FormWidgetResult(value0) {
        this.value0 = value0;
    };
    FormWidgetResult.create = function (value0) {
        return new FormWidgetResult(value0);
    };
    return FormWidgetResult;
})();
var toWidget = function (dict) {
    return dict.toWidget;
};
var setFromJson = function (dictFormWidget) {
    return function (dictDecodeJson) {
        var decodeJson = Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson);
        return function (v) {
            return function (inner) {
                return function (json) {
                    return bind($$Error["wrapJsonError$prime"](json)(decodeJson(json)))(function (val) {
                        return inner(val);
                    });
                };
            };
        };
    };
};
var setData = function (dict) {
    return dict.setData;
};
var noSetData = function (v) {
    return function (s) {
        return function (v1) {
            return new Data_Either.Right(s);
        };
    };
};
var noGetData = function (v) {
    return function (v1) {
        return Data_Argonaut_Core.jsonNull;
    };
};
var isLeaf = function (dict) {
    return dict.isLeaf;
};
var initFormContext = function (account) {
    return function (doc) {
        return {
            parentIds: "",
            curIndex: 0,
            doc: new Data_Maybe.Just(doc),
            isAutofilling: false,
            autofillArgs: API_Autofill.initAutofillArgs(doc.formId)(doc.student.id),
            account: account
        };
    };
};
var getId = function (dict) {
    return dict.getId;
};
var getData = function (dict) {
    return dict.getData;
};
var fwrFunctor = {
    map: function (f) {
        return function (v) {
            return new FormWidgetResult({
                state: f(v.value0.state),
                action: v.value0.action
            });
        };
    }
};
var formWidgetState = function (state) {
    return new FormWidgetResult({
        state: state,
        action: Data_Maybe.Nothing.value
    });
};
var formWidgetAction = function (state) {
    return function (act) {
        return new FormWidgetResult({
            state: state,
            action: new Data_Maybe.Just(act)
        });
    };
};
var fieldId = function (v) {
    return function (v1) {
        if (v.parentIds === "") {
            return v1;
        };
        return v.parentIds + ("." + v1);
    };
};
var fieldClass = function (v) {
    if (v.cls === "") {
        return "flex flex-wrap col-span-12";
    };
    var $30 = Data_String_CodeUnits.contains("col-span-")(v.cls);
    if ($30) {
        return "flex flex-wrap " + v.cls;
    };
    return "flex flex-wrap col-span-12 " + v.cls;
};
var blankFormContext = /* #__PURE__ */ (function () {
    return {
        parentIds: "",
        curIndex: 0,
        doc: Data_Maybe.Nothing.value,
        isAutofilling: false,
        autofillArgs: API_Autofill.initAutofillArgs(GraphQL_Type.dummyId)(GraphQL_Type.dummyId),
        account: {
            type: API_Account.TeacherAccount.value,
            student: Data_Maybe.Nothing.value
        }
    };
})();
var asField = function (dict) {
    return dict.asField;
};
var addParentId = function (fc) {
    return function (id) {
        return {
            parentIds: fieldId(fc)(id),
            curIndex: fc.curIndex,
            isAutofilling: fc.isAutofilling,
            autofillArgs: fc.autofillArgs,
            account: fc.account,
            doc: fc.doc
        };
    };
};
export {
    asField,
    getData,
    getId,
    isLeaf,
    setData,
    toWidget,
    initFormContext,
    blankFormContext,
    fieldId,
    fieldClass,
    addParentId,
    formWidgetState,
    formWidgetAction,
    noGetData,
    noSetData,
    FormWidgetResult,
    FWAutoFill,
    setFromJson,
    fwrFunctor
};
