// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Dynamic from "../Widgets.Form.Dynamic/index.js";
import * as Widgets_Form_WidgetMap from "../Widgets.Form.WidgetMap/index.js";
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var summary = /* #__PURE__ */ Concur_React_DOM.summary(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var details = /* #__PURE__ */ Concur_React_DOM.details(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var legend = /* #__PURE__ */ Concur_React_DOM.legend(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var pure = /* #__PURE__ */ Control_Applicative.pure(Control_Applicative.applicativeArray);
var fieldset = /* #__PURE__ */ Concur_React_DOM.fieldset(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var asField = /* #__PURE__ */ Widgets_Form_Class.asField(Widgets_Form_Dynamic.dynFormWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map2 = /* #__PURE__ */ Data_Functor.map(Widgets_Form_Class.fwrFunctor);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map4 = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var show = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "cls";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "collapsible";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "expanded";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "fields";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "label";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "labelClass";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "section";
    }
})(Data_Show.showBoolean))(Data_Show.showString))(/* #__PURE__ */ Data_Maybe.showMaybe(Data_Show.showString)))(Data_Show.showString))(/* #__PURE__ */ Data_Show.showArray(Widgets_Form_Dynamic.dynWidgetShow)))(Data_Show.showBoolean))(Data_Show.showBoolean))(Data_Show.showString)));
var SubForm = function (x) {
    return x;
};
var subformClasses = function (v) {
    return "widget-sub-form " + (function () {
        var $78 = Data_String_CodeUnits.contains("col-span-")(v.cls);
        if ($78) {
            return v.cls;
        };
        var $79 = v.cls === "";
        if ($79) {
            return "col-span-12";
        };
        return "col-span-12 " + (v.cls + (function () {
            if (v.section) {
                return " section";
            };
            return "";
        })());
    })();
};
var subFormWrapper = function (v) {
    return function (v1) {
        return function (v2) {
            if (v1.collapsible) {
                var maybeLabel = Data_Maybe.maybe([  ])(function (l) {
                    return [ summary([ Concur_React_Props.className(v1.labelClass) ])([ text(l) ]) ];
                })(v1.label);
                return details([ Concur_React_Props.open(v1.expanded), Concur_React_Props.className(subformClasses(v1)), Concur_React_Props.key(Widgets_Form_Class.fieldId(v)(v1.id)), Concur_React_Props["_id"](Widgets_Form_Class.fieldId(v)(v1.id)) ])(append1(maybeLabel)([ v2 ]));
            };
            if (!v1.collapsible) {
                var legend1 = function (l) {
                    return legend([ Concur_React_Props["_id"](Widgets_Form_Class.fieldId(v)(v1.id)), Concur_React_Props.className(v1.labelClass) ])([ text(l) ]);
                };
                var emptyAnchor = a([ Concur_React_Props["_id"](Widgets_Form_Class.fieldId(v)(v1.id)) ])([  ]);
                var maybeLabel = pure(Data_Maybe.maybe(emptyAnchor)(legend1)(v1.label));
                return fieldset([ Concur_React_Props.className(subformClasses(v1)), Concur_React_Props.key(Widgets_Form_Class.fieldId(v)(v1.id)) ])(append1(maybeLabel)([ v2 ]));
            };
            throw new Error("Failed pattern match at Widgets.Form.SubForm (line 50, column 1 - line 50, column 89): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var subFormShape = function (v) {
    return Data_Array.catMaybes(map(asField)(v.fields));
};
var subFormFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return false;
    },
    asField: function (v) {
        return new Data_Maybe.Just(new GraphQL_Shape.QObject(v.id, Data_Array.catMaybes(map(asField)(v.fields))));
    },
    toWidget: function (fc) {
        return function (v) {
            var subfc = Widgets_Form_Class.addParentId(fc)(v.id);
            var subWidgets = map1(function (f) {
                return map2(map3(SubForm)(function (v1) {
                    return {
                        fields: v1,
                        cls: v.cls,
                        collapsible: v.collapsible,
                        expanded: v.expanded,
                        id: v.id,
                        label: v.label,
                        labelClass: v.labelClass,
                        section: v.section
                    };
                }))(f);
            })(div([ Concur_React_Props.className("fields-wrapper") ])(Widgets_Form_WidgetMap.renderWidgets(subfc)(v.fields)));
            return subFormWrapper(fc)(v)(subWidgets);
        };
    },
    getData: function (fc) {
        return function (v) {
            return Widgets_Form_WidgetMap.getWMData(fc)(v.fields);
        };
    },
    setData: function (fc) {
        return function (v) {
            return function ($$new) {
                return map4(map3(SubForm)(function (v1) {
                    return {
                        fields: v1,
                        cls: v.cls,
                        collapsible: v.collapsible,
                        expanded: v.expanded,
                        id: v.id,
                        label: v.label,
                        labelClass: v.labelClass,
                        section: v.section
                    };
                }))(Widgets_Form_WidgetMap.setWMData(fc)(v.fields)($$new));
            };
        };
    }
};
var showSubForm = {
    show: function (v) {
        return show(v);
    }
};
export {
    SubForm,
    subFormWrapper,
    subformClasses,
    subFormShape,
    showSubForm,
    subFormFW
};
