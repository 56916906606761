// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Patterns from "../Concur.Core.Patterns/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as Internal_Util from "../Internal.Util/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var map1 = /* #__PURE__ */ Data_Functor.map(GraphQL_Query.queryFunctor);
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Data_Eq.eqInt));
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Data_Eq.eqString));
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var length = /* #__PURE__ */ Data_Foldable.length(Data_Foldable.foldableArray)(Data_Semiring.semiringInt);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var elem = /* #__PURE__ */ Data_Array.elem(Data_Eq.eqInt);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var map3 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var map4 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var sortWith = /* #__PURE__ */ Data_Array.sortWith(Data_Ord.ordInt);
var bind = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var runGraphQL_ = /* #__PURE__ */ GraphQL_Join.runGraphQL_(GraphQL_Query.qgql);
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var bind1 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var bind2 = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var map5 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var pure2 = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Maybe.bindMaybe);
var sum = /* #__PURE__ */ Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringInt);
var voidRight1 = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Types.functorWidget);
var bindFlipped1 = /* #__PURE__ */ Control_Bind.bindFlipped(Concur_Core_Types.bindWidget);
var loopState = /* #__PURE__ */ Concur_Core_Patterns.loopState(Concur_Core_Types.widgetMonad);
var SelLoading = /* #__PURE__ */ (function () {
    function SelLoading() {

    };
    SelLoading.value = new SelLoading();
    return SelLoading;
})();
var SelError = /* #__PURE__ */ (function () {
    function SelError(value0) {
        this.value0 = value0;
    };
    SelError.create = function (value0) {
        return new SelError(value0);
    };
    return SelError;
})();
var SelOK = /* #__PURE__ */ (function () {
    function SelOK() {

    };
    SelOK.value = new SelOK();
    return SelOK;
})();
var Up = /* #__PURE__ */ (function () {
    function Up() {

    };
    Up.value = new Up();
    return Up;
})();
var Down = /* #__PURE__ */ (function () {
    function Down() {

    };
    Down.value = new Down();
    return Down;
})();
var Absolute = /* #__PURE__ */ (function () {
    function Absolute(value0) {
        this.value0 = value0;
    };
    Absolute.create = function (value0) {
        return new Absolute(value0);
    };
    return Absolute;
})();
var Last = /* #__PURE__ */ (function () {
    function Last() {

    };
    Last.value = new Last();
    return Last;
})();
var SelectOpt = /* #__PURE__ */ (function () {
    function SelectOpt(value0) {
        this.value0 = value0;
    };
    SelectOpt.create = function (value0) {
        return new SelectOpt(value0);
    };
    return SelectOpt;
})();
var Deselect = /* #__PURE__ */ (function () {
    function Deselect() {

    };
    Deselect.value = new Deselect();
    return Deselect;
})();
var MoveHighlight = /* #__PURE__ */ (function () {
    function MoveHighlight(value0) {
        this.value0 = value0;
    };
    MoveHighlight.create = function (value0) {
        return new MoveHighlight(value0);
    };
    return MoveHighlight;
})();
var EnterPressed = /* #__PURE__ */ (function () {
    function EnterPressed() {

    };
    EnterPressed.value = new EnterPressed();
    return EnterPressed;
})();
var ChangeSearch = /* #__PURE__ */ (function () {
    function ChangeSearch(value0) {
        this.value0 = value0;
    };
    ChangeSearch.create = function (value0) {
        return new ChangeSearch(value0);
    };
    return ChangeSearch;
})();
var Expanded = /* #__PURE__ */ (function () {
    function Expanded(value0) {
        this.value0 = value0;
    };
    Expanded.create = function (value0) {
        return new Expanded(value0);
    };
    return Expanded;
})();
var OnBlur = /* #__PURE__ */ (function () {
    function OnBlur() {

    };
    OnBlur.value = new OnBlur();
    return OnBlur;
})();
var Hovering = /* #__PURE__ */ (function () {
    function Hovering(value0) {
        this.value0 = value0;
    };
    Hovering.create = function (value0) {
        return new Hovering(value0);
    };
    return Hovering;
})();
var FrontendSearch = /* #__PURE__ */ (function () {
    function FrontendSearch(value0) {
        this.value0 = value0;
    };
    FrontendSearch.create = function (value0) {
        return new FrontendSearch(value0);
    };
    return FrontendSearch;
})();
var BackendSearch = /* #__PURE__ */ (function () {
    function BackendSearch(value0) {
        this.value0 = value0;
    };
    BackendSearch.create = function (value0) {
        return new BackendSearch(value0);
    };
    return BackendSearch;
})();
var StaticSearch = /* #__PURE__ */ (function () {
    function StaticSearch(value0) {
        this.value0 = value0;
    };
    StaticSearch.create = function (value0) {
        return new StaticSearch(value0);
    };
    return StaticSearch;
})();
var SelAct = /* #__PURE__ */ (function () {
    function SelAct(value0) {
        this.value0 = value0;
    };
    SelAct.create = function (value0) {
        return new SelAct(value0);
    };
    return SelAct;
})();
var SearchResult = /* #__PURE__ */ (function () {
    function SearchResult(value0) {
        this.value0 = value0;
    };
    SearchResult.create = function (value0) {
        return new SearchResult(value0);
    };
    return SearchResult;
})();
var wrapSelectOption$prime = function (label) {
    return map(function (opt) {
        return {
            label: label(opt),
            value: opt
        };
    });
};
var wrapSelectOption = function (label) {
    return function (query) {
        return map1(wrapSelectOption$prime(label))(query);
    };
};
var stringMatch = function (v) {
    return function (v1) {
        if (v === "") {
            return 1;
        };
        if (Data_String_CodeUnits.contains(Data_String_Common.toLower(v))(Data_String_Common.toLower(v1))) {
            return 1;
        };
        return 0;
    };
};
var staticFrontendSearch = function (key) {
    return function (items) {
        return new StaticSearch({
            items: items,
            rank: function (s) {
                var $203 = stringMatch(s);
                return function ($204) {
                    return $203(key($204));
                };
            }
        });
    };
};
var simpleFrontendSearch = function (key) {
    return function (query) {
        return new FrontendSearch({
            query: query,
            rank: function (s) {
                var $205 = stringMatch(s);
                return function ($206) {
                    return $205(key($206));
                };
            }
        });
    };
};
var setSelected = function (state) {
    return function (opt) {
        return {
            id: state.id,
            search: Data_Maybe.maybe("")(function (v1) {
                return v1.label;
            })(opt),
            searchAff: state.searchAff,
            groupFunc: state.groupFunc,
            config: state.config,
            selected: opt,
            highlighted: state.highlighted,
            expanded: state.expanded,
            hovering: state.hovering,
            loadNext: state.loadNext,
            options: state.options,
            state: state.state
        };
    };
};
var setSearchAff = function (state) {
    return function (searchAff) {
        return {
            id: state.id,
            search: state.search,
            searchAff: searchAff,
            groupFunc: state.groupFunc,
            config: state.config,
            selected: state.selected,
            highlighted: state.highlighted,
            expanded: state.expanded,
            hovering: state.hovering,
            loadNext: true,
            options: [  ],
            state: state.state
        };
    };
};
var selectedItem = function (v) {
    return v.selected;
};
var renderList = function (v) {
    if (v.state instanceof SelOK) {
        var listItem = function (idx) {
            return function (opt) {
                var activeClass = (function () {
                    var $124 = eq(new Data_Maybe.Just(idx))(v.highlighted) || eq1(new Data_Maybe.Just(opt.label))(map2(function (v1) {
                        return v1.label;
                    })(v.selected));
                    if ($124) {
                        return [ new Data_Maybe.Just("active") ];
                    };
                    return [  ];
                })();
                return a([ Concur_React_Props.classList(append([ new Data_Maybe.Just("widget-select-option") ])(activeClass)), Concur_React_Props.href("javascript:void(0);"), voidRight(new SelectOpt(opt))(Concur_React_Props.onClick), voidRight(new Hovering(true))(Concur_React_Props.onMouseOver), voidRight(new Hovering(false))(Concur_React_Props.onMouseOut) ])([ text(opt.label) ]);
            };
        };
        var listGroup = function (v1) {
            return function (v2) {
                if (v2.label === "") {
                    return Data_Array.mapWithIndex(listItem)(v2.opts);
                };
                if (length(v.options) < 2) {
                    return Data_Array.mapWithIndex(listItem)(v2.opts);
                };
                return [ div([ Concur_React_Props.className("widget-select-group") ])(append([ div([ Concur_React_Props.className("widget-select-group-header") ])([ text(v2.label) ]) ])(Data_Array.mapWithIndex(listItem)(v2.opts))) ];
            };
        };
        var renderGroups = function (v1) {
            return function (v2) {
                return function (v3) {
                    if (v3.length === 0) {
                        return [  ];
                    };
                    var v4 = Data_Array.index(v3)(v1);
                    if (v4 instanceof Data_Maybe.Just) {
                        return append(listGroup(v2)(v4.value0))(renderGroups(v1 + 1 | 0)(v2 + length(v4.value0.opts) | 0)(v3));
                    };
                    if (v4 instanceof Data_Maybe.Nothing) {
                        return [  ];
                    };
                    throw new Error("Failed pattern match at Widgets.BetterSelect (line 477, column 35 - line 479, column 22): " + [ v4.constructor.name ]);
                };
            };
        };
        var deselectLabel = Data_Maybe.fromMaybe(Data_Maybe.fromMaybe(Translate.lookupTranslation("select-choose"))(v.config.placeholder))(v.config.deselectLabel);
        var deselectOption = (function () {
            var activeClass = (function () {
                var $132 = eq(v.highlighted)(new Data_Maybe.Just(-1 | 0));
                if ($132) {
                    return [ new Data_Maybe.Just("active") ];
                };
                return [  ];
            })();
            return a([ Concur_React_Props.classList(append([ new Data_Maybe.Just("widget-select-option") ])(activeClass)), Concur_React_Props.href("javascript:void(0);"), voidRight(Deselect.value)(Concur_React_Props.onClick), voidRight(new Hovering(true))(Concur_React_Props.onMouseOver), voidRight(new Hovering(false))(Concur_React_Props.onMouseOut) ])([ text(deselectLabel) ]);
        })();
        return div([ Concur_React_Props.className("widget-select-dropdown") ])(append((function () {
            if (v.config.allowDeselect) {
                return [ deselectOption ];
            };
            return [  ];
        })())(Data_Array.concat(Data_Array.mapWithIndex(listGroup)(v.options))));
    };
    if (v.state instanceof SelLoading) {
        return div([ Concur_React_Props.className("widget-select-dropdown widget-select-loading") ])([ Widgets_Loading.loadingWidget ]);
    };
    if (v.state instanceof SelError) {
        return div([ Concur_React_Props.className("widget-select-dropdown widget-select-error") ])([ text($$Error.userError(v.state.value0)) ]);
    };
    throw new Error("Failed pattern match at Widgets.BetterSelect (line 457, column 1 - line 457, column 65): " + [ v.constructor.name ]);
};
var renderInput = function (selState) {
    return function (search) {
        var keyList = [ 38, 40, 13 ];
        var handleKey = function (v) {
            if (v === 38) {
                return new MoveHighlight(Up.value);
            };
            if (v === 40) {
                return new MoveHighlight(Down.value);
            };
            if (v === 13) {
                return EnterPressed.value;
            };
            return new MoveHighlight(Up.value);
        };
        var eventKey = function (e) {
            return e.which;
        };
        var classes = [ new Data_Maybe.Just("widget-select-input"), new Data_Maybe.Just((function () {
            var $140 = Data_Maybe.isJust(selState.selected);
            if ($140) {
                return "selected";
            };
            return "not-selected";
        })()) ];
        var canHandleKey = function (event) {
            return elem(event.which)(keyList);
        };
        return div([ Concur_React_Props.className("widget-select") ])(Data_Array.catMaybes([ new Data_Maybe.Just(input([ Concur_React_Props.classList(classes), Concur_React_Props["_id"](selState.id), Concur_React_Props.autoComplete("off"), Concur_React_Props.value(search), map3(map4(ChangeSearch.create)(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onInput), map3(map4(handleKey)(eventKey))(Concur_Core_Props.filterProp(canHandleKey)(Concur_React_Props.onKeyDown)), voidRight(OnBlur.value)(Concur_React_Props.onBlur), voidRight(new Expanded(true))(Concur_React_Props.onFocus), Concur_React_Props.placeholder(Data_Maybe.fromMaybe(Translate.lookupTranslation("select-choose"))(selState.config.placeholder)) ])), Internal_Util.justIf(Data_Maybe.isJust(selState.selected))(button([ Concur_React_Props.className("widget-select-clear-button"), voidRight(Deselect.value)(Concur_React_Props.onClick) ])([ text("clear") ])) ]));
    };
};
var render = function (state) {
    return div([ Concur_React_Props.key(state.id + "-wrapper"), Concur_React_Props["_id"](state.id + "-wrapper"), Concur_React_Props.className("widget-select-container") ])(Data_Array.catMaybes([ new Data_Maybe.Just(renderInput(state)(state.search)), Internal_Util.justIf(state.expanded)(renderList(state)) ]));
};
var rankByLabel = function (s) {
    return function (v) {
        return stringMatch(s)(v.label);
    };
};
var preloadSelect = function (state) {
    return function (opts) {
        return {
            id: state.id,
            search: state.search,
            searchAff: state.searchAff,
            groupFunc: state.groupFunc,
            config: state.config,
            selected: state.selected,
            highlighted: state.highlighted,
            expanded: state.expanded,
            hovering: state.hovering,
            loadNext: false,
            options: [ {
                label: "",
                opts: opts
            } ],
            state: state.state
        };
    };
};
var movePointer = function (v) {
    return function (v1) {
        return function (v2) {
            if (v === 0) {
                return Data_Maybe.Nothing.value;
            };
            if (v1 instanceof Up && (v2 instanceof Data_Maybe.Just && v2.value0 > 0)) {
                return new Data_Maybe.Just(v2.value0 - 1 | 0);
            };
            if (v1 instanceof Up && v2 instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just(v - 1 | 0);
            };
            if (v1 instanceof Up && v2 instanceof Data_Maybe.Nothing) {
                return new Data_Maybe.Just(0);
            };
            if (v1 instanceof Down && (v2 instanceof Data_Maybe.Just && v2.value0 < (v - 1 | 0))) {
                return new Data_Maybe.Just(v2.value0 + 1 | 0);
            };
            if (v1 instanceof Down && v2 instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just(0);
            };
            if (v1 instanceof Down && v2 instanceof Data_Maybe.Nothing) {
                return new Data_Maybe.Just(0);
            };
            if (v1 instanceof Absolute && v1.value0 > v) {
                return new Data_Maybe.Just(v - 1 | 0);
            };
            if (v1 instanceof Absolute && v1.value0 < 0) {
                return new Data_Maybe.Just(0);
            };
            if (v1 instanceof Absolute) {
                return new Data_Maybe.Just(v1.value0);
            };
            if (v1 instanceof Last) {
                return new Data_Maybe.Just(v - 1 | 0);
            };
            throw new Error("Failed pattern match at Widgets.BetterSelect (line 246, column 1 - line 246, column 57): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var mapSearchResult = function (f) {
    return function (res) {
        var func = function (opt) {
            return {
                label: opt.label,
                value: f(opt.value)
            };
        };
        return map1(map(func))(res);
    };
};
var mapSearchAff = function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof BackendSearch) {
                return new BackendSearch((function () {
                    var $207 = mapSearchResult(v);
                    return function ($208) {
                        return $207(v2.value0($208));
                    };
                })());
            };
            if (v2 instanceof FrontendSearch) {
                return new FrontendSearch({
                    query: mapSearchResult(v)(v2.value0.query),
                    rank: v1
                });
            };
            if (v2 instanceof StaticSearch) {
                return new StaticSearch({
                    items: map(function (opt) {
                        return {
                            value: v(opt.value),
                            label: opt.label
                        };
                    })(v2.value0.items),
                    rank: v1
                });
            };
            throw new Error("Failed pattern match at Widgets.BetterSelect (line 172, column 1 - line 172, column 104): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var initGroupedSelect$prime = function (id) {
    return function (searchAff) {
        return function (groupFunc) {
            return function (config) {
                return {
                    id: id,
                    searchAff: searchAff,
                    config: config,
                    groupFunc: groupFunc,
                    options: [  ],
                    state: SelOK.value,
                    search: "",
                    selected: Data_Maybe.Nothing.value,
                    highlighted: Data_Maybe.Nothing.value,
                    loadNext: true,
                    expanded: false,
                    hovering: false
                };
            };
        };
    };
};
var initSelect$prime = function (id) {
    return function (searchAff) {
        return function (config) {
            return initGroupedSelect$prime(id)(searchAff)(Data_Maybe.Nothing.value)(config);
        };
    };
};
var initSimpleSelect$prime = function (id) {
    return function (options) {
        return initSelect$prime(id)(staticFrontendSearch(function (v) {
            return v.label;
        })(options));
    };
};
var initGroupedSelect = function (id) {
    return function (aff) {
        return function (group) {
            return function (config) {
                var v = initSelect$prime(id)(aff)(config);
                return {
                    id: v.id,
                    search: v.search,
                    searchAff: v.searchAff,
                    groupFunc: new Data_Maybe.Just(group),
                    config: v.config,
                    selected: v.selected,
                    highlighted: v.highlighted,
                    expanded: v.expanded,
                    hovering: v.hovering,
                    loadNext: v.loadNext,
                    options: v.options,
                    state: v.state
                };
            };
        };
    };
};
var groupOptsByKey = function (dictOrd) {
    var eq3 = Data_Eq.eq(dictOrd.Eq0());
    return function (func) {
        return function (label) {
            return function (opts) {
                var labelGroup = function (grouped) {
                    return {
                        label: label((function (v) {
                            return v.value;
                        })(Data_Array_NonEmpty.head(grouped))),
                        opts: Data_Array_NonEmpty.toArray(grouped)
                    };
                };
                return map(labelGroup)(Data_Array.groupBy(function (a1) {
                    return function (b) {
                        return eq3(func(a1.value))(func(b.value));
                    };
                })(opts));
            };
        };
    };
};
var groupOpts = function (v) {
    return function (v1) {
        if (v.groupFunc instanceof Data_Maybe.Nothing) {
            return [ {
                label: "",
                opts: v1
            } ];
        };
        if (v.groupFunc instanceof Data_Maybe.Just) {
            return v.groupFunc.value0(v1);
        };
        throw new Error("Failed pattern match at Widgets.BetterSelect (line 388, column 1 - line 388, column 83): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var getItem = function (state) {
    return function (idx) {
        return Data_Array.index(Data_Maybe.fromMaybe([  ])(map2(function (v) {
            return v.opts;
        })(Data_Array.index(state.options)(0))))(idx);
    };
};
var filterSort = function (rank) {
    var $209 = sortWith(rank);
    var $210 = Data_Array.filter(function (o) {
        return rank(o) > 0;
    });
    return function ($211) {
        return $209($210($211));
    };
};
var loadSearchAff = function (v) {
    return function (v1) {
        if (v1 instanceof BackendSearch) {
            return Control_Monad_Except_Trans.runExceptT(bind(runGraphQL_(v1.value0(v)))(function (result) {
                return pure({
                    result: result,
                    searchAff: v1
                });
            }));
        };
        if (v1 instanceof FrontendSearch) {
            return bind1(runGraphQL(v1.value0.query))(function (v2) {
                if (v2 instanceof Data_Either.Right) {
                    return loadSearchAff(v)(new StaticSearch({
                        items: v2.value0,
                        rank: v1.value0.rank
                    }));
                };
                if (v2 instanceof Data_Either.Left) {
                    return pure1(new Data_Either.Left(v2.value0));
                };
                throw new Error("Failed pattern match at Widgets.BetterSelect (line 379, column 78 - line 381, column 32): " + [ v2.constructor.name ]);
            });
        };
        if (v1 instanceof StaticSearch) {
            var result = filterSort(v1.value0.rank(v))(v1.value0.items);
            return pure1(new Data_Either.Right({
                result: result,
                searchAff: v1
            }));
        };
        throw new Error("Failed pattern match at Widgets.BetterSelect (line 373, column 1 - line 373, column 98): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var emptySearchAff = /* #__PURE__ */ (function () {
    return new StaticSearch({
        items: [  ],
        rank: function (v) {
            return function (v1) {
                return 0;
            };
        }
    });
})();
var defSelectConfig = /* #__PURE__ */ (function () {
    return {
        placeholder: Data_Maybe.Nothing.value,
        deselectLabel: Data_Maybe.Nothing.value,
        allowDeselect: true
    };
})();
var initSelect = function (id) {
    return function (searchAff) {
        return initSelect$prime(id)(searchAff)(defSelectConfig);
    };
};
var initSimpleSelect = function (id) {
    return function (options) {
        return initSimpleSelect$prime(id)(options)(defSelectConfig);
    };
};
var debounceDelay = 300.0;
var loadSearch = function (state) {
    return bind2(alt(map5(SelAct.create)(render({
        id: state.id,
        search: state.search,
        searchAff: state.searchAff,
        groupFunc: state.groupFunc,
        config: state.config,
        selected: state.selected,
        highlighted: state.highlighted,
        expanded: state.expanded,
        hovering: state.hovering,
        loadNext: state.loadNext,
        options: [  ],
        state: SelLoading.value
    })))(map5(SearchResult.create)(liftAff(loadSearchAff(state.search)(state.searchAff)))))(function (act) {
        if (act instanceof SelAct) {
            return handleAction(state)(act.value0);
        };
        if (act instanceof SearchResult && act.value0 instanceof Data_Either.Right) {
            return pure2(new Data_Either.Left({
                options: groupOpts(state)(act.value0.value0.result),
                searchAff: act.value0.value0.searchAff,
                state: SelOK.value,
                config: state.config,
                expanded: state.expanded,
                groupFunc: state.groupFunc,
                highlighted: state.highlighted,
                hovering: state.hovering,
                id: state.id,
                loadNext: state.loadNext,
                search: state.search,
                selected: state.selected
            }));
        };
        if (act instanceof SearchResult && act.value0 instanceof Data_Either.Left) {
            return pure2(new Data_Either.Left({
                options: [  ],
                state: new SelError(act.value0.value0),
                config: state.config,
                expanded: state.expanded,
                groupFunc: state.groupFunc,
                highlighted: state.highlighted,
                hovering: state.hovering,
                id: state.id,
                loadNext: state.loadNext,
                search: state.search,
                searchAff: state.searchAff,
                selected: state.selected
            }));
        };
        throw new Error("Failed pattern match at Widgets.BetterSelect (line 353, column 5 - line 365, column 18): " + [ act.constructor.name ]);
    });
};
var handleAction = function (v) {
    return function (v1) {
        var state = v;
        if (v1 instanceof SelectOpt) {
            return pure2(new Data_Either.Right({
                selected: new Data_Maybe.Just(v1.value0),
                loadNext: true,
                expanded: false,
                search: v1.value0.label,
                config: state.config,
                groupFunc: state.groupFunc,
                highlighted: state.highlighted,
                hovering: state.hovering,
                id: state.id,
                options: state.options,
                searchAff: state.searchAff,
                state: state.state
            }));
        };
        var state = v;
        if (!v.config.allowDeselect && v1 instanceof Deselect) {
            return pure2(new Data_Either.Right({
                search: "",
                loadNext: true,
                expanded: false,
                config: state.config,
                groupFunc: state.groupFunc,
                highlighted: state.highlighted,
                hovering: state.hovering,
                id: state.id,
                options: state.options,
                searchAff: state.searchAff,
                selected: state.selected,
                state: state.state
            }));
        };
        var state = v;
        if (v1 instanceof Deselect) {
            return pure2(new Data_Either.Right({
                selected: Data_Maybe.Nothing.value,
                search: "",
                loadNext: true,
                expanded: false,
                config: state.config,
                groupFunc: state.groupFunc,
                highlighted: state.highlighted,
                hovering: state.hovering,
                id: state.id,
                options: state.options,
                searchAff: state.searchAff,
                state: state.state
            }));
        };
        var state = v;
        if (v1 instanceof EnterPressed && state.expanded) {
            var v2 = bindFlipped(getItem(state))(state.highlighted);
            if (v2 instanceof Data_Maybe.Just) {
                return handleAction(state)(new SelectOpt(v2.value0));
            };
            if (v2 instanceof Data_Maybe.Nothing) {
                return handleAction(state)(Deselect.value);
            };
            throw new Error("Failed pattern match at Widgets.BetterSelect (line 309, column 5 - line 311, column 47): " + [ v2.constructor.name ]);
        };
        var state = v;
        if (v1 instanceof EnterPressed) {
            return pure2(new Data_Either.Left({
                expanded: true,
                config: state.config,
                groupFunc: state.groupFunc,
                highlighted: state.highlighted,
                hovering: state.hovering,
                id: state.id,
                loadNext: state.loadNext,
                options: state.options,
                search: state.search,
                searchAff: state.searchAff,
                selected: state.selected,
                state: state.state
            }));
        };
        var state = v;
        if (v1 instanceof MoveHighlight) {
            var optcount = sum(map(function ($212) {
                return length((function (v2) {
                    return v2.opts;
                })($212));
            })(state.options));
            var ptr = movePointer(optcount)(v1.value0)(state.highlighted);
            return pure2(new Data_Either.Left({
                highlighted: ptr,
                expanded: true,
                config: state.config,
                groupFunc: state.groupFunc,
                hovering: state.hovering,
                id: state.id,
                loadNext: state.loadNext,
                options: state.options,
                search: state.search,
                searchAff: state.searchAff,
                selected: state.selected,
                state: state.state
            }));
        };
        var state = v;
        if (v1 instanceof Expanded) {
            return pure2(new Data_Either.Left({
                expanded: v1.value0,
                hovering: false,
                config: state.config,
                groupFunc: state.groupFunc,
                highlighted: state.highlighted,
                id: state.id,
                loadNext: state.loadNext,
                options: state.options,
                search: state.search,
                searchAff: state.searchAff,
                selected: state.selected,
                state: state.state
            }));
        };
        var state = v;
        if (v1 instanceof OnBlur && state.hovering) {
            return pure2(new Data_Either.Left(state));
        };
        var state = v;
        if (v1 instanceof OnBlur) {
            var search = Data_Maybe.maybe("")(function (v2) {
                return v2.label;
            })(state.selected);
            return pure2(new Data_Either.Left({
                expanded: false,
                hovering: false,
                search: search,
                loadNext: true,
                config: state.config,
                groupFunc: state.groupFunc,
                highlighted: state.highlighted,
                id: state.id,
                options: state.options,
                searchAff: state.searchAff,
                selected: state.selected,
                state: state.state
            }));
        };
        var state = v;
        if (v1 instanceof Hovering) {
            return pure2(new Data_Either.Left({
                hovering: v1.value0,
                config: state.config,
                expanded: state.expanded,
                groupFunc: state.groupFunc,
                highlighted: state.highlighted,
                id: state.id,
                loadNext: state.loadNext,
                options: state.options,
                search: state.search,
                searchAff: state.searchAff,
                selected: state.selected,
                state: state.state
            }));
        };
        if (v1 instanceof ChangeSearch) {
            var state = {
                search: v1.value0,
                config: v.config,
                expanded: v.expanded,
                groupFunc: v.groupFunc,
                highlighted: v.highlighted,
                hovering: v.hovering,
                id: v.id,
                loadNext: v.loadNext,
                options: v.options,
                searchAff: v.searchAff,
                selected: v.selected,
                state: v.state
            };
            return bind2(alt(voidRight1(Data_Maybe.Nothing.value)(liftAff(Effect_Aff.delay(debounceDelay))))(map5(Data_Maybe.Just.create)(render(state))))(function (v2) {
                if (v2 instanceof Data_Maybe.Nothing) {
                    return loadSearch({
                        id: state.id,
                        search: state.search,
                        searchAff: state.searchAff,
                        groupFunc: state.groupFunc,
                        config: state.config,
                        selected: state.selected,
                        highlighted: state.highlighted,
                        expanded: true,
                        hovering: state.hovering,
                        loadNext: false,
                        options: state.options,
                        state: state.state
                    });
                };
                if (v2 instanceof Data_Maybe.Just) {
                    return handleAction(state)(v2.value0);
                };
                throw new Error("Failed pattern match at Widgets.BetterSelect (line 338, column 5 - line 342, column 43): " + [ v2.constructor.name ]);
            });
        };
        throw new Error("Failed pattern match at Widgets.BetterSelect (line 294, column 1 - line 294, column 99): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var runSelect = function (v) {
    if (v.loadNext && v.expanded) {
        var search = Data_Maybe.maybe("")(function (opt) {
            var $199 = v.search === opt.label;
            if ($199) {
                return "";
            };
            return v.search;
        })(selectedItem(v));
        return loadSearch({
            id: v.id,
            search: search,
            searchAff: v.searchAff,
            groupFunc: v.groupFunc,
            config: v.config,
            selected: v.selected,
            highlighted: v.highlighted,
            expanded: v.expanded,
            hovering: v.hovering,
            loadNext: false,
            options: v.options,
            state: v.state
        });
    };
    return bindFlipped1(handleAction(v))(render(v));
};
var selectWidget = function (state) {
    return loopState({
        expanded: false,
        config: state.config,
        groupFunc: state.groupFunc,
        highlighted: state.highlighted,
        hovering: state.hovering,
        id: state.id,
        loadNext: state.loadNext,
        options: state.options,
        search: state.search,
        searchAff: state.searchAff,
        selected: state.selected,
        state: state.state
    })(runSelect);
};
var selectWidgetWithId = function (id) {
    return function (v) {
        return map5(function (v1) {
            return {
                id: v.id,
                config: v1.config,
                expanded: v1.expanded,
                groupFunc: v1.groupFunc,
                highlighted: v1.highlighted,
                hovering: v1.hovering,
                loadNext: v1.loadNext,
                options: v1.options,
                search: v1.search,
                searchAff: v1.searchAff,
                selected: v1.selected,
                state: v1.state
            };
        })(selectWidget({
            id: id,
            search: v.search,
            searchAff: v.searchAff,
            groupFunc: v.groupFunc,
            config: v.config,
            selected: v.selected,
            highlighted: v.highlighted,
            expanded: v.expanded,
            hovering: v.hovering,
            loadNext: v.loadNext,
            options: v.options,
            state: v.state
        }));
    };
};
export {
    selectWidget,
    selectWidgetWithId,
    initSelect,
    initSelect$prime,
    preloadSelect,
    setSearchAff,
    initGroupedSelect,
    initGroupedSelect$prime,
    defSelectConfig,
    initSimpleSelect,
    initSimpleSelect$prime,
    selectedItem,
    setSelected,
    FrontendSearch,
    BackendSearch,
    StaticSearch,
    groupOptsByKey,
    simpleFrontendSearch,
    staticFrontendSearch,
    wrapSelectOption,
    wrapSelectOption$prime,
    emptySearchAff,
    mapSearchAff,
    rankByLabel
};
