// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Combinators from "../Data.Argonaut.Decode.Combinators/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_FunctorWithIndex from "../Data.FunctorWithIndex/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as $$Error from "../Error/index.js";
import * as Internal_JsonUtil from "../Internal.JsonUtil/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_Dynamic from "../Widgets.Form.Dynamic/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var setData = /* #__PURE__ */ Widgets_Form_Class.setData(Widgets_Form_Dynamic.dynFormWidget);
var show = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "entries";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "label";
    }
})(Data_Show.showString))(Data_Show.showString))(/* #__PURE__ */ Data_Show.showArray(Widgets_Form_Dynamic.dynWidgetShow))));
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map2 = /* #__PURE__ */ Data_Functor.map(Widgets_Form_Class.fwrFunctor);
var toWidget = /* #__PURE__ */ Widgets_Form_Class.toWidget(Widgets_Form_Dynamic.dynFormWidget);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var mapWithIndex = /* #__PURE__ */ Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var h4$prime = /* #__PURE__ */ Concur_React_DOM["h4$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJson));
var getData = /* #__PURE__ */ Widgets_Form_Class.getData(Widgets_Form_Dynamic.dynFormWidget);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var map4 = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var getFieldOptional$prime = /* #__PURE__ */ Data_Argonaut_Decode_Combinators["getFieldOptional$prime"](Data_Argonaut_Decode_Class.decodeJsonInt);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Data_Either.applicativeEither);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var decodeJson = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeJson(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonJson));
var foldM = /* #__PURE__ */ Data_Foldable.foldM(Data_Foldable.foldableArray)(Data_Either.monadEither);
var ListWidget = function (x) {
    return x;
};
var simpleListWidget = function (v) {
    return {
        id: v.id,
        label: v.label,
        cls: v.cls,
        entries: [  ],
        addBtns: [ {
            label: Translate.lookupTranslation("add"),
            newEntries: function (v1) {
                return pure([ v.template ]);
            }
        } ],
        load: function (fc) {
            return function (json) {
                return setData(fc)(v.template)(json);
            };
        },
        loadExist: function (fc) {
            return function (oldw) {
                return function (json) {
                    return setData(fc)(oldw)(json);
                };
            };
        }
    };
};
var showLW = {
    show: function (v) {
        return show({
            id: v.id,
            label: v.label,
            entries: v.entries
        });
    }
};
var modalListWidget = function (v) {
    return {
        id: v.id,
        label: v.label,
        addBtns: v.addBtns,
        load: v.load,
        loadExist: v.loadExist,
        cls: v.cls,
        entries: [  ]
    };
};
var listFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return false;
    },
    asField: function (v) {
        return Data_Maybe.Nothing.value;
    },
    toWidget: function (fc) {
        return function (v) {
            var updateEntry = function (idx) {
                return function (w) {
                    return map(function (v1) {
                        return {
                            entries: v1,
                            addBtns: v.addBtns,
                            cls: v.cls,
                            id: v.id,
                            label: v.label,
                            load: v.load,
                            loadExist: v.loadExist
                        };
                    })(Data_Maybe.fromMaybe(v.entries))(Data_Array.updateAt(idx)(w)(v.entries));
                };
            };
            var removeEntry = function (idx) {
                return map(function (v1) {
                    return {
                        entries: v1,
                        addBtns: v.addBtns,
                        cls: v.cls,
                        id: v.id,
                        label: v.label,
                        load: v.load,
                        loadExist: v.loadExist
                    };
                })(Data_Maybe.fromMaybe(v.entries))(Data_Array.deleteAt(idx)(v.entries));
            };
            var subWidget = function (idx) {
                return function (w) {
                    var subfc = Widgets_Form_Class.addParentId({
                        parentIds: fc.parentIds,
                        curIndex: idx,
                        isAutofilling: fc.isAutofilling,
                        autofillArgs: fc.autofillArgs,
                        account: fc.account,
                        doc: fc.doc
                    })(show1(idx));
                    return div([ Concur_React_Props.className("list-entry") ])([ map1(function (v1) {
                        return map2(updateEntry(idx))(v1);
                    })(toWidget(subfc)(w)), button([ voidRight(Widgets_Form_Class.formWidgetState(removeEntry(idx)))(Concur_React_Props.onClick), Concur_React_Props.className("btn"), Concur_React_Props["_type"]("button") ])([ text(Translate.lookupTranslation("delete")) ]) ]);
                };
            };
            var subWidgets = mapWithIndex(subWidget)(v.entries);
            var id = Widgets_Form_Class.fieldId(fc)(v.id);
            var addButton = function (v1) {
                return bind(button([ Concur_React_Props.className("btn list-add widget-input"), Concur_React_Props.onClick, Concur_React_Props["_type"]("button") ])([ text(v1.label) ]))(function () {
                    return bind(v1.newEntries(fc))(function (ents) {
                        return pure({
                            entries: Data_Array.concat([ v.entries, ents ]),
                            addBtns: v.addBtns,
                            cls: v.cls,
                            id: v.id,
                            label: v.label,
                            load: v.load,
                            loadExist: v.loadExist
                        });
                    });
                });
            };
            var addButtons = div([ Concur_React_Props.className("list-add-buttons btn-group") ])(map3(addButton)(v.addBtns));
            var html = div([ Concur_React_Props.className(Widgets_Form_Class.fieldClass(v)), Concur_React_Props.key(id) ])([ div([ Concur_React_Props.className("header") ])([ h4$prime([ text(v.label) ]) ]), div([ Concur_React_Props.className("list w-full") ])(subWidgets), map1(Widgets_Form_Class.formWidgetState)(addButtons) ]);
            return map1(function (v1) {
                return map2(ListWidget)(v1);
            })(html);
        };
    },
    getData: function (fc) {
        return function (v) {
            return encodeJson(map3(getData(fc))(v.entries));
        };
    },
    setData: function (fc) {
        return function (v) {
            return function (json) {
                var loadEntry = function ($copy_v1) {
                    return function ($copy_v2) {
                        var $tco_var_v1 = $copy_v1;
                        var $tco_done = false;
                        var $tco_result;
                        function $tco_loop(v1, v2) {
                            if (Data_Argonaut_Core.isArray(v2)) {
                                $tco_var_v1 = v1;
                                $copy_v2 = Data_Argonaut_Core.jsonEmptyObject;
                                return;
                            };
                            $tco_done = true;
                            return bind1(map4(Data_Maybe.fromMaybe(-1 | 0))($$Error["wrapJsonError$prime"](v2)(bind1(Internal_JsonUtil.assertObject(v2))(function (v3) {
                                return getFieldOptional$prime(v3)("_index");
                            }))))(function (v3) {
                                var v4 = Data_Array.index(v1)(v3);
                                if (v4 instanceof Data_Maybe.Nothing) {
                                    return bind1(v.load(fc)(v2))(function (entry) {
                                        return pure1(append(v1)([ entry ]));
                                    });
                                };
                                if (v4 instanceof Data_Maybe.Just) {
                                    return bind1(v.loadExist(fc)(v4.value0)(v2))(function (entry) {
                                        var v5 = Data_Array.updateAt(v3)(entry)(v1);
                                        if (v5 instanceof Data_Maybe.Nothing) {
                                            return pure1(append(v1)([ entry ]));
                                        };
                                        if (v5 instanceof Data_Maybe.Just) {
                                            return pure1(v5.value0);
                                        };
                                        throw new Error("Failed pattern match at Widgets.Form.List (line 162, column 21 - line 164, column 49): " + [ v5.constructor.name ]);
                                    });
                                };
                                throw new Error("Failed pattern match at Widgets.Form.List (line 156, column 13 - line 164, column 49): " + [ v4.constructor.name ]);
                            });
                        };
                        while (!$tco_done) {
                            $tco_result = $tco_loop($tco_var_v1, $copy_v2);
                        };
                        return $tco_result;
                    };
                };
                return bind1($$Error["wrapJsonError$prime"](json)(decodeJson(json)))(function (v1) {
                    return bind1(foldM(loadEntry)(v.entries)(v1))(function (entries) {
                        return new Data_Either.Right({
                            id: v.id,
                            label: v.label,
                            cls: v.cls,
                            entries: entries,
                            addBtns: v.addBtns,
                            load: v.load,
                            loadExist: v.loadExist
                        });
                    });
                });
            };
        };
    }
};
export {
    ListWidget,
    simpleListWidget,
    modalListWidget,
    showLW,
    listFW
};
