// Generated by purs version 0.15.7
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
import * as Widgets_BetterSelect from "../Widgets.BetterSelect/index.js";
import * as Widgets_Form_AutoFillSelect from "../Widgets.Form.AutoFillSelect/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
import * as Widgets_Form_SubForm from "../Widgets.Form.SubForm/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Widgets_Form_Class.fwrFunctor);
var toWidget = /* #__PURE__ */ Widgets_Form_Class.toWidget(Widgets_Form_AutoFillSelect.afsFW);
var toWidget1 = /* #__PURE__ */ Widgets_Form_Class.toWidget(Widgets_Form_SubForm.subFormFW);
var div = /* #__PURE__ */ Concur_React_DOM.div(/* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray))(Concur_Core_Types.widgetShiftMap);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var fromFoldable = /* #__PURE__ */ Foreign_Object.fromFoldable(Data_Foldable.foldableArray);
var getData = /* #__PURE__ */ Widgets_Form_Class.getData(Widgets_Form_SubForm.subFormFW);
var fromHomogeneous = /* #__PURE__ */ Foreign_Object.fromHomogeneous();
var bind1 = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var setData = /* #__PURE__ */ Widgets_Form_Class.setData(Widgets_Form_SubForm.subFormFW);
var setData1 = /* #__PURE__ */ Widgets_Form_Class.setData(Widgets_Form_AutoFillSelect.afsFW);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Either.applicativeEither);
var AutoFillSubForm = function (x) {
    return x;
};
var showAFSF = {
    show: function (v) {
        return "AutoFillSubForm";
    }
};
var initAutoFillSubForm = function (afsf) {
    var updateSF = function (v) {
        return {
            id: v.id,
            label: v.label,
            labelClass: v.labelClass,
            section: v.section,
            collapsible: true,
            expanded: v.expanded,
            cls: v.cls,
            fields: v.fields
        };
    };
    return {
        id: afsf.id,
        select: afsf.select,
        subform: updateSF(afsf.subform)
    };
};
var employerSubForm = function (v) {
    return initAutoFillSubForm({
        id: v.id,
        subform: v,
        select: Widgets_Form_AutoFillSelect.employerAutoFillSelect({
            id: v.id + "-afsf-select"
        })
    });
};
var courseSubForm = function (v) {
    return initAutoFillSubForm({
        id: v.id,
        subform: v,
        select: Widgets_Form_AutoFillSelect.courseAutoFillSelect({
            id: v.id + "-afsf-select"
        })
    });
};
var afsfFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return false;
    },
    asField: function (v) {
        return Data_Maybe.Nothing.value;
    },
    toWidget: function (fc) {
        return function (v) {
            var searchWidget = map(map1(function (v1) {
                return {
                    select: v1,
                    id: v.id,
                    subform: v.subform
                };
            }))(toWidget(fc)(v.select));
            var bodyWidget = map(map1(function (v1) {
                return {
                    subform: v1,
                    id: v.id,
                    select: v.select
                };
            }))(toWidget1(fc)(v.subform));
            return map(map1(AutoFillSubForm))(div([ Concur_React_Props.className("search-subform"), Concur_React_Props.key(v.id) ])([ div([ Concur_React_Props.className("search-subform-search") ])([ searchWidget ]), div([ Concur_React_Props.className("search-subform-body") ])([ bodyWidget ]) ]));
        };
    },
    getData: function (fc) {
        return function (v) {
            var subSelId = function (sstate) {
                return bind(Widgets_BetterSelect.selectedItem(sstate.select))(function (sel) {
                    return new Data_Maybe.Just(new Data_Tuple.Tuple(sstate.id + "Id", Data_Argonaut_Core.fromString(sstate.funcs.itemId(sel.value))));
                });
            };
            var subSelIds = Data_Array.mapMaybe(subSelId)(v.select.subsels);
            var subSelFields = fromFoldable(subSelIds);
            var sfData = getData(fc)(v.subform);
            var mainSelFields = Data_Maybe.maybe(Foreign_Object.empty)(function (id) {
                return fromHomogeneous({
                    id: Data_Argonaut_Core.fromString(id)
                });
            })(Widgets_Form_AutoFillSelect.afSelectedId(v.select));
            var selJson = Foreign_Object.union(mainSelFields)(subSelFields);
            var addIDs = function (json) {
                return Data_Argonaut_Core.fromObject(Foreign_Object.union(json)(selJson));
            };
            var obj = Data_Argonaut_Core.caseJsonObject(sfData)(addIDs)(sfData);
            return obj;
        };
    },
    setData: function (fc) {
        return function (v) {
            return function (json) {
                return bind1(setData(fc)(v.subform)(json))(function (newsf) {
                    return bind1(setData1(fc)(v.select)(json))(function (newsel) {
                        return pure({
                            id: v.id,
                            select: newsel,
                            subform: newsf
                        });
                    });
                });
            };
        };
    }
};
export {
    courseSubForm,
    employerSubForm,
    initAutoFillSubForm,
    AutoFillSubForm,
    showAFSF,
    afsfFW
};
