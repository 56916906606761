// Generated by purs version 0.15.7
import * as API_Signature from "../API.Signature/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as Internal_Clipboard from "../Internal.Clipboard/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as Internal_Luxon from "../Internal.Luxon/index.js";
import * as Internal_Util from "../Internal.Util/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_Dropdown from "../Widgets.Dropdown/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
import * as Widgets_Modal from "../Widgets.Modal/index.js";
import * as Widgets_Signature_Email from "../Widgets.Signature.Email/index.js";
import * as Widgets_Signature_Form from "../Widgets.Signature.Form/index.js";
import * as Widgets_Signature_Pad from "../Widgets.Signature.Pad/index.js";
import * as Widgets_Signature_Types from "../Widgets.Signature.Types/index.js";
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.bindWidget);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var img = /* #__PURE__ */ Concur_React_DOM.img(Concur_Core_LiftWidget.widgetLiftWidget);
var p = /* #__PURE__ */ Concur_React_DOM.p(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var length = /* #__PURE__ */ Data_Foldable.length(Data_Foldable.foldableArray)(Data_Semiring.semiringInt);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var show = /* #__PURE__ */ Data_Show.show($$Error.showErrorWrapper);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Concur_Core_Types.bindWidget);
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var OpenSigForm = /* #__PURE__ */ (function () {
    function OpenSigForm() {

    };
    OpenSigForm.value = new OpenSigForm();
    return OpenSigForm;
})();
var OpenEmailForm = /* #__PURE__ */ (function () {
    function OpenEmailForm() {

    };
    OpenEmailForm.value = new OpenEmailForm();
    return OpenEmailForm;
})();
var SignWithSaved = /* #__PURE__ */ (function () {
    function SignWithSaved() {

    };
    SignWithSaved.value = new SignWithSaved();
    return SignWithSaved;
})();
var LocalSignature = /* #__PURE__ */ (function () {
    function LocalSignature(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    LocalSignature.create = function (value0) {
        return function (value1) {
            return new LocalSignature(value0, value1);
        };
    };
    return LocalSignature;
})();
var RemoteSignature = /* #__PURE__ */ (function () {
    function RemoteSignature(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    RemoteSignature.create = function (value0) {
        return function (value1) {
            return new RemoteSignature(value0, value1);
        };
    };
    return RemoteSignature;
})();
var Emailed = /* #__PURE__ */ (function () {
    function Emailed(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Emailed.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Emailed(value0, value1, value2);
            };
        };
    };
    return Emailed;
})();
var NotSigned = /* #__PURE__ */ (function () {
    function NotSigned() {

    };
    NotSigned.value = new NotSigned();
    return NotSigned;
})();
var signedTime = function (v) {
    if (v.signature instanceof RemoteSignature) {
        return new Data_Maybe.Just(v.signature.value1);
    };
    if (v.signature instanceof LocalSignature) {
        return new Data_Maybe.Just(v.signature.value1);
    };
    if (v.signature instanceof Emailed) {
        return Data_Maybe.Nothing.value;
    };
    if (v.signature instanceof NotSigned) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at Widgets.Signature (line 96, column 1 - line 96, column 48): " + [ v.constructor.name ]);
};
var renderSigImage = function (v) {
    if (v.signature instanceof Emailed) {
        var sentLinkBtn = function (lbl) {
            return a([ Concur_React_Props.onClick, Concur_React_Props.href("javascript:void(0);"), Concur_React_Props.className("signature-copy-link") ])([ text(Translate.lookupTranslation(lbl)) ]);
        };
        var sentLink = function (lbl) {
            return bind(sentLinkBtn(lbl))(function () {
                return discard(alt(liftAff(Internal_Clipboard.writeText(v.signature.value2)))(Internal_ConcurUtil.loopInfinitely(sentLinkBtn(lbl))))(function () {
                    return bind(alt(map(Data_Either.Left.create)(sentLink("email-sent-link-copied")))(discard(liftAff(Effect_Aff.delay(5000.0)))(function () {
                        return pure(new Data_Either.Right(Data_Unit.unit));
                    })))(function (ret) {
                        if (ret instanceof Data_Either.Left) {
                            return pure(ret.value0);
                        };
                        if (ret instanceof Data_Either.Right) {
                            return sentLink("email-sent-link");
                        };
                        throw new Error("Failed pattern match at Widgets.Signature (line 175, column 9 - line 177, column 50): " + [ ret.constructor.name ]);
                    });
                });
            });
        };
        return div([ Concur_React_Props.className("sign-here emailed") ])([ div([ Concur_React_Props.className("emailed-notice") ])([ div$prime([ text(Translate.lookupTranslation("email-sent-to") + (" " + v.info.email)) ]), div$prime([ text(Translate.lookupTranslation("email-sent-at") + (" " + Internal_Luxon.prettyPrint(v.signature.value0))) ]), div$prime([ sentLink("email-sent-link") ]) ]) ]);
    };
    if (v.signature instanceof LocalSignature) {
        var name = v.info.first_name + (" " + v.info.last_name);
        return button(append1((function () {
            if (v.field.can.create) {
                return [ voidRight(new Data_Either.Right(OpenSigForm.value))(Concur_React_Props.onClick) ];
            };
            return [  ];
        })())([ Concur_React_Props.className("sign-here signed") ]))([ Widgets_Signature_Pad.signatureImage([ Concur_React_Props.alt("Signature of " + name) ])(v.signature.value0) ]);
    };
    if (v.signature instanceof RemoteSignature) {
        var name = v.info.first_name + (" " + v.info.last_name);
        return button(append1((function () {
            if (v.field.can.create) {
                return [ voidRight(new Data_Either.Right(OpenSigForm.value))(Concur_React_Props.onClick) ];
            };
            return [  ];
        })())([ Concur_React_Props.className("sign-here signed") ]))([ img([ Concur_React_Props.alt("Signature of " + name), Concur_React_Props.src(v.signature.value0) ]) ]);
    };
    if (v.signature instanceof NotSigned && !v.field.can.create) {
        return button([ Concur_React_Props.className("sign-here disabled") ])([ text(Translate.lookupTranslation("no-sign-here")) ]);
    };
    if (v.signature instanceof NotSigned) {
        return button([ voidRight(new Data_Either.Right(OpenSigForm.value))(Concur_React_Props.onClick), Concur_React_Props.className("sign-here") ])([ text(Translate.lookupTranslation("sign-here")) ]);
    };
    throw new Error("Failed pattern match at Widgets.Signature (line 154, column 1 - line 154, column 88): " + [ v.constructor.name ]);
};
var renderNameWidget = function (v) {
    var name = v.info.first_name + (" " + v.info.last_name);
    var v1 = signedTime(v);
    if (v1 instanceof Data_Maybe.Just) {
        return [ p([ Concur_React_Props.className("signature-name") ])([ text(name) ]), p([ Concur_React_Props.className("signature-time") ])([ text(Translate.lookupTranslation("signed-at") + (" " + Internal_Luxon.prettyPrint(v1.value0))) ]) ];
    };
    if (v1 instanceof Data_Maybe.Nothing) {
        return [  ];
    };
    throw new Error("Failed pattern match at Widgets.Signature (line 207, column 62 - line 213, column 18): " + [ v1.constructor.name ]);
};
var renderEmailWidget = function (v) {
    if (v.info.email === "" && !v.field.can.request) {
        return div$prime([  ]);
    };
    if (v.info.email === "") {
        return button([ voidRight(new Data_Either.Right(OpenEmailForm.value))(Concur_React_Props.onClick), Concur_React_Props.className("signature-add-email") ])([ text(Translate.lookupTranslation("signature-add-email")) ]);
    };
    var alreadySent = (function () {
        if (v.signature instanceof Emailed) {
            return true;
        };
        return false;
    })();
    var sendText = (function () {
        if (alreadySent) {
            return "signature-resend-email";
        };
        return "signature-send-email";
    })();
    return p([ Concur_React_Props.className("signature-email") ])([ text(v.info.email + " "), button([ voidRight(new Data_Either.Right(OpenEmailForm.value))(Concur_React_Props.onClick), Concur_React_Props.className("signature-add-email") ])([ text(Translate.lookupTranslation(sendText)) ]) ]);
};
var loadVerification = function (state) {
    return function (result) {
        var comment = Data_Maybe.fromMaybe("")(result.data.comment);
        return {
            field: state.field,
            info: {
                first_name: state.info.first_name,
                last_name: state.info.last_name,
                email: result.data.email
            },
            docId: state.docId,
            signature: new Emailed(result.created_at, comment, result.link)
        };
    };
};
var loadSignature = function (state) {
    return function (result) {
        return {
            field: state.field,
            info: {
                first_name: result.contact.first_name,
                last_name: result.contact.last_name,
                email: result.contact.email
            },
            docId: state.docId,
            signature: new RemoteSignature(result.url, result.created_at)
        };
    };
};
var loadDefaults = function (v) {
    return function (v1) {
        if (v.signature instanceof NotSigned) {
            return {
                field: v.field,
                info: {
                    first_name: Data_Maybe.fromMaybe("")(v1.default_first_name),
                    last_name: Data_Maybe.fromMaybe("")(v1.default_last_name),
                    email: Data_Maybe.fromMaybe("")(v1.default_email)
                },
                docId: v.docId,
                signature: v.signature
            };
        };
        return v;
    };
};
var isWidgetSignedOrEmailed = function (v) {
    if (v.signature instanceof NotSigned) {
        return false;
    };
    return true;
};
var isWidgetSigned = function (v) {
    if (v.signature instanceof NotSigned) {
        return false;
    };
    if (v.signature instanceof Emailed) {
        return false;
    };
    return true;
};
var renderDropdownButton = function (state) {
    var signedOrEmailed = isWidgetSignedOrEmailed(state);
    var signed = isWidgetSigned(state);
    var renderBtn = button([ Concur_React_Props.className("dropdown-button"), Concur_React_Props.onClick ])([ text("\u2807") ]);
    var menu = Data_Array.catMaybes([ Internal_Util.justIf(state.field.can.create && !signed)({
        value: new Data_Either.Right(OpenSigForm.value),
        label: Translate.lookupTranslation("sign")
    }), Internal_Util.justIf(state.field.can.useDefault && !signed)({
        value: new Data_Either.Right(SignWithSaved.value),
        label: Translate.lookupTranslation("sign-with-saved")
    }), Internal_Util.justIf(state.field.can.request && !signedOrEmailed)({
        value: new Data_Either.Right(OpenEmailForm.value),
        label: Translate.lookupTranslation("email-to-sign")
    }), Internal_Util.justIf(state.field.can.request)((function () {
        var $131 = state.info.email === "";
        if ($131) {
            return {
                value: new Data_Either.Right(OpenEmailForm.value),
                label: Translate.lookupTranslation("add-email")
            };
        };
        return {
            value: new Data_Either.Right(OpenEmailForm.value),
            label: Translate.lookupTranslation("edit-email")
        };
    })()) ]);
    var emptyMenu = length(menu) === 0;
    if (emptyMenu) {
        return div$prime([  ]);
    };
    return Widgets_Dropdown.dropdownButton(renderBtn)(menu);
};
var renderSigWidget = function (state) {
    return div([ Concur_React_Props.className("signature-widget") ])(append1([ renderSigImage(state), renderDropdownButton(state), p([ Concur_React_Props.className("signature-type") ])([ text(state.field.label) ]) ])(append1(renderNameWidget(state))((function () {
        if (state.field.can.request) {
            return [ renderEmailWidget(state) ];
        };
        return [  ];
    })())));
};
var initSigWidget = function (docId) {
    return function (field) {
        return function (info) {
            return {
                docId: docId,
                field: field,
                info: info,
                signature: NotSigned.value
            };
        };
    };
};
var handleSWAction = function (v) {
    return function (v1) {
        if (v1 instanceof OpenSigForm) {
            var initSig = Widgets_Signature_Types.addSignature(v.info)(Data_Maybe.Nothing.value);
            var sfState = Widgets_Signature_Form.initSigFormState(v.docId)(v.field)(initSig);
            return bind(Widgets_Modal.errorWrapperModal(renderSigWidget(v))(Widgets_Signature_Form.signatureForm(sfState)))(function (res) {
                if (res instanceof Widgets_Modal.ModalClosed) {
                    return pure(v);
                };
                if (res instanceof Widgets_Modal.ModalReturned) {
                    return pure({
                        signature: new RemoteSignature(res.value0.url, res.value0.created_at),
                        info: res.value0.contact,
                        docId: v.docId,
                        field: v.field
                    });
                };
                throw new Error("Failed pattern match at Widgets.Signature (line 115, column 5 - line 118, column 100): " + [ res.constructor.name ]);
            });
        };
        if (v1 instanceof OpenEmailForm) {
            var prevComment = (function () {
                if (v.signature instanceof Emailed) {
                    return v.signature.value1;
                };
                return "";
            })();
            return bind(Widgets_Modal.modalWidget(renderSigWidget(v))(Widgets_Signature_Email.singleEmailWidget({
                email: v.info.email,
                comment: prevComment
            })(v.docId)(v.field)))(function (res) {
                if (res instanceof Widgets_Modal.ModalClosed) {
                    return pure(v);
                };
                if (res instanceof Widgets_Modal.ModalReturned && (res.value0 instanceof Data_Either.Right && res.value0.value0 instanceof Widgets_Signature_Email.SaveEmail)) {
                    return pure({
                        info: {
                            email: res.value0.value0.value0.email,
                            first_name: v.info.first_name,
                            last_name: v.info.last_name
                        },
                        docId: v.docId,
                        field: v.field,
                        signature: v.signature
                    });
                };
                if (res instanceof Widgets_Modal.ModalReturned && (res.value0 instanceof Data_Either.Right && res.value0.value0 instanceof Widgets_Signature_Email.SaveAndSendEmail)) {
                    if (res.value0.value0.value0.length === 1) {
                        return pure(loadVerification(v)(res["value0"]["value0"]["value0"][0]));
                    };
                    return $$Error.handleCritical(new $$Error.WithContext(new $$Error.InModule("signature widget"), new $$Error.StringError("Backend did not respond with the expected number of verifications")));
                };
                if (res instanceof Widgets_Modal.ModalReturned && res.value0 instanceof Data_Either.Left) {
                    return discard(liftEffect(Effect_Console.log(show(res.value0.value0))))(function () {
                        return pure(v);
                    });
                };
                throw new Error("Failed pattern match at Widgets.Signature (line 126, column 5 - line 138, column 22): " + [ res.constructor.name ]);
            });
        };
        if (v1 instanceof SignWithSaved) {
            return bind(bindFlipped($$Error.wrapCritical)(alt(Widgets_Loading.loadingWidget)(liftAff(runGraphQL(API_Signature.signWithSaved(v.docId)(v.field))))))(function (sig) {
                return pure({
                    signature: new RemoteSignature(sig.url, sig.created_at),
                    info: sig.contact,
                    docId: v.docId,
                    field: v.field
                });
            });
        };
        throw new Error("Failed pattern match at Widgets.Signature (line 109, column 1 - line 109, column 82): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var signatureWidget = function (state) {
    return bind(renderSigWidget(state))(function (v) {
        if (v instanceof Data_Either.Left) {
            return signatureWidget(v.value0);
        };
        if (v instanceof Data_Either.Right) {
            return handleSWAction(state)(v.value0);
        };
        throw new Error("Failed pattern match at Widgets.Signature (line 105, column 51 - line 107, column 42): " + [ v.constructor.name ]);
    });
};
var fromSigField = function (docId) {
    return function (field) {
        return initSigWidget(docId)(field)(Widgets_Signature_Types.emptySigInfo);
    };
};
export {
    LocalSignature,
    RemoteSignature,
    Emailed,
    NotSigned,
    OpenSigForm,
    OpenEmailForm,
    SignWithSaved,
    initSigWidget,
    fromSigField,
    loadSignature,
    loadVerification,
    loadDefaults,
    isWidgetSignedOrEmailed,
    isWidgetSigned,
    signedTime,
    signatureWidget,
    handleSWAction,
    renderSigWidget,
    renderSigImage,
    renderNameWidget,
    renderEmailWidget,
    renderDropdownButton
};
