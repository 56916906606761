"use strict";

import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas';

export default class extends Component {
  sigPad = {}
  render() {
    return (
      <SignatureCanvas penColor='black'
        {...this.props}
        ref={(ref) => this.sigPad = ref}
        canvasProps={{className: this.props.className ? this.props.className : '', id: this.props.id}}
        clearOnResize={false}
      />
    )
  }

  componentDidMount() {
    // If we were given an initial signature to render, draw it on the canvas.
    if (this.props.sigBlob) {
      // Convert the blob into a data URL (base-64 string).
      let dataUrl = URL.createObjectURL(this.props.sigBlob)
      // Draw that on the canvas.
      this.sigPad.fromDataURL(dataUrl)
    }
    window.addEventListener("resize", this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  onResize = (_) => {
    resizeCanvas(this.sigPad)
  }
}

export async function getCanvasBlobFromId(canvasId) {
  let canvas = document.getElementById(canvasId)
  return await new Promise(resolve => cropSignatureCanvas(resolve, canvas))
}

function resizeCanvas(sigPad) {
  let canvas = sigPad.getCanvas()
  if (canvas.offsetWidth != 0 && canvas.offsetHeight != 0) {
    // Get the signature as a list of points.
    let data = sigPad.toData()

    // Do the resize.
    // From example here: https://github.com/szimek/signature_pad#handling-high-dpi-screens
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext("2d").scale(ratio, ratio)

    // Clear the contents of the pad and re-draw the signature from before.
    sigPad.clear()
    sigPad.fromData(data)
  }
}

function cropSignatureCanvas(resolve, canvas) {
    // First duplicate the canvas to not alter the original
    var croppedCanvas = document.createElement('canvas'),
        croppedCtx    = croppedCanvas.getContext('2d');

    croppedCanvas.width  = canvas.width;
    croppedCanvas.height = canvas.height;
    croppedCtx.drawImage(canvas, 0, 0);

    // Next do the actual cropping
    var w         = croppedCanvas.width,
        h         = croppedCanvas.height,
        pix       = {x:[], y:[]},
        imageData = croppedCtx.getImageData(0,0,croppedCanvas.width,croppedCanvas.height),
        x, y, index;

    for (y = 0; y < h; y++) {
        for (x = 0; x < w; x++) {
            index = (y * w + x) * 4;
            if (imageData.data[index+3] > 0) {
                pix.x.push(x);
                pix.y.push(y);

            }
        }
    }
    pix.x.sort(function(a,b){return a-b});
    pix.y.sort(function(a,b){return a-b});
    var n = pix.x.length-1;

    w = pix.x[n] - pix.x[0];
    h = pix.y[n] - pix.y[0];
    var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

    croppedCanvas.width = w;
    croppedCanvas.height = h;
    croppedCtx.putImageData(cut, 0, 0);

    return croppedCanvas.toBlob(resolve, 'image/png');
}
