// Generated by purs version 0.15.7
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_Luxon from "../Internal.Luxon/index.js";
var rltvCons = /* #__PURE__ */ GraphQL_Query.rltvCons(GraphQL_Query.rltvNil);
var idIsSymbol = {
    reflectSymbol: function () {
        return "id";
    }
};
var encRNTY = /* #__PURE__ */ GraphQL_Type.encRNTY(Data_Argonaut_Encode_Class.encodeJsonJString);
var dataIsSymbol = {
    reflectSymbol: function () {
        return "data";
    }
};
var rltvCons1 = /* #__PURE__ */ GraphQL_Query.rltvCons(/* #__PURE__ */ rltvCons(idIsSymbol)(/* #__PURE__ */ GraphQL_Type.rntyType({
    reflectSymbol: function () {
        return "ID";
    }
}))(encRNTY)()())(dataIsSymbol)(/* #__PURE__ */ GraphQL_Type.rntyType({
    reflectSymbol: function () {
        return "SignFormInput";
    }
}));
var gEncodeJsonCons = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(encRNTY);
var gEncodeJsonCons1 = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJBoolean)(Data_Argonaut_Encode_Class.gEncodeJsonNil);
var use_defaultIsSymbol = {
    reflectSymbol: function () {
        return "use_default";
    }
};
var keyIsSymbol = {
    reflectSymbol: function () {
        return "key";
    }
};
var rltvfConsOther = /* #__PURE__ */ GraphQL_Query.rltvfConsOther(GraphQL_Query.rltvfNil);
var rltvfNamedTypeRec = /* #__PURE__ */ GraphQL_Query.rltvfNamedTypeRec(/* #__PURE__ */ rltvfConsOther(idIsSymbol)())(dataIsSymbol)()();
var gDecodeJsonCons = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString));
var gDecodeJsonCons1 = /* #__PURE__ */ gDecodeJsonCons(Data_Argonaut_Decode_Class.gDecodeJsonNil);
var last_nameIsSymbol = {
    reflectSymbol: function () {
        return "last_name";
    }
};
var first_nameIsSymbol = {
    reflectSymbol: function () {
        return "first_name";
    }
};
var emailIsSymbol = {
    reflectSymbol: function () {
        return "email";
    }
};
var gDecodeJsonCons2 = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Internal_Luxon.decodeLDT));
var gDecodeJsonCons3 = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ GraphQL_Type.decRNTY(Data_Argonaut_Decode_Class.decodeJsonString)));
var urlIsSymbol = {
    reflectSymbol: function () {
        return "url";
    }
};
var relationshipIsSymbol = {
    reflectSymbol: function () {
        return "relationship";
    }
};
var created_atIsSymbol = {
    reflectSymbol: function () {
        return "created_at";
    }
};
var contactIsSymbol = {
    reflectSymbol: function () {
        return "contact";
    }
};
var rlhsCons = /* #__PURE__ */ GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsNil);
var jsonQR = /* #__PURE__ */ GraphQL_Query.jsonQR(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons1(last_nameIsSymbol)()())(first_nameIsSymbol)()())(emailIsSymbol)()())()))(/* #__PURE__ */ gDecodeJsonCons2(/* #__PURE__ */ gDecodeJsonCons3(/* #__PURE__ */ gDecodeJsonCons3(/* #__PURE__ */ gDecodeJsonCons3(/* #__PURE__ */ gDecodeJsonCons1(urlIsSymbol)()())(relationshipIsSymbol)()())(keyIsSymbol)()())(idIsSymbol)()())(created_atIsSymbol)()())(contactIsSymbol)()())())(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(urlIsSymbol)(GraphQL_Shape.scalarShape)()())(relationshipIsSymbol)(GraphQL_Shape.scalarShape)()())(keyIsSymbol)(GraphQL_Shape.scalarShape)()())(idIsSymbol)(GraphQL_Shape.scalarShape)()())(created_atIsSymbol)(GraphQL_Shape.scalarShape)()())(contactIsSymbol)(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(last_nameIsSymbol)(GraphQL_Shape.scalarShape)()())(first_nameIsSymbol)(GraphQL_Shape.scalarShape)()())(emailIsSymbol)(GraphQL_Shape.scalarShape)()())())()())());
var buildMutation = /* #__PURE__ */ GraphQL_Query.buildMutation(/* #__PURE__ */ GraphQL_Query.rlTVars(/* #__PURE__ */ rltvCons1(/* #__PURE__ */ GraphQL_Type.encRNTY(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons1(use_defaultIsSymbol)())(keyIsSymbol)())()))()())(/* #__PURE__ */ rltvfNamedTypeRec(/* #__PURE__ */ GraphQL_Query.rltvfConsOther(/* #__PURE__ */ rltvfConsOther(use_defaultIsSymbol)())(keyIsSymbol)()))())(jsonQR);
var gEncodeJsonCons2 = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString);
var save_defaultIsSymbol = {
    reflectSymbol: function () {
        return "save_default";
    }
};
var fileIsSymbol = {
    reflectSymbol: function () {
        return "file";
    }
};
var buildMutation1 = /* #__PURE__ */ GraphQL_Query.buildMutation(/* #__PURE__ */ GraphQL_Query.rlTVars(/* #__PURE__ */ rltvCons1(/* #__PURE__ */ GraphQL_Type.encRNTY(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons2(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(GraphQL_Query.fuEnc)(/* #__PURE__ */ gEncodeJsonCons2(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons2(/* #__PURE__ */ gEncodeJsonCons1(save_defaultIsSymbol)())(last_nameIsSymbol)())(keyIsSymbol)())(first_nameIsSymbol)())(fileIsSymbol)())(emailIsSymbol)())()))()())(/* #__PURE__ */ rltvfNamedTypeRec(/* #__PURE__ */ GraphQL_Query.rltvfConsOther(/* #__PURE__ */ GraphQL_Query.rltvfConsFU(/* #__PURE__ */ GraphQL_Query.rltvfConsOther(/* #__PURE__ */ GraphQL_Query.rltvfConsOther(/* #__PURE__ */ GraphQL_Query.rltvfConsOther(/* #__PURE__ */ rltvfConsOther(save_defaultIsSymbol)())(last_nameIsSymbol)())(keyIsSymbol)())(first_nameIsSymbol)())(fileIsSymbol)())(emailIsSymbol)()))())(jsonQR);
var inputIsSymbol = {
    reflectSymbol: function () {
        return "input";
    }
};
var commentIsSymbol = {
    reflectSymbol: function () {
        return "comment";
    }
};
var linkIsSymbol = {
    reflectSymbol: function () {
        return "link";
    }
};
var buildMutation2 = /* #__PURE__ */ GraphQL_Query.buildMutation(/* #__PURE__ */ GraphQL_Query.rlTVars(/* #__PURE__ */ rltvCons(inputIsSymbol)(/* #__PURE__ */ GraphQL_Type.hqtArray(/* #__PURE__ */ GraphQL_Type.rntyType({
    reflectSymbol: function () {
        return "RequestSignatureInput";
    }
})))(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonArray(/* #__PURE__ */ GraphQL_Type.encRNTY(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons2(/* #__PURE__ */ gEncodeJsonCons2(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons(Data_Argonaut_Encode_Class.gEncodeJsonNil)(keyIsSymbol)())({
    reflectSymbol: function () {
        return "form_id";
    }
})())(emailIsSymbol)())(commentIsSymbol)())())))()())(/* #__PURE__ */ rltvfConsOther(inputIsSymbol)())())(/* #__PURE__ */ GraphQL_Query.arrayQR(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons2(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonString))(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons3(Data_Argonaut_Decode_Class.gDecodeJsonNil)(keyIsSymbol)()())(emailIsSymbol)()())(commentIsSymbol)()())()))(/* #__PURE__ */ gDecodeJsonCons3(/* #__PURE__ */ gDecodeJsonCons1(linkIsSymbol)()())(idIsSymbol)()())(dataIsSymbol)()())(created_atIsSymbol)()())()))(/* #__PURE__ */ GraphQL_Shape.arrayShape(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(linkIsSymbol)(GraphQL_Shape.scalarShape)()())(idIsSymbol)(GraphQL_Shape.scalarShape)()())(dataIsSymbol)(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(keyIsSymbol)(GraphQL_Shape.scalarShape)()())(emailIsSymbol)(GraphQL_Shape.scalarShape)()())(commentIsSymbol)(/* #__PURE__ */ GraphQL_Shape.maybeShape(GraphQL_Shape.scalarShape))()())())()())(created_atIsSymbol)(GraphQL_Shape.scalarShape)()())())));
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var signWithSaved = function (docId) {
    return function (v) {
        return buildMutation("signForm")({
            id: docId,
            data: GraphQL_Type.namedType({
                key: v.key,
                use_default: true
            })
        });
    };
};
var signForm = function (docId) {
    return function (v) {
        return function (v1) {
            return function (v2) {
                return buildMutation1("signForm")({
                    id: docId,
                    data: GraphQL_Type.namedType({
                        key: v.key,
                        first_name: v1.first_name,
                        last_name: v1.last_name,
                        email: v1.email,
                        file: GraphQL_Query.blobUpload(v1.signature),
                        save_default: v2.saveDefault
                    })
                });
            };
        };
    };
};
var requestSigs = function (reqs) {
    var mapReq = function (v) {
        return GraphQL_Type.namedType({
            form_id: v.docId,
            email: v.email,
            comment: v.comment,
            key: v.key
        });
    };
    return buildMutation2("requestSignature")({
        input: map(mapReq)(reqs)
    });
};
export {
    signForm,
    signWithSaved,
    requestSigs
};
