// Generated by purs version 0.15.7
import * as Concur_Core_IsWidget from "../Concur.Core.IsWidget/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Effect from "../Effect/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var mkNodeWidget = function (mkView) {
    return function (w) {
        var vp = function (vc) {
            return function (cb) {
                return mkView(function (a) {
                    return cb(new Concur_Core_Types.Completed(a));
                })(vc);
            };
        };
        var f = function (cb) {
            return function (x) {
                if (x instanceof Concur_Core_Types.View) {
                    return cb(new Concur_Core_Types.View(vp(x.value0)(cb)));
                };
                if (x instanceof Concur_Core_Types.Completed) {
                    return cb(new Concur_Core_Types.Completed(x.value0));
                };
                throw new Error("Failed pattern match at Concur.Core (line 25, column 18 - line 27, column 38): " + [ x.constructor.name ]);
            };
        };
        return Concur_Core_Types.mkWidget(function (cb) {
            return Concur_Core_Types.runWidget(w)(f(cb));
        });
    };
};
var mkLeafWidget = function (mkView) {
    var v = function (cb) {
        return mkView(function (a) {
            return cb(new Concur_Core_Types.Completed(a));
        });
    };
    return Concur_Core_Types.mkWidget(function (cb) {
        return function __do() {
            cb(new Concur_Core_Types.View(v(cb)))();
            return pure(Concur_Core_Types.unWid(mkLeafWidget(mkView)));
        };
    });
};
export {
    mkLeafWidget,
    mkNodeWidget
};
export {
    liftWidget
} from "../Concur.Core.LiftWidget/index.js";
export {
    Completed,
    View,
    Widget,
    mkWidget,
    runWidget,
    unWid
} from "../Concur.Core.Types/index.js";
