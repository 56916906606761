// Generated by purs version 0.15.7
import * as API_Signature from "../API.Signature/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Patterns from "../Concur.Core.Patterns/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_Checkbox from "../Widgets.Checkbox/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
import * as Widgets_Signature_Pad from "../Widgets.Signature.Pad/index.js";
import * as Widgets_Signature_Types from "../Widgets.Signature.Types/index.js";
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var form = /* #__PURE__ */ Concur_React_DOM.form(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var signaturePad = /* #__PURE__ */ Widgets_Signature_Pad.signaturePad(Concur_Core_Types.widgetShiftMap)(widgetMultiAlternative);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var map2 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.bindWidget);
var loopState = /* #__PURE__ */ Concur_Core_Patterns.loopState(Concur_Core_Types.widgetMonad);
var FinishSigning = /* #__PURE__ */ (function () {
    function FinishSigning() {

    };
    FinishSigning.value = new FinishSigning();
    return FinishSigning;
})();
var ClearSignature = /* #__PURE__ */ (function () {
    function ClearSignature() {

    };
    ClearSignature.value = new ClearSignature();
    return ClearSignature;
})();
var SignWithSaved = /* #__PURE__ */ (function () {
    function SignWithSaved() {

    };
    SignWithSaved.value = new SignWithSaved();
    return SignWithSaved;
})();
var validateSigForm = function (v) {
    return v.sig.first_name !== "" && (v.sig.last_name !== "" && v.sig.email !== "");
};
var renderSigForm = function (v) {
    var maybeMsg = (function () {
        if (v.msg instanceof Data_Maybe.Just) {
            return [ text(v.msg.value0) ];
        };
        if (v.msg instanceof Data_Maybe.Nothing) {
            return [  ];
        };
        throw new Error("Failed pattern match at Widgets.Signature.Form (line 151, column 16 - line 153, column 22): " + [ v.msg.constructor.name ]);
    })();
    return form([ Concur_React_Props.className("signature-form modal-grid") ])(append((function () {
        var $52 = !v.field.can.useDefault;
        if ($52) {
            return [  ];
        };
        return [ button([ Concur_React_Props.className("btn signature-insert-default"), Concur_React_Props["_type"]("button"), voidRight(new Data_Either.Right(SignWithSaved.value))(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("sig-form-sign-with-saved")) ]) ];
    })())([ div([ Concur_React_Props.className("signature-form-pad-container") ])([ signaturePad(append([ Concur_React_Props.className("signature-form-pad-canvas"), Concur_React_Props["_id"](GraphQL_Type.innerStr(v.field.key)), Concur_React_Props.key(GraphQL_Type.innerStr(v.field.key) + "-sigpad") ])(Data_Maybe.maybe([  ])(function (blob) {
        return [ Widgets_Signature_Pad.initialSignature(blob) ];
    })(v.sig.signature))), button([ voidRight(new Data_Either.Right(ClearSignature.value))(Concur_React_Props.onClick), Concur_React_Props.className("signature-clear btn"), Concur_React_Props["_type"]("button") ])([ text(Translate.lookupTranslation("clear-lowercase")) ]) ]), div([ Concur_React_Props.className("signature-form-info-container") ])(maybeMsg), div([ Concur_React_Props.className("signature-form-info-container") ])(append([ input([ Concur_React_Props.key(GraphQL_Type.innerStr(v.field.key) + "-first-name"), Concur_React_Props["_id"](GraphQL_Type.innerStr(v.field.key) + "-first-name"), Concur_React_Props.className("widget-input"), Concur_React_Props.placeholder(Translate.lookupTranslation("first-name")), Concur_React_Props.value(v.sig.first_name), map(map1(map1(Data_Either.Left.create)(function (v1) {
        return {
            sig: {
                first_name: v1,
                email: v.sig.email,
                last_name: v.sig.last_name,
                signature: v.sig.signature
            },
            field: v.field,
            formId: v.formId,
            msg: v.msg,
            save: v.save
        };
    }))(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onChange) ]), input([ Concur_React_Props.key(GraphQL_Type.innerStr(v.field.key) + "-last-name"), Concur_React_Props["_id"](GraphQL_Type.innerStr(v.field.key) + "-last-name"), Concur_React_Props.className("widget-input"), Concur_React_Props.placeholder(Translate.lookupTranslation("last-name")), Concur_React_Props.value(v.sig.last_name), map(map1(map1(Data_Either.Left.create)(function (v1) {
        return {
            sig: {
                last_name: v1,
                email: v.sig.email,
                first_name: v.sig.first_name,
                signature: v.sig.signature
            },
            field: v.field,
            formId: v.formId,
            msg: v.msg,
            save: v.save
        };
    }))(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onChange) ]), input([ Concur_React_Props.key(GraphQL_Type.innerStr(v.field.key) + "-email"), Concur_React_Props["_id"](GraphQL_Type.innerStr(v.field.key) + "-email"), Concur_React_Props.className("widget-input"), Concur_React_Props.autoComplete("email"), Concur_React_Props.placeholder(Translate.lookupTranslation("email")), Concur_React_Props.value(v.sig.email), map(map1(map1(Data_Either.Left.create)(function (v1) {
        return {
            sig: {
                email: v1,
                first_name: v.sig.first_name,
                last_name: v.sig.last_name,
                signature: v.sig.signature
            },
            field: v.field,
            formId: v.formId,
            msg: v.msg,
            save: v.save
        };
    }))(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onChange) ]) ])((function () {
        var $53 = !v.field.can.saveDefault;
        if ($53) {
            return [  ];
        };
        return [ map2(map1(Data_Either.Left.create)(function (v1) {
            return {
                save: v1,
                field: v.field,
                formId: v.formId,
                msg: v.msg,
                sig: v.sig
            };
        }))(Widgets_Checkbox.labelCheckbox(GraphQL_Type.innerStr(v.field.key) + "-savecheck")(Translate.lookupTranslation("save-signature"))(v.save)) ];
    })())), div([ Concur_React_Props.className("modal-buttons") ])([ button([ voidRight(new Data_Either.Right(FinishSigning.value))(Concur_React_Props.onClick), Concur_React_Props.className("btn btn-primary"), Concur_React_Props["_type"]("button") ])([ text(Translate.lookupTranslation("sign")) ]) ]) ]));
};
var initSigFormState = function (formId) {
    return function (field) {
        return function (sig) {
            return {
                field: field,
                formId: formId,
                sig: sig,
                msg: Data_Maybe.Nothing.value,
                save: false
            };
        };
    };
};
var handleSFAction = function (v) {
    return function (v1) {
        if (v1 instanceof FinishSigning) {
            var $57 = !validateSigForm(v);
            if ($57) {
                return pure(new Data_Either.Left({
                    msg: new Data_Maybe.Just(Translate.lookupTranslation("signature-form-invalid")),
                    field: v.field,
                    formId: v.formId,
                    save: v.save,
                    sig: v.sig
                }));
            };
            return bind(alt(liftAff(Widgets_Signature_Pad.getCanvasBlob(GraphQL_Type.innerStr(v.field.key))))(Internal_ConcurUtil.loopInfinitely(renderSigForm(v))))(function (blob) {
                var sig$prime = Widgets_Signature_Types.addSignature(v.sig)(blob);
                var opts = {
                    saveDefault: v.save
                };
                return bind(alt(Widgets_Loading.loadingWidget)(liftAff(runGraphQL(API_Signature.signForm(v.formId)(v.field)(sig$prime)(opts)))))(function (res) {
                    if (res instanceof Data_Either.Right) {
                        return pure(new Data_Either.Right(new Data_Either.Right(res.value0)));
                    };
                    if (res instanceof Data_Either.Left) {
                        return pure(new Data_Either.Right(new Data_Either.Left(res.value0)));
                    };
                    throw new Error("Failed pattern match at Widgets.Signature.Form (line 73, column 13 - line 75, column 52): " + [ res.constructor.name ]);
                });
            });
        };
        if (v1 instanceof SignWithSaved) {
            return bind(alt(Widgets_Loading.loadingWidget)(liftAff(runGraphQL(API_Signature.signWithSaved(v.formId)(v.field)))))(function (res) {
                if (res instanceof Data_Either.Right) {
                    return pure(new Data_Either.Right(new Data_Either.Right(res.value0)));
                };
                if (res instanceof Data_Either.Left) {
                    return pure(new Data_Either.Right(new Data_Either.Left(res.value0)));
                };
                throw new Error("Failed pattern match at Widgets.Signature.Form (line 79, column 5 - line 81, column 44): " + [ res.constructor.name ]);
            });
        };
        if (v1 instanceof ClearSignature) {
            return discard(liftAff(Effect_Aff.delay(1.0)))(function () {
                return pure(new Data_Either.Left({
                    sig: {
                        signature: Data_Maybe.Nothing.value,
                        email: v.sig.email,
                        first_name: v.sig.first_name,
                        last_name: v.sig.last_name
                    },
                    field: v.field,
                    formId: v.formId,
                    msg: v.msg,
                    save: v.save
                }));
            });
        };
        throw new Error("Failed pattern match at Widgets.Signature.Form (line 62, column 1 - line 62, column 99): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var runSigForm = function (state) {
    return bind(renderSigForm(state))(function (res) {
        if (res instanceof Data_Either.Right) {
            return handleSFAction(state)(res.value0);
        };
        if (res instanceof Data_Either.Left) {
            return pure(new Data_Either.Left(res.value0));
        };
        throw new Error("Failed pattern match at Widgets.Signature.Form (line 54, column 5 - line 56, column 42): " + [ res.constructor.name ]);
    });
};
var signatureForm = function (state) {
    return loopState(state)(runSigForm);
};
export {
    initSigFormState,
    signatureForm,
    FinishSigning,
    ClearSignature,
    SignWithSaved,
    runSigForm,
    validateSigForm,
    handleSFAction,
    renderSigForm
};
