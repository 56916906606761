// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Effect_Uncurried from "../Effect.Uncurried/index.js";
var y = /* #__PURE__ */ $foreign.unsafeMkProps("y");
var x = /* #__PURE__ */ $foreign.unsafeMkProps("x");
var wrap = /* #__PURE__ */ $foreign.unsafeMkProps("wrap");
var wmode = /* #__PURE__ */ $foreign.unsafeMkProps("wmode");
var width = /* #__PURE__ */ $foreign.unsafeMkProps("width");
var vocab = /* #__PURE__ */ $foreign.unsafeMkProps("vocab");
var viewBox = /* #__PURE__ */ $foreign.unsafeMkProps("viewBox");
var valueArray = /* #__PURE__ */ $foreign.unsafeMkProps("value");
var value = /* #__PURE__ */ $foreign.unsafeMkProps("value");
var useMap = /* #__PURE__ */ $foreign.unsafeMkProps("useMap");
var unselectable = /* #__PURE__ */ $foreign.unsafeMkProps("unselectable");
var $$typeof = /* #__PURE__ */ $foreign.unsafeMkProps("typeof");
var title = /* #__PURE__ */ $foreign.unsafeMkProps("title");
var target = /* #__PURE__ */ $foreign.unsafeMkProps("target");
var tabIndex = /* #__PURE__ */ $foreign.unsafeMkProps("tabIndex");
var suppressContentEditableWarning = /* #__PURE__ */ $foreign.unsafeMkProps("suppressContentEditableWarning");
var summary = /* #__PURE__ */ $foreign.unsafeMkProps("summary");
var style = /* #__PURE__ */ $foreign.unsafeUnfoldProps("style");
var strokeWidth = /* #__PURE__ */ $foreign.unsafeMkProps("strokeWidth");
var stroke = /* #__PURE__ */ $foreign.unsafeMkProps("stroke");
var step = /* #__PURE__ */ $foreign.unsafeMkProps("step");
var start = /* #__PURE__ */ $foreign.unsafeMkProps("start");
var srcSet = /* #__PURE__ */ $foreign.unsafeMkProps("srcSet");
var srcLang = /* #__PURE__ */ $foreign.unsafeMkProps("srcLang");
var srcDoc = /* #__PURE__ */ $foreign.unsafeMkProps("srcDoc");
var src = /* #__PURE__ */ $foreign.unsafeMkProps("src");
var spellCheck = /* #__PURE__ */ $foreign.unsafeMkProps("spellCheck");
var span = /* #__PURE__ */ $foreign.unsafeMkProps("span");
var sizes = /* #__PURE__ */ $foreign.unsafeMkProps("sizes");
var size = /* #__PURE__ */ $foreign.unsafeMkProps("size");
var shape = /* #__PURE__ */ $foreign.unsafeMkProps("shape");
var selected = /* #__PURE__ */ $foreign.unsafeMkProps("selected");
var security = /* #__PURE__ */ $foreign.unsafeMkProps("security");
var seamless = /* #__PURE__ */ $foreign.unsafeMkProps("seamless");
var scrolling = /* #__PURE__ */ $foreign.unsafeMkProps("scrolling");
var scoped = /* #__PURE__ */ $foreign.unsafeMkProps("scoped");
var scope = /* #__PURE__ */ $foreign.unsafeMkProps("scope");
var sandbox = /* #__PURE__ */ $foreign.unsafeMkProps("sandbox");
var rows = /* #__PURE__ */ $foreign.unsafeMkProps("rows");
var rowSpan = /* #__PURE__ */ $foreign.unsafeMkProps("rowSpan");
var role = /* #__PURE__ */ $foreign.unsafeMkProps("role");
var reversed = /* #__PURE__ */ $foreign.unsafeMkProps("reversed");
var results = /* #__PURE__ */ $foreign.unsafeMkProps("results");
var resource = /* #__PURE__ */ $foreign.unsafeMkProps("resource");
var required = /* #__PURE__ */ $foreign.unsafeMkProps("required");
var rel = /* #__PURE__ */ $foreign.unsafeMkProps("rel");
var ref = /* #__PURE__ */ $foreign.unsafeMkProps("ref");
var readOnly = /* #__PURE__ */ $foreign.unsafeMkProps("readOnly");
var radioGroup = /* #__PURE__ */ $foreign.unsafeMkProps("radioGroup");
var r = /* #__PURE__ */ $foreign.unsafeMkProps("r");
var property = /* #__PURE__ */ $foreign.unsafeMkProps("property");
var profile = /* #__PURE__ */ $foreign.unsafeMkProps("profile");
var preload = /* #__PURE__ */ $foreign.unsafeMkProps("preload");
var prefix = /* #__PURE__ */ $foreign.unsafeMkProps("prefix");
var poster = /* #__PURE__ */ $foreign.unsafeMkProps("poster");
var points = /* #__PURE__ */ $foreign.unsafeMkProps("points");
var placeholder = /* #__PURE__ */ $foreign.unsafeMkProps("placeholder");
var pattern = /* #__PURE__ */ $foreign.unsafeMkProps("pattern");
var optimum = /* #__PURE__ */ $foreign.unsafeMkProps("optimum");
var open = /* #__PURE__ */ $foreign.unsafeMkProps("open");
var opacity = /* #__PURE__ */ $foreign.unsafeMkProps("opacity");
var onWheelCapture = function (f) {
    return $foreign.unsafeMkProps("onWheelCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onWheel = function (f) {
    return $foreign.unsafeMkProps("onWheel")(Effect_Uncurried.mkEffectFn1(f));
};
var onWaitingCapture = function (f) {
    return $foreign.unsafeMkProps("onWaitingCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onWaiting = function (f) {
    return $foreign.unsafeMkProps("onWaiting")(Effect_Uncurried.mkEffectFn1(f));
};
var onVolumeChangeCapture = function (f) {
    return $foreign.unsafeMkProps("onVolumeChangeCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onVolumeChange = function (f) {
    return $foreign.unsafeMkProps("onVolumeChange")(Effect_Uncurried.mkEffectFn1(f));
};
var onTransitionEndCapture = function (f) {
    return $foreign.unsafeMkProps("onTransitionEndCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTransitionEnd = function (f) {
    return $foreign.unsafeMkProps("onTransitionEnd")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchStartCapture = function (f) {
    return $foreign.unsafeMkProps("onTouchStartCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchStart = function (f) {
    return $foreign.unsafeMkProps("onTouchStart")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchMoveCapture = function (f) {
    return $foreign.unsafeMkProps("onTouchMoveCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchMove = function (f) {
    return $foreign.unsafeMkProps("onTouchMove")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchEndCapture = function (f) {
    return $foreign.unsafeMkProps("onTouchEndCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchEnd = function (f) {
    return $foreign.unsafeMkProps("onTouchEnd")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchCancelCapture = function (f) {
    return $foreign.unsafeMkProps("onTouchCancelCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchCancel = function (f) {
    return $foreign.unsafeMkProps("onTouchCancel")(Effect_Uncurried.mkEffectFn1(f));
};
var onToggleCapture = function (f) {
    return $foreign.unsafeMkProps("onToggleCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onToggle = function (f) {
    return $foreign.unsafeMkProps("onToggle")(Effect_Uncurried.mkEffectFn1(f));
};
var onTimeUpdateCapture = function (f) {
    return $foreign.unsafeMkProps("onTimeUpdateCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTimeUpdate = function (f) {
    return $foreign.unsafeMkProps("onTimeUpdate")(Effect_Uncurried.mkEffectFn1(f));
};
var onSuspendCapture = function (f) {
    return $foreign.unsafeMkProps("onSuspendCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onSuspend = function (f) {
    return $foreign.unsafeMkProps("onSuspend")(Effect_Uncurried.mkEffectFn1(f));
};
var onSubmitCapture = function (f) {
    return $foreign.unsafeMkProps("onSubmitCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onSubmit = function (f) {
    return $foreign.unsafeMkProps("onSubmit")(Effect_Uncurried.mkEffectFn1(f));
};
var onStalledCapture = function (f) {
    return $foreign.unsafeMkProps("onStalledCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onStalled = function (f) {
    return $foreign.unsafeMkProps("onStalled")(Effect_Uncurried.mkEffectFn1(f));
};
var onSelectCapture = function (f) {
    return $foreign.unsafeMkProps("onSelectCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onSelect = function (f) {
    return $foreign.unsafeMkProps("onSelect")(Effect_Uncurried.mkEffectFn1(f));
};
var onSeekingCapture = function (f) {
    return $foreign.unsafeMkProps("onSeekingCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onSeeking = function (f) {
    return $foreign.unsafeMkProps("onSeeking")(Effect_Uncurried.mkEffectFn1(f));
};
var onSeekedCapture = function (f) {
    return $foreign.unsafeMkProps("onSeekedCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onSeeked = function (f) {
    return $foreign.unsafeMkProps("onSeeked")(Effect_Uncurried.mkEffectFn1(f));
};
var onScrollCapture = function (f) {
    return $foreign.unsafeMkProps("onScrollCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onScroll = function (f) {
    return $foreign.unsafeMkProps("onScroll")(Effect_Uncurried.mkEffectFn1(f));
};
var onRateChangeCapture = function (f) {
    return $foreign.unsafeMkProps("onRateChangeCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onRateChange = function (f) {
    return $foreign.unsafeMkProps("onRateChange")(Effect_Uncurried.mkEffectFn1(f));
};
var onProgressCapture = function (f) {
    return $foreign.unsafeMkProps("onProgressCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onProgress = function (f) {
    return $foreign.unsafeMkProps("onProgress")(Effect_Uncurried.mkEffectFn1(f));
};
var onPlayingCapture = function (f) {
    return $foreign.unsafeMkProps("onPlayingCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onPlaying = function (f) {
    return $foreign.unsafeMkProps("onPlaying")(Effect_Uncurried.mkEffectFn1(f));
};
var onPlayCapture = function (f) {
    return $foreign.unsafeMkProps("onPlayCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onPlay = function (f) {
    return $foreign.unsafeMkProps("onPlay")(Effect_Uncurried.mkEffectFn1(f));
};
var onPauseCapture = function (f) {
    return $foreign.unsafeMkProps("onPauseCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onPause = function (f) {
    return $foreign.unsafeMkProps("onPause")(Effect_Uncurried.mkEffectFn1(f));
};
var onPasteCapture = function (f) {
    return $foreign.unsafeMkProps("onPasteCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onPaste = function (f) {
    return $foreign.unsafeMkProps("onPaste")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseUpCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseUpCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseUp = function (f) {
    return $foreign.unsafeMkProps("onMouseUp")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseOverCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseOverCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseOver = function (f) {
    return $foreign.unsafeMkProps("onMouseOver")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseOutCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseOutCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseOut = function (f) {
    return $foreign.unsafeMkProps("onMouseOut")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseMoveCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseMoveCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseMove = function (f) {
    return $foreign.unsafeMkProps("onMouseMove")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseLeaveCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseLeaveCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseLeave = function (f) {
    return $foreign.unsafeMkProps("onMouseLeave")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseEnterCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseEnterCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseEnter = function (f) {
    return $foreign.unsafeMkProps("onMouseEnter")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseDownCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseDownCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseDown = function (f) {
    return $foreign.unsafeMkProps("onMouseDown")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadedMetadataCapture = function (f) {
    return $foreign.unsafeMkProps("onLoadedMetadataCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadedMetadata = function (f) {
    return $foreign.unsafeMkProps("onLoadedMetadata")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadedDataCapture = function (f) {
    return $foreign.unsafeMkProps("onLoadedDataCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadedData = function (f) {
    return $foreign.unsafeMkProps("onLoadedData")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadStartCapture = function (f) {
    return $foreign.unsafeMkProps("onLoadStartCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadStart = function (f) {
    return $foreign.unsafeMkProps("onLoadStart")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadCapture = function (f) {
    return $foreign.unsafeMkProps("onLoadCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoad = function (f) {
    return $foreign.unsafeMkProps("onLoad")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyUpCapture = function (f) {
    return $foreign.unsafeMkProps("onKeyUpCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyUp = function (f) {
    return $foreign.unsafeMkProps("onKeyUp")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyPressCapture = function (f) {
    return $foreign.unsafeMkProps("onKeyPressCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyPress = function (f) {
    return $foreign.unsafeMkProps("onKeyPress")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyDownCapture = function (f) {
    return $foreign.unsafeMkProps("onKeyDownCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyDown = function (f) {
    return $foreign.unsafeMkProps("onKeyDown")(Effect_Uncurried.mkEffectFn1(f));
};
var onInvalidCapture = function (f) {
    return $foreign.unsafeMkProps("onInvalidCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onInvalid = function (f) {
    return $foreign.unsafeMkProps("onInvalid")(Effect_Uncurried.mkEffectFn1(f));
};
var onInputCapture = function (f) {
    return $foreign.unsafeMkProps("onInputCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onInput = function (f) {
    return $foreign.unsafeMkProps("onInput")(Effect_Uncurried.mkEffectFn1(f));
};
var onFocusCapture = function (f) {
    return $foreign.unsafeMkProps("onFocusCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onFocus = function (f) {
    return $foreign.unsafeMkProps("onFocus")(Effect_Uncurried.mkEffectFn1(f));
};
var onErrorCapture = function (f) {
    return $foreign.unsafeMkProps("onErrorCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onError = function (f) {
    return $foreign.unsafeMkProps("onError")(Effect_Uncurried.mkEffectFn1(f));
};
var onEndedCapture = function (f) {
    return $foreign.unsafeMkProps("onEndedCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onEnded = function (f) {
    return $foreign.unsafeMkProps("onEnded")(Effect_Uncurried.mkEffectFn1(f));
};
var onEncryptedCapture = function (f) {
    return $foreign.unsafeMkProps("onEncryptedCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onEncrypted = function (f) {
    return $foreign.unsafeMkProps("onEncrypted")(Effect_Uncurried.mkEffectFn1(f));
};
var onEmptiedCapture = function (f) {
    return $foreign.unsafeMkProps("onEmptiedCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onEmptied = function (f) {
    return $foreign.unsafeMkProps("onEmptied")(Effect_Uncurried.mkEffectFn1(f));
};
var onDurationChangeCapture = function (f) {
    return $foreign.unsafeMkProps("onDurationChangeCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDurationChange = function (f) {
    return $foreign.unsafeMkProps("onDurationChange")(Effect_Uncurried.mkEffectFn1(f));
};
var onDropCapture = function (f) {
    return $foreign.unsafeMkProps("onDropCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDrop = function (f) {
    return $foreign.unsafeMkProps("onDrop")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragStartCapture = function (f) {
    return $foreign.unsafeMkProps("onDragStartCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragStart = function (f) {
    return $foreign.unsafeMkProps("onDragStart")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragOverCapture = function (f) {
    return $foreign.unsafeMkProps("onDragOverCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragOver = function (f) {
    return $foreign.unsafeMkProps("onDragOver")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragLeaveCapture = function (f) {
    return $foreign.unsafeMkProps("onDragLeaveCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragLeave = function (f) {
    return $foreign.unsafeMkProps("onDragLeave")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragExitCapture = function (f) {
    return $foreign.unsafeMkProps("onDragExitCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragExit = function (f) {
    return $foreign.unsafeMkProps("onDragExit")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragEnterCapture = function (f) {
    return $foreign.unsafeMkProps("onDragEnterCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragEnter = function (f) {
    return $foreign.unsafeMkProps("onDragEnter")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragEndCapture = function (f) {
    return $foreign.unsafeMkProps("onDragEndCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragEnd = function (f) {
    return $foreign.unsafeMkProps("onDragEnd")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragCapture = function (f) {
    return $foreign.unsafeMkProps("onDragCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDrag = function (f) {
    return $foreign.unsafeMkProps("onDrag")(Effect_Uncurried.mkEffectFn1(f));
};
var onDoubleClickCapture = function (f) {
    return $foreign.unsafeMkProps("onDoubleClickCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDoubleClick = function (f) {
    return $foreign.unsafeMkProps("onDoubleClick")(Effect_Uncurried.mkEffectFn1(f));
};
var onCutCapture = function (f) {
    return $foreign.unsafeMkProps("onCutCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCut = function (f) {
    return $foreign.unsafeMkProps("onCut")(Effect_Uncurried.mkEffectFn1(f));
};
var onCopyCapture = function (f) {
    return $foreign.unsafeMkProps("onCopyCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCopy = function (f) {
    return $foreign.unsafeMkProps("onCopy")(Effect_Uncurried.mkEffectFn1(f));
};
var onContextMenuCapture = function (f) {
    return $foreign.unsafeMkProps("onContextMenuCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onContextMenu = function (f) {
    return $foreign.unsafeMkProps("onContextMenu")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionUpdateCapture = function (f) {
    return $foreign.unsafeMkProps("onCompositionUpdateCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionUpdate = function (f) {
    return $foreign.unsafeMkProps("onCompositionUpdate")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionStartCapture = function (f) {
    return $foreign.unsafeMkProps("onCompositionStartCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionStart = function (f) {
    return $foreign.unsafeMkProps("onCompositionStart")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionEndCapture = function (f) {
    return $foreign.unsafeMkProps("onCompositionEndCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionEnd = function (f) {
    return $foreign.unsafeMkProps("onCompositionEnd")(Effect_Uncurried.mkEffectFn1(f));
};
var onClickCapture = function (f) {
    return $foreign.unsafeMkProps("onClickCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onClick = function (f) {
    return $foreign.unsafeMkProps("onClick")(Effect_Uncurried.mkEffectFn1(f));
};
var onChangeCapture = function (f) {
    return $foreign.unsafeMkProps("onChangeCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onChange = function (f) {
    return $foreign.unsafeMkProps("onChange")(Effect_Uncurried.mkEffectFn1(f));
};
var onCanPlayThroughCapture = function (f) {
    return $foreign.unsafeMkProps("onCanPlayThroughCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCanPlayThrough = function (f) {
    return $foreign.unsafeMkProps("onCanPlayThrough")(Effect_Uncurried.mkEffectFn1(f));
};
var onCanPlayCapture = function (f) {
    return $foreign.unsafeMkProps("onCanPlayCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCanPlay = function (f) {
    return $foreign.unsafeMkProps("onCanPlay")(Effect_Uncurried.mkEffectFn1(f));
};
var onBlurCapture = function (f) {
    return $foreign.unsafeMkProps("onBlurCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onBlur = function (f) {
    return $foreign.unsafeMkProps("onBlur")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationStartCapture = function (f) {
    return $foreign.unsafeMkProps("onAnimationStartCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationStart = function (f) {
    return $foreign.unsafeMkProps("onAnimationStart")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationIterationCapture = function (f) {
    return $foreign.unsafeMkProps("onAnimationIterationCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationIteration = function (f) {
    return $foreign.unsafeMkProps("onAnimationIteration")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationEndCapture = function (f) {
    return $foreign.unsafeMkProps("onAnimationEndCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationEnd = function (f) {
    return $foreign.unsafeMkProps("onAnimationEnd")(Effect_Uncurried.mkEffectFn1(f));
};
var onAbortCapture = function (f) {
    return $foreign.unsafeMkProps("onAbortCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onAbort = function (f) {
    return $foreign.unsafeMkProps("onAbort")(Effect_Uncurried.mkEffectFn1(f));
};
var nonce = /* #__PURE__ */ $foreign.unsafeMkProps("nonce");
var noValidate = /* #__PURE__ */ $foreign.unsafeMkProps("noValidate");
var name = /* #__PURE__ */ $foreign.unsafeMkProps("name");
var muted = /* #__PURE__ */ $foreign.unsafeMkProps("muted");
var multiple = /* #__PURE__ */ $foreign.unsafeMkProps("multiple");
var minLength = /* #__PURE__ */ $foreign.unsafeMkProps("minLength");
var min = /* #__PURE__ */ $foreign.unsafeMkProps("min");
var method = /* #__PURE__ */ $foreign.unsafeMkProps("method");
var mediaGroup = /* #__PURE__ */ $foreign.unsafeMkProps("mediaGroup");
var media = /* #__PURE__ */ $foreign.unsafeMkProps("media");
var maxLength = /* #__PURE__ */ $foreign.unsafeMkProps("maxLength");
var max = /* #__PURE__ */ $foreign.unsafeMkProps("max");
var marginWidth = /* #__PURE__ */ $foreign.unsafeMkProps("marginWidth");
var marginHeight = /* #__PURE__ */ $foreign.unsafeMkProps("marginHeight");
var manifest = /* #__PURE__ */ $foreign.unsafeMkProps("manifest");
var low = /* #__PURE__ */ $foreign.unsafeMkProps("low");
var loop = /* #__PURE__ */ $foreign.unsafeMkProps("loop");
var list = /* #__PURE__ */ $foreign.unsafeMkProps("list");
var lang = /* #__PURE__ */ $foreign.unsafeMkProps("lang");
var label = /* #__PURE__ */ $foreign.unsafeMkProps("label");
var kind = /* #__PURE__ */ $foreign.unsafeMkProps("kind");
var keyType = /* #__PURE__ */ $foreign.unsafeMkProps("keyType");
var keyParams = /* #__PURE__ */ $foreign.unsafeMkProps("keyParams");
var key = /* #__PURE__ */ $foreign.unsafeMkProps("key");
var itemType = /* #__PURE__ */ $foreign.unsafeMkProps("itemType");
var itemScope = /* #__PURE__ */ $foreign.unsafeMkProps("itemScope");
var itemRef = /* #__PURE__ */ $foreign.unsafeMkProps("itemRef");
var itemProp = /* #__PURE__ */ $foreign.unsafeMkProps("itemProp");
var itemID = /* #__PURE__ */ $foreign.unsafeMkProps("itemID");
var is = /* #__PURE__ */ $foreign.unsafeMkProps("is");
var integrity = /* #__PURE__ */ $foreign.unsafeMkProps("integrity");
var inputMode = /* #__PURE__ */ $foreign.unsafeMkProps("inputMode");
var inlist = /* #__PURE__ */ $foreign.unsafeMkProps("inlist");
var icon = /* #__PURE__ */ $foreign.unsafeMkProps("icon");
var httpEquiv = /* #__PURE__ */ $foreign.unsafeMkProps("httpEquiv");
var htmlFor = /* #__PURE__ */ $foreign.unsafeMkProps("htmlFor");
var hrefLang = /* #__PURE__ */ $foreign.unsafeMkProps("hrefLang");
var href = /* #__PURE__ */ $foreign.unsafeMkProps("href");
var high = /* #__PURE__ */ $foreign.unsafeMkProps("high");
var hidden = /* #__PURE__ */ $foreign.unsafeMkProps("hidden");
var height = /* #__PURE__ */ $foreign.unsafeMkProps("height");
var headers = /* #__PURE__ */ $foreign.unsafeMkProps("headers");
var frameBorder = /* #__PURE__ */ $foreign.unsafeMkProps("frameBorder");
var formTarget = /* #__PURE__ */ $foreign.unsafeMkProps("formTarget");
var formNoValidate = /* #__PURE__ */ $foreign.unsafeMkProps("formNoValidate");
var formMethod = /* #__PURE__ */ $foreign.unsafeMkProps("formMethod");
var formEncType = /* #__PURE__ */ $foreign.unsafeMkProps("formEncType");
var formAction = /* #__PURE__ */ $foreign.unsafeMkProps("formAction");
var form = /* #__PURE__ */ $foreign.unsafeMkProps("form");
var fillOpacity = /* #__PURE__ */ $foreign.unsafeMkProps("fillOpacity");
var fill = /* #__PURE__ */ $foreign.unsafeMkProps("fill");
var encType = /* #__PURE__ */ $foreign.unsafeMkProps("encType");
var draggable = /* #__PURE__ */ $foreign.unsafeMkProps("draggable");
var download = /* #__PURE__ */ $foreign.unsafeMkProps("download");
var disabled = /* #__PURE__ */ $foreign.unsafeMkProps("disabled");
var dir = /* #__PURE__ */ $foreign.unsafeMkProps("dir");
var defer = /* #__PURE__ */ $foreign.unsafeMkProps("defer");
var defaultValue = /* #__PURE__ */ $foreign.unsafeMkProps("defaultValue");
var defaultChecked = /* #__PURE__ */ $foreign.unsafeMkProps("defaultChecked");
var $$default = /* #__PURE__ */ $foreign.unsafeMkProps("default");
var dateTime = /* #__PURE__ */ $foreign.unsafeMkProps("dateTime");
var datatype = /* #__PURE__ */ $foreign.unsafeMkProps("datatype");
var dangerouslySetInnerHTML = /* #__PURE__ */ $foreign.unsafeMkProps("dangerouslySetInnerHTML");
var d = /* #__PURE__ */ $foreign.unsafeMkProps("d");
var cy = /* #__PURE__ */ $foreign.unsafeMkProps("cy");
var cx = /* #__PURE__ */ $foreign.unsafeMkProps("cx");
var crossOrigin = /* #__PURE__ */ $foreign.unsafeMkProps("crossOrigin");
var coords = /* #__PURE__ */ $foreign.unsafeMkProps("coords");
var controls = /* #__PURE__ */ $foreign.unsafeMkProps("controls");
var contextMenu = /* #__PURE__ */ $foreign.unsafeMkProps("contextMenu");
var contentEditable = /* #__PURE__ */ $foreign.unsafeMkProps("contentEditable");
var content = /* #__PURE__ */ $foreign.unsafeMkProps("content");
var cols = /* #__PURE__ */ $foreign.unsafeMkProps("cols");
var color = /* #__PURE__ */ $foreign.unsafeMkProps("color");
var colSpan = /* #__PURE__ */ $foreign.unsafeMkProps("colSpan");
var className = /* #__PURE__ */ $foreign.unsafeMkProps("className");
var classID = /* #__PURE__ */ $foreign.unsafeMkProps("classID");
var cite = /* #__PURE__ */ $foreign.unsafeMkProps("cite");
var checked = /* #__PURE__ */ $foreign.unsafeMkProps("checked");
var charSet = /* #__PURE__ */ $foreign.unsafeMkProps("charSet");
var challenge = /* #__PURE__ */ $foreign.unsafeMkProps("checked");
var cellSpacing = /* #__PURE__ */ $foreign.unsafeMkProps("cellSpacing");
var cellPadding = /* #__PURE__ */ $foreign.unsafeMkProps("cellPadding");
var capture = /* #__PURE__ */ $foreign.unsafeMkProps("capture");
var autoSave = /* #__PURE__ */ $foreign.unsafeMkProps("autoSave");
var autoPlay = /* #__PURE__ */ $foreign.unsafeMkProps("autoPlay");
var autoFocus = /* #__PURE__ */ $foreign.unsafeMkProps("autoFocus");
var autoCorrect = /* #__PURE__ */ $foreign.unsafeMkProps("autoCorrect");
var autoComplete = /* #__PURE__ */ $foreign.unsafeMkProps("autoComplete");
var autoCapitalize = /* #__PURE__ */ $foreign.unsafeMkProps("autoCapitalize");
var async = /* #__PURE__ */ $foreign.unsafeMkProps("async");
var aria = /* #__PURE__ */ $foreign.unsafePrefixProps("aria-");
var alt = /* #__PURE__ */ $foreign.unsafeMkProps("alt");
var allowTransparency = /* #__PURE__ */ $foreign.unsafeMkProps("allowTransparency");
var allowFullScreen = /* #__PURE__ */ $foreign.unsafeMkProps("allowFullScreen");
var action = /* #__PURE__ */ $foreign.unsafeMkProps("action");
var accessKey = /* #__PURE__ */ $foreign.unsafeMkProps("accessKey");
var acceptCharset = /* #__PURE__ */ $foreign.unsafeMkProps("acceptCharset");
var accept = /* #__PURE__ */ $foreign.unsafeMkProps("accept");
var about = /* #__PURE__ */ $foreign.unsafeMkProps("about");
var _type = /* #__PURE__ */ $foreign.unsafeMkProps("type");
var _id = /* #__PURE__ */ $foreign.unsafeMkProps("id");
var _data = /* #__PURE__ */ $foreign.unsafePrefixProps("data-");
export {
    unsafeMkProps,
    unsafeUnfoldProps,
    unsafePrefixProps,
    unsafeFromPropsArray
} from "./foreign.js";
export {
    aria,
    _data,
    style,
    dangerouslySetInnerHTML,
    accept,
    acceptCharset,
    accessKey,
    action,
    allowFullScreen,
    allowTransparency,
    alt,
    async,
    autoComplete,
    autoFocus,
    autoPlay,
    capture,
    cellPadding,
    cellSpacing,
    charSet,
    challenge,
    checked,
    cite,
    classID,
    className,
    cols,
    colSpan,
    content,
    contentEditable,
    contextMenu,
    controls,
    coords,
    crossOrigin,
    dateTime,
    $$default as default,
    defaultChecked,
    defaultValue,
    defer,
    dir,
    disabled,
    download,
    draggable,
    encType,
    form,
    formAction,
    formEncType,
    formMethod,
    formNoValidate,
    formTarget,
    frameBorder,
    headers,
    height,
    hidden,
    high,
    href,
    hrefLang,
    htmlFor,
    httpEquiv,
    icon,
    _id,
    inputMode,
    integrity,
    is,
    key,
    keyParams,
    keyType,
    kind,
    label,
    lang,
    list,
    loop,
    low,
    manifest,
    marginHeight,
    marginWidth,
    max,
    maxLength,
    media,
    mediaGroup,
    method,
    min,
    minLength,
    multiple,
    muted,
    name,
    nonce,
    noValidate,
    open,
    optimum,
    pattern,
    placeholder,
    poster,
    preload,
    profile,
    radioGroup,
    readOnly,
    rel,
    required,
    reversed,
    role,
    rows,
    rowSpan,
    sandbox,
    scope,
    scoped,
    scrolling,
    seamless,
    selected,
    shape,
    size,
    sizes,
    span,
    spellCheck,
    src,
    srcDoc,
    srcLang,
    srcSet,
    start,
    step,
    summary,
    tabIndex,
    target,
    title,
    _type,
    useMap,
    value,
    valueArray,
    width,
    wmode,
    wrap,
    about,
    datatype,
    inlist,
    prefix,
    property,
    resource,
    $$typeof as typeof,
    vocab,
    autoCapitalize,
    autoCorrect,
    autoSave,
    color,
    itemProp,
    itemScope,
    itemType,
    itemID,
    itemRef,
    results,
    security,
    unselectable,
    onAnimationStart,
    onAnimationEnd,
    onAnimationIteration,
    onTransitionEnd,
    onToggle,
    onError,
    onLoad,
    onAbort,
    onCanPlay,
    onCanPlayThrough,
    onDurationChange,
    onEmptied,
    onEncrypted,
    onEnded,
    onLoadedData,
    onLoadedMetadata,
    onLoadStart,
    onPause,
    onPlay,
    onPlaying,
    onProgress,
    onRateChange,
    onSeeked,
    onSeeking,
    onStalled,
    onSuspend,
    onTimeUpdate,
    onVolumeChange,
    onWaiting,
    onCopy,
    onCut,
    onPaste,
    onCompositionEnd,
    onCompositionStart,
    onCompositionUpdate,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onFocus,
    onBlur,
    onChange,
    onInput,
    onInvalid,
    onSubmit,
    onClick,
    onContextMenu,
    onDoubleClick,
    onDrag,
    onDragEnd,
    onDragEnter,
    onDragExit,
    onDragLeave,
    onDragOver,
    onDragStart,
    onDrop,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    onMouseOut,
    onMouseOver,
    onMouseUp,
    onSelect,
    onTouchCancel,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    onScroll,
    onWheel,
    onAnimationStartCapture,
    onAnimationEndCapture,
    onAnimationIterationCapture,
    onTransitionEndCapture,
    onToggleCapture,
    onErrorCapture,
    onLoadCapture,
    onAbortCapture,
    onCanPlayCapture,
    onCanPlayThroughCapture,
    onDurationChangeCapture,
    onEmptiedCapture,
    onEncryptedCapture,
    onEndedCapture,
    onLoadedDataCapture,
    onLoadedMetadataCapture,
    onLoadStartCapture,
    onPauseCapture,
    onPlayCapture,
    onPlayingCapture,
    onProgressCapture,
    onRateChangeCapture,
    onSeekedCapture,
    onSeekingCapture,
    onStalledCapture,
    onSuspendCapture,
    onTimeUpdateCapture,
    onVolumeChangeCapture,
    onWaitingCapture,
    onCopyCapture,
    onCutCapture,
    onPasteCapture,
    onCompositionEndCapture,
    onCompositionStartCapture,
    onCompositionUpdateCapture,
    onKeyDownCapture,
    onKeyPressCapture,
    onKeyUpCapture,
    onFocusCapture,
    onBlurCapture,
    onChangeCapture,
    onInputCapture,
    onInvalidCapture,
    onSubmitCapture,
    onClickCapture,
    onContextMenuCapture,
    onDoubleClickCapture,
    onDragCapture,
    onDragEndCapture,
    onDragEnterCapture,
    onDragExitCapture,
    onDragLeaveCapture,
    onDragOverCapture,
    onDragStartCapture,
    onDropCapture,
    onMouseDownCapture,
    onMouseEnterCapture,
    onMouseLeaveCapture,
    onMouseMoveCapture,
    onMouseOutCapture,
    onMouseOverCapture,
    onMouseUpCapture,
    onSelectCapture,
    onTouchCancelCapture,
    onTouchEndCapture,
    onTouchMoveCapture,
    onTouchStartCapture,
    onScrollCapture,
    onWheelCapture,
    ref,
    suppressContentEditableWarning,
    x,
    y,
    cx,
    cy,
    r,
    fill,
    opacity,
    fillOpacity,
    stroke,
    strokeWidth,
    points,
    d,
    viewBox
};
