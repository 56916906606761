// Generated by purs version 0.15.7
import * as API_Pagination from "../API.Pagination/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Array_NonEmpty_Internal from "../Data.Array.NonEmpty.Internal/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_SelectionMap from "../Internal.SelectionMap/index.js";
import * as Translate from "../Translate/index.js";
import * as Widget_Pager from "../Widget.Pager/index.js";
import * as Widgets_BetterSelect from "../Widgets.BetterSelect/index.js";
import * as Widgets_Checkbox from "../Widgets.Checkbox/index.js";
import * as Widgets_Modal from "../Widgets.Modal/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var ordRNTY = /* #__PURE__ */ GraphQL_Type.ordRNTY(Data_Ord.ordString);
var selectionCheckbox = /* #__PURE__ */ Internal_SelectionMap.selectionCheckbox(ordRNTY);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var h2$prime = /* #__PURE__ */ Concur_React_DOM["h2$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var fromFoldable = /* #__PURE__ */ Data_Array.fromFoldable(Data_Array_NonEmpty_Internal.foldableNonEmptyArray);
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Data_Eq.eqString));
var h1$prime = /* #__PURE__ */ Concur_React_DOM["h1$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.bindWidget);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var show = /* #__PURE__ */ Data_Show.show($$Error.showErrorWrapper);
var selectAllCheckbox$prime = /* #__PURE__ */ Internal_SelectionMap["selectAllCheckbox$prime"](ordRNTY)(Data_Functor.functorArray)(Data_Foldable.foldableArray);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var p$prime = /* #__PURE__ */ Concur_React_DOM["p$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var nav = /* #__PURE__ */ Concur_React_DOM.nav(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Concur_Core_Types.bindWidget);
var ChangeTab = /* #__PURE__ */ (function () {
    function ChangeTab(value0) {
        this.value0 = value0;
    };
    ChangeTab.create = function (value0) {
        return new ChangeTab(value0);
    };
    return ChangeTab;
})();
var CourseChange = /* #__PURE__ */ (function () {
    function CourseChange(value0) {
        this.value0 = value0;
    };
    CourseChange.create = function (value0) {
        return new CourseChange(value0);
    };
    return CourseChange;
})();
var Selection = /* #__PURE__ */ (function () {
    function Selection(value0) {
        this.value0 = value0;
    };
    Selection.create = function (value0) {
        return new Selection(value0);
    };
    return Selection;
})();
var ChangePage = /* #__PURE__ */ (function () {
    function ChangePage(value0) {
        this.value0 = value0;
    };
    ChangePage.create = function (value0) {
        return new ChangePage(value0);
    };
    return ChangePage;
})();
var Done = /* #__PURE__ */ (function () {
    function Done() {

    };
    Done.value = new Done();
    return Done;
})();
var EPAction = /* #__PURE__ */ (function () {
    function EPAction(value0) {
        this.value0 = value0;
    };
    EPAction.create = function (value0) {
        return new EPAction(value0);
    };
    return EPAction;
})();
var LoadResult = /* #__PURE__ */ (function () {
    function LoadResult(value0) {
        this.value0 = value0;
    };
    LoadResult.create = function (value0) {
        return new LoadResult(value0);
    };
    return LoadResult;
})();
var selectedExpectations = function (state) {
    return Internal_SelectionMap.listSelected(state.selection);
};
var rankFilterEntry = Widgets_BetterSelect.rankByLabel;
var pager = function (page) {
    return map(ChangePage.create)(Widget_Pager.pagerWidget(page));
};
var initExpectationPicker = function (init) {
    var tab = function (v) {
        return {
            label: v.label,
            query: v.query,
            page: new Data_Either.Left(""),
            select: v.select
        };
    };
    var tabs = map1(tab)(init.tabs);
    return {
        selection: Internal_SelectionMap.empty,
        tabs: tabs,
        activeTab: 0
    };
};
var expectationCheck = function (state) {
    return function (exp) {
        return map(Selection.create)(selectionCheckbox(exp.id)(exp)(Widgets_Checkbox.labelCheckbox(GraphQL_Type.innerId(exp.id))(exp.description))(state.selection));
    };
};
var exListItem = function (state) {
    return function (exp) {
        return expectationCheck(state)(exp);
    };
};
var exSubCategory = function (state) {
    return function (exps) {
        var category = (Data_Array_NonEmpty.head(exps)).sub_category;
        return div([ Concur_React_Props.className("expectation-category") ])(append((function () {
            if (category instanceof Data_Maybe.Just) {
                return [ h2$prime([ text(category.value0) ]) ];
            };
            if (category instanceof Data_Maybe.Nothing) {
                return [  ];
            };
            throw new Error("Failed pattern match at Widgets.ExpectationPicker (line 223, column 6 - line 225, column 22): " + [ category.constructor.name ]);
        })())(map1(exListItem(state))(fromFoldable(exps))));
    };
};
var exCategory = function (state) {
    return function (exps) {
        var grouped = Data_Array.groupBy(function (a1) {
            return function (b) {
                return eq(a1.sub_category)(b.sub_category);
            };
        })(fromFoldable(exps));
        var category = (Data_Array_NonEmpty.head(exps)).category;
        return div([ Concur_React_Props.className("expectation-category") ])(append((function () {
            if (category instanceof Data_Maybe.Just) {
                return [ h1$prime([ text(category.value0) ]) ];
            };
            if (category instanceof Data_Maybe.Nothing) {
                return [  ];
            };
            throw new Error("Failed pattern match at Widgets.ExpectationPicker (line 210, column 6 - line 212, column 22): " + [ category.constructor.name ]);
        })())(map1(exSubCategory(state))(grouped)));
    };
};
var currentTab = function (state) {
    return fromJust(Data_Array.index(state.tabs)(state.activeTab));
};
var select$prime = function (state) {
    return map(CourseChange.create)(Widgets_BetterSelect.selectWidget((currentTab(state)).select));
};
var updateTab = function (state) {
    return function (update) {
        return bind(update(currentTab(state)))(function (newtab) {
            var v = Data_Array.updateAt(state.activeTab)(newtab)(state.tabs);
            if (v instanceof Data_Maybe.Just) {
                return pure({
                    tabs: v.value0,
                    activeTab: state.activeTab,
                    selection: state.selection
                });
            };
            if (v instanceof Data_Maybe.Nothing) {
                return discard(liftEffect(Effect_Console.log("Expectation picker current tab index out of bounds!!")))(function () {
                    return pure(state);
                });
            };
            throw new Error("Failed pattern match at Widgets.ExpectationPicker (line 253, column 5 - line 259, column 22): " + [ v.constructor.name ]);
        });
    };
};
var handle$prime = function (v) {
    return function (v1) {
        if (v1 instanceof Data_Either.Right) {
            return updateTab(v)(function (tab) {
                return pure({
                    page: new Data_Either.Right(v1.value0),
                    label: tab.label,
                    query: tab.query,
                    select: tab.select
                });
            });
        };
        if (v1 instanceof Data_Either.Left) {
            return updateTab(v)(function (tab) {
                return pure({
                    page: new Data_Either.Left("Error loading expectations: " + show(v1.value0)),
                    label: tab.label,
                    query: tab.query,
                    select: tab.select
                });
            });
        };
        throw new Error("Failed pattern match at Widgets.ExpectationPicker (line 147, column 1 - line 149, column 41): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var allCheck = function (page) {
    return function (state) {
        return map(Selection.create)(selectAllCheckbox$prime(API_Pagination.pageItems(page))(Widgets_Checkbox.labelCheckbox("expectations-select-all")(Translate.lookupTranslation("select-all")))(state.selection));
    };
};
var exList = function (page) {
    return function (state) {
        var grouped = Data_Array.groupBy(function (a1) {
            return function (b) {
                return eq(a1.category)(b.category);
            };
        })(API_Pagination.pageItems(page));
        return div([ Concur_React_Props.className("expectation-list") ])(append([ allCheck(page)(state) ])(map1(exCategory(state))(grouped)));
    };
};
var exDiv = function (v) {
    return function (v1) {
        if (v1.page instanceof Data_Either.Left) {
            return div$prime([ p$prime([ text(v1.page.value0) ]) ]);
        };
        if (v1.page instanceof Data_Either.Right) {
            return div([ Concur_React_Props.className("expectation-pager") ])([ exList(v1.page.value0)(v), pager(v1.page.value0) ]);
        };
        throw new Error("Failed pattern match at Widgets.ExpectationPicker (line 192, column 1 - line 192, column 108): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var curTabWidget = function (state) {
    return div([ Concur_React_Props.className("expectation-picker-body") ])([ select$prime(state), div([ Concur_React_Props.className("expectation-pager") ])([ exDiv(state)(currentTab(state)) ]) ]);
};
var renderWidget = function (state) {
    var tab = function (idx) {
        return function (t) {
            return a([ Concur_React_Props.className("tab" + (function () {
                var $96 = state.activeTab === idx;
                if ($96) {
                    return " active";
                };
                return "";
            })()), Concur_React_Props.href("javascript:void(0);"), voidRight(new ChangeTab(idx))(Concur_React_Props.onClick) ])([ text(t.label) ]);
        };
    };
    var tabs = nav([ Concur_React_Props.className("tabs") ])(Data_Array.mapWithIndex(tab)(state.tabs));
    var doneBtn = div([ Concur_React_Props.className("expectation-picker-buttons modal-buttons") ])([ button([ Concur_React_Props["_type"]("button"), Concur_React_Props.className("btn btn-primary"), voidRight(Done.value)(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("done")) ]) ]);
    return div([ Concur_React_Props.className("expectation-picker modal-grid"), Concur_React_Props["_id"]("expectation-picker") ])([ tabs, curTabWidget(state), doneBtn ]);
};
var loadPageChange = function (dictDecodeJson) {
    var changePage = API_Pagination.changePage(dictDecodeJson);
    return function (state) {
        return function (idx) {
            var v = (currentTab(state)).page;
            if (v instanceof Data_Either.Right) {
                return doLoadAction(dictDecodeJson)(state)(changePage(idx)(v.value0))(handle$prime(state));
            };
            if (v instanceof Data_Either.Left) {
                return pure(new Widgets_Modal.ModalReturned(state));
            };
            throw new Error("Failed pattern match at Widgets.ExpectationPicker (line 155, column 28 - line 157, column 41): " + [ v.constructor.name ]);
        };
    };
};
var loadExpectations = function (dictDecodeJson) {
    return function (state) {
        return function (selected) {
            return doLoadAction(dictDecodeJson)(state)((currentTab(state)).query(selected))(handle$prime(state));
        };
    };
};
var handleAction = function (dictDecodeJson) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof ChangeTab && (v1.value0 >= 0 && v1.value0 < Data_Array.length(v.tabs))) {
                var state$prime = {
                    activeTab: v1.value0,
                    selection: v.selection,
                    tabs: v.tabs
                };
                var curTab = currentTab(state$prime);
                var $102 = Data_Either.isRight(curTab.page);
                if ($102) {
                    return pure(new Widgets_Modal.ModalReturned(state$prime));
                };
                var v2 = Widgets_BetterSelect.selectedItem(curTab.select);
                if (v2 instanceof Data_Maybe.Just) {
                    return loadExpectations(dictDecodeJson)(state$prime)(v2.value0.value.id);
                };
                if (v2 instanceof Data_Maybe.Nothing) {
                    return pure(new Widgets_Modal.ModalReturned(state$prime));
                };
                throw new Error("Failed pattern match at Widgets.ExpectationPicker (line 105, column 14 - line 107, column 51): " + [ v2.constructor.name ]);
            };
            if (v1 instanceof ChangeTab) {
                return discard(liftEffect(Effect_Console.log("Warning: tab index out of range: " + show1(v1.value0))))(function () {
                    return pure(new Widgets_Modal.ModalReturned(v));
                });
            };
            if (v1 instanceof CourseChange) {
                return bind(updateTab(v)(function (tab) {
                    return pure({
                        select: v1.value0,
                        label: tab.label,
                        page: tab.page,
                        query: tab.query
                    });
                }))(function (newstate) {
                    var v2 = Widgets_BetterSelect.selectedItem(v1.value0);
                    if (v2 instanceof Data_Maybe.Just) {
                        return loadExpectations(dictDecodeJson)(newstate)(v2.value0.value.id);
                    };
                    if (v2 instanceof Data_Maybe.Nothing) {
                        return pure(new Widgets_Modal.ModalReturned(newstate));
                    };
                    throw new Error("Failed pattern match at Widgets.ExpectationPicker (line 115, column 5 - line 117, column 49): " + [ v2.constructor.name ]);
                });
            };
            if (v1 instanceof ChangePage) {
                return loadPageChange(dictDecodeJson)(v)(v1.value0);
            };
            if (v1 instanceof Selection) {
                return pure(new Widgets_Modal.ModalReturned({
                    selection: v1.value0,
                    activeTab: v.activeTab,
                    tabs: v.tabs
                }));
            };
            if (v1 instanceof Done) {
                return pure(Widgets_Modal.ModalClosed.value);
            };
            throw new Error("Failed pattern match at Widgets.ExpectationPicker (line 95, column 1 - line 98, column 55): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var doLoadAction = function (dictDecodeJson) {
    return function (state) {
        return function (action) {
            return function (handle) {
                return bind(updateTab(state)(function (tab) {
                    return pure({
                        page: new Data_Either.Left("Loading..."),
                        label: tab.label,
                        query: tab.query,
                        select: tab.select
                    });
                }))(function (loadState) {
                    return bind(alt(map(EPAction.create)(renderWidget(loadState)))(map(LoadResult.create)(liftAff(action))))(function (act) {
                        if (act instanceof EPAction) {
                            return handleAction(dictDecodeJson)(state)(act.value0);
                        };
                        if (act instanceof LoadResult) {
                            return map(Widgets_Modal.ModalReturned.create)(handle(act.value0));
                        };
                        throw new Error("Failed pattern match at Widgets.ExpectationPicker (line 139, column 5 - line 141, column 51): " + [ act.constructor.name ]);
                    });
                });
            };
        };
    };
};
var runExpectationPicker = function (dictDecodeJson) {
    var handleAction1 = handleAction(dictDecodeJson);
    return function (state) {
        return bindFlipped(handleAction1(state))(renderWidget(state));
    };
};
var expectationPicker = function (dictDecodeJson) {
    return Widgets_Modal.loopModal(div$prime([  ]))(runExpectationPicker(dictDecodeJson));
};
export {
    expectationPicker,
    initExpectationPicker,
    selectedExpectations,
    rankFilterEntry
};
