// Generated by purs version 0.15.7
import * as API_Account from "../API.Account/index.js";
import * as API_Pagination from "../API.Pagination/index.js";
import * as API_Search from "../API.Search/index.js";
import * as API_SelStudent from "../API.SelStudent/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Patterns from "../Concur.Core.Patterns/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Compound from "../GraphQL.Compound/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as Internal_Luxon from "../Internal.Luxon/index.js";
import * as Internal_SelectionMap from "../Internal.SelectionMap/index.js";
import * as Internal_Util from "../Internal.Util/index.js";
import * as Steps_Step from "../Steps.Step/index.js";
import * as Steps_WorkQueue from "../Steps.WorkQueue/index.js";
import * as Translate from "../Translate/index.js";
import * as Widget_Pager from "../Widget.Pager/index.js";
import * as Widgets_Checkbox from "../Widgets.Checkbox/index.js";
import * as Widgets_Export from "../Widgets.Export/index.js";
import * as Widgets_HiddenSelect from "../Widgets.HiddenSelect/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
import * as Widgets_Modal from "../Widgets.Modal/index.js";
import * as Widgets_Search from "../Widgets.Search/index.js";
var ordRNTY = /* #__PURE__ */ GraphQL_Type.ordRNTY(Data_Ord.ordString);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var selectAll$prime = /* #__PURE__ */ Internal_SelectionMap["selectAll$prime"](ordRNTY)(Data_Functor.functorArray)(Data_Foldable.foldableArray);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var selectAllCheckbox$prime = /* #__PURE__ */ Internal_SelectionMap["selectAllCheckbox$prime"](ordRNTY)(Data_Functor.functorArray)(Data_Foldable.foldableArray);
var select$prime = /* #__PURE__ */ Internal_SelectionMap["select$prime"](ordRNTY);
var bind = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var gDecodeJsonCons = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString));
var nameIsSymbol = {
    reflectSymbol: function () {
        return "name";
    }
};
var changePage = /* #__PURE__ */ API_Pagination.changePage(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ GraphQL_Type.decRNTY(Data_Argonaut_Decode_Class.decodeJsonString)))(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldMaybe(Internal_Luxon.decodeLDT))(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonString))(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons(Data_Argonaut_Decode_Class.gDecodeJsonNil)(nameIsSymbol)()())({
    reflectSymbol: function () {
        return "color";
    }
})()())())))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
    reflectSymbol: function () {
        return "tags";
    }
})()())({
    reflectSymbol: function () {
        return "preferred_name";
    }
})()())(nameIsSymbol)()())({
    reflectSymbol: function () {
        return "last_imported_at";
    }
})()())({
    reflectSymbol: function () {
        return "id";
    }
})()())());
var span = /* #__PURE__ */ Concur_React_DOM.span(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var selectionCheckbox = /* #__PURE__ */ Internal_SelectionMap.selectionCheckbox(ordRNTY);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var section = /* #__PURE__ */ Concur_React_DOM.section(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var show = /* #__PURE__ */ Data_Show.show($$Error.showErrorWrapper);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var liftAff1 = /* #__PURE__ */ Effect_Aff_Class.liftAff(Effect_Aff_Class.monadAffAff);
var isSelected1 = /* #__PURE__ */ Internal_SelectionMap.isSelected(ordRNTY);
var deselect1 = /* #__PURE__ */ Internal_SelectionMap.deselect(ordRNTY);
var voidRight1 = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Types.functorWidget);
var h1 = /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var loopState = /* #__PURE__ */ Concur_Core_Patterns.loopState(Concur_Core_Types.widgetMonad);
var bind2 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var runGraphQL1 = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Compound.cqGQL);
var pure2 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var errorModalWidget = /* #__PURE__ */ Widgets_Modal.errorModalWidget($$Error.showErrorWrapper);
var notEq = /* #__PURE__ */ Data_Eq.notEq(API_Account.eqAccType);
var isEmpty = /* #__PURE__ */ Internal_SelectionMap.isEmpty(ordRNTY);
var header = /* #__PURE__ */ Concur_React_DOM.header(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var p$prime = /* #__PURE__ */ Concur_React_DOM["p$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map4 = /* #__PURE__ */ Data_Functor.map(Steps_Step.wrFunctor);
var areAllSelected1 = /* #__PURE__ */ Internal_SelectionMap.areAllSelected(ordRNTY)(Data_Foldable.foldableArray);
var SNormal = /* #__PURE__ */ (function () {
    function SNormal() {

    };
    SNormal.value = new SNormal();
    return SNormal;
})();
var SLoading = /* #__PURE__ */ (function () {
    function SLoading() {

    };
    SLoading.value = new SLoading();
    return SLoading;
})();
var SError = /* #__PURE__ */ (function () {
    function SError(value0) {
        this.value0 = value0;
    };
    SError.create = function (value0) {
        return new SError(value0);
    };
    return SError;
})();
var StateChange = /* #__PURE__ */ (function () {
    function StateChange(value0) {
        this.value0 = value0;
    };
    StateChange.create = function (value0) {
        return new StateChange(value0);
    };
    return StateChange;
})();
var WorkQA = /* #__PURE__ */ (function () {
    function WorkQA(value0) {
        this.value0 = value0;
    };
    WorkQA.create = function (value0) {
        return new WorkQA(value0);
    };
    return WorkQA;
})();
var Done = /* #__PURE__ */ (function () {
    function Done() {

    };
    Done.value = new Done();
    return Done;
})();
var Cancel = /* #__PURE__ */ (function () {
    function Cancel() {

    };
    Cancel.value = new Cancel();
    return Cancel;
})();
var Export = /* #__PURE__ */ (function () {
    function Export() {

    };
    Export.value = new Export();
    return Export;
})();
var ChangeSearch = /* #__PURE__ */ (function () {
    function ChangeSearch(value0) {
        this.value0 = value0;
    };
    ChangeSearch.create = function (value0) {
        return new ChangeSearch(value0);
    };
    return ChangeSearch;
})();
var ChangePage = /* #__PURE__ */ (function () {
    function ChangePage(value0) {
        this.value0 = value0;
    };
    ChangePage.create = function (value0) {
        return new ChangePage(value0);
    };
    return ChangePage;
})();
var QueryResult = /* #__PURE__ */ (function () {
    function QueryResult(value0) {
        this.value0 = value0;
    };
    QueryResult.create = function (value0) {
        return new QueryResult(value0);
    };
    return QueryResult;
})();
var QueryFailed = /* #__PURE__ */ (function () {
    function QueryFailed(value0) {
        this.value0 = value0;
    };
    QueryFailed.create = function (value0) {
        return new QueryFailed(value0);
    };
    return QueryFailed;
})();
var updatePage = function (page) {
    return function (state) {
        return {
            page: new Data_Maybe.Just(page),
            students: state.students,
            search: state.search,
            status: state.status,
            account: state.account,
            msg: state.msg
        };
    };
};
var studentsToMap = /* #__PURE__ */ (function () {
    var $219 = Data_Map_Internal.fromFoldable(ordRNTY)(Data_Foldable.foldableArray);
    var $220 = map(function (s) {
        return new Data_Tuple.Tuple(s.id, s);
    });
    return function ($221) {
        return $219($220($221));
    };
})();
var setAllSelected = function (v) {
    return function (v1) {
        if (v1.page instanceof Data_Maybe.Just) {
            return {
                page: v1.page,
                students: selectAll$prime(API_Pagination.pageItems(v1.page.value0))(v1.students)(v),
                search: v1.search,
                status: v1.status,
                account: v1.account,
                msg: v1.msg
            };
        };
        if (v1.page instanceof Data_Maybe.Nothing) {
            return v1;
        };
        throw new Error("Failed pattern match at Form.StudentSelect (line 107, column 1 - line 107, column 70): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var selectAllCheck_ = function (val) {
    return div([ Concur_React_Props.className("student-sel-check") ])([ Widgets_Checkbox.labelCheckbox("select-all")(Translate.lookupTranslation("select-all"))(val) ]);
};
var selectAllCheck = function (v) {
    if (v.page instanceof Data_Maybe.Just) {
        return map1(function (v1) {
            return {
                students: v1,
                account: v.account,
                msg: v.msg,
                page: v.page,
                search: v.search,
                status: v.status
            };
        })(selectAllCheckbox$prime(API_Pagination.pageItems(v.page.value0))(selectAllCheck_)(v.students));
    };
    if (v.page instanceof Data_Maybe.Nothing) {
        return Internal_ConcurUtil.loopInfinitely(selectAllCheck_(false));
    };
    throw new Error("Failed pattern match at Form.StudentSelect (line 359, column 1 - line 359, column 71): " + [ v.constructor.name ]);
};
var select = function (student) {
    return function (state) {
        return {
            page: state.page,
            students: select$prime(student)(state.students),
            search: state.search,
            status: state.status,
            account: state.account,
            msg: state.msg
        };
    };
};
var runSearch = function (state) {
    return bind(runGraphQL(API_SelStudent.searchStudents(Widgets_Search.stateToQuery(state.search))))(function (result) {
        if (result instanceof Data_Either.Left) {
            return pure(new QueryFailed(result.value0));
        };
        if (result instanceof Data_Either.Right) {
            return pure(new QueryResult(result.value0));
        };
        throw new Error("Failed pattern match at Form.StudentSelect (line 311, column 5 - line 313, column 46): " + [ result.constructor.name ]);
    });
};
var runPageChange = function (v) {
    return function (v1) {
        if (v.page instanceof Data_Maybe.Just) {
            return bind(changePage(v1)(v.page.value0))(function (result) {
                if (result instanceof Data_Either.Left) {
                    return pure(new QueryFailed(result.value0));
                };
                if (result instanceof Data_Either.Right) {
                    return pure(new QueryResult(result.value0));
                };
                throw new Error("Failed pattern match at Form.StudentSelect (line 318, column 5 - line 320, column 49): " + [ result.constructor.name ]);
            });
        };
        return pure(new QueryFailed(new $$Error.StringError("Attempted to change page when no page was loaded.")));
    };
};
var renderTags = function (v) {
    var renderTag = function (v1) {
        return span([ Concur_React_Props.className("student-tag"), Concur_React_Props.style({
            backgroundColor: v1.color
        }) ])([ text(v1.name) ]);
    };
    return div([ Concur_React_Props.className("student-tags") ])(map(renderTag)(v.tags));
};
var studentCheck = function (student) {
    return function (state) {
        var name = API_SelStudent.studentName(state.account)(student);
        return map1(function (v) {
            return {
                students: v,
                account: state.account,
                msg: state.msg,
                page: state.page,
                search: state.search,
                status: state.status
            };
        })(div([ Concur_React_Props.className("student-sel-check") ])([ selectionCheckbox(student.id)(student)(Widgets_Checkbox.labelCheckbox(GraphQL_Type.innerId(student.id))(name))(state.students), renderTags(API_SelStudent.tagImported(student)) ]));
    };
};
var studentList = function (v) {
    if (v.page instanceof Data_Maybe.Nothing) {
        return Widgets_Loading.loadingWidget;
    };
    if (v.page instanceof Data_Maybe.Just && Data_Array.length(API_Pagination.pageItems(v.page.value0)) > 0) {
        var studentChecks = map(function (st) {
            return studentCheck(st)(v);
        })(API_Pagination.pageItems(v.page.value0));
        var pager = map1(map2(Data_Either.Right.create)(ChangePage.create))(Widget_Pager.pagerWidget(v.page.value0));
        var checkboxes = Data_Array.cons(map1(Data_Either.Left.create)(selectAllCheck(v)))(map(map1(Data_Either.Left.create))(studentChecks));
        return div([ Concur_React_Props.className("student-select") ])(append(checkboxes)([ pager ]));
    };
    if (Widgets_Search.hasFiltersSet(v.search)) {
        return div([ Concur_React_Props.className("student-select") ])([ text(Translate.lookupTranslation("nothing-here-clearable")), button([ Concur_React_Props.className("btn btn-primary"), voidRight(new Data_Either.Right(new ChangeSearch(Widgets_Search.initSearchState)))(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("clear-filters")) ]) ]);
    };
    return div([ Concur_React_Props.className("student-select") ])([ text(Translate.lookupTranslation("nothing-here")) ]);
};
var renderStudentSelect = function (state) {
    return section([ Concur_React_Props.className("select-students") ])([ div([ Concur_React_Props.className("recent-form-group heading") ])([ map1(map2(Data_Either.Right.create)(ChangeSearch.create))(Widgets_Search.searchWidget(state.search)), (function () {
        if (state.status instanceof SNormal) {
            return studentList(state);
        };
        if (state.status instanceof SLoading) {
            return Widgets_Loading.loadingWidget;
        };
        if (state.status instanceof SError) {
            return text(show(state.status.value0));
        };
        throw new Error("Failed pattern match at Form.StudentSelect (line 328, column 11 - line 331, column 44): " + [ state.status.constructor.name ]);
    })() ]) ]);
};
var renderLoading = function (state) {
    return function (load) {
        return bind1(alt(map1(Data_Either.Right.create)(renderStudentSelect({
            page: state.page,
            students: state.students,
            search: state.search,
            status: SLoading.value,
            account: state.account,
            msg: state.msg
        })))(map1(Data_Either.Left.create)(liftAff(load))))(function (res) {
            if (res instanceof Data_Either.Right) {
                return pure1(res.value0);
            };
            if (res instanceof Data_Either.Left) {
                return pure1(new Data_Either.Right(res.value0));
            };
            throw new Error("Failed pattern match at Form.StudentSelect (line 302, column 5 - line 304, column 39): " + [ res.constructor.name ]);
        });
    };
};
var refreshSearchWidget = function (state) {
    return renderLoading(state)(liftAff1(runSearch(state)));
};
var pageChangeWidget = function (state) {
    return function (idx) {
        return renderLoading(state)(runPageChange(state)(idx));
    };
};
var isSelected = function (student) {
    return function (state) {
        return isSelected1(student.id)(state.students);
    };
};
var initState$prime = function (search) {
    return function (sdata) {
        return {
            page: Data_Maybe.Nothing.value,
            students: Internal_SelectionMap.empty,
            search: Data_Maybe.fromMaybe(Widgets_Search.loadSearchState(sdata))(search),
            status: SNormal.value,
            msg: Data_Maybe.Nothing.value,
            account: {
                type: API_Account.TeacherAccount.value,
                student: Data_Maybe.Nothing.value
            }
        };
    };
};
var initState = function (workQ) {
    return {
        page: Data_Maybe.Nothing.value,
        students: workQ.students,
        search: Widgets_Search.loadSearchState({
            teachers: workQ.searchData.teachers,
            semesters: workQ.searchData.semesters,
            classes: workQ.searchData.classes,
            defaults: {
                teacher: Data_Maybe.Nothing.value,
                semester: workQ.searchData.defaults.semester,
                group: Data_Maybe.Nothing.value
            }
        }),
        status: SNormal.value,
        msg: Data_Maybe.Nothing.value,
        account: workQ.account
    };
};
var deselect = function (student) {
    return function (state) {
        return {
            page: state.page,
            students: deselect1(student.id)(state.students),
            search: state.search,
            status: state.status,
            account: state.account,
            msg: state.msg
        };
    };
};
var debounceDelay = 300.0;
var handleAction = function (v) {
    return function (v1) {
        if (v1 instanceof ChangeSearch) {
            var state = {
                search: v1.value0,
                account: v.account,
                msg: v.msg,
                page: v.page,
                status: v.status,
                students: v.students
            };
            var handleResult = function (v2) {
                if (v2 instanceof Data_Either.Right) {
                    return handleAction(state)(v2.value0);
                };
                if (v2 instanceof Data_Either.Left) {
                    return pure1(v2.value0);
                };
                throw new Error("Failed pattern match at Form.StudentSelect (line 267, column 24 - line 269, column 41): " + [ v2.constructor.name ]);
            };
            return bind1(alt(voidRight1(Data_Maybe.Nothing.value)(liftAff(Effect_Aff.delay(debounceDelay))))(map1(Data_Maybe.Just.create)(renderStudentSelect(state))))(function (v2) {
                if (v2 instanceof Data_Maybe.Nothing) {
                    return bind1(refreshSearchWidget(state))(handleResult);
                };
                if (v2 instanceof Data_Maybe.Just) {
                    return handleResult(v2.value0);
                };
                throw new Error("Failed pattern match at Form.StudentSelect (line 272, column 5 - line 276, column 39): " + [ v2.constructor.name ]);
            });
        };
        if (v1 instanceof ChangePage) {
            return bind1(pageChangeWidget(v)(v1.value0))(function (v2) {
                if (v2 instanceof Data_Either.Right) {
                    return handleAction(v)(v2.value0);
                };
                if (v2 instanceof Data_Either.Left) {
                    return pure1(v2.value0);
                };
                throw new Error("Failed pattern match at Form.StudentSelect (line 277, column 78 - line 279, column 31): " + [ v2.constructor.name ]);
            });
        };
        if (v1 instanceof QueryResult) {
            return pure1(updatePage(v1.value0)({
                page: v.page,
                students: v.students,
                search: v.search,
                status: SNormal.value,
                account: v.account,
                msg: v.msg
            }));
        };
        if (v1 instanceof QueryFailed) {
            return bind1(liftAff($$Error.reportError(v1.value0)))(function () {
                return pure1({
                    status: new SError(v1.value0),
                    account: v.account,
                    msg: v.msg,
                    page: v.page,
                    search: v.search,
                    students: v.students
                });
            });
        };
        throw new Error("Failed pattern match at Form.StudentSelect (line 264, column 1 - line 264, column 92): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var studentSelectWidget = function (state) {
    return bind1(renderStudentSelect(state))(function (v) {
        if (v instanceof Data_Either.Left) {
            return pure1(v.value0);
        };
        if (v instanceof Data_Either.Right) {
            return handleAction(state)(v.value0);
        };
        throw new Error("Failed pattern match at Form.StudentSelect (line 258, column 59 - line 260, column 40): " + [ v.constructor.name ]);
    });
};
var studentSelectModalWidget = function (msg) {
    return function (state) {
        var actionBtns = div([ Concur_React_Props.className("btn-group modal-buttons") ])([ button([ voidRight(new Data_Either.Right(Widgets_Modal.ModalClosed.value))(Concur_React_Props.onClick), Concur_React_Props.className("btn") ])([ text(Translate.lookupTranslation("cancel")) ]), button([ voidRight(new Data_Either.Right(new Widgets_Modal.ModalReturned(state)))(Concur_React_Props.onClick), Concur_React_Props.className("btn btn-primary") ])([ text(Translate.lookupTranslation("copy-forms")) ]) ]);
        return div([ Concur_React_Props.className("student-select-wrapper modal-grid") ])([ h1([ Concur_React_Props.className("modal-heading") ])([ text(msg) ]), div([ Concur_React_Props.className("modal-body") ])([ map1(Data_Either.Left.create)(studentSelectWidget(state)) ]), actionBtns ]);
    };
};
var runSelectStudentModal = function (msg) {
    return function (state) {
        return loopState(state)(studentSelectModalWidget(msg));
    };
};
var selectStudentModal = function (outer) {
    return function (msg) {
        return function (search) {
            return Widgets_Modal["modalWidget$prime"](outer)(bind1(alt(Widgets_Loading.loadingWidget)(liftAff(Control_Monad_Except_Trans.runExceptT(bind2(runGraphQL1(API_Search.searchDataQuery))(function (sdata) {
                var state = initState$prime(search)(sdata);
                return bind2(runGraphQL(API_SelStudent.searchStudents(Widgets_Search.stateToQuery(state.search))))(function (page) {
                    return pure2({
                        page: new Data_Maybe.Just(page),
                        account: state.account,
                        msg: state.msg,
                        search: state.search,
                        status: state.status,
                        students: state.students
                    });
                });
            })))))(function (result) {
                if (result instanceof Data_Either.Right) {
                    return runSelectStudentModal(msg)(result.value0);
                };
                if (result instanceof Data_Either.Left) {
                    return errorModalWidget(result.value0);
                };
                throw new Error("Failed pattern match at Form.StudentSelect (line 218, column 5 - line 220, column 41): " + [ result.constructor.name ]);
            }));
        };
    };
};
var studentSelectStepWidget = function (workQ) {
    return function (state) {
        var notBoardAcc = notEq(workQ.account.type)(API_Account.BoardAccount.value);
        var actionBtns = div([ Concur_React_Props.className("btn-group") ])(Data_Array.catMaybes([ new Data_Maybe.Just(button([ Concur_React_Props.className("btn cancel"), voidRight(Cancel.value)(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("cancel")) ])), Internal_Util.justIf(!Steps_WorkQueue.isWorkQueueEmpty(workQ))(button([ Concur_React_Props.className("btn export"), voidRight(Export.value)(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("export-pdf")) ])), new Data_Maybe.Just(button([ Concur_React_Props.className("btn btn-primary"), Concur_React_Props.disabled(isEmpty(state.students)), voidRight(Done.value)(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("next")) ])) ]));
        return div([ Concur_React_Props.className("step-container step-2a") ])(Data_Array.catMaybes([ new Data_Maybe.Just(header([ Concur_React_Props.className("page-header") ])([ h1([ Concur_React_Props.className("page-heading") ])([ text(Translate.lookupTranslation("select-students")) ]), actionBtns ])), new Data_Maybe.Just(map1(WorkQA.create)(Steps_WorkQueue.workQueueWidget(workQ))), map3(function (msg) {
            return div([ Concur_React_Props.className("error") ])([ text(msg) ]);
        })(state.msg), new Data_Maybe.Just(div([ Concur_React_Props.className("teacher-semester-selections") ])(Data_Array.catMaybes([ new Data_Maybe.Just(p$prime([ text(Translate.lookupTranslation("semester-hidden-select")) ])), new Data_Maybe.Just(map1(map2(map2(WorkQA.create)(Steps_WorkQueue.StateChange.create))(function (v) {
            return {
                semester: v,
                account: workQ.account,
                currentDoc: workQ.currentDoc,
                defSemester: workQ.defSemester,
                defTeacher: workQ.defTeacher,
                documents: workQ.documents,
                editDocs: workQ.editDocs,
                expandNext: workQ.expandNext,
                formCache: workQ.formCache,
                forms: workQ.forms,
                loaded: workQ.loaded,
                search: workQ.search,
                searchData: workQ.searchData,
                students: workQ.students,
                teacher: workQ.teacher
            };
        }))(Widgets_HiddenSelect.hiddenSelect(workQ.semester))), Internal_Util.justIf(notBoardAcc)(p$prime([ text(Translate.lookupTranslation("teacher-hidden-select")) ])), Internal_Util.justIf(notBoardAcc)(map1(map2(map2(WorkQA.create)(Steps_WorkQueue.StateChange.create))(function (v) {
            return {
                teacher: v,
                account: workQ.account,
                currentDoc: workQ.currentDoc,
                defSemester: workQ.defSemester,
                defTeacher: workQ.defTeacher,
                documents: workQ.documents,
                editDocs: workQ.editDocs,
                expandNext: workQ.expandNext,
                formCache: workQ.formCache,
                forms: workQ.forms,
                loaded: workQ.loaded,
                search: workQ.search,
                searchData: workQ.searchData,
                semester: workQ.semester,
                students: workQ.students
            };
        }))(Widgets_HiddenSelect.hiddenSelect(workQ.teacher))) ]))), new Data_Maybe.Just(div([ Concur_React_Props.className("step-2a-right") ])([ map1(StateChange.create)(studentSelectWidget(state)) ])) ]));
    };
};
var runSelectStudentStep = function (workQ) {
    return function (state) {
        return bind1(studentSelectStepWidget(workQ)(state))(function (v) {
            if (v instanceof StateChange) {
                return runSelectStudentStep({
                    forms: workQ.forms,
                    formCache: workQ.formCache,
                    students: v.value0.students,
                    documents: workQ.documents,
                    editDocs: workQ.editDocs,
                    currentDoc: workQ.currentDoc,
                    expandNext: workQ.expandNext,
                    search: workQ.search,
                    searchData: workQ.searchData,
                    semester: workQ.semester,
                    teacher: workQ.teacher,
                    defTeacher: workQ.defTeacher,
                    defSemester: workQ.defSemester,
                    account: workQ.account,
                    loaded: workQ.loaded
                })(v.value0);
            };
            if (v instanceof WorkQA && v.value0 instanceof Steps_WorkQueue.StateChange) {
                return runSelectStudentStep(v.value0.value0)(state);
            };
            if (v instanceof WorkQA) {
                return runSelectStudentStep(workQ)(state);
            };
            if (v instanceof Done && Steps_WorkQueue.formForSelected(workQ)) {
                return pure1(new Steps_Step.WidgetResult(Steps_Step.NavNext.value, workQ));
            };
            if (v instanceof Done) {
                return runSelectStudentStep(workQ)({
                    page: state.page,
                    students: state.students,
                    search: state.search,
                    status: state.status,
                    account: state.account,
                    msg: new Data_Maybe.Just(Translate.lookupTranslation("must-select-teacher-semester"))
                });
            };
            if (v instanceof Cancel) {
                return pure1(new Steps_Step.WidgetResult(Steps_Step.NavCancel.value, workQ));
            };
            if (v instanceof Export && Steps_WorkQueue.formForSelected(workQ)) {
                return bind1(Widgets_Export.exportAllWidget(workQ))(function () {
                    return pure1(new Steps_Step.WidgetResult(Steps_Step.NavCancel.value, workQ));
                });
            };
            if (v instanceof Export) {
                return runSelectStudentStep(workQ)({
                    page: state.page,
                    students: state.students,
                    search: state.search,
                    status: state.status,
                    account: state.account,
                    msg: new Data_Maybe.Just(Translate.lookupTranslation("must-select-teacher-semester"))
                });
            };
            throw new Error("Failed pattern match at Form.StudentSelect (line 142, column 76 - line 154, column 87): " + [ v.constructor.name ]);
        });
    };
};
var selectStudentStep = function (stepState) {
    var wrapResult = function (workQ) {
        return {
            step: stepState.step,
            workQueue: workQ,
            pushStateInterface: stepState.pushStateInterface,
            preselectForms: stepState.preselectForms
        };
    };
    return bind1(alt(Steps_WorkQueue.loadingWidgetWQ(stepState.workQueue))(liftAff(Control_Monad_Except_Trans.runExceptT((function () {
        var state = initState(stepState.workQueue);
        return bind2(runGraphQL(API_SelStudent.searchStudents(Widgets_Search.stateToQuery(state.search))))(function (page) {
            return pure2({
                page: new Data_Maybe.Just(page),
                account: state.account,
                msg: state.msg,
                search: state.search,
                status: state.status,
                students: state.students
            });
        });
    })()))))(function (result) {
        if (result instanceof Data_Either.Left) {
            return $$Error.handleError(stepState)(result.value0);
        };
        if (result instanceof Data_Either.Right) {
            return map1(map4(wrapResult))(runSelectStudentStep(stepState.workQueue)(result.value0));
        };
        throw new Error("Failed pattern match at Form.StudentSelect (line 133, column 5 - line 135, column 76): " + [ result.constructor.name ]);
    });
};
var areAllSelected = function (v) {
    if (v.page instanceof Data_Maybe.Just) {
        return areAllSelected1(map(function (v1) {
            return v1.id;
        })(API_Pagination.pageItems(v.page.value0)))(v.students);
    };
    return false;
};
export {
    debounceDelay,
    SNormal,
    SLoading,
    SError,
    initState,
    initState$prime,
    updatePage,
    select,
    deselect,
    isSelected,
    areAllSelected,
    studentsToMap,
    setAllSelected,
    ChangeSearch,
    ChangePage,
    QueryResult,
    QueryFailed,
    StateChange,
    WorkQA,
    Done,
    Cancel,
    Export,
    selectStudentStep,
    runSelectStudentStep,
    studentSelectStepWidget,
    selectStudentModal,
    runSelectStudentModal,
    studentSelectModalWidget,
    studentSelectWidget,
    handleAction,
    refreshSearchWidget,
    pageChangeWidget,
    renderLoading,
    runSearch,
    runPageChange,
    renderStudentSelect,
    studentList,
    selectAllCheck,
    selectAllCheck_,
    studentCheck,
    renderTags
};
