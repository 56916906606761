// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Promise from "../Control.Promise/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as React from "../React/index.js";
import * as React_DOM_Props from "../React.DOM.Props/index.js";
var img = /* #__PURE__ */ Concur_React_DOM.img(Concur_Core_LiftWidget.widgetLiftWidget);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var signaturePad = function (dictShiftMap) {
    var el$prime = Concur_React_DOM["el$prime"](dictShiftMap);
    return function (dictMultiAlternative) {
        var el$prime1 = el$prime(dictMultiAlternative);
        return function (props) {
            return el$prime1((function () {
                var $7 = React.unsafeCreateElement($foreign.classSignaturePad);
                return function ($8) {
                    return $7(React_DOM_Props.unsafeFromPropsArray($8));
                };
            })())(props)([  ]);
        };
    };
};
var signatureImage = function (props) {
    return function (sig) {
        return img(append(props)([ Concur_React_Props.src($foreign.signatureDataUrl(sig)) ]));
    };
};
var initialSignature = /* #__PURE__ */ Concur_React_Props.unsafeMkProp("sigBlob");
var getCanvasBlob = function (id) {
    return Control_Promise.toAff($foreign.getCanvasBlobFromId(id));
};
export {
    classSignaturePad,
    getCanvasBlobFromId,
    signatureDataUrl
} from "./foreign.js";
export {
    signaturePad,
    initialSignature,
    getCanvasBlob,
    signatureImage
};
