"use strict";

function getEndpoint() {
    if (window.graphqlEndpoint === undefined) {
        return process.env.GRAPHQL_ENDPOINT
    } else {
        return window.graphqlEndpoint
    }
}

export const graphqlEndpoint = getEndpoint()
