// Generated by purs version 0.15.7
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Symbol from "../Data.Symbol/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeJsonJString);
var map = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var RenamedType = /* #__PURE__ */ (function () {
    function RenamedType(value0) {
        this.value0 = value0;
    };
    RenamedType.create = function (value0) {
        return new RenamedType(value0);
    };
    return RenamedType;
})();
var QType = /* #__PURE__ */ (function () {
    function QType(value0) {
        this.value0 = value0;
    };
    QType.create = function (value0) {
        return new QType(value0);
    };
    return QType;
})();
var Nullable = /* #__PURE__ */ (function () {
    function Nullable(value0) {
        this.value0 = value0;
    };
    Nullable.create = function (value0) {
        return new Nullable(value0);
    };
    return Nullable;
})();
var ArrayOf = /* #__PURE__ */ (function () {
    function ArrayOf(value0) {
        this.value0 = value0;
    };
    ArrayOf.create = function (value0) {
        return new ArrayOf(value0);
    };
    return ArrayOf;
})();
var QID = /* #__PURE__ */ (function () {
    function QID(value0) {
        this.value0 = value0;
    };
    QID.create = function (value0) {
        return new QID(value0);
    };
    return QID;
})();
var DyNamedType = /* #__PURE__ */ (function () {
    function DyNamedType(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    DyNamedType.create = function (value0) {
        return function (value1) {
            return new DyNamedType(value0, value1);
        };
    };
    return DyNamedType;
})();
var typedStr = /* #__PURE__ */ (function () {
    return RenamedType.create;
})();
var typedId = /* #__PURE__ */ (function () {
    return RenamedType.create;
})();
var typeToString = function (v) {
    if (v instanceof Nullable && v.value0 instanceof QType) {
        return v.value0.value0;
    };
    if (v instanceof QType) {
        return v.value0 + "!";
    };
    if (v instanceof Nullable && v.value0 instanceof ArrayOf) {
        return "[" + (typeToString(v.value0.value0) + "]");
    };
    if (v instanceof ArrayOf) {
        return "[" + (typeToString(v.value0) + "]!");
    };
    if (v instanceof Nullable && v.value0 instanceof Nullable) {
        return typeToString(v.value0);
    };
    throw new Error("Failed pattern match at GraphQL.Type (line 13, column 1 - line 13, column 32): " + [ v.constructor.name ]);
};
var rntyType = function (dictIsSymbol) {
    var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
    return {
        gqlType: function (v) {
            return new QType(reflectSymbol(Type_Proxy["Proxy"].value));
        }
    };
};
var namedType = /* #__PURE__ */ (function () {
    return RenamedType.create;
})();
var innerType = function (v) {
    return v.value0;
};
var innerStr = function (v) {
    return v.value0;
};
var innerId = function (v) {
    return v.value0;
};
var hqtStr = {
    gqlType: function (v) {
        return new QType("String");
    }
};
var hqtInt = {
    gqlType: function (v) {
        return new QType("Int");
    }
};
var hqtID = {
    gqlType: function (v) {
        return new QType("ID");
    }
};
var hqtFloat = {
    gqlType: function (v) {
        return new QType("Float");
    }
};
var hqtBoolean = {
    gqlType: function (v) {
        return new QType("Boolean");
    }
};
var gqlType = function (dict) {
    return dict.gqlType;
};
var hqtArray = function (dictHasQType) {
    var gqlType1 = gqlType(dictHasQType);
    return {
        gqlType: function (v) {
            return new ArrayOf(gqlType1(Type_Proxy["Proxy"].value));
        }
    };
};
var hqtMaybe = function (dictHasQType) {
    var gqlType1 = gqlType(dictHasQType);
    return {
        gqlType: function (v) {
            return new Nullable(gqlType1(Type_Proxy["Proxy"].value));
        }
    };
};
var eqRNTY = function (dictEq) {
    var eq = Data_Eq.eq(dictEq);
    return {
        eq: function (v) {
            return function (v1) {
                return eq(v.value0)(v1.value0);
            };
        }
    };
};
var ordRNTY = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    var eqRNTY1 = eqRNTY(dictOrd.Eq0());
    return {
        compare: function (v) {
            return function (v1) {
                return compare(v.value0)(v1.value0);
            };
        },
        Eq0: function () {
            return eqRNTY1;
        }
    };
};
var eqDYNT = function (dictEq) {
    var eq = Data_Eq.eq(dictEq);
    return {
        eq: function (v) {
            return function (v1) {
                return eq(v.value1)(v1.value1);
            };
        }
    };
};
var ordDYNT = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    var eqDYNT1 = eqDYNT(dictOrd.Eq0());
    return {
        compare: function (v) {
            return function (v1) {
                return compare(v.value1)(v1.value1);
            };
        },
        Eq0: function () {
            return eqDYNT1;
        }
    };
};
var encRNTY = function (dictEncodeJson) {
    var encodeJson1 = Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson);
    return {
        encodeJson: function (v) {
            return encodeJson1(v.value0);
        }
    };
};
var encQID = {
    encodeJson: function (v) {
        return encodeJson(v.value0);
    }
};
var encDYNT = function (dictEncodeJson) {
    var encodeJson1 = Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson);
    return {
        encodeJson: function (v) {
            return encodeJson1(v.value1);
        }
    };
};
var dummyId = /* #__PURE__ */ (function () {
    return new RenamedType("-1");
})();
var maybeId = function (v) {
    if (v instanceof Data_Maybe.Nothing) {
        return dummyId;
    };
    if (v instanceof Data_Maybe.Just) {
        return v.value0;
    };
    throw new Error("Failed pattern match at GraphQL.Type (line 75, column 1 - line 75, column 58): " + [ v.constructor.name ]);
};
var decRNTY = function (dictDecodeJson) {
    var decodeJson = Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson);
    return {
        decodeJson: function (json) {
            return map(RenamedType.create)(decodeJson(json));
        }
    };
};
export {
    gqlType,
    QType,
    Nullable,
    ArrayOf,
    typeToString,
    QID,
    typedId,
    innerId,
    dummyId,
    maybeId,
    typedStr,
    innerStr,
    namedType,
    RenamedType,
    innerType,
    DyNamedType,
    hqtMaybe,
    hqtArray,
    hqtStr,
    hqtInt,
    hqtFloat,
    hqtBoolean,
    hqtID,
    encQID,
    rntyType,
    encRNTY,
    decRNTY,
    eqRNTY,
    ordRNTY,
    encDYNT,
    eqDYNT,
    ordDYNT
};
