// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Data_Symbol from "../Data.Symbol/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var get = function () {
    return function (dictIsSymbol) {
        var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
        return function (l) {
            return function (r) {
                return $foreign.unsafeGet(reflectSymbol(l))(r);
            };
        };
    };
};
var get1 = /* #__PURE__ */ get();
var isTrusted = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "isTrusted";
        }
    })(Type_Proxy["Proxy"].value);
})();
var key = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "key";
        }
    })(Type_Proxy["Proxy"].value);
})();
var keyCode = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "keyCode";
        }
    })(Type_Proxy["Proxy"].value);
})();
var locale = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "locale";
        }
    })(Type_Proxy["Proxy"].value);
})();
var location = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "location";
        }
    })(Type_Proxy["Proxy"].value);
})();
var metaKey = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "metaKey";
        }
    })(Type_Proxy["Proxy"].value);
})();
var nativeEvent = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "nativeEvent";
        }
    })(Type_Proxy["Proxy"].value);
})();
var pageX = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "pageX";
        }
    })(Type_Proxy["Proxy"].value);
})();
var pageY = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "pageY";
        }
    })(Type_Proxy["Proxy"].value);
})();
var propertyName = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "propertyName";
        }
    })(Type_Proxy["Proxy"].value);
})();
var pseudoElement = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "pseudoElement";
        }
    })(Type_Proxy["Proxy"].value);
})();
var relatedTarget = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "relatedTarget";
        }
    })(Type_Proxy["Proxy"].value);
})();
var repeat = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "repeat";
        }
    })(Type_Proxy["Proxy"].value);
})();
var screenX = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "screenX";
        }
    })(Type_Proxy["Proxy"].value);
})();
var screenY = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "screenY";
        }
    })(Type_Proxy["Proxy"].value);
})();
var shiftKey = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "shiftKey";
        }
    })(Type_Proxy["Proxy"].value);
})();
var target = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "target";
        }
    })(Type_Proxy["Proxy"].value);
})();
var targetTouches = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "targetTouches";
        }
    })(Type_Proxy["Proxy"].value);
})();
var timeStamp = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "timeStamp";
        }
    })(Type_Proxy["Proxy"].value);
})();
var touches = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "touches";
        }
    })(Type_Proxy["Proxy"].value);
})();
var type_ = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "type";
        }
    })(Type_Proxy["Proxy"].value);
})();
var view = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "view";
        }
    })(Type_Proxy["Proxy"].value);
})();
var which = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "which";
        }
    })(Type_Proxy["Proxy"].value);
})();
var eventPhase = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "eventPhase";
        }
    })(Type_Proxy["Proxy"].value);
})();
var elapsedTime = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "elapsedTime";
        }
    })(Type_Proxy["Proxy"].value);
})();
var detail = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "detail";
        }
    })(Type_Proxy["Proxy"].value);
})();
var deltaZ = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "deltaZ";
        }
    })(Type_Proxy["Proxy"].value);
})();
var deltaY = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "deltaY";
        }
    })(Type_Proxy["Proxy"].value);
})();
var deltaX = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "deltaX";
        }
    })(Type_Proxy["Proxy"].value);
})();
var deltaMode = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "deltaMode";
        }
    })(Type_Proxy["Proxy"].value);
})();
var defaultPrevented = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "defaultPrevented";
        }
    })(Type_Proxy["Proxy"].value);
})();
var data_ = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "data";
        }
    })(Type_Proxy["Proxy"].value);
})();
var currentTarget = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "currentTarget";
        }
    })(Type_Proxy["Proxy"].value);
})();
var ctrlKey = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "ctrlKey";
        }
    })(Type_Proxy["Proxy"].value);
})();
var clipboardData = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "clipboardData";
        }
    })(Type_Proxy["Proxy"].value);
})();
var clientY = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "clientY";
        }
    })(Type_Proxy["Proxy"].value);
})();
var clientX = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "clientX";
        }
    })(Type_Proxy["Proxy"].value);
})();
var charCode = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "charCode";
        }
    })(Type_Proxy["Proxy"].value);
})();
var changedTouches = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "changedTouches";
        }
    })(Type_Proxy["Proxy"].value);
})();
var cancelable = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "cancelable";
        }
    })(Type_Proxy["Proxy"].value);
})();
var buttons = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "buttons";
        }
    })(Type_Proxy["Proxy"].value);
})();
var button = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "button";
        }
    })(Type_Proxy["Proxy"].value);
})();
var bubbles = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "bubbles";
        }
    })(Type_Proxy["Proxy"].value);
})();
var animationName = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "animationName";
        }
    })(Type_Proxy["Proxy"].value);
})();
var altKey = /* #__PURE__ */ (function () {
    return get1({
        reflectSymbol: function () {
            return "altKey";
        }
    })(Type_Proxy["Proxy"].value);
})();
export {
    preventDefault,
    isDefaultPrevented,
    stopPropagation,
    isPropagationStopped,
    persist,
    getModifierState
} from "./foreign.js";
export {
    bubbles,
    cancelable,
    currentTarget,
    defaultPrevented,
    eventPhase,
    isTrusted,
    nativeEvent,
    target,
    timeStamp,
    type_,
    animationName,
    clipboardData,
    data_,
    relatedTarget,
    charCode,
    key,
    keyCode,
    locale,
    location,
    repeat,
    which,
    button,
    buttons,
    clientX,
    clientY,
    pageX,
    pageY,
    screenX,
    screenY,
    changedTouches,
    targetTouches,
    touches,
    altKey,
    ctrlKey,
    metaKey,
    shiftKey,
    propertyName,
    pseudoElement,
    elapsedTime,
    detail,
    view,
    deltaMode,
    deltaX,
    deltaY,
    deltaZ
};
