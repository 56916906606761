// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Unsafe_Coerce from "../Unsafe.Coerce/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var writeStateWithCallback = $foreign.setStateWithCallbackImpl;
var writeState = $foreign.setStateImpl;
var unsafeCreateLeafElement = $foreign.createLeafElementImpl;
var unsafeCreateElementDynamic = $foreign.createElementDynamicImpl;
var unsafeCreateElement = $foreign.createElementImpl;
var toElement = function (dict) {
    return dict.toElement;
};
var setStateWithCallback = function () {
    return $foreign.setStateWithCallbackImpl;
};
var setState = function () {
    return $foreign.setStateImpl;
};
var reactPureComponentSpec = function () {
    return function () {
        return {};
    };
};
var reactPropFields = function () {
    return function () {
        return {};
    };
};
var reactComponentSpec = function () {
    return function () {
        return {};
    };
};
var pureComponentWithDerivedState = function () {
    return $foreign.componentWithDerivedStateImpl;
};
var pureComponent = function () {
    return $foreign.pureComponentImpl;
};
var modifyStateWithCallback = $foreign.setStateWithCallbackImpl;
var modifyState = $foreign.setStateImpl;
var isReactElementString = {
    toElement: Unsafe_Coerce.unsafeCoerce
};
var isReactElementReactElement = {
    toElement: /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn)
};
var isReactElementNumber = {
    toElement: Unsafe_Coerce.unsafeCoerce
};
var isReactElementInt = {
    toElement: Unsafe_Coerce.unsafeCoerce
};
var isReactElementChildren = {
    toElement: Unsafe_Coerce.unsafeCoerce
};
var forceUpdate = function ($$this) {
    return $foreign.forceUpdateWithCallback($$this)(pure(Data_Unit.unit));
};
var createLeafElement = function () {
    return $foreign.createLeafElementImpl;
};
var createElementDynamic = function () {
    return $foreign.createElementDynamicImpl;
};
var createElement = function () {
    return $foreign.createElementImpl;
};
var createElement1 = /* #__PURE__ */ createElement();
var fragmentWithKey = /* #__PURE__ */ (function () {
    var $7 = createElement1($foreign.fragment);
    return function ($8) {
        return $7((function (v) {
            return {
                key: v
            };
        })($8));
    };
})();
var isReactElementArray = {
    toElement: /* #__PURE__ */ createElement1($foreign.fragment)({})
};
var toElement1 = /* #__PURE__ */ toElement(isReactElementArray);
var semigroupReactElement = {
    append: function (a) {
        return function (b) {
            return toElement1([ a, b ]);
        };
    }
};
var monoidReactElement = {
    mempty: /* #__PURE__ */ toElement1([  ]),
    Semigroup0: function () {
        return semigroupReactElement;
    }
};
var componentWithDerivedState = function () {
    return $foreign.componentWithDerivedStateImpl;
};
var component = function () {
    return $foreign.componentImpl;
};
export {
    statelessComponent,
    getProps,
    getState,
    forceUpdateWithCallback,
    createElementTagName,
    createElementTagNameDynamic,
    childrenToArray,
    childrenCount,
    fragment,
    createContext
} from "./foreign.js";
export {
    component,
    componentWithDerivedState,
    pureComponent,
    pureComponentWithDerivedState,
    setState,
    setStateWithCallback,
    writeState,
    writeStateWithCallback,
    modifyState,
    modifyStateWithCallback,
    forceUpdate,
    createElement,
    unsafeCreateElement,
    createElementDynamic,
    unsafeCreateElementDynamic,
    createLeafElement,
    unsafeCreateLeafElement,
    toElement,
    fragmentWithKey,
    semigroupReactElement,
    monoidReactElement,
    reactComponentSpec,
    reactPureComponentSpec,
    reactPropFields,
    isReactElementString,
    isReactElementNumber,
    isReactElementInt,
    isReactElementChildren,
    isReactElementReactElement,
    isReactElementArray
};
