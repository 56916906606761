// Generated by purs version 0.15.7
import * as API_Pagination from "../API.Pagination/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Translate from "../Translate/index.js";
var max = /* #__PURE__ */ Data_Ord.max(Data_Ord.ordInt);
var div = /* #__PURE__ */ Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt);
var min = /* #__PURE__ */ Data_Ord.min(Data_Ord.ordInt);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var elem = /* #__PURE__ */ Data_Array.elem(Data_Eq.eqInt);
var div1 = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var nearbyNumbers = function (v) {
    return function (v1) {
        return function (v2) {
            if (v > v1) {
                return Data_Array.range(1)(v1);
            };
            var startAt = max(1)(v2 - div(v)(2) | 0);
            var less = (function () {
                if (v2 > 1) {
                    return Data_Array.range(startAt)(v2 - 1 | 0);
                };
                return [  ];
            })();
            var endLen = v - (v2 - startAt | 0) | 0;
            var endAt = min(v1)((v2 + endLen | 0) - 1 | 0);
            var more = Data_Array.range(v2)(endAt);
            return append(less)(more);
        };
    };
};
var pagerWidget = function (v) {
    if (API_Pagination.pageLast(v) <= 1) {
        return div$prime([  ]);
    };
    var pageNo = API_Pagination.pageNumber(v);
    var pageLink = function (n) {
        return function (txt) {
            var cls = (function () {
                if (n === pageNo) {
                    return "btn btn-primary";
                };
                return "btn";
            })();
            return button([ Concur_React_Props.className(cls), voidRight(n)(Concur_React_Props.onClick), Concur_React_Props["_type"]("button") ])([ text(txt) ]);
        };
    };
    var pageLinkN = function (n) {
        return pageLink(n)(show(n));
    };
    var prevLink = (function () {
        if (pageNo > 1) {
            return [ pageLink(pageNo - 1 | 0)(Translate.lookupTranslation("prev")) ];
        };
        return [  ];
    })();
    var lastPage = API_Pagination.pageLast(v);
    var nearNums = nearbyNumbers(10)(lastPage)(pageNo);
    var pageNumLinks = map(pageLinkN)(nearNums);
    var nextLink = (function () {
        if (pageNo < lastPage) {
            return [ pageLink(pageNo + 1 | 0)(Translate.lookupTranslation("next")) ];
        };
        return [  ];
    })();
    var lastLink = (function () {
        if (elem(lastPage)(nearNums)) {
            return [  ];
        };
        return [ pageLink(lastPage)("... " + show(lastPage)) ];
    })();
    var firstLink = (function () {
        if (elem(1)(nearNums)) {
            return [  ];
        };
        return [ pageLink(1)("1 ...") ];
    })();
    return div1([ Concur_React_Props.className("pager btn-group") ])(append(prevLink)(append(firstLink)(append(pageNumLinks)(append(lastLink)(nextLink)))));
};
export {
    pagerWidget
};
