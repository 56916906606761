// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
var Killed = /* #__PURE__ */ (function () {
    function Killed(value0) {
        this.value0 = value0;
    };
    Killed.create = function (value0) {
        return new Killed(value0);
    };
    return Killed;
})();
var Filled = /* #__PURE__ */ (function () {
    function Filled(value0) {
        this.value0 = value0;
    };
    Filled.create = function (value0) {
        return new Filled(value0);
    };
    return Filled;
})();
var Empty = /* #__PURE__ */ (function () {
    function Empty() {

    };
    Empty.value = new Empty();
    return Empty;
})();
var $$new = $foreign["_newVar"];
var isKilled = function (v) {
    if (v instanceof Killed) {
        return true;
    };
    return false;
};
var isFilled = function (v) {
    if (v instanceof Filled) {
        return true;
    };
    return false;
};
var isEmpty = function (v) {
    if (v instanceof Empty) {
        return true;
    };
    return false;
};
var ffiUtil = /* #__PURE__ */ (function () {
    return {
        left: Data_Either.Left.create,
        right: Data_Either.Right.create,
        nothing: Data_Maybe.Nothing.value,
        just: Data_Maybe.Just.create,
        killed: Killed.create,
        filled: Filled.create,
        empty: Empty.value
    };
})();
var kill = function (err) {
    return function (avar) {
        return $foreign["_killVar"](ffiUtil, err, avar);
    };
};
var put = function (value) {
    return function (avar) {
        return function (cb) {
            return $foreign["_putVar"](ffiUtil, value, avar, cb);
        };
    };
};
var read = function (avar) {
    return function (cb) {
        return $foreign["_readVar"](ffiUtil, avar, cb);
    };
};
var status = function (avar) {
    return $foreign["_status"](ffiUtil, avar);
};
var take = function (avar) {
    return function (cb) {
        return $foreign["_takeVar"](ffiUtil, avar, cb);
    };
};
var tryPut = function (value) {
    return function (avar) {
        return $foreign["_tryPutVar"](ffiUtil, value, avar);
    };
};
var tryRead = function (avar) {
    return $foreign["_tryReadVar"](ffiUtil, avar);
};
var tryTake = function (avar) {
    return $foreign["_tryTakeVar"](ffiUtil, avar);
};
export {
    empty
} from "./foreign.js";
export {
    Killed,
    Filled,
    Empty,
    $$new as new,
    take,
    tryTake,
    put,
    tryPut,
    read,
    tryRead,
    kill,
    status,
    isEmpty,
    isFilled,
    isKilled
};
