"use strict";

import { DateTime } from "luxon";

export const now = function () {
    return DateTime.now();
}

export const fromISOInternal = function(just, nothing) {
    return function(str) {
        var dt = DateTime.fromISO(str.replace(' ', 'T'));
        if (dt.isValid) {
            return just(dt);
        } else {
            return nothing;
        }
    }
}

export const toISO = function(dt) {
    return dt.toISO();
}

export const toISONoTZ = function(dt) {
    return dt.toISO({ includeOffset: false });
}

export const toMillis = function (dt) {
    return dt.toMillis();
}

export const toRelative = function (dt) {
    return dt.toRelative();
}

export const toFormat = function (fmt) {
    return function(dt) {
        return dt.toFormat(fmt);
    }
}

export const dateTimeEq = function (a) {
    return function(b) {
        return a.equals(b);
    }
}

export const showDateTime = function (dt) {
    return dt.toString();
}
