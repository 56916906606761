// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Nullable from "../Data.Nullable/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
var FileName = function (x) {
    return x;
};
var EntryName = function (x) {
    return x;
};
var setBlob = function (name) {
    return function (value) {
        return function (filename) {
            return function (fd) {
                return function () {
                    return $foreign["_setBlob"](name, value, Data_Nullable.toNullable(filename), fd);
                };
            };
        };
    };
};
var set = function (name) {
    return function (value) {
        return function (fd) {
            return function () {
                return $foreign["_set"](name, value, fd);
            };
        };
    };
};
var ordFileName = Data_Ord.ordString;
var ordEntryName = Data_Ord.ordString;
var newtypeFileName = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeEntryName = {
    Coercible0: function () {
        return undefined;
    }
};
var has = function (name) {
    return function (fd) {
        return function () {
            return $foreign["_has"](name, fd);
        };
    };
};
var fromFormElement = function (formElement) {
    return function () {
        return $foreign["_fromFormElement"](formElement);
    };
};
var eqFileName = Data_Eq.eqString;
var eqEntryName = Data_Eq.eqString;
var $$delete = function (name) {
    return function (fd) {
        return function () {
            return $foreign["_delete"](name, fd);
        };
    };
};
var appendBlob = function (name) {
    return function (value) {
        return function (filename) {
            return function (fd) {
                return function () {
                    return $foreign["_appendBlob"](name, value, Data_Nullable.toNullable(filename), fd);
                };
            };
        };
    };
};
var append = function (name) {
    return function (value) {
        return function (fd) {
            return function () {
                return $foreign["_append"](name, value, fd);
            };
        };
    };
};
export {
    new
} from "./foreign.js";
export {
    EntryName,
    FileName,
    fromFormElement,
    append,
    appendBlob,
    $$delete as delete,
    has,
    set,
    setBlob,
    eqEntryName,
    ordEntryName,
    newtypeEntryName,
    eqFileName,
    ordFileName,
    newtypeFileName
};
