// Generated by purs version 0.15.7
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Combinators from "../Data.Argonaut.Decode.Combinators/index.js";
import * as Data_Argonaut_Decode_Decoders from "../Data.Argonaut.Decode.Decoders/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Symbol from "../Data.Symbol/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as $$Error from "../Error/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
import * as GraphQL from "../GraphQL/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as Record from "../Record/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var query = /* #__PURE__ */ GraphQL.query(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeForeignObject(Data_Argonaut_Encode_Class.encodeJsonJson));
var except = /* #__PURE__ */ Control_Monad_Except_Trans.except(Effect_Aff.applicativeAff);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var getField = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeForeignObject(Data_Argonaut_Decode_Class.decodeJsonJson));
var getField1 = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(Data_Argonaut_Decode_Class.decodeJsonJson);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Either.applicativeEither);
var CompQuery = /* #__PURE__ */ (function () {
    function CompQuery(value0) {
        this.value0 = value0;
    };
    CompQuery.create = function (value0) {
        return new CompQuery(value0);
    };
    return CompQuery;
})();
var subQueryVars = function (dict) {
    return dict.subQueryVars;
};
var subQueryShape = function (dict) {
    return dict.subQueryShape;
};
var subQueryDecode = function (dict) {
    return dict.subQueryDecode;
};
var subQueryArgs = function (dict) {
    return dict.subQueryArgs;
};
var querySQ = {
    subQueryVars: function (v) {
        return v.value0.vars;
    },
    subQueryArgs: function (v) {
        return v.value0.args;
    },
    subQueryShape: function (v) {
        return v.value0.shape;
    },
    subQueryDecode: function (v) {
        return v.value0.decode;
    }
};
var cqVars = function (dict) {
    return dict.cqVars;
};
var cqShape = function (dict) {
    return dict.cqShape;
};
var cqNil = {
    cqVars: function (v) {
        return function (v1) {
            return function (v2) {
                return Foreign_Object.empty;
            };
        };
    },
    cqArgs: function (v) {
        return function (v1) {
            return function (v2) {
                return [  ];
            };
        };
    },
    cqShape: function (v) {
        return function (v1) {
            return function (v2) {
                return [  ];
            };
        };
    },
    cqDecode: function (v) {
        return function (v1) {
            return function (v2) {
                return new Data_Either.Right({});
            };
        };
    }
};
var cqJoin = {
    getQuery: function (v) {
        return {
            query: GraphQL_Query.queryToString(v.value0),
            variables: Data_Argonaut_Core.fromObject(v.value0.vars)
        };
    },
    getDecode: function (v) {
        return v.value0.decode;
    }
};
var getDecode = /* #__PURE__ */ GraphQL_Join.getDecode(cqJoin);
var cqGQL = {
    runGraphQL: function (v) {
        return Control_Monad_Except_Trans.runExceptT(bind(query(GraphQL_Query.queryToString(v.value0))(v.value0.vars))(function (ret) {
            return except(getDecode(v)(ret));
        }));
    }
};
var cqDecode = function (dict) {
    return dict.cqDecode;
};
var cqArgs = function (dict) {
    return dict.cqArgs;
};
var qcCons = function (dictComposeQueries) {
    var cqVars1 = cqVars(dictComposeQueries);
    var cqArgs1 = cqArgs(dictComposeQueries);
    var cqShape1 = cqShape(dictComposeQueries);
    var cqDecode1 = cqDecode(dictComposeQueries);
    return function (dictIsSymbol) {
        var get = Record.get(dictIsSymbol)();
        var $$delete = Record["delete"](dictIsSymbol)()();
        var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
        var insert = Record.insert(dictIsSymbol)()();
        return function () {
            return function () {
                return function () {
                    return function () {
                        return function (dictSubQueryable) {
                            var subQueryVars1 = subQueryVars(dictSubQueryable);
                            var subQueryArgs1 = subQueryArgs(dictSubQueryable);
                            var subQueryShape1 = subQueryShape(dictSubQueryable);
                            var subQueryDecode1 = subQueryDecode(dictSubQueryable);
                            return {
                                cqVars: function (v) {
                                    return function (v1) {
                                        return function (q) {
                                            var val = get(Type_Proxy["Proxy"].value)(q);
                                            return Foreign_Object.union(subQueryVars1(val))(cqVars1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value)($$delete(Type_Proxy["Proxy"].value)(q)));
                                        };
                                    };
                                },
                                cqArgs: function (v) {
                                    return function (v1) {
                                        return function (q) {
                                            var renamed = function (a) {
                                                return a;
                                            };
                                            var val = get(Type_Proxy["Proxy"].value)(q);
                                            return append(renamed(subQueryArgs1(val)))(cqArgs1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value)($$delete(Type_Proxy["Proxy"].value)(q)));
                                        };
                                    };
                                },
                                cqShape: function (v) {
                                    return function (v1) {
                                        return function (q) {
                                            var val = get(Type_Proxy["Proxy"].value)(q);
                                            var aliased = GraphQL_Shape.QAlias.create(reflectSymbol(Type_Proxy["Proxy"].value));
                                            return append(map(aliased)(subQueryShape1(val)))(cqShape1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value)($$delete(Type_Proxy["Proxy"].value)(q)));
                                        };
                                    };
                                },
                                cqDecode: function (v) {
                                    return function (q) {
                                        return function (json) {
                                            var name = reflectSymbol(Type_Proxy["Proxy"].value);
                                            var val = get(Type_Proxy["Proxy"].value)(q);
                                            return bind1(cqDecode1(Type_Proxy["Proxy"].value)($$delete(Type_Proxy["Proxy"].value)(q))(json))(function (rest) {
                                                return bind1($$Error["wrapJsonError$prime"](json)(bind1(bind1(Data_Argonaut_Decode_Decoders.decodeJObject(json))(function (v1) {
                                                    return getField(v1)("data");
                                                }))(function (v1) {
                                                    return getField1(v1)(name);
                                                })))(function (inner) {
                                                    return bind1(subQueryDecode1(val)(inner))(function (rval) {
                                                        return pure(insert(Type_Proxy["Proxy"].value)(rval)(rest));
                                                    });
                                                });
                                            });
                                        };
                                    };
                                }
                            };
                        };
                    };
                };
            };
        };
    };
};
var composeQueries = function () {
    return function (dictComposeQueries) {
        var cqShape1 = cqShape(dictComposeQueries);
        var cqVars1 = cqVars(dictComposeQueries);
        var cqDecode1 = cqDecode(dictComposeQueries);
        var cqArgs1 = cqArgs(dictComposeQueries);
        return function (name) {
            return function (queries) {
                var shape = cqShape1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value)(queries);
                var vars = cqVars1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value)(queries);
                var decode = cqDecode1(Type_Proxy["Proxy"].value)(queries);
                var args = cqArgs1(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value)(queries);
                var query1 = {
                    name: name,
                    args: args,
                    shape: shape,
                    files: [  ],
                    mutation: false
                };
                return new CompQuery(GraphQL_Query.fromBaseQuery(query1)(vars)(decode));
            };
        };
    };
};
export {
    cqArgs,
    cqDecode,
    cqShape,
    cqVars,
    subQueryArgs,
    subQueryDecode,
    subQueryShape,
    subQueryVars,
    CompQuery,
    composeQueries,
    cqGQL,
    cqJoin,
    querySQ,
    qcCons,
    cqNil
};
