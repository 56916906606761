"use strict";

import console from 'console';

import translations_en from '../../translation.en.json';

var resources = {
    "en": translations_en
};

var currentLanguage = "en";

export const setLanguage = (str) => {
    currentLanguage = str;
    console.log('Language has been set to %s', currentLanguage);
};

export const lookupTranslation = function (key) {
    if (!(currentLanguage in resources)) {
        console.warn('Language %s was not found in loaded resources', currentLanguage);
        return key;
    }

    const translations = resources[currentLanguage];

    if (!(key in translations)) {
        console.warn('Key %s was not found in %s resources', key, currentLanguage);
        return key;
    }

    return translations[key];
};