// Generated by purs version 0.15.7
import * as API_Search from "../API.Search/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Patterns from "../Concur.Core.Patterns/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_BetterSelect from "../Widgets.BetterSelect/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
import * as Widgets_Modal from "../Widgets.Modal/index.js";
var loopState = /* #__PURE__ */ Concur_Core_Patterns.loopState(Concur_Core_Types.widgetMonad);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h1 = /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var length = /* #__PURE__ */ Data_Foldable.length(Data_Foldable.foldableArray)(Data_Semiring.semiringInt);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var inputIsSymbol = {
    reflectSymbol: function () {
        return "input";
    }
};
var encRNTY = /* #__PURE__ */ GraphQL_Type.encRNTY(Data_Argonaut_Encode_Class.encodeJsonJString);
var idIsSymbol = {
    reflectSymbol: function () {
        return "id";
    }
};
var formsIsSymbol = {
    reflectSymbol: function () {
        return "forms";
    }
};
var rlhsCons = /* #__PURE__ */ GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsNil);
var buildMutation = /* #__PURE__ */ GraphQL_Query.buildMutation(/* #__PURE__ */ GraphQL_Query.rlTVars(/* #__PURE__ */ GraphQL_Query.rltvCons(GraphQL_Query.rltvNil)(inputIsSymbol)(/* #__PURE__ */ GraphQL_Type.hqtArray(/* #__PURE__ */ GraphQL_Type.rntyType({
    reflectSymbol: function () {
        return "UpdateFormInput";
    }
})))(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonArray(/* #__PURE__ */ GraphQL_Type.encRNTY(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(encRNTY)(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJString))(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonMaybe(encRNTY))(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
    reflectSymbol: function () {
        return "teacher_id";
    }
})())({
    reflectSymbol: function () {
        return "semester";
    }
})())({
    reflectSymbol: function () {
        return "form_id";
    }
})())())))()())(/* #__PURE__ */ GraphQL_Query.rltvfConsOther(GraphQL_Query.rltvfNil)(inputIsSymbol)())())(/* #__PURE__ */ GraphQL_Query.jsonQR(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ GraphQL_Type.decRNTY(Data_Argonaut_Decode_Class.decodeJsonString)))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(idIsSymbol)()())())))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(formsIsSymbol)()())())(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ rlhsCons(formsIsSymbol)(/* #__PURE__ */ GraphQL_Shape.arrayShape(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ rlhsCons(idIsSymbol)(GraphQL_Shape.scalarShape)()())()))()())()));
var formUpdateSelect = function (state$prime) {
    return loopState(state$prime)(function (state) {
        return div([ Concur_React_Props.className("modal-col modal-narrow") ])([ h1([ Concur_React_Props.className("modal-heading") ])([ text(state.header) ]), map(map1(Data_Either.Left.create)(function (v) {
            return {
                select: v,
                doUpdate: state.doUpdate,
                forms: state.forms,
                header: state.header
            };
        }))(Widgets_BetterSelect.selectWidget(state.select)), button([ Concur_React_Props.className("btn btn-primary"), Concur_React_Props.disabled(Data_Maybe.isNothing(Widgets_BetterSelect.selectedItem(state.select))), voidRight(new Data_Either.Right(state))(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("save")) ]) ]);
    });
};
var formUpdateModal = function (state) {
    var finalSelection = function (v) {
        if (v instanceof Widgets_Modal.ModalClosed) {
            return Data_Maybe.Nothing.value;
        };
        if (v instanceof Widgets_Modal.ModalReturned) {
            return map2(function (v1) {
                return v1.value;
            })(Widgets_BetterSelect.selectedItem(v.value0.select));
        };
        throw new Error("Failed pattern match at Widgets.UpdateForm (line 94, column 5 - line 94, column 65): " + [ v.constructor.name ]);
    };
    return bind(map(finalSelection)(Widgets_Modal.modalWidget(div$prime([  ]))(formUpdateSelect(state))))(function (selected) {
        if (selected instanceof Data_Maybe.Just) {
            return bind(alt(Widgets_Loading.loadingWidget)(liftAff(runGraphQL(state.doUpdate(selected.value0)(state.forms)))))(function (ret) {
                return bind($$Error.wrapCritical(ret))(function () {
                    return pure(new Data_Maybe.Just(selected.value0));
                });
            });
        };
        if (selected instanceof Data_Maybe.Nothing) {
            return pure(Data_Maybe.Nothing.value);
        };
        throw new Error("Failed pattern match at Widgets.UpdateForm (line 87, column 5 - line 92, column 32): " + [ selected.constructor.name ]);
    });
};
var semesterUpdateModal = function (forms) {
    var genUpdateInputs = function (sem) {
        return map3(function (f) {
            return GraphQL_Type.namedType({
                form_id: f.id,
                semester: new Data_Maybe.Just(sem.semester),
                teacher_id: Data_Maybe.Nothing.value
            });
        });
    };
    var count = length(forms);
    return formUpdateModal({
        forms: forms,
        header: Translate.lookupTranslation("update-semester-for") + (" " + (show(count) + (" " + Translate.lookupTranslation("forms")))),
        select: Widgets_BetterSelect["initSelect$prime"]("semester")(API_Search.semesterSearchAff)({
            placeholder: new Data_Maybe.Just(Translate.lookupTranslation("search-semester")),
            deselectLabel: Widgets_BetterSelect.defSelectConfig.deselectLabel,
            allowDeselect: false
        }),
        doUpdate: function (sem) {
            return function (fs) {
                return GraphQL_Query.nameQuery("MassUpdateSemesters")(buildMutation("updateForms")({
                    input: genUpdateInputs(sem)(fs)
                }));
            };
        }
    });
};
var teacherUpdateModal = function (forms) {
    var genUpdateInputs = function (teacher) {
        return map3(function (f) {
            return GraphQL_Type.namedType({
                form_id: f.id,
                semester: Data_Maybe.Nothing.value,
                teacher_id: new Data_Maybe.Just(teacher.id)
            });
        });
    };
    var count = length(forms);
    return formUpdateModal({
        forms: forms,
        header: Translate.lookupTranslation("update-teacher-for") + (" " + (show(count) + (" " + Translate.lookupTranslation("forms")))),
        select: Widgets_BetterSelect["initSelect$prime"]("teacher")(API_Search.teacherSearchAff)({
            placeholder: new Data_Maybe.Just(Translate.lookupTranslation("search-teacher")),
            deselectLabel: Widgets_BetterSelect.defSelectConfig.deselectLabel,
            allowDeselect: false
        }),
        doUpdate: function (teacher) {
            return function (fs) {
                return GraphQL_Query.nameQuery("MassUpdateTeachers")(buildMutation("updateForms")({
                    input: genUpdateInputs(teacher)(fs)
                }));
            };
        }
    });
};
export {
    semesterUpdateModal,
    teacherUpdateModal,
    formUpdateModal,
    formUpdateSelect
};
