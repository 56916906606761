// Generated by purs version 0.15.7
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad from "../Control.Monad/index.js";
import * as Control_Monad_Error_Class from "../Control.Monad.Error.Class/index.js";
import * as Control_Monad_Logger_Class from "../Control.Monad.Logger.Class/index.js";
import * as Control_Monad_Reader_Class from "../Control.Monad.Reader.Class/index.js";
import * as Control_Monad_Rec_Class from "../Control.Monad.Rec.Class/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var LoggerT = function (x) {
    return x;
};
var withLoggerT = function (f) {
    return function (v) {
        return function ($76) {
            return v(f($76));
        };
    };
};
var runLoggerT = function (v) {
    return v;
};
var newtypeLoggerT = {
    Coercible0: function () {
        return undefined;
    }
};
var monadTransLoggerT = {
    lift: function (dictMonad) {
        return function ($77) {
            return LoggerT(Data_Function["const"]($77));
        };
    }
};
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(monadTransLoggerT);
var mapLoggerT = function (f) {
    return function (v) {
        return function ($78) {
            return f(v($78));
        };
    };
};
var functorLoggerT = function (dictFunctor) {
    return {
        map: (function () {
            var $79 = Data_Functor.map(dictFunctor);
            return function ($80) {
                return mapLoggerT($79($80));
            };
        })()
    };
};
var monadLoggerT = function (dictMonad) {
    return {
        Applicative0: function () {
            return applicativeLoggerT(dictMonad);
        },
        Bind1: function () {
            return bindLoggerT(dictMonad);
        }
    };
};
var bindLoggerT = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    return {
        bind: function (v) {
            return function (f) {
                return function (l) {
                    return bind(v(l))(function ($81) {
                        return (function (v1) {
                            return v1(l);
                        })(unwrap(f($81)));
                    });
                };
            };
        },
        Apply0: function () {
            return applyLoggerT(dictMonad);
        }
    };
};
var applyLoggerT = function (dictMonad) {
    var functorLoggerT1 = functorLoggerT(((dictMonad.Bind1()).Apply0()).Functor0());
    return {
        apply: Control_Monad.ap(monadLoggerT(dictMonad)),
        Functor0: function () {
            return functorLoggerT1;
        }
    };
};
var applicativeLoggerT = function (dictMonad) {
    return {
        pure: (function () {
            var $82 = Control_Applicative.pure(dictMonad.Applicative0());
            return function ($83) {
                return LoggerT(Data_Function["const"]($82($83)));
            };
        })(),
        Apply0: function () {
            return applyLoggerT(dictMonad);
        }
    };
};
var monadAskLoggerT = function (dictMonadAsk) {
    var Monad0 = dictMonadAsk.Monad0();
    var monadLoggerT1 = monadLoggerT(Monad0);
    return {
        ask: lift(Monad0)(Control_Monad_Reader_Class.ask(dictMonadAsk)),
        Monad0: function () {
            return monadLoggerT1;
        }
    };
};
var monadReaderLoggerT = function (dictMonadReader) {
    var monadAskLoggerT1 = monadAskLoggerT(dictMonadReader.MonadAsk0());
    return {
        local: (function () {
            var $84 = Control_Monad_Reader_Class.local(dictMonadReader);
            return function ($85) {
                return mapLoggerT($84($85));
            };
        })(),
        MonadAsk0: function () {
            return monadAskLoggerT1;
        }
    };
};
var monadEffectLoggerT = function (dictMonadEffect) {
    var Monad0 = dictMonadEffect.Monad0();
    var monadLoggerT1 = monadLoggerT(Monad0);
    return {
        liftEffect: (function () {
            var $86 = lift(Monad0);
            var $87 = Effect_Class.liftEffect(dictMonadEffect);
            return function ($88) {
                return $86($87($88));
            };
        })(),
        Monad0: function () {
            return monadLoggerT1;
        }
    };
};
var monadAffLoggerT = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var monadEffectLoggerT1 = monadEffectLoggerT(MonadEffect0);
    return {
        liftAff: (function () {
            var $89 = lift(MonadEffect0.Monad0());
            var $90 = Effect_Aff_Class.liftAff(dictMonadAff);
            return function ($91) {
                return $89($90($91));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectLoggerT1;
        }
    };
};
var monadLoggerLoggerT = function (dictMonadEffect) {
    var monadEffectLoggerT1 = monadEffectLoggerT(dictMonadEffect);
    return {
        log: function (message) {
            return function (v) {
                return v(message);
            };
        },
        MonadEffect0: function () {
            return monadEffectLoggerT1;
        }
    };
};
var monadRecLoggerT = function (dictMonadRec) {
    var tailRecM = Control_Monad_Rec_Class.tailRecM(dictMonadRec);
    var monadLoggerT1 = monadLoggerT(dictMonadRec.Monad0());
    return {
        tailRecM: function (step) {
            return function (a) {
                return function (l) {
                    return tailRecM(function (a$prime) {
                        return unwrap(step(a$prime))(l);
                    })(a);
                };
            };
        },
        Monad0: function () {
            return monadLoggerT1;
        }
    };
};
var monadStateLoggerT = function (dictMonadState) {
    var Monad0 = dictMonadState.Monad0();
    var monadLoggerT1 = monadLoggerT(Monad0);
    return {
        state: (function () {
            var $92 = lift(Monad0);
            var $93 = Control_Monad_State_Class.state(dictMonadState);
            return function ($94) {
                return $92($93($94));
            };
        })(),
        Monad0: function () {
            return monadLoggerT1;
        }
    };
};
var monadThrowLoggerT = function (dictMonadThrow) {
    var Monad0 = dictMonadThrow.Monad0();
    var monadLoggerT1 = monadLoggerT(Monad0);
    return {
        throwError: (function () {
            var $95 = lift(Monad0);
            var $96 = Control_Monad_Error_Class.throwError(dictMonadThrow);
            return function ($97) {
                return $95($96($97));
            };
        })(),
        Monad0: function () {
            return monadLoggerT1;
        }
    };
};
var monadErrorLoggerT = function (dictMonadError) {
    var catchError = Control_Monad_Error_Class.catchError(dictMonadError);
    var monadThrowLoggerT1 = monadThrowLoggerT(dictMonadError.MonadThrow0());
    return {
        catchError: function (v) {
            return function (h) {
                return function (l) {
                    return catchError(v(l))(function ($98) {
                        return (function (v1) {
                            return v1(l);
                        })(unwrap(h($98)));
                    });
                };
            };
        },
        MonadThrow0: function () {
            return monadThrowLoggerT1;
        }
    };
};
export {
    LoggerT,
    runLoggerT,
    mapLoggerT,
    newtypeLoggerT,
    functorLoggerT,
    applyLoggerT,
    applicativeLoggerT,
    bindLoggerT,
    monadLoggerT,
    monadTransLoggerT,
    monadEffectLoggerT,
    monadAffLoggerT,
    monadAskLoggerT,
    monadStateLoggerT,
    monadReaderLoggerT,
    monadRecLoggerT,
    monadLoggerLoggerT,
    monadThrowLoggerT,
    monadErrorLoggerT
};
export {
    debug,
    error,
    info,
    trace,
    warn
} from "../Control.Monad.Logger.Class/index.js";
export {
    lift
} from "../Control.Monad.Trans.Class/index.js";
