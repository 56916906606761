// Generated by purs version 0.15.7
import * as API_Account from "../API.Account/index.js";
import * as API_FormType from "../API.FormType/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Patterns from "../Concur.Core.Patterns/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_SelectionMap from "../Internal.SelectionMap/index.js";
import * as Internal_Util from "../Internal.Util/index.js";
import * as Steps_Step from "../Steps.Step/index.js";
import * as Steps_WorkQueue from "../Steps.WorkQueue/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_Checkbox from "../Widgets.Checkbox/index.js";
import * as Widgets_Export from "../Widgets.Export/index.js";
import * as Widgets_HiddenSelect from "../Widgets.HiddenSelect/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var areFormsSelected = /* #__PURE__ */ API_FormType.areFormsSelected(Data_Functor.functorArray)(Data_Foldable.foldableArray);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var selectForms = /* #__PURE__ */ API_FormType.selectForms(Data_Functor.functorArray)(Data_Foldable.foldableArray);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var label = /* #__PURE__ */ Concur_React_DOM.label(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var abbr = /* #__PURE__ */ Concur_React_DOM.abbr(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Data_Eq.eqInt));
var bind1 = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var mod = /* #__PURE__ */ Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var span = /* #__PURE__ */ Concur_React_DOM.span(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var length = /* #__PURE__ */ Data_Foldable.length(Data_Foldable.foldableArray)(Data_Semiring.semiringInt);
var map2 = /* #__PURE__ */ Data_Functor.map(GraphQL_Query.queryFunctor);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var show1 = /* #__PURE__ */ Data_Show.show($$Error.showErrorWrapper);
var notEq = /* #__PURE__ */ Data_Eq.notEq(API_Account.eqAccType);
var isEmpty = /* #__PURE__ */ Internal_SelectionMap.isEmpty(/* #__PURE__ */ GraphQL_Type.ordRNTY(Data_Ord.ordString));
var header = /* #__PURE__ */ Concur_React_DOM.header(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h1 = /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var p$prime = /* #__PURE__ */ Concur_React_DOM["p$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var loopState = /* #__PURE__ */ Concur_Core_Patterns.loopState(Concur_Core_Types.widgetMonad);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var State = /* #__PURE__ */ (function () {
    function State(value0) {
        this.value0 = value0;
    };
    State.create = function (value0) {
        return new State(value0);
    };
    return State;
})();
var Navigate = /* #__PURE__ */ (function () {
    function Navigate(value0) {
        this.value0 = value0;
    };
    Navigate.create = function (value0) {
        return new Navigate(value0);
    };
    return Navigate;
})();
var WorkQR = /* #__PURE__ */ (function () {
    function WorkQR(value0) {
        this.value0 = value0;
    };
    WorkQR.create = function (value0) {
        return new WorkQR(value0);
    };
    return WorkQR;
})();
var Export = /* #__PURE__ */ (function () {
    function Export() {

    };
    Export.value = new Export();
    return Export;
})();
var withForms = function (state) {
    return function (func) {
        return {
            forms: state.forms,
            workQ: {
                forms: func(state.workQ.forms),
                formCache: state.workQ.formCache,
                students: state.workQ.students,
                documents: state.workQ.documents,
                editDocs: state.workQ.editDocs,
                currentDoc: state.workQ.currentDoc,
                expandNext: state.workQ.expandNext,
                search: state.workQ.search,
                searchData: state.workQ.searchData,
                semester: state.workQ.semester,
                teacher: state.workQ.teacher,
                defTeacher: state.workQ.defTeacher,
                defSemester: state.workQ.defSemester,
                account: state.workQ.account,
                loaded: state.workQ.loaded
            },
            reorder: state.reorder,
            movedDownIdx: state.movedDownIdx
        };
    };
};
var setFormReorder = function (fps) {
    return function (reorder) {
        return {
            forms: fps.forms,
            workQ: fps.workQ,
            reorder: reorder,
            movedDownIdx: fps.movedDownIdx
        };
    };
};
var selectAllFormsCheckbox = function (state) {
    return bind(Widgets_Checkbox["labelCheckbox$prime"]("form-picker-select-all")("form-picker-select-all")(Translate.lookupTranslation("select-all"))(areFormsSelected(state.forms)(state.workQ.forms)))(function (v) {
        return pure(withForms(state)(selectForms(state.forms)(v)));
    });
};
var renderFormCheckboxes = function (state) {
    var update = function (v) {
        return function (v1) {
            if (v1) {
                return withForms(state)(API_FormType.selectForm(v));
            };
            if (!v1) {
                return withForms(state)(API_FormType.deselectForm(v));
            };
            throw new Error("Failed pattern match at Steps.FormPicker (line 167, column 9 - line 167, column 61): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
    var selectedClass = function (form) {
        var $79 = API_FormType.isFormSelected(form)(state.workQ.forms);
        if ($79) {
            return " selected";
        };
        return "";
    };
    var formCheckBoxRender = function (form) {
        return div([ Concur_React_Props.className("form-type" + selectedClass(form)), Concur_React_Props.key("form-type-" + (GraphQL_Type.innerId(form.id) + "-container")), Concur_React_Props["_id"]("form-type-" + (GraphQL_Type.innerId(form.id) + "-container")) ])([ map(update(form))(Widgets_Checkbox.checkbox([ Concur_React_Props["_id"]("form-" + GraphQL_Type.innerId(form.id)), Concur_React_Props.className("form-checkbox") ])(API_FormType.isFormSelected(form)(state.workQ.forms))), label([ Concur_React_Props.htmlFor("form-" + GraphQL_Type.innerId(form.id)), Concur_React_Props.className("form-label") ])([ abbr([ Concur_React_Props.className("form-abbr"), Concur_React_Props.style({
            backgroundColor: form.bgColor
        }) ])([ text(form.abbr) ]), text(form.label) ]) ]);
    };
    return map1(formCheckBoxRender)(state.forms);
};
var renderFormArrows = function (state) {
    var wasMovedUp = function (v) {
        if (eq(new Data_Maybe.Just(v + 1 | 0))(state.movedDownIdx)) {
            return true;
        };
        return false;
    };
    var wasMovedDown = function (v) {
        if (eq(new Data_Maybe.Just(v))(state.movedDownIdx)) {
            return true;
        };
        return false;
    };
    var moveDown = function (idx) {
        var update = bind1(Data_Array.index(state.forms)(idx))(function ($$this) {
            return bind1(Data_Array.index(state.forms)(idx + 1 | 0))(function (after) {
                return bind1(Data_Array.updateAt(idx)(after)(state.forms))(Data_Array.updateAt(idx + 1 | 0)($$this));
            });
        });
        if (update instanceof Data_Maybe.Nothing) {
            return state;
        };
        if (update instanceof Data_Maybe.Just) {
            return {
                forms: update.value0,
                movedDownIdx: new Data_Maybe.Just(idx + 1 | 0),
                reorder: state.reorder,
                workQ: state.workQ
            };
        };
        throw new Error("Failed pattern match at Steps.FormPicker (line 198, column 20 - line 200, column 73): " + [ update.constructor.name ]);
    };
    var formArrowRender = function (idx) {
        return function (form) {
            return div([ Concur_React_Props.className("form-type" + ((function () {
                var $84 = wasMovedDown(idx);
                if ($84) {
                    return " moved-down";
                };
                return "";
            })() + (function () {
                var $85 = wasMovedUp(idx);
                if ($85) {
                    return " moved-up-" + show(mod(idx)(2));
                };
                return "";
            })())), Concur_React_Props.key("form-type-" + (GraphQL_Type.innerId(form.id) + "-container")), Concur_React_Props["_id"]("form-type-" + (GraphQL_Type.innerId(form.id) + "-container")) ])([ div([ Concur_React_Props.className("form-arrows") ])(append1((function () {
                var $86 = idx > 0;
                if ($86) {
                    return [ button([ Concur_React_Props.className("arrow-up"), voidRight(moveDown(idx - 1 | 0))(Concur_React_Props.onClick) ])([ span([ Concur_React_Props.className("arrow-up-icon") ])([  ]), span([ Concur_React_Props.className("sr-only") ])([ text(Translate.lookupTranslation("up")) ]) ]) ];
                };
                return [ span([ Concur_React_Props.className("arrow-up-icon-first") ])([  ]) ];
            })())((function () {
                var $87 = idx < (length(state.forms) - 1 | 0);
                if ($87) {
                    return [ button([ Concur_React_Props.className("arrow-down"), voidRight(moveDown(idx))(Concur_React_Props.onClick) ])([ span([ Concur_React_Props.className("arrow-down-icon") ])([  ]), span([ Concur_React_Props.className("sr-only") ])([ text(Translate.lookupTranslation("down")) ]) ]) ];
                };
                return [ span([ Concur_React_Props.className("arrow-down-icon-last") ])([  ]) ];
            })())), label([ Concur_React_Props.className("form-label") ])([ abbr([ Concur_React_Props.className("form-abbr"), Concur_React_Props.style({
                backgroundColor: form.bgColor
            }) ])([ text(form.abbr) ]), text(form.label) ]) ]);
        };
    };
    return Data_Array.mapWithIndex(formArrowRender)(state.forms);
};
var renderFormPicker = function (state) {
    var render = (function () {
        if (state.reorder) {
            return renderFormArrows(state);
        };
        return renderFormCheckboxes(state);
    })();
    return div([ Concur_React_Props.className("form-picker") ])(Data_Array.cons(selectAllFormsCheckbox(state))(render));
};
var initFormPicker = function (workQ) {
    return function (v) {
        return {
            workQ: workQ,
            reorder: false,
            movedDownIdx: Data_Maybe.Nothing.value,
            forms: v
        };
    };
};
var loadFormPicker = function (workQ) {
    return map2(initFormPicker(workQ))(API_FormType.formListQuery);
};
var initFormPickerWidget = function (workQ) {
    return bind(alt(Steps_WorkQueue.loadingWidgetWQ(workQ))(liftAff(runGraphQL(loadFormPicker(workQ)))))(function (res) {
        if (res instanceof Data_Either.Left) {
            return text(show1(res.value0));
        };
        if (res instanceof Data_Either.Right) {
            return pure(res.value0);
        };
        throw new Error("Failed pattern match at Steps.FormPicker (line 143, column 5 - line 145, column 34): " + [ res.constructor.name ]);
    });
};
var formPickerWidget = function (fps) {
    return div([ Concur_React_Props.className("form-picker-step") ])([ renderFormPicker(fps) ]);
};
var step2BWidget = function (ss) {
    var notBoardAcc = notEq(ss.workQueue.account.type)(API_Account.BoardAccount.value);
    var handle = function (v) {
        return function (v1) {
            if (v1 instanceof State) {
                return pure(new Data_Either.Left(v1.value0));
            };
            if (v1 instanceof WorkQR && v1.value0 instanceof Steps_WorkQueue.StateChange) {
                return pure(new Data_Either.Left({
                    workQ: v1.value0.value0,
                    forms: v.forms,
                    movedDownIdx: v.movedDownIdx,
                    reorder: v.reorder
                }));
            };
            if (v1 instanceof WorkQR) {
                return pure(new Data_Either.Left(v));
            };
            if (v1 instanceof Navigate && (v1.value0 instanceof Steps_Step.NavNext && !Steps_WorkQueue.formForSelected(v.workQ))) {
                return pure(new Data_Either.Left(v));
            };
            if (v1 instanceof Navigate) {
                return pure(new Data_Either.Right(new Steps_Step.WidgetResult(v1.value0, {
                    workQueue: v.workQ,
                    preselectForms: ss.preselectForms,
                    pushStateInterface: ss.pushStateInterface,
                    step: ss.step
                })));
            };
            if (v1 instanceof Export) {
                return bind(Widgets_Export.exportAllWidget(v.workQ))(function () {
                    return pure(new Data_Either.Right(new Steps_Step.WidgetResult(Steps_Step.NavCancel.value, ss)));
                });
            };
            throw new Error("Failed pattern match at Steps.FormPicker (line 44, column 5 - line 46, column 59): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
    var actionBtns = function (state) {
        return div([ Concur_React_Props.className("btn-group") ])(Data_Array.catMaybes([ new Data_Maybe.Just(button([ Concur_React_Props.className("btn cancel"), voidRight(new Navigate(Steps_Step.NavCancel.value))(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("cancel")) ])), Internal_Util.justIf(!Steps_WorkQueue.isWorkQueueEmpty(state.workQ))(button([ Concur_React_Props.className("btn export"), Concur_React_Props.disabled(isEmpty(state.workQ.forms) || !Steps_WorkQueue.formForSelected(state.workQ)), voidRight(Export.value)(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("export-pdf")) ])), new Data_Maybe.Just(button([ Concur_React_Props.className("btn btn-primary"), Concur_React_Props.disabled(isEmpty(state.workQ.forms) || !Steps_WorkQueue.formForSelected(state.workQ)), voidRight(new Navigate(Steps_Step.NavNext.value))(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("next")) ])) ]));
    };
    var widget = function (state) {
        return div([ Concur_React_Props.className("step-container step-2b") ])(Data_Array.catMaybes([ new Data_Maybe.Just(header([ Concur_React_Props.className("page-header") ])([ h1([ Concur_React_Props.className("page-heading") ])([ text(Translate.lookupTranslation("select-forms")) ]), actionBtns(state) ])), new Data_Maybe.Just(map(WorkQR.create)(Steps_WorkQueue.workQueueWidget(state.workQ))), Internal_Util.justIf(!Steps_WorkQueue.formForSelected(state.workQ))(div([ Concur_React_Props.className("error") ])([ text(Translate.lookupTranslation("must-select-teacher-semester")) ])), new Data_Maybe.Just(div([ Concur_React_Props.className("teacher-semester-selections") ])(Data_Array.catMaybes([ new Data_Maybe.Just(p$prime([ text(Translate.lookupTranslation("semester-hidden-select")) ])), new Data_Maybe.Just(map(map3(State.create)(function (v) {
            return {
                workQ: {
                    semester: v,
                    account: state.workQ.account,
                    currentDoc: state.workQ.currentDoc,
                    defSemester: state.workQ.defSemester,
                    defTeacher: state.workQ.defTeacher,
                    documents: state.workQ.documents,
                    editDocs: state.workQ.editDocs,
                    expandNext: state.workQ.expandNext,
                    formCache: state.workQ.formCache,
                    forms: state.workQ.forms,
                    loaded: state.workQ.loaded,
                    search: state.workQ.search,
                    searchData: state.workQ.searchData,
                    students: state.workQ.students,
                    teacher: state.workQ.teacher
                },
                forms: state.forms,
                movedDownIdx: state.movedDownIdx,
                reorder: state.reorder
            };
        }))(Widgets_HiddenSelect.hiddenSelect(state.workQ.semester))), Internal_Util.justIf(notBoardAcc)(p$prime([ text(Translate.lookupTranslation("teacher-hidden-select")) ])), Internal_Util.justIf(notBoardAcc)(map(map3(State.create)(function (v) {
            return {
                workQ: {
                    teacher: v,
                    account: state.workQ.account,
                    currentDoc: state.workQ.currentDoc,
                    defSemester: state.workQ.defSemester,
                    defTeacher: state.workQ.defTeacher,
                    documents: state.workQ.documents,
                    editDocs: state.workQ.editDocs,
                    expandNext: state.workQ.expandNext,
                    formCache: state.workQ.formCache,
                    forms: state.workQ.forms,
                    loaded: state.workQ.loaded,
                    search: state.workQ.search,
                    searchData: state.workQ.searchData,
                    semester: state.workQ.semester,
                    students: state.workQ.students
                },
                forms: state.forms,
                movedDownIdx: state.movedDownIdx,
                reorder: state.reorder
            };
        }))(Widgets_HiddenSelect.hiddenSelect(state.workQ.teacher))) ]))), new Data_Maybe.Just(div([ Concur_React_Props.className("step-2b-right") ])([ map(State.create)(formPickerWidget(state)) ])) ]));
    };
    return bind(initFormPickerWidget(ss.workQueue))(function (fpState) {
        return loopState(fpState)(function (state) {
            return bind(widget(state))(handle(state));
        });
    });
};
var endFormReorder = function (fps) {
    return Control_Monad_Except_Trans.runExceptT(discard(runGraphQL(API_FormType.setFormOrder(map1(function (v) {
        return v.id;
    })(fps.forms))))(function () {
        return pure1({
            reorder: false,
            forms: fps.forms,
            movedDownIdx: fps.movedDownIdx,
            workQ: fps.workQ
        });
    }));
};
var emptyFormPickerState = /* #__PURE__ */ (function () {
    return {
        forms: [  ],
        reorder: false,
        movedDownIdx: Data_Maybe.Nothing.value,
        workQ: Steps_WorkQueue.initialWorkQueue
    };
})();
export {
    formPickerWidget,
    renderFormPicker,
    step2BWidget,
    initFormPicker,
    initFormPickerWidget,
    emptyFormPickerState,
    setFormReorder,
    endFormReorder
};
