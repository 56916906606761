// Generated by purs version 0.15.7
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Unfoldable from "../Data.Unfoldable/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ GraphQL_Type.eqRNTY(Data_Eq.eqString));
var ordRNTY = /* #__PURE__ */ GraphQL_Type.ordRNTY(Data_Ord.ordString);
var lookup = /* #__PURE__ */ Data_Map_Internal.lookup(ordRNTY);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var ordRecord = /* #__PURE__ */ Data_Ord.ordRecord()(/* #__PURE__ */ Data_Ord.ordRecordCons(/* #__PURE__ */ Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "studentId";
    }
})(ordRNTY))()({
    reflectSymbol: function () {
        return "formId";
    }
})(ordRNTY));
var lookup1 = /* #__PURE__ */ Data_Map_Internal.lookup(ordRecord);
var filterKeys = /* #__PURE__ */ Data_Map_Internal.filterKeys(ordRecord);
var fromMaybe = /* #__PURE__ */ Data_Unfoldable.fromMaybe(Data_Unfoldable.unfoldableArray);
var fromFoldable = /* #__PURE__ */ Data_Array.fromFoldable(Data_List_Types.foldableList);
var map = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var insert = /* #__PURE__ */ Data_Map_Internal.insert(ordRNTY);
var insert1 = /* #__PURE__ */ Data_Map_Internal.insert(ordRecord);
var matchStudent = function (student) {
    return function (docRef) {
        return eq(student)(docRef.studentId);
    };
};
var matchForm = function (form) {
    return function (docRef) {
        return eq(form)(docRef.formId);
    };
};
var listDocs = function (dm) {
    return Data_Map_Internal.values(dm.docs);
};
var getDocById = function (id) {
    return function (dm) {
        return lookup(id)(dm.docs);
    };
};
var getDocByLookup = function (dl) {
    return function (dm) {
        return bind(lookup1(dl)(dm.byLookup))(function (id) {
            return getDocById(id)(dm);
        });
    };
};
var filterOnStudentAndForm = function (student) {
    return function (form) {
        return function (dm) {
            return {
                docs: dm.docs,
                byLookup: filterKeys(function (docRef) {
                    return matchStudent(student)(docRef) && matchForm(form)(docRef);
                })(dm.byLookup)
            };
        };
    };
};
var filterOnStudent = function (student) {
    return function (dm) {
        var doLookup = function (id) {
            return lookup(id)(dm.docs);
        };
        return Data_Array.concatMap(fromMaybe)(fromFoldable(map(doLookup)(Data_Map_Internal.values(filterKeys(matchStudent(student))(dm.byLookup)))));
    };
};
var emptyDocMap = {
    docs: Data_Map_Internal.empty,
    byLookup: Data_Map_Internal.empty
};
var documentLookup = function (doc) {
    return {
        studentId: doc.student.id,
        formId: doc.formId
    };
};
var insertDoc = function (doc) {
    return function (dm) {
        return {
            docs: insert(doc.id)(doc)(dm.docs),
            byLookup: insert1(documentLookup(doc))(doc.id)(dm.byLookup)
        };
    };
};
export {
    emptyDocMap,
    insertDoc,
    listDocs,
    getDocById,
    getDocByLookup,
    documentLookup,
    matchStudent,
    matchForm,
    filterOnStudent,
    filterOnStudentAndForm
};
