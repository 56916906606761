// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_Modal from "../Widgets.Modal/index.js";
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h1 = /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var p = /* #__PURE__ */ Concur_React_DOM.p(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var confirmWidget = function (title) {
    return function (primaryButtonText) {
        return function (msg) {
            return div([ Concur_React_Props.className("confirmation-widget modal-grid") ])([ h1([ Concur_React_Props.className("modal-heading") ])([ text(title) ]), p([ Concur_React_Props.className("modal-body") ])([ text(msg) ]), div([ Concur_React_Props.className("btn-group btn-group-confirmation-dialog modal-buttons") ])([ button([ Concur_React_Props.className("btn"), voidRight(false)(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("cancel")) ]), button([ Concur_React_Props.className("btn btn-primary"), voidRight(true)(Concur_React_Props.onClick) ])([ text(primaryButtonText) ]) ]) ]);
        };
    };
};
var confirmModal = function (outer) {
    return function (title) {
        return function (primaryButtonText) {
            return function (msg) {
                return bind(Widgets_Modal.modalWidget(outer)(confirmWidget(title)(primaryButtonText)(msg)))(function (v) {
                    if (v instanceof Widgets_Modal.ModalClosed) {
                        return pure(false);
                    };
                    if (v instanceof Widgets_Modal.ModalReturned) {
                        return pure(v.value0);
                    };
                    throw new Error("Failed pattern match at Widgets.Confirmation (line 11, column 116 - line 13, column 30): " + [ v.constructor.name ]);
                });
            };
        };
    };
};
export {
    confirmModal,
    confirmWidget
};
