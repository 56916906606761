"use strict";

import * as Sentry from '@sentry/browser';

export const prettyPrintJson = function(json) {
    return JSON.stringify(json, null, 2)
}

export const sentryFlag = process.env.ENABLE_SENTRY == "true";

export const initSentry = function() {
    console.log('Sentry:' + exports.sentryFlag)
    if (exports.sentryFlag) {
        Sentry.init({
            dsn: process.env.SENTRY_DSN,
            environment: process.env.SENTRY_ENVIRONMENT,
        })
        console.log('Initialized Sentry')
    }
}

export const captureError = function(err) {
    return function() {
        var errId = ""
        console.log('Error: ' + err.toString())
        if (exports.sentryFlag) {
            errId = Sentry.captureMessage(err.message, {
                contexts: err.contexts
            })
            console.log('Reported to Sentry')
        }
        return errId
    }
}

export const reloadPage = function() {
    console.log("Reloading")
    window.location.reload()
}
