"use strict";

import React, { Component } from 'react';

export default class extends Component {
    div = {}

    render() {
        return (
            <div ref={ref=>this.div=ref}
                {...this.props}>
                {this.props.children}
            </div>
        )
    }

    // Fires a fake `blur` event that concur will pick up on.
    fireBlurEvent() {
        // Oddly just calling the handler function seems to be the best way to
        // do this.
        this.props.onBlur(new FocusEvent('blur'))
    }

    // Called when the user clicks anywhere on the page. Fires the blur event
    // if the click was outside this element.
    onClickAnywhere = (ev) => {
        if (this.div && !this.div.contains(ev.target)) {
            this.fireBlurEvent()
        }
    }

    componentDidMount() {
        // HACK: For some reason if we register the handler immediately, we
        // receive the click event that opened the dropdown, which counts as
        // outside the dropdown, which closes it. To fix this, we wait a bit
        // and then register the handler.
        setTimeout(() => {
            // After mounting the component, register a global click handler.
            document.addEventListener('click', this.onClickAnywhere)
        }, 1)
    }

    componentWillUnmount() {
        // Remove the event listener when this gets removed from the page.
        document.removeEventListener('click', this.onClickAnywhere)
    }
}
