// Generated by purs version 0.15.7
import * as API_Search from "../API.Search/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_BetterSelect from "../Widgets.BetterSelect/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var label = /* #__PURE__ */ Concur_React_DOM.label(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var map2 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var teacherSelectConfig = /* #__PURE__ */ (function () {
    return {
        placeholder: new Data_Maybe.Just(Translate.lookupTranslation("search-teacher")),
        deselectLabel: new Data_Maybe.Just(Translate.lookupTranslation("search-teacher-all")),
        allowDeselect: Widgets_BetterSelect.defSelectConfig.allowDeselect
    };
})();
var semesterSelectConfig = /* #__PURE__ */ (function () {
    return {
        placeholder: new Data_Maybe.Just(Translate.lookupTranslation("search-semester")),
        deselectLabel: new Data_Maybe.Just(Translate.lookupTranslation("search-semester-all")),
        allowDeselect: Widgets_BetterSelect.defSelectConfig.allowDeselect
    };
})();
var selectedTeacher = /* #__PURE__ */ (function () {
    var $54 = map(function (v) {
        return v.value;
    });
    return function ($55) {
        return $54(Widgets_BetterSelect.selectedItem((function (v) {
            return v.teacher;
        })($55)));
    };
})();
var selectedSemester = /* #__PURE__ */ (function () {
    var $56 = map(function (v) {
        return v.value;
    });
    return function ($57) {
        return $56(Widgets_BetterSelect.selectedItem((function (v) {
            return v.semester;
        })($57)));
    };
})();
var selectedClass = /* #__PURE__ */ (function () {
    var $58 = map(function (v) {
        return v.value;
    });
    return function ($59) {
        return $58(Widgets_BetterSelect.selectedItem((function (v) {
            return v["class"];
        })($59)));
    };
})();
var stateToQuery = function (state) {
    return {
        teacher: map(function (v) {
            return v.id;
        })(selectedTeacher(state)),
        "class": map(function (v) {
            return v.id;
        })(selectedClass(state)),
        semester: map(function (v) {
            return v.semester;
        })(selectedSemester(state)),
        search: (function () {
            var $40 = state.keyword !== "";
            if ($40) {
                return new Data_Maybe.Just(state.keyword);
            };
            return Data_Maybe.Nothing.value;
        })()
    };
};
var searchInput = function (q) {
    return input([ Concur_React_Props["_type"]("text"), Concur_React_Props.className("form-control search widget-input"), Concur_React_Props.value(q), map1(Concur_React_Props.unsafeTargetValue)(Concur_React_Props.onInput), Concur_React_Props.key("search-search-input"), Concur_React_Props.placeholder(Translate.lookupTranslation("search")) ]);
};
var searchWidget = function (state) {
    return div([ Concur_React_Props.className("search-form") ])([ div([ Concur_React_Props.className("search-selects") ])([ div([ Concur_React_Props.className("teacher-search-wrapper") ])([ label([ Concur_React_Props.className("teacher-search-label"), Concur_React_Props.htmlFor("teacher") ])([ text(Translate.lookupTranslation("teacher")) ]), map2(function (v) {
        return {
            teacher: v,
            "class": state["class"],
            keyword: state.keyword,
            semester: state.semester
        };
    })(Widgets_BetterSelect.selectWidget(state.teacher)) ]), div([ Concur_React_Props.className("semester-search-wrapper") ])([ label([ Concur_React_Props.className("semester-search-label"), Concur_React_Props.htmlFor("semester") ])([ text(Translate.lookupTranslation("semester")) ]), map2(function (v) {
        return {
            semester: v,
            "class": state["class"],
            keyword: state.keyword,
            teacher: state.teacher
        };
    })(Widgets_BetterSelect.selectWidget(state.semester)) ]), div([ Concur_React_Props.className("class-search-wrapper") ])([ label([ Concur_React_Props.className("class-search-label"), Concur_React_Props.htmlFor("class") ])([ text(Translate.lookupTranslation("class")) ]), map2(function (v) {
        return {
            "class": v,
            keyword: state.keyword,
            semester: state.semester,
            teacher: state.teacher
        };
    })(Widgets_BetterSelect.selectWidget(state["class"])) ]) ]), map2(function (v) {
        return {
            keyword: v,
            "class": state["class"],
            semester: state.semester,
            teacher: state.teacher
        };
    })(searchInput(state.keyword)) ]);
};
var hasFiltersSet = function (v) {
    return Data_Maybe.isJust(Widgets_BetterSelect.selectedItem(v.teacher)) || (Data_Maybe.isJust(Widgets_BetterSelect.selectedItem(v.semester)) || (Data_Maybe.isJust(Widgets_BetterSelect.selectedItem(v["class"])) || Data_String_CodePoints.length(v.keyword) > 0));
};
var classSelectConfig = /* #__PURE__ */ (function () {
    return {
        placeholder: new Data_Maybe.Just(Translate.lookupTranslation("search-class")),
        deselectLabel: new Data_Maybe.Just(Translate.lookupTranslation("search-class-all")),
        allowDeselect: Widgets_BetterSelect.defSelectConfig.allowDeselect
    };
})();
var initSearchState = {
    teacher: /* #__PURE__ */ Widgets_BetterSelect["initSelect$prime"]("teacher")(API_Search.teacherSearchAff)(teacherSelectConfig),
    semester: /* #__PURE__ */ Widgets_BetterSelect["initSelect$prime"]("semester")(API_Search.semesterSearchAff)(semesterSelectConfig),
    "class": /* #__PURE__ */ Widgets_BetterSelect["initSelect$prime"]("class")(API_Search.classSearchAff)(classSelectConfig),
    keyword: ""
};
var loadSearchState = function (v) {
    var teachers$prime = Widgets_BetterSelect["wrapSelectOption$prime"](function (v1) {
        return v1.name;
    })(v.teachers);
    var semesters$prime = Widgets_BetterSelect["wrapSelectOption$prime"](function (v1) {
        return v1.name;
    })(v.semesters);
    var classes$prime = Widgets_BetterSelect["wrapSelectOption$prime"](function (v1) {
        return v1.name;
    })(v.classes);
    return {
        teacher: Widgets_BetterSelect.setSelected(Widgets_BetterSelect.preloadSelect(initSearchState.teacher)(teachers$prime))(map(function (v2) {
            return {
                label: v2.name,
                value: v2
            };
        })(v.defaults.teacher)),
        semester: Widgets_BetterSelect.setSelected(Widgets_BetterSelect.preloadSelect(initSearchState.semester)(semesters$prime))(map(function (v2) {
            return {
                label: v2.name,
                value: v2
            };
        })(v.defaults.semester)),
        "class": Widgets_BetterSelect.setSelected(Widgets_BetterSelect.preloadSelect(initSearchState["class"])(classes$prime))(map(function (v2) {
            return {
                label: v2.name,
                value: v2
            };
        })(v.defaults.group)),
        keyword: initSearchState.keyword
    };
};
export {
    hasFiltersSet,
    loadSearchState,
    initSearchState,
    teacherSelectConfig,
    semesterSelectConfig,
    classSelectConfig,
    selectedTeacher,
    selectedSemester,
    selectedClass,
    stateToQuery,
    searchWidget,
    searchInput
};
