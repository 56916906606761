// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as $$Error from "../Error/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
var showRecord = /* #__PURE__ */ Data_Show.showRecord()();
var idIsSymbol = {
    reflectSymbol: function () {
        return "id";
    }
};
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var p = /* #__PURE__ */ Concur_React_DOM.p(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h4$prime = /* #__PURE__ */ Concur_React_DOM["h4$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var Label = function (x) {
    return x;
};
var FormError = function (x) {
    return x;
};
var showLabel = /* #__PURE__ */ showRecord(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "cls";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons(idIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "label";
    }
})(Data_Show.showString))(Data_Show.showString))(Data_Show.showString));
var showFormError = /* #__PURE__ */ showRecord(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "err";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil(idIsSymbol)(Data_Show.showString))($$Error.showErrorWrapper));
var labelFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return true;
    },
    asField: function (v) {
        return Data_Maybe.Nothing.value;
    },
    toWidget: function (fc) {
        return function (v) {
            return p([ Concur_React_Props.dangerouslySetInnerHTML({
                "__html": v.label
            }), Concur_React_Props.className(Widgets_Form_Class.fieldClass(v)), Concur_React_Props.key(Widgets_Form_Class.fieldId(fc)(v.id)), Concur_React_Props["_id"](Widgets_Form_Class.fieldId(fc)(v.id)) ])([  ]);
        };
    },
    getData: Widgets_Form_Class.noGetData,
    setData: Widgets_Form_Class.noSetData
};
var formErrFW = {
    getId: function (v) {
        return v.id;
    },
    isLeaf: function (v) {
        return true;
    },
    asField: function (v) {
        return Data_Maybe.Nothing.value;
    },
    toWidget: function (fc) {
        return function (v) {
            var errText = "An error occurred while handling widget with ID " + Widgets_Form_Class.fieldId(fc)(v.id);
            return div([ Concur_React_Props.className("widget-error"), Concur_React_Props.key(Widgets_Form_Class.fieldId(fc)(v.id)) ])([ h4$prime([ text(errText) ]), $$Error.renderErrorDetails(v.err) ]);
        };
    },
    getData: Widgets_Form_Class.noGetData,
    setData: Widgets_Form_Class.noSetData
};
export {
    Label,
    FormError,
    showLabel,
    labelFW,
    showFormError,
    formErrFW
};
