// Generated by purs version 0.15.7
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Effect_Ref from "../Effect.Ref/index.js";
import * as React from "../React/index.js";
var toElement = /* #__PURE__ */ React.toElement(React.isReactElementArray);
var $$void = /* #__PURE__ */ Data_Functor["void"](Effect.functorEffect);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Effect.applyEffect);
var createLeafElement = /* #__PURE__ */ React.createLeafElement();
var component1 = /* #__PURE__ */ React.component();
var mempty = /* #__PURE__ */ Data_Monoid.mempty(/* #__PURE__ */ Effect.monoidEffect(Data_Monoid.monoidUnit));
var render = function (st) {
    return toElement(st.view);
};
var mkComponentState = function (v) {
    return {
        view: v
    };
};
var component = function (onMount) {
    return function (vinit) {
        return function (ref) {
            return function ($$this) {
                var handler = function (t) {
                    return function (v) {
                        return function (r) {
                            return function __do() {
                                Effect_Ref.write(new Data_Maybe.Just(t))(r)();
                                return $$void(React.writeState(t)(mkComponentState(v)))();
                            };
                        };
                    };
                };
                return pure({
                    state: mkComponentState(vinit),
                    render: map(render)(React.getState($$this)),
                    componentDidMount: applySecond(onMount)(handler($$this)(vinit)(ref))
                });
            };
        };
    };
};
var element = function (onMount) {
    return function (v) {
        return function (ref) {
            return createLeafElement(component1("Concur")(component(onMount)(v)(ref)))({});
        };
    };
};
var componentClassWithMount = function (onMount) {
    return function (w) {
        return function (renderer) {
            return function __do() {
                var thisRef = Effect_Ref["new"](Data_Maybe.Nothing.value)();
                return $$void(Concur_Core_Types.runWidget(w)(function (res) {
                    if (res instanceof Concur_Core_Types.View) {
                        return function __do() {
                            var mt = Effect_Ref.read(thisRef)();
                            if (mt instanceof Data_Maybe.Just) {
                                return $$void(React.writeState(mt.value0)(mkComponentState(res.value0)))();
                            };
                            if (mt instanceof Data_Maybe.Nothing) {
                                return renderer(element(onMount)(res.value0)(thisRef))();
                            };
                            throw new Error("Failed pattern match at Concur.React (line 34, column 9 - line 36, column 58): " + [ mt.constructor.name ]);
                        };
                    };
                    return Effect_Console.log("Application exited");
                }))();
            };
        };
    };
};
var renderComponent = function (winit) {
    return function (renderer) {
        return componentClassWithMount(mempty)(winit)(renderer);
    };
};
export {
    mkComponentState,
    componentClassWithMount,
    render,
    component,
    element,
    renderComponent
};
