// Generated by purs version 0.15.7
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Widgets_Checkbox from "../Widgets.Checkbox/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var selectAll = function (dictOrd) {
    var union = Data_Map_Internal.union(dictOrd);
    var fromFoldable1 = Data_Map_Internal.fromFoldable(dictOrd);
    var difference = Data_Map_Internal.difference(dictOrd);
    return function (dictFoldable) {
        var fromFoldable2 = fromFoldable1(dictFoldable);
        return function (v) {
            return function (v1) {
                return function (v2) {
                    if (v2) {
                        return {
                            sel: union(v1.sel)(fromFoldable2(v))
                        };
                    };
                    if (!v2) {
                        return {
                            sel: difference(v1.sel)(fromFoldable2(v))
                        };
                    };
                    throw new Error("Failed pattern match at Internal.SelectionMap (line 56, column 1 - line 57, column 71): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
                };
            };
        };
    };
};
var selectAll$prime = function (dictOrd) {
    var selectAll1 = selectAll(dictOrd);
    return function (dictFunctor) {
        var map1 = Data_Functor.map(dictFunctor);
        return function (dictFoldable) {
            var selectAll2 = selectAll1(dictFoldable);
            return function (vs) {
                return selectAll2(map1(function (v) {
                    return new Data_Tuple.Tuple(v.id, v);
                })(vs));
            };
        };
    };
};
var select = function (dictOrd) {
    var insert = Data_Map_Internal.insert(dictOrd);
    return function (k) {
        return function (v) {
            return function (s) {
                return {
                    sel: insert(k)(v)(s.sel)
                };
            };
        };
    };
};
var select$prime = function (dictOrd) {
    var select1 = select(dictOrd);
    return function (v) {
        return select1(v.id)(v);
    };
};
var listSelected = function (v) {
    return Data_Map_Internal.values(v.sel);
};
var isSelected = function (dictOrd) {
    var member = Data_Map_Internal.member(dictOrd);
    return function (k) {
        return function (v) {
            return member(k)(v.sel);
        };
    };
};
var isEmpty = function (dictOrd) {
    return function (v) {
        return Data_Map_Internal.isEmpty(v.sel);
    };
};
var getSelected = function (dictOrd) {
    var lookup = Data_Map_Internal.lookup(dictOrd);
    return function (k) {
        return function (v) {
            return lookup(k)(v.sel);
        };
    };
};
var fromFoldable = function (dictOrd) {
    var fromFoldable1 = Data_Map_Internal.fromFoldable(dictOrd);
    return function (dictFoldable) {
        var fromFoldable2 = fromFoldable1(dictFoldable);
        return function (arr) {
            return {
                sel: fromFoldable2(arr)
            };
        };
    };
};
var fromFoldable$prime = function (dictOrd) {
    var fromFoldable1 = fromFoldable(dictOrd);
    return function (dictFunctor) {
        var map1 = Data_Functor.map(dictFunctor);
        return function (dictFoldable) {
            var $100 = fromFoldable1(dictFoldable);
            var $101 = map1(function (v) {
                return new Data_Tuple.Tuple(v.id, v);
            });
            return function ($102) {
                return $100($101($102));
            };
        };
    };
};
var empty = {
    sel: Data_Map_Internal.empty
};
var deselect = function (dictOrd) {
    var $$delete = Data_Map_Internal["delete"](dictOrd);
    return function (k) {
        return function (s) {
            return {
                sel: $$delete(k)(s.sel)
            };
        };
    };
};
var setSelected = function (dictOrd) {
    var select1 = select(dictOrd);
    var deselect1 = deselect(dictOrd);
    return function (v) {
        return function (v1) {
            return function (v2) {
                return function (v3) {
                    if (v3) {
                        return select1(v)(v1)(v2);
                    };
                    if (!v3) {
                        return deselect1(v)(v2);
                    };
                    throw new Error("Failed pattern match at Internal.SelectionMap (line 40, column 1 - line 40, column 94): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name, v3.constructor.name ]);
                };
            };
        };
    };
};
var selectionCheckbox = function (dictOrd) {
    var setSelected1 = setSelected(dictOrd);
    var isSelected1 = isSelected(dictOrd);
    return function (k) {
        return function (v) {
            return function (renderCheck) {
                return function (sel) {
                    return map(setSelected1(k)(v)(sel))(renderCheck(isSelected1(k)(sel)));
                };
            };
        };
    };
};
var selectionCheckbox$prime = function (dictOrd) {
    var selectionCheckbox1 = selectionCheckbox(dictOrd);
    return function (k) {
        return function (v) {
            return function (props) {
                return selectionCheckbox1(k)(v)(Widgets_Checkbox.checkbox(props));
            };
        };
    };
};
var areAllSelected = function (dictOrd) {
    var member = Data_Map_Internal.member(dictOrd);
    return function (dictFoldable) {
        var all = Data_Foldable.all(dictFoldable)(Data_HeytingAlgebra.heytingAlgebraBoolean);
        return function (ks) {
            return function (v) {
                return all(function (v1) {
                    return member(v1)(v.sel);
                })(ks);
            };
        };
    };
};
var selectAllCheckbox = function (dictFunctor) {
    var map1 = Data_Functor.map(dictFunctor);
    return function (dictFoldable) {
        return function (dictOrd) {
            var selectAll1 = selectAll(dictOrd)(dictFoldable);
            var areAllSelected1 = areAllSelected(dictOrd)(dictFoldable);
            return function (items) {
                return function (renderCheck) {
                    return function (sel) {
                        var itemKeys = map1(Data_Tuple.fst)(items);
                        return map(selectAll1(items)(sel))(renderCheck(areAllSelected1(itemKeys)(sel)));
                    };
                };
            };
        };
    };
};
var selectAllCheckbox$prime = function (dictOrd) {
    return function (dictFunctor) {
        var selectAllCheckbox1 = selectAllCheckbox(dictFunctor);
        var map1 = Data_Functor.map(dictFunctor);
        return function (dictFoldable) {
            var selectAllCheckbox2 = selectAllCheckbox1(dictFoldable)(dictOrd);
            return function (items) {
                return selectAllCheckbox2(map1(function (v) {
                    return new Data_Tuple.Tuple(v.id, v);
                })(items));
            };
        };
    };
};
export {
    empty,
    fromFoldable,
    fromFoldable$prime,
    select,
    select$prime,
    deselect,
    setSelected,
    isSelected,
    getSelected,
    areAllSelected,
    isEmpty,
    selectAll,
    selectAll$prime,
    listSelected,
    selectionCheckbox,
    selectionCheckbox$prime,
    selectAllCheckbox,
    selectAllCheckbox$prime
};
