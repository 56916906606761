// Generated by purs version 0.15.7
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Widgets_Form_Class from "../Widgets.Form.Class/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var map2 = /* #__PURE__ */ Data_Functor.map(Widgets_Form_Class.fwrFunctor);
var DynFormWidget = /* #__PURE__ */ (function () {
    function DynFormWidget(value0) {
        this.value0 = value0;
    };
    DynFormWidget.create = function (value0) {
        return new DynFormWidget(value0);
    };
    return DynFormWidget;
})();
var dynWidgetShow = {
    show: function (v) {
        return v.value0.show(v);
    }
};
var dynFormWidget = {
    getId: function (v) {
        return v.value0.getId(v);
    },
    isLeaf: function (v) {
        return v.value0.isLeaf(v);
    },
    asField: function (v) {
        return v.value0.asField(v);
    },
    toWidget: function (fc) {
        return function (v) {
            return v.value0.toWidget(fc)(v);
        };
    },
    getData: function (fc) {
        return function (v) {
            return v.value0.getData(fc)(v);
        };
    },
    setData: function (fc) {
        return function (v) {
            return function (json) {
                return v.value0.setData(fc)(v)(json);
            };
        };
    }
};
var isLeaf = /* #__PURE__ */ Widgets_Form_Class.isLeaf(dynFormWidget);
var wrapFormWidget = function (dictShow) {
    var show = Data_Show.show(dictShow);
    return function (dictFormWidget) {
        var setData = Widgets_Form_Class.setData(dictFormWidget);
        var toWidget = Widgets_Form_Class.toWidget(dictFormWidget);
        var getId = Widgets_Form_Class.getId(dictFormWidget);
        var isLeaf1 = Widgets_Form_Class.isLeaf(dictFormWidget);
        var asField = Widgets_Form_Class.asField(dictFormWidget);
        var getData = Widgets_Form_Class.getData(dictFormWidget);
        return function (widget) {
            var updateDFW = function (v) {
                return function ($$new) {
                    return new DynFormWidget({
                        inner: $$new,
                        getId: v.value0.getId,
                        isLeaf: v.value0.isLeaf,
                        asField: v.value0.asField,
                        toWidget: v.value0.toWidget,
                        getData: v.value0.getData,
                        setData: v.value0.setData,
                        show: v.value0.show
                    });
                };
            };
            var fromDFW = function (v) {
                return v.value0.inner;
            };
            var wrappedSetData = function (fc) {
                return function (dfw) {
                    return function (json) {
                        return map(updateDFW(dfw))(setData(fc)(fromDFW(dfw))(json));
                    };
                };
            };
            var wrappedToWidget = function (v) {
                return function (v1) {
                    if (isLeaf(v1)) {
                        return map1(function (v2) {
                            return map2(updateDFW(v1))(v2);
                        })(toWidget(v)(fromDFW(v1)));
                    };
                    return map1(function (v2) {
                        return map2(updateDFW(v1))(v2);
                    })(toWidget(v)(fromDFW(v1)));
                };
            };
            return new DynFormWidget({
                inner: widget,
                getId: function ($54) {
                    return getId(fromDFW($54));
                },
                isLeaf: function ($55) {
                    return isLeaf1(fromDFW($55));
                },
                asField: function ($56) {
                    return asField(fromDFW($56));
                },
                toWidget: wrappedToWidget,
                getData: function (fc) {
                    var $57 = getData(fc);
                    return function ($58) {
                        return $57(fromDFW($58));
                    };
                },
                setData: wrappedSetData,
                show: function ($59) {
                    return show(fromDFW($59));
                }
            });
        };
    };
};
export {
    wrapFormWidget,
    dynWidgetShow,
    dynFormWidget
};
