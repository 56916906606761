// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Control_Promise from "../Control.Promise/index.js";
var writeText = function ($1) {
    return Control_Promise.toAff($foreign.writeText_($1));
};
export {
    writeText_
} from "./foreign.js";
export {
    writeText
};
