// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Number from "../Data.Number/index.js";
var EndByte = function (x) {
    return x;
};
var StartByte = function (x) {
    return x;
};
var type_ = function (blob) {
    var blobType = $foreign.typeImpl(blob);
    var $2 = blobType === "";
    if ($2) {
        return Data_Maybe.Nothing.value;
    };
    return new Data_Maybe.Just(blobType);
};
var slice$prime = /* #__PURE__ */ $foreign.slice("");
var idxFromNumber = function ($3) {
    return Data_Number.round($3);
};
var idxFromInt = function ($4) {
    return Data_Int.toNumber($4);
};
var fromString = function (str) {
    return function (ct) {
        return $foreign.blobImpl([ str ])(ct);
    };
};
var fromArray = function (args) {
    return function (opts) {
        return $foreign.blobImpl(args)(opts);
    };
};
export {
    size,
    slice
} from "./foreign.js";
export {
    fromString,
    fromArray,
    type_,
    StartByte,
    EndByte,
    idxFromInt,
    idxFromNumber,
    slice$prime
};
