// Generated by purs version 0.15.7
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Combinators from "../Data.Argonaut.Decode.Combinators/index.js";
import * as Data_Argonaut_Decode_Error from "../Data.Argonaut.Decode.Error/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Show from "../Data.Show/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var getField = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(Data_Argonaut_Decode_Class.decodeJsonJson);
var foldM = /* #__PURE__ */ Data_Foldable.foldM(Data_Foldable.foldableArray)(Data_Either.monadEither);
var ObjectKey = /* #__PURE__ */ (function () {
    function ObjectKey(value0) {
        this.value0 = value0;
    };
    ObjectKey.create = function (value0) {
        return new ObjectKey(value0);
    };
    return ObjectKey;
})();
var ArrayIndex = /* #__PURE__ */ (function () {
    function ArrayIndex(value0) {
        this.value0 = value0;
    };
    ArrayIndex.create = function (value0) {
        return new ArrayIndex(value0);
    };
    return ArrayIndex;
})();
var numberToString = function (v) {
    if (Data_Argonaut_Core.isString(v)) {
        return Data_Either.note(new Data_Argonaut_Decode_Error.TypeMismatch("String"))(Data_Argonaut_Core.toString(v));
    };
    if (Data_Argonaut_Core.isNumber(v)) {
        return Data_Either.note(new Data_Argonaut_Decode_Error.TypeMismatch("Number"))(map(map1(show)(Data_Int.floor))(Data_Argonaut_Core.toNumber(v)));
    };
    return new Data_Either.Left(new Data_Argonaut_Decode_Error.TypeMismatch("String or Number"));
};
var jsonErrToString = function (v) {
    if (v instanceof Data_Either.Left) {
        return new Data_Either.Left(Data_Argonaut_Decode_Error.printJsonDecodeError(v.value0));
    };
    if (v instanceof Data_Either.Right) {
        return new Data_Either.Right(v.value0);
    };
    throw new Error("Failed pattern match at Internal.JsonUtil (line 29, column 1 - line 29, column 73): " + [ v.constructor.name ]);
};
var assertObject = function (json) {
    var v = Data_Argonaut_Core.toObject(json);
    if (v instanceof Data_Maybe.Just) {
        return new Data_Either.Right(v.value0);
    };
    if (v instanceof Data_Maybe.Nothing) {
        return new Data_Either.Left(new Data_Argonaut_Decode_Error.TypeMismatch("Expected object"));
    };
    throw new Error("Failed pattern match at Internal.JsonUtil (line 17, column 21 - line 19, column 53): " + [ v.constructor.name ]);
};
var assertArray = function (json) {
    var v = Data_Argonaut_Core.toArray(json);
    if (v instanceof Data_Maybe.Just) {
        return new Data_Either.Right(v.value0);
    };
    if (v instanceof Data_Maybe.Nothing) {
        return new Data_Either.Left(new Data_Argonaut_Decode_Error.TypeMismatch("Expected array"));
    };
    throw new Error("Failed pattern match at Internal.JsonUtil (line 24, column 20 - line 26, column 52): " + [ v.constructor.name ]);
};
var lookupPathElem = function (v) {
    return function (v1) {
        if (v instanceof ObjectKey) {
            return bind(assertObject(v1))(function (v2) {
                return getField(v2)(v.value0);
            });
        };
        if (v instanceof ArrayIndex) {
            var err = new Data_Argonaut_Decode_Error.AtIndex(v.value0, Data_Argonaut_Decode_Error.MissingValue.value);
            return bind(assertArray(v1))(function (j) {
                return Data_Either.note(err)(Data_Array.index(j)(v.value0));
            });
        };
        throw new Error("Failed pattern match at Internal.JsonUtil (line 39, column 1 - line 39, column 70): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var lookupPath = function (path) {
    return function (json) {
        return foldM(function (j) {
            return function (p) {
                return lookupPathElem(p)(j);
            };
        })(json)(path);
    };
};
export {
    assertObject,
    assertArray,
    jsonErrToString,
    lookupPath,
    lookupPathElem,
    numberToString,
    ObjectKey,
    ArrayIndex
};
