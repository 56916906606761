// Generated by purs version 0.15.7
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Decoders from "../Data.Argonaut.Decode.Decoders/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var getFieldOptional$prime = function (dictDecodeJson) {
    return Data_Argonaut_Decode_Decoders["getFieldOptional$prime"](Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson));
};
var getFieldOptional = function (dictDecodeJson) {
    return Data_Argonaut_Decode_Decoders.getFieldOptional(Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson));
};
var getField = function (dictDecodeJson) {
    return Data_Argonaut_Decode_Decoders.getField(Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson));
};
var defaultField = function (parser) {
    return function ($$default) {
        return map(Data_Maybe.fromMaybe($$default))(parser);
    };
};
export {
    getField,
    getFieldOptional,
    getFieldOptional$prime,
    defaultField
};
