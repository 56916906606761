// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Affjax from "../Affjax/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Combinators from "../Data.Argonaut.Decode.Combinators/index.js";
import * as Data_Argonaut_Decode_Decoders from "../Data.Argonaut.Decode.Decoders/index.js";
import * as Data_Argonaut_Decode_Error from "../Data.Argonaut.Decode.Error/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as Internal_Debug from "../Internal.Debug/index.js";
import * as Steps_Step from "../Steps.Step/index.js";
import * as Translate from "../Translate/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var getField = /* #__PURE__ */ Data_Argonaut_Decode_Combinators.getField(Data_Argonaut_Decode_Class.decodeJsonString);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var p$prime = /* #__PURE__ */ Concur_React_DOM["p$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var pre$prime = /* #__PURE__ */ Concur_React_DOM["pre$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var details$prime = /* #__PURE__ */ Concur_React_DOM["details$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var summary$prime = /* #__PURE__ */ Concur_React_DOM["summary$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var ul$prime = /* #__PURE__ */ Concur_React_DOM["ul$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var li$prime = /* #__PURE__ */ Concur_React_DOM["li$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var gEncodeJsonCons = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString);
var gEncodeJsonCons1 = /* #__PURE__ */ gEncodeJsonCons(Data_Argonaut_Encode_Class.gEncodeJsonNil);
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons1({
    reflectSymbol: function () {
        return "widget";
    }
})())());
var encodeJson1 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons1({
    reflectSymbol: function () {
        return "module";
    }
})())());
var encodeJson2 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ GraphQL_Type.encRNTY(Data_Argonaut_Encode_Class.encodeJsonJString))(/* #__PURE__ */ gEncodeJsonCons1({
    reflectSymbol: function () {
        return "name";
    }
})())({
    reflectSymbol: function () {
        return "id";
    }
})())());
var encodeJson3 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons1({
    reflectSymbol: function () {
        return "vars";
    }
})())({
    reflectSymbol: function () {
        return "query";
    }
})())());
var encodeJson4 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons1({
    reflectSymbol: function () {
        return "json";
    }
})())({
    reflectSymbol: function () {
        return "description";
    }
})())());
var encodeJson5 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons1({
    reflectSymbol: function () {
        return "action";
    }
})())());
var h1$prime = /* #__PURE__ */ Concur_React_DOM["h1$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.bindWidget);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var fromHomogeneous = /* #__PURE__ */ Foreign_Object.fromHomogeneous();
var encodeJson6 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJson))(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
    reflectSymbol: function () {
        return "errors";
    }
})())());
var liftEffect1 = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var InFormWidget = /* #__PURE__ */ (function () {
    function InFormWidget(value0) {
        this.value0 = value0;
    };
    InFormWidget.create = function (value0) {
        return new InFormWidget(value0);
    };
    return InFormWidget;
})();
var InModule = /* #__PURE__ */ (function () {
    function InModule(value0) {
        this.value0 = value0;
    };
    InModule.create = function (value0) {
        return new InModule(value0);
    };
    return InModule;
})();
var InQuery = /* #__PURE__ */ (function () {
    function InQuery(value0) {
        this.value0 = value0;
    };
    InQuery.create = function (value0) {
        return new InQuery(value0);
    };
    return InQuery;
})();
var InForm = /* #__PURE__ */ (function () {
    function InForm(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    InForm.create = function (value0) {
        return function (value1) {
            return new InForm(value0, value1);
        };
    };
    return InForm;
})();
var LoadingJson = /* #__PURE__ */ (function () {
    function LoadingJson(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    LoadingJson.create = function (value0) {
        return function (value1) {
            return new LoadingJson(value0, value1);
        };
    };
    return LoadingJson;
})();
var DuringAction = /* #__PURE__ */ (function () {
    function DuringAction(value0) {
        this.value0 = value0;
    };
    DuringAction.create = function (value0) {
        return new DuringAction(value0);
    };
    return DuringAction;
})();
var AffJaxError = /* #__PURE__ */ (function () {
    function AffJaxError(value0) {
        this.value0 = value0;
    };
    AffJaxError.create = function (value0) {
        return new AffJaxError(value0);
    };
    return AffJaxError;
})();
var DecodeError = /* #__PURE__ */ (function () {
    function DecodeError(value0) {
        this.value0 = value0;
    };
    DecodeError.create = function (value0) {
        return new DecodeError(value0);
    };
    return DecodeError;
})();
var QueryError = /* #__PURE__ */ (function () {
    function QueryError(value0) {
        this.value0 = value0;
    };
    QueryError.create = function (value0) {
        return new QueryError(value0);
    };
    return QueryError;
})();
var StringError = /* #__PURE__ */ (function () {
    function StringError(value0) {
        this.value0 = value0;
    };
    StringError.create = function (value0) {
        return new StringError(value0);
    };
    return StringError;
})();
var WithContext = /* #__PURE__ */ (function () {
    function WithContext(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WithContext.create = function (value0) {
        return function (value1) {
            return new WithContext(value0, value1);
        };
    };
    return WithContext;
})();
var withError = function (v) {
    return function (v1) {
        if (v1 instanceof Data_Either.Left) {
            return new Data_Either.Left(v(v1.value0));
        };
        if (v1 instanceof Data_Either.Right) {
            return new Data_Either.Right(v1.value0);
        };
        throw new Error("Failed pattern match at Error (line 187, column 1 - line 187, column 67): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var withError$prime = function (dictMonad) {
    var bind2 = Control_Bind.bind(dictMonad.Bind1());
    var pure1 = Control_Applicative.pure(dictMonad.Applicative0());
    return function (f) {
        return function (ext) {
            return bind2(Control_Monad_Except_Trans.runExceptT(ext))(function (ret) {
                return pure1(withError(f)(ret));
            });
        };
    };
};
var userError = function ($copy_v) {
    var $tco_done = false;
    var $tco_result;
    function $tco_loop(v) {
        if (v instanceof QueryError) {
            var msgM = bind(Data_Array.index(v.value0)(0))(function (json) {
                return bind(Data_Either.hush(Data_Argonaut_Decode_Decoders.decodeJObject(json)))(function (obj) {
                    return Data_Either.hush(getField(obj)("message"));
                });
            });
            if (msgM instanceof Data_Maybe.Just) {
                $tco_done = true;
                return msgM.value0;
            };
            if (msgM instanceof Data_Maybe.Nothing) {
                $tco_done = true;
                return Translate.lookupTranslation("generic-error");
            };
            throw new Error("Failed pattern match at Error (line 76, column 5 - line 78, column 53): " + [ msgM.constructor.name ]);
        };
        if (v instanceof WithContext) {
            $copy_v = v.value1;
            return;
        };
        $tco_done = true;
        return Translate.lookupTranslation("generic-error");
    };
    while (!$tco_done) {
        $tco_result = $tco_loop($copy_v);
    };
    return $tco_result;
};
var tagContext$prime = function (dictMonad) {
    var withError$prime1 = withError$prime(dictMonad);
    return function (ctx) {
        return withError$prime1(WithContext.create(ctx));
    };
};
var tagContext = function (ctx) {
    return withError(WithContext.create(ctx));
};
var tagModule = function ($205) {
    return tagContext(InModule.create($205));
};
var tagWidget = function ($206) {
    return tagContext(InFormWidget.create($206));
};
var tagAction = function ($207) {
    return tagContext(DuringAction.create($207));
};
var showCtx = {
    show: function (v) {
        if (v instanceof InFormWidget) {
            return "form widget \"" + (v.value0 + "\"");
        };
        if (v instanceof InModule) {
            return "purescript module \"" + (v.value0 + "\"");
        };
        if (v instanceof InForm) {
            return "form \"" + (v.value1 + ("\" (" + (GraphQL_Type.innerId(v.value0) + ")")));
        };
        if (v instanceof InQuery) {
            return "query \"" + (v.value0.query + "\"");
        };
        if (v instanceof LoadingJson && v.value0 === "") {
            return "while loading json: \"" + (Data_Argonaut_Core.stringify(v.value1) + "\"");
        };
        if (v instanceof LoadingJson) {
            return "while loading " + (v.value0 + (" json: \"" + (Data_Argonaut_Core.stringify(v.value1) + "\"")));
        };
        if (v instanceof DuringAction) {
            return "while " + v.value0;
        };
        throw new Error("Failed pattern match at Error (line 169, column 1 - line 176, column 48): " + [ v.constructor.name ]);
    }
};
var show = /* #__PURE__ */ Data_Show.show(showCtx);
var showErrorWrapper = {
    show: function (v) {
        if (v instanceof AffJaxError) {
            return Affjax.printError(v.value0);
        };
        if (v instanceof DecodeError) {
            return Data_Argonaut_Decode_Error.printJsonDecodeError(v.value0);
        };
        if (v instanceof QueryError) {
            return "backend rejected query";
        };
        if (v instanceof StringError) {
            return v.value0;
        };
        if (v instanceof WithContext) {
            return show(v.value0) + (": " + Data_Show.show(showErrorWrapper)(v.value1));
        };
        throw new Error("Failed pattern match at Error (line 66, column 1 - line 71, column 62): " + [ v.constructor.name ]);
    }
};
var show1 = /* #__PURE__ */ Data_Show.show(showErrorWrapper);
var renderError_ = function (v) {
    if (v instanceof WithContext && v.value0 instanceof InQuery) {
        return div([ Concur_React_Props.className("error-context-wrapper pl-4") ])([ p$prime([ text("While executing the following query: ") ]), pre$prime([ text(v.value0.value0.query) ]), details$prime([ summary$prime([ text("Click to show query variables") ]), pre$prime([ text($foreign.prettyPrintJson(v.value0.value0.vars)) ]) ]), renderError_(v.value1) ]);
    };
    if (v instanceof WithContext && v.value0 instanceof LoadingJson) {
        return div([ Concur_React_Props.className("error-context-wrapper pl-4") ])([ details$prime([ summary$prime([ text("While decoding JSON data" + (function () {
            var $161 = v.value0.value0 === "";
            if ($161) {
                return "";
            };
            return " for " + v.value0.value0;
        })()) ]), pre$prime([ text($foreign.prettyPrintJson(v.value0.value1)) ]) ]), renderError_(v.value1) ]);
    };
    if (v instanceof WithContext) {
        return div([ Concur_React_Props.className("error-context-wrapper pl-4") ])([ text("In context: " + show(v.value0)), renderError_(v.value1) ]);
    };
    if (v instanceof QueryError) {
        return div([ Concur_React_Props.className("error-context-error pl-4") ])([ text("The backend rejected the query with the following errors:"), ul$prime(map(function (msg) {
            return li$prime([ pre$prime([ text($foreign.prettyPrintJson(msg)) ]) ]);
        })(v.value0)) ]);
    };
    return div([ Concur_React_Props.className("error-context-error pl-4") ])([ p$prime([ text("Error: " + show1(v)) ]) ]);
};
var renderErrorDetails = function (err) {
    return div$prime([ p$prime([ text("More information about this error:") ]), div([ Concur_React_Props.className("error-details") ])([ renderError_(err) ]) ]);
};
var onLeft = function (fn) {
    return function (v) {
        if (v instanceof Data_Either.Left) {
            return new Data_Either.Left(fn(v.value0));
        };
        if (v instanceof Data_Either.Right) {
            return new Data_Either.Right(v.value0);
        };
        throw new Error("Failed pattern match at Error (line 288, column 13 - line 290, column 23): " + [ v.constructor.name ]);
    };
};
var wrapJsonError = /* #__PURE__ */ (function () {
    return onLeft(DecodeError.create);
})();
var wrapJsonError$prime = function (obj) {
    return onLeft((function () {
        var $208 = WithContext.create(new LoadingJson("", obj));
        return function ($209) {
            return $208(DecodeError.create($209));
        };
    })());
};
var encodeContext = function (v) {
    if (v instanceof InFormWidget) {
        return new Data_Tuple.Tuple("form-widget", encodeJson({
            widget: v.value0
        }));
    };
    if (v instanceof InModule) {
        return new Data_Tuple.Tuple("module", encodeJson1({
            module: v.value0
        }));
    };
    if (v instanceof InForm) {
        return new Data_Tuple.Tuple("form", encodeJson2({
            id: v.value0,
            name: v.value1
        }));
    };
    if (v instanceof InQuery) {
        return new Data_Tuple.Tuple("query", encodeJson3({
            query: v.value0.query,
            vars: $foreign.prettyPrintJson(v.value0.vars)
        }));
    };
    if (v instanceof LoadingJson) {
        return new Data_Tuple.Tuple("load-json-" + v.value0, encodeJson4({
            description: v.value0,
            json: $foreign.prettyPrintJson(v.value1)
        }));
    };
    if (v instanceof DuringAction) {
        return new Data_Tuple.Tuple("action", encodeJson5({
            action: v.value0
        }));
    };
    throw new Error("Failed pattern match at Error (line 153, column 1 - line 153, column 51): " + [ v.constructor.name ]);
};
var displayError_ = function (errId) {
    return function (btn) {
        return function (inner) {
            return div([ Concur_React_Props.className("error-page") ])([ h1$prime([ text(Translate.lookupTranslation("error-page-header")) ]), p$prime([ text(Translate.lookupTranslation("error-refcode-text") + (" " + errId)) ]), p$prime([ text(Translate.lookupTranslation("error-button-pre-text")) ]), btn, inner ]);
        };
    };
};
var displayErrorN = function (state) {
    return function (errId) {
        return function (inner) {
            return displayError_(errId)(button([ voidRight(new Steps_Step.WidgetResult(Steps_Step.NavCancel.value, state))(Concur_React_Props.onClick), Concur_React_Props.className("btn btn-primary") ])([ text(Translate.lookupTranslation("error-button-text")) ]))(Internal_ConcurUtil.loopInfinitely(inner));
        };
    };
};
var displayError = function (errId) {
    return displayError_(errId)(bind1(button([ Concur_React_Props.onClick, Concur_React_Props.className("btn btn-primary") ])([ text(Translate.lookupTranslation("error-button-text")) ]))(function () {
        return discard(liftEffect($foreign.reloadPage))(function () {
            return Internal_ConcurUtil.loopInfinitely(div$prime([  ]));
        });
    }));
};
var contextPrefix = function (v) {
    if (v instanceof InFormWidget) {
        return "in form widget " + (v.value0 + ": ");
    };
    if (v instanceof InForm) {
        return "form \"" + (v.value1 + ("\" (" + (GraphQL_Type.innerId(v.value0) + "): ")));
    };
    if (v instanceof DuringAction) {
        return "while " + (v.value0 + ": ");
    };
    return "";
};
var toSentry = function (v) {
    if (v instanceof WithContext) {
        var v1 = encodeContext(v.value0);
        var inner = toSentry(v.value1);
        var $190 = Foreign_Object.member(v1.value0)(inner.contexts);
        if ($190) {
            return inner;
        };
        return {
            message: contextPrefix(v.value0) + inner.message,
            contexts: Foreign_Object.insert(v1.value0)(v1.value1)(inner.contexts)
        };
    };
    if (v instanceof QueryError) {
        return {
            message: show1(v),
            contexts: fromHomogeneous({
                backend_errors: encodeJson6({
                    errors: v.value0
                })
            })
        };
    };
    return {
        message: show1(v),
        contexts: Foreign_Object.empty
    };
};
var reportError = function (error) {
    var serror = toSentry(error);
    var v = Internal_Debug["debugLog$prime"]("Reporting error to sentry")(serror);
    return liftEffect1($foreign.captureError(serror));
};
var handleCritical = function (err) {
    return bind1(liftAff(reportError(err)))(function (errId) {
        return displayError(errId)((function () {
            if ($foreign.sentryFlag) {
                return div$prime([  ]);
            };
            return renderErrorDetails(err);
        })());
    });
};
var wrapCritical = function (v) {
    if (v instanceof Data_Either.Left) {
        return handleCritical(v.value0);
    };
    if (v instanceof Data_Either.Right) {
        return pure(v.value0);
    };
    throw new Error("Failed pattern match at Error (line 223, column 1 - line 223, column 65): " + [ v.constructor.name ]);
};
var handleError = function (a) {
    return function (err) {
        return bind1(liftAff(reportError(err)))(function (errId) {
            return displayErrorN(a)(errId)((function () {
                if ($foreign.sentryFlag) {
                    return div$prime([  ]);
                };
                return renderErrorDetails(err);
            })());
        });
    };
};
var wrapError = function (v) {
    return function (v1) {
        if (v1 instanceof Data_Either.Left) {
            return handleError(v)(v1.value0);
        };
        if (v1 instanceof Data_Either.Right) {
            return pure(v1.value0);
        };
        throw new Error("Failed pattern match at Error (line 237, column 1 - line 237, column 97): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
export {
    sentryFlag,
    initSentry,
    reloadPage,
    captureError,
    prettyPrintJson
} from "./foreign.js";
export {
    AffJaxError,
    DecodeError,
    QueryError,
    StringError,
    WithContext,
    userError,
    renderError_,
    toSentry,
    InFormWidget,
    InModule,
    InQuery,
    InForm,
    LoadingJson,
    DuringAction,
    encodeContext,
    contextPrefix,
    wrapJsonError,
    wrapJsonError$prime,
    withError,
    withError$prime,
    tagContext,
    tagContext$prime,
    tagWidget,
    tagModule,
    tagAction,
    handleCritical,
    wrapCritical,
    handleError,
    wrapError,
    reportError,
    displayErrorN,
    displayError,
    displayError_,
    renderErrorDetails,
    onLeft,
    showErrorWrapper,
    showCtx
};
