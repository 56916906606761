// Generated by purs version 0.15.7
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as $$Error from "../Error/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as Translate from "../Translate/index.js";
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h1$prime = /* #__PURE__ */ Concur_React_DOM["h1$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var p$prime = /* #__PURE__ */ Concur_React_DOM["p$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var ModalClosed = /* #__PURE__ */ (function () {
    function ModalClosed() {

    };
    ModalClosed.value = new ModalClosed();
    return ModalClosed;
})();
var ModalReturned = /* #__PURE__ */ (function () {
    function ModalReturned(value0) {
        this.value0 = value0;
    };
    ModalReturned.create = function (value0) {
        return new ModalReturned(value0);
    };
    return ModalReturned;
})();
var modalWidget$prime = function (outer) {
    return function (inner) {
        return alt(Internal_ConcurUtil.loopInfinitely(outer))(div([ Concur_React_Props.className("modal") ])([ inner, a([ voidRight(ModalClosed.value)(Concur_React_Props.onClick), Concur_React_Props.className("modal-close") ])([ text("Close") ]) ]));
    };
};
var modalWidget = function (outer) {
    return function (inner) {
        return modalWidget$prime(outer)(map(ModalReturned.create)(inner));
    };
};
var loopModal = function (outer) {
    return function (inner) {
        return function (state) {
            return bind(modalWidget$prime(outer)(inner(state)))(function (ret) {
                if (ret instanceof ModalReturned) {
                    return loopModal(outer)(inner)(ret.value0);
                };
                if (ret instanceof ModalClosed) {
                    return pure(state);
                };
                throw new Error("Failed pattern match at Widgets.Modal (line 41, column 5 - line 43, column 34): " + [ ret.constructor.name ]);
            });
        };
    };
};
var errorModalWidget = function (dictShow) {
    var show = Data_Show.show(dictShow);
    return function (err) {
        return bind(div$prime([ h1$prime([ text(Translate.lookupTranslation("error-occurred")) ]), p$prime([ text(show(err)) ]), button([ voidRight(0)(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("ok")) ]) ]))(function () {
            return pure(ModalClosed.value);
        });
    };
};
var errorModalWidget1 = /* #__PURE__ */ errorModalWidget($$Error.showErrorWrapper);
var errorWrapperModal = function (outer) {
    return function (inner) {
        return bind(modalWidget(outer)(inner))(function (v) {
            if (v instanceof ModalClosed) {
                return pure(ModalClosed.value);
            };
            if (v instanceof ModalReturned && v.value0 instanceof Data_Either.Right) {
                return pure(new ModalReturned(v.value0.value0));
            };
            if (v instanceof ModalReturned && v.value0 instanceof Data_Either.Left) {
                return modalWidget$prime(outer)(errorModalWidget1(v.value0.value0));
            };
            throw new Error("Failed pattern match at Widgets.Modal (line 53, column 61 - line 56, column 74): " + [ v.constructor.name ]);
        });
    };
};
export {
    ModalClosed,
    ModalReturned,
    modalWidget,
    modalWidget$prime,
    loopModal,
    errorWrapperModal,
    errorModalWidget
};
