// Generated by purs version 0.15.7
import * as API_Comments from "../API.Comments/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as $$Error from "../Error/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Internal_ConcurUtil from "../Internal.ConcurUtil/index.js";
import * as Internal_Luxon from "../Internal.Luxon/index.js";
import * as Internal_Util from "../Internal.Util/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_Checkbox from "../Widgets.Checkbox/index.js";
import * as Widgets_Confirmation from "../Widgets.Confirmation/index.js";
import * as Widgets_Dropdown from "../Widgets.Dropdown/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var textarea = /* #__PURE__ */ Concur_React_DOM.textarea(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var map2 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var eqRNTY = /* #__PURE__ */ GraphQL_Type.eqRNTY(Data_Eq.eqString);
var eq = /* #__PURE__ */ Data_Eq.eq(eqRNTY);
var h1 = /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var notEq = /* #__PURE__ */ Data_Eq.notEq(Internal_Luxon.eqLDT);
var br$prime = /* #__PURE__ */ Concur_React_DOM["br$prime"](Concur_Core_LiftWidget.widgetLiftWidget);
var details = /* #__PURE__ */ Concur_React_DOM.details(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var length = /* #__PURE__ */ Data_Foldable.length(Data_Foldable.foldableArray)(Data_Semiring.semiringInt);
var summary$prime = /* #__PURE__ */ Concur_React_DOM["summary$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var find = /* #__PURE__ */ Data_Foldable.find(Data_Foldable.foldableArray);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Concur_Core_Types.bindWidget);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var notEq1 = /* #__PURE__ */ Data_Eq.notEq(eqRNTY);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var Posting = /* #__PURE__ */ (function () {
    function Posting(value0) {
        this.value0 = value0;
    };
    Posting.create = function (value0) {
        return new Posting(value0);
    };
    return Posting;
})();
var Editing = /* #__PURE__ */ (function () {
    function Editing(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Editing.create = function (value0) {
        return function (value1) {
            return new Editing(value0, value1);
        };
    };
    return Editing;
})();
var PostComment = /* #__PURE__ */ (function () {
    function PostComment() {

    };
    PostComment.value = new PostComment();
    return PostComment;
})();
var DeleteComment = /* #__PURE__ */ (function () {
    function DeleteComment(value0) {
        this.value0 = value0;
    };
    DeleteComment.create = function (value0) {
        return new DeleteComment(value0);
    };
    return DeleteComment;
})();
var EditComment = /* #__PURE__ */ (function () {
    function EditComment(value0) {
        this.value0 = value0;
    };
    EditComment.create = function (value0) {
        return new EditComment(value0);
    };
    return EditComment;
})();
var StopEditing = /* #__PURE__ */ (function () {
    function StopEditing() {

    };
    StopEditing.value = new StopEditing();
    return StopEditing;
})();
var StateChange = /* #__PURE__ */ (function () {
    function StateChange(value0) {
        this.value0 = value0;
    };
    StateChange.create = function (value0) {
        return new StateChange(value0);
    };
    return StateChange;
})();
var initPostForm = {
    text: "",
    "public": false
};
var initComments = function (v) {
    return {
        id: v.id,
        formId: v.formId,
        comments: v.comments,
        can: v.can,
        post: new Posting(initPostForm)
    };
};
var commentForm = function (v) {
    var wrapPState = function (p) {
        if (v.post instanceof Editing) {
            return new Editing(v.post.value0, p);
        };
        if (v.post instanceof Posting) {
            return new Posting(p);
        };
        throw new Error("Failed pattern match at Widgets.Comments (line 179, column 20 - line 181, column 31): " + [ v.post.constructor.name ]);
    };
    var setPublic = function (pub) {
        if (v.post instanceof Editing) {
            return {
                post: new Editing(v.post.value0, {
                    text: v.post.value1.text,
                    "public": pub
                }),
                can: v.can,
                comments: v.comments,
                formId: v.formId,
                id: v.id
            };
        };
        if (v.post instanceof Posting) {
            return {
                post: new Posting({
                    text: v.post.value0.text,
                    "public": pub
                }),
                can: v.can,
                comments: v.comments,
                formId: v.formId,
                id: v.id
            };
        };
        throw new Error("Failed pattern match at Widgets.Comments (line 188, column 21 - line 190, column 67): " + [ v.post.constructor.name ]);
    };
    var post = (function () {
        if (v.post instanceof Editing) {
            return v.post.value1;
        };
        if (v.post instanceof Posting) {
            return v.post.value0;
        };
        throw new Error("Failed pattern match at Widgets.Comments (line 182, column 12 - line 184, column 23): " + [ v.post.constructor.name ]);
    })();
    var isEditing = (function () {
        if (v.post instanceof Editing) {
            return true;
        };
        if (v.post instanceof Posting) {
            return false;
        };
        throw new Error("Failed pattern match at Widgets.Comments (line 185, column 17 - line 187, column 27): " + [ v.post.constructor.name ]);
    })();
    var buttonLbl = (function () {
        if (v.post instanceof Editing) {
            return "save-comment";
        };
        if (v.post instanceof Posting) {
            return "post-comment";
        };
        throw new Error("Failed pattern match at Widgets.Comments (line 191, column 17 - line 193, column 36): " + [ v.post.constructor.name ]);
    })();
    return div([ Concur_React_Props.className("comment-form") ])(Data_Array.catMaybes([ new Data_Maybe.Just(textarea([ Concur_React_Props.key(v.id + "_textarea"), Concur_React_Props.className("comment-form-textarea"), Concur_React_Props.placeholder(Translate.lookupTranslation("comment-form-placeholder")), Concur_React_Props.value(post.text), map(map1(map1(StateChange.create)(function (t) {
        return {
            post: wrapPState({
                text: t,
                "public": post["public"]
            }),
            can: v.can,
            comments: v.comments,
            formId: v.formId,
            id: v.id
        };
    }))(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onInput) ])([  ])), Internal_Util.justIf(v.can.private_comment)(map2(map1(StateChange.create)(setPublic))(Widgets_Checkbox.labelCheckbox("comment-public-check")(Translate.lookupTranslation("comment-public-check"))(post["public"]))), new Data_Maybe.Just(div([ Concur_React_Props.className("comment-buttons") ])(Data_Array.catMaybes([ new Data_Maybe.Just(button([ Concur_React_Props.className("btn comment-form-post-button"), voidRight(PostComment.value)(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation(buttonLbl)) ])), Internal_Util.justIf(isEditing)(button([ Concur_React_Props.className("btn comment-form-cancel-button"), voidRight(StopEditing.value)(Concur_React_Props.onClick) ])([ text(Translate.lookupTranslation("cancel-edit-comment")) ])) ]))) ]));
};
var renderComment = function (v) {
    return function (v1) {
        if (v.post instanceof Editing && eq(v1.id)(v.post.value0)) {
            return div([ Concur_React_Props.className("comment") ])([ commentForm(v) ]);
        };
        var renderDBtn = button([ Concur_React_Props.className("dropdown-button"), Concur_React_Props.onClick ])([ text("\u2807") ]);
        var maybeDropBtn = function (v2) {
            if (v2.length === 0) {
                return Data_Maybe.Nothing.value;
            };
            return new Data_Maybe.Just(Widgets_Dropdown.dropdownButton(renderDBtn)(v2));
        };
        var commentLines = Data_String_Common.split("\x0a")(v1.comment);
        return div([ Concur_React_Props.className("comment") ])([ div([ Concur_React_Props.className("comment-head") ])(Data_Array.catMaybes([ new Data_Maybe.Just(h1([ Concur_React_Props.className("comment-poster") ])([ text(Translate.lookupTranslation("comment-by") + v1.author.name) ])), new Data_Maybe.Just(div([ Concur_React_Props.className("comment-times") ])(Data_Array.catMaybes([ new Data_Maybe.Just(text(Internal_Luxon.toRelative(v1.created_at))), Internal_Util.justIf(notEq(v1.updated_at)(v1.created_at))(text(" (" + (Translate.lookupTranslation("comment-updated-at") + (" " + (Internal_Luxon.toRelative(v1.updated_at) + ")"))))) ]))), new Data_Maybe.Just(div([ Concur_React_Props.className("comment-is-public") ])([ text(Translate.lookupTranslation((function () {
            if (v1["public"]) {
                return "comment-public-indicator";
            };
            return "comment-private-indicator";
        })())) ])), maybeDropBtn(Data_Array.catMaybes([ Internal_Util.justIf(v1.can.update)({
            value: new EditComment(v1.id),
            label: Translate.lookupTranslation("edit-comment")
        }), Internal_Util.justIf(v1["can"]["delete"])({
            value: new DeleteComment(v1.id),
            label: Translate.lookupTranslation("delete-comment")
        }) ])) ])), div([ Concur_React_Props.className("comment-body") ])(Data_Array.concatMap(function (l) {
            return [ text(l), br$prime ];
        })(commentLines)) ]);
    };
};
var renderComments = function (v) {
    var isPosting = (function () {
        if (v.post instanceof Posting) {
            return true;
        };
        return false;
    })();
    return details([ Concur_React_Props.key(v.id), Concur_React_Props["_id"](v.id), Concur_React_Props.open(length(v.comments) > 0) ])([ summary$prime([ h1([ Concur_React_Props.className("comment-section-heading") ])([ text(Translate.lookupTranslation("comment-section")) ]) ]), div([ Concur_React_Props.className("comment-section") ])(Data_Array.catMaybes([ new Data_Maybe.Just(div([ Concur_React_Props.className("comment-section-list") ])(map3(renderComment(v))(v.comments))), Internal_Util.justIf(isPosting && v.can.comment)(commentForm(v)) ])) ]);
};
var renderLoading = function (state) {
    return alt(Widgets_Loading.loadingWidgetOverlay)(Internal_ConcurUtil.loopInfinitely(renderComments(state)));
};
var handleResult = function (v) {
    return function (v1) {
        if (v1 instanceof StateChange) {
            return pure(v1.value0);
        };
        if (v1 instanceof StopEditing) {
            return pure({
                post: new Posting(initPostForm),
                can: v.can,
                comments: v.comments,
                formId: v.formId,
                id: v.id
            });
        };
        if (v1 instanceof EditComment) {
            var v2 = find(function (c) {
                return eq(c.id)(v1.value0);
            })(v.comments);
            if (v2 instanceof Data_Maybe.Just) {
                return pure({
                    post: new Editing(v1.value0, {
                        text: v2.value0.comment,
                        "public": v2["value0"]["public"]
                    }),
                    can: v.can,
                    comments: v.comments,
                    formId: v.formId,
                    id: v.id
                });
            };
            if (v2 instanceof Data_Maybe.Nothing) {
                return pure(v);
            };
            throw new Error("Failed pattern match at Widgets.Comments (line 59, column 5 - line 61, column 30): " + [ v2.constructor.name ]);
        };
        if (v1 instanceof DeleteComment) {
            return bind(Widgets_Confirmation.confirmModal(renderComments(v))(Translate.lookupTranslation("confirm-delete-comment-title"))(Translate.lookupTranslation("confirm-delete-comment-primary-button"))(Translate.lookupTranslation("confirm-delete-comment")))(function (conf) {
                if (conf) {
                    return bind(bindFlipped($$Error.wrapCritical)(alt(renderLoading(v))(liftAff(runGraphQL(API_Comments.deleteComment(v1.value0))))))(function () {
                        return pure({
                            comments: Data_Array.filter(function (c) {
                                return notEq1(c.id)(v1.value0);
                            })(v.comments),
                            can: v.can,
                            formId: v.formId,
                            id: v.id,
                            post: v.post
                        });
                    });
                };
                return pure(v);
            });
        };
        if (v.post instanceof Posting && v1 instanceof PostComment) {
            var $$public = (function () {
                if (v.can.private_comment) {
                    return v["post"]["value0"]["public"];
                };
                return true;
            })();
            return bind(bindFlipped($$Error.wrapCritical)(alt(renderLoading(v))(liftAff(runGraphQL(API_Comments.postComment(new API_Comments.FormComment(v.formId))({
                comment: v.post.value0.text,
                "public": $$public
            }))))))(function (comment) {
                return pure({
                    comments: append1(v.comments)([ comment ]),
                    post: new Posting({
                        text: "",
                        "public": $$public
                    }),
                    can: v.can,
                    formId: v.formId,
                    id: v.id
                });
            });
        };
        if (v.post instanceof Editing && v1 instanceof PostComment) {
            return bind(bindFlipped($$Error.wrapCritical)(alt(renderLoading(v))(liftAff(runGraphQL(API_Comments.updateComment(v.post.value0)({
                comment: v.post.value1.text,
                "public": v["post"]["value1"]["public"]
            }))))))(function (comment) {
                var update = function (c) {
                    var $120 = eq(c.id)(v.post.value0);
                    if ($120) {
                        return comment;
                    };
                    return c;
                };
                return pure({
                    comments: map3(update)(v.comments),
                    post: new Posting({
                        text: "",
                        "public": v["post"]["value1"]["public"]
                    }),
                    can: v.can,
                    formId: v.formId,
                    id: v.id
                });
            });
        };
        throw new Error("Failed pattern match at Widgets.Comments (line 55, column 1 - line 55, column 70): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var runComments = function (state) {
    return bindFlipped(handleResult(state))(renderComments(state));
};
export {
    Posting,
    Editing,
    PostComment,
    DeleteComment,
    EditComment,
    StopEditing,
    StateChange,
    initComments,
    initPostForm,
    runComments,
    handleResult,
    renderComments,
    renderComment,
    commentForm,
    renderLoading
};
