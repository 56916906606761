// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Web_HTML_Common from "../Web.HTML.Common/index.js";
import * as Web_HTML_HTMLAnchorElement from "../Web.HTML.HTMLAnchorElement/index.js";
import * as Web_HTML_HTMLAreaElement from "../Web.HTML.HTMLAreaElement/index.js";
import * as Web_HTML_HTMLAudioElement from "../Web.HTML.HTMLAudioElement/index.js";
import * as Web_HTML_HTMLBRElement from "../Web.HTML.HTMLBRElement/index.js";
import * as Web_HTML_HTMLBaseElement from "../Web.HTML.HTMLBaseElement/index.js";
import * as Web_HTML_HTMLBodyElement from "../Web.HTML.HTMLBodyElement/index.js";
import * as Web_HTML_HTMLButtonElement from "../Web.HTML.HTMLButtonElement/index.js";
import * as Web_HTML_HTMLCanvasElement from "../Web.HTML.HTMLCanvasElement/index.js";
import * as Web_HTML_HTMLDListElement from "../Web.HTML.HTMLDListElement/index.js";
import * as Web_HTML_HTMLDataElement from "../Web.HTML.HTMLDataElement/index.js";
import * as Web_HTML_HTMLDataListElement from "../Web.HTML.HTMLDataListElement/index.js";
import * as Web_HTML_HTMLDivElement from "../Web.HTML.HTMLDivElement/index.js";
import * as Web_HTML_HTMLDocument from "../Web.HTML.HTMLDocument/index.js";
import * as Web_HTML_HTMLElement from "../Web.HTML.HTMLElement/index.js";
import * as Web_HTML_HTMLEmbedElement from "../Web.HTML.HTMLEmbedElement/index.js";
import * as Web_HTML_HTMLFieldSetElement from "../Web.HTML.HTMLFieldSetElement/index.js";
import * as Web_HTML_HTMLFormElement from "../Web.HTML.HTMLFormElement/index.js";
import * as Web_HTML_HTMLHRElement from "../Web.HTML.HTMLHRElement/index.js";
import * as Web_HTML_HTMLHeadElement from "../Web.HTML.HTMLHeadElement/index.js";
import * as Web_HTML_HTMLHeadingElement from "../Web.HTML.HTMLHeadingElement/index.js";
import * as Web_HTML_HTMLIFrameElement from "../Web.HTML.HTMLIFrameElement/index.js";
import * as Web_HTML_HTMLImageElement from "../Web.HTML.HTMLImageElement/index.js";
import * as Web_HTML_HTMLInputElement from "../Web.HTML.HTMLInputElement/index.js";
import * as Web_HTML_HTMLKeygenElement from "../Web.HTML.HTMLKeygenElement/index.js";
import * as Web_HTML_HTMLLIElement from "../Web.HTML.HTMLLIElement/index.js";
import * as Web_HTML_HTMLLabelElement from "../Web.HTML.HTMLLabelElement/index.js";
import * as Web_HTML_HTMLLegendElement from "../Web.HTML.HTMLLegendElement/index.js";
import * as Web_HTML_HTMLLinkElement from "../Web.HTML.HTMLLinkElement/index.js";
import * as Web_HTML_HTMLMapElement from "../Web.HTML.HTMLMapElement/index.js";
import * as Web_HTML_HTMLMediaElement from "../Web.HTML.HTMLMediaElement/index.js";
import * as Web_HTML_HTMLMetaElement from "../Web.HTML.HTMLMetaElement/index.js";
import * as Web_HTML_HTMLMeterElement from "../Web.HTML.HTMLMeterElement/index.js";
import * as Web_HTML_HTMLModElement from "../Web.HTML.HTMLModElement/index.js";
import * as Web_HTML_HTMLOListElement from "../Web.HTML.HTMLOListElement/index.js";
import * as Web_HTML_HTMLObjectElement from "../Web.HTML.HTMLObjectElement/index.js";
import * as Web_HTML_HTMLOptGroupElement from "../Web.HTML.HTMLOptGroupElement/index.js";
import * as Web_HTML_HTMLOptionElement from "../Web.HTML.HTMLOptionElement/index.js";
import * as Web_HTML_HTMLOutputElement from "../Web.HTML.HTMLOutputElement/index.js";
import * as Web_HTML_HTMLParagraphElement from "../Web.HTML.HTMLParagraphElement/index.js";
import * as Web_HTML_HTMLParamElement from "../Web.HTML.HTMLParamElement/index.js";
import * as Web_HTML_HTMLPreElement from "../Web.HTML.HTMLPreElement/index.js";
import * as Web_HTML_HTMLProgressElement from "../Web.HTML.HTMLProgressElement/index.js";
import * as Web_HTML_HTMLQuoteElement from "../Web.HTML.HTMLQuoteElement/index.js";
import * as Web_HTML_HTMLScriptElement from "../Web.HTML.HTMLScriptElement/index.js";
import * as Web_HTML_HTMLSelectElement from "../Web.HTML.HTMLSelectElement/index.js";
import * as Web_HTML_HTMLSourceElement from "../Web.HTML.HTMLSourceElement/index.js";
import * as Web_HTML_HTMLSpanElement from "../Web.HTML.HTMLSpanElement/index.js";
import * as Web_HTML_HTMLStyleElement from "../Web.HTML.HTMLStyleElement/index.js";
import * as Web_HTML_HTMLTableCaptionElement from "../Web.HTML.HTMLTableCaptionElement/index.js";
import * as Web_HTML_HTMLTableCellElement from "../Web.HTML.HTMLTableCellElement/index.js";
import * as Web_HTML_HTMLTableColElement from "../Web.HTML.HTMLTableColElement/index.js";
import * as Web_HTML_HTMLTableDataCellElement from "../Web.HTML.HTMLTableDataCellElement/index.js";
import * as Web_HTML_HTMLTableElement from "../Web.HTML.HTMLTableElement/index.js";
import * as Web_HTML_HTMLTableHeaderCellElement from "../Web.HTML.HTMLTableHeaderCellElement/index.js";
import * as Web_HTML_HTMLTableRowElement from "../Web.HTML.HTMLTableRowElement/index.js";
import * as Web_HTML_HTMLTableSectionElement from "../Web.HTML.HTMLTableSectionElement/index.js";
import * as Web_HTML_HTMLTemplateElement from "../Web.HTML.HTMLTemplateElement/index.js";
import * as Web_HTML_HTMLTextAreaElement from "../Web.HTML.HTMLTextAreaElement/index.js";
import * as Web_HTML_HTMLTimeElement from "../Web.HTML.HTMLTimeElement/index.js";
import * as Web_HTML_HTMLTitleElement from "../Web.HTML.HTMLTitleElement/index.js";
import * as Web_HTML_HTMLTrackElement from "../Web.HTML.HTMLTrackElement/index.js";
import * as Web_HTML_HTMLUListElement from "../Web.HTML.HTMLUListElement/index.js";
import * as Web_HTML_HTMLVideoElement from "../Web.HTML.HTMLVideoElement/index.js";
import * as Web_HTML_History from "../Web.HTML.History/index.js";
import * as Web_HTML_Location from "../Web.HTML.Location/index.js";
import * as Web_HTML_Navigator from "../Web.HTML.Navigator/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";

export {
    window
} from "./foreign.js";
