// Generated by purs version 0.15.7
import * as API_SelStudent from "../API.SelStudent/index.js";
import * as API_Signature from "../API.Signature/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Patterns from "../Concur.Core.Patterns/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_FunctorWithIndex from "../Data.FunctorWithIndex/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as GraphQL_Join from "../GraphQL.Join/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Translate from "../Translate/index.js";
import * as Widgets_Checkbox from "../Widgets.Checkbox/index.js";
import * as Widgets_Confirmation from "../Widgets.Confirmation/index.js";
import * as Widgets_Loading from "../Widgets.Loading/index.js";
var loopState = /* #__PURE__ */ Concur_Core_Patterns.loopState(Concur_Core_Types.widgetMonad);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var form = /* #__PURE__ */ Concur_React_DOM.form(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var h1 = /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var label = /* #__PURE__ */ Concur_React_DOM.label(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var textarea = /* #__PURE__ */ Concur_React_DOM.textarea(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.applicativeWidget);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var runGraphQL = /* #__PURE__ */ GraphQL_Join.runGraphQL(GraphQL_Query.qgql);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var any = /* #__PURE__ */ Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean);
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ GraphQL_Type.eqRNTY(Data_Eq.eqString));
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var map4 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.functorWidget);
var all = /* #__PURE__ */ Data_Foldable.all(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var mapWithIndex = /* #__PURE__ */ Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var SaveEmail = /* #__PURE__ */ (function () {
    function SaveEmail(value0) {
        this.value0 = value0;
    };
    SaveEmail.create = function (value0) {
        return new SaveEmail(value0);
    };
    return SaveEmail;
})();
var SaveAndSendEmail = /* #__PURE__ */ (function () {
    function SaveAndSendEmail(value0) {
        this.value0 = value0;
    };
    SaveAndSendEmail.create = function (value0) {
        return new SaveAndSendEmail(value0);
    };
    return SaveAndSendEmail;
})();
var runSingleEmailWidget = function (state) {
    return function (field) {
        return loopState(state)(function (v) {
            return form([ Concur_React_Props.className("signature-email-single-form modal-grid"), Concur_React_Props.action("javascript:void(0);"), voidRight(new Data_Either.Right(new SaveAndSendEmail(v)))(Concur_React_Props.onSubmit) ])([ h1([ Concur_React_Props.className("modal-heading") ])([ text(field.emailLabel) ]), input([ map(map1(map1(Data_Either.Left.create)(function (v1) {
                return {
                    email: v1,
                    comment: v.comment
                };
            }))(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onChange), Concur_React_Props.value(v.email), Concur_React_Props["_id"]("email-input"), Concur_React_Props["_type"]("email"), Concur_React_Props.required(true), Concur_React_Props.autoComplete("email"), Concur_React_Props.placeholder(Translate.lookupTranslation("email-address")), Concur_React_Props.className("widget-input email-address") ]), div([ Concur_React_Props.className("signature-comment-container") ])([ label([ Concur_React_Props.htmlFor("comment-input"), Concur_React_Props.className("signature-comment-label") ])([ text(Translate.lookupTranslation("signature-comment")) ]), textarea([ map(map1(map1(Data_Either.Left.create)(function (v1) {
                return {
                    comment: v1,
                    email: v.email
                };
            }))(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onChange), Concur_React_Props.value(v.comment), Concur_React_Props["_id"]("comment-input"), Concur_React_Props.placeholder(Translate.lookupTranslation("signature-comment-placeholder")), Concur_React_Props.className("widget-input signature-comment") ])([  ]) ]), div([ Concur_React_Props.className("btn-group btn-group-email-single modal-buttons") ])([ button([ Concur_React_Props.className("btn"), Concur_React_Props["_type"]("submit") ])([ text(Translate.lookupTranslation("send-email")) ]) ]) ]);
        });
    };
};
var singleEmailWidget = function (initState) {
    return function (docId) {
        return function (field) {
            return bind(runSingleEmailWidget(initState)(field))(function (v) {
                if (v instanceof SaveEmail) {
                    return pure(new Data_Either.Right(new SaveEmail(v.value0)));
                };
                if (v instanceof SaveAndSendEmail) {
                    return bind(Widgets_Confirmation.confirmWidget(Translate.lookupTranslation("confirm-email-title"))(Translate.lookupTranslation("yes"))(Translate.lookupTranslation("confirm-email") + (" " + (v.value0.email + "?"))))(function (conf) {
                        if (conf) {
                            return bind(liftAff(runGraphQL(API_Signature.requestSigs([ {
                                docId: docId,
                                email: v.value0.email,
                                comment: v.value0.comment,
                                key: field.key
                            } ]))))(function (res) {
                                return pure(map2(SaveAndSendEmail.create)(res));
                            });
                        };
                        return singleEmailWidget(v.value0)(docId)(field);
                    });
                };
                throw new Error("Failed pattern match at Widgets.Signature.Email (line 38, column 84 - line 48, column 53): " + [ v.constructor.name ]);
            });
        };
    };
};
var renderEmailRow = function (account) {
    return function (idx) {
        return function (v) {
            var wasSent = Data_Maybe.maybe(false)(function (d) {
                return any(function (v1) {
                    return eq(v1.data.key)(v.field.key);
                })(d.verifications);
            })(v.doc);
            var sname = function (doc) {
                return API_SelStudent.studentName(account)(doc.student);
            };
            var sentLbl = (function () {
                if (wasSent) {
                    return " (" + (Translate.lookupTranslation("already-emailed") + ")");
                };
                return "";
            })();
            var label1 = (function () {
                if (v.doc instanceof Data_Maybe.Nothing) {
                    return v.field.emailLabel;
                };
                if (v.doc instanceof Data_Maybe.Just) {
                    return v.doc.value0.form_type.abbr + (" - " + (sname(v.doc.value0) + (" - " + (v.field.emailLabel + sentLbl))));
                };
                throw new Error("Failed pattern match at Widgets.Signature.Email (line 187, column 13 - line 189, column 101): " + [ v.doc.constructor.name ]);
            })();
            return div([ Concur_React_Props.className("email-all-row") ])([ input([ Concur_React_Props["_type"]("checkbox"), Concur_React_Props["_id"]("checkbox" + show(idx)), Concur_React_Props.checked(v.selected), voidRight({
                selected: !v.selected,
                doc: v.doc,
                email: v.email,
                field: v.field
            })(Concur_React_Props.onChange), Concur_React_Props.className("email-all-row-checkbox") ]), div([ Concur_React_Props.className("email-wrapper") ])([ label([ Concur_React_Props.htmlFor("checkbox" + show(idx)) ])([ text(label1) ]), input([ Concur_React_Props["_type"]("email"), Concur_React_Props.required(v.selected), Concur_React_Props["_id"]("input" + show(idx)), map(map1(function (v1) {
                return {
                    email: v1,
                    doc: v.doc,
                    field: v.field,
                    selected: v.selected
                };
            })(Concur_React_Props.unsafeTargetValue))(Concur_React_Props.onChange), Concur_React_Props.value(v.email), Concur_React_Props.placeholder(v.field.emailLabel), Concur_React_Props.className("widget-input email-all-row-email") ]) ]) ]);
        };
    };
};
var renderEmailAllForm = function (account) {
    return function (emails) {
        var selectAll = function (v) {
            return map3(function (e) {
                return {
                    selected: v,
                    doc: e.doc,
                    email: e.email,
                    field: e.field
                };
            })(emails);
        };
        var emailRow = function (idx) {
            return function (ent) {
                return map4(map1(Data_Either.Left.create)(function (e) {
                    return Data_Maybe.fromMaybe(emails)(Data_Array.updateAt(idx)(e)(emails));
                }))(renderEmailRow(account)(idx)(ent));
            };
        };
        var allSelected = all(function (v) {
            return v.selected;
        })(emails);
        var selectAllCheck = map4(map1(Data_Either.Left.create)(selectAll))(Widgets_Checkbox.labelCheckbox("select-all-emails")(Translate.lookupTranslation("select-all"))(allSelected));
        var actionBtns = div([ Concur_React_Props.className("btn-group btn-group-email-all modal-buttons") ])([ button([ Concur_React_Props.className("btn"), Concur_React_Props["_type"]("submit") ])([ text(Translate.lookupTranslation("send-email")) ]) ]);
        return form([ Concur_React_Props.className("signature-email-all-form modal-grid"), Concur_React_Props.action("javascript:void(0);"), voidRight(new Data_Either.Right(emails))(Concur_React_Props.onSubmit) ])([ h1([ Concur_React_Props.className("modal-heading") ])([ text(Translate.lookupTranslation("request-signatures")) ]), div([ Concur_React_Props.className("email-all-rows") ])(append1([ selectAllCheck ])(mapWithIndex(emailRow)(emails))), actionBtns ]);
    };
};
var runEmailAllForm = function (account) {
    return function (emails) {
        return loopState(emails)(renderEmailAllForm(account));
    };
};
var emailAllForm = function (workQ) {
    return function (docId) {
        return function (emails$prime) {
            var toSigReq = function (v) {
                return {
                    docId: Data_Maybe.maybe(docId)(function (v1) {
                        return v1.id;
                    })(v.doc),
                    email: v.email,
                    comment: "",
                    key: v.field.key
                };
            };
            return bind(runEmailAllForm(workQ.account)(emails$prime))(function (emails) {
                return bind(Widgets_Confirmation.confirmWidget(Translate.lookupTranslation("confirm-email-title"))(Translate.lookupTranslation("yes"))(Translate.lookupTranslation("confirm-email-multiple")))(function (conf) {
                    if (conf) {
                        return alt(Widgets_Loading.loadingWidget)(liftAff(runGraphQL(API_Signature.requestSigs(map3(toSigReq)(Data_Array.filter(function (v) {
                            return v.selected;
                        })(emails))))));
                    };
                    return emailAllForm(workQ)(docId)(emails);
                });
            });
        };
    };
};
export {
    SaveEmail,
    SaveAndSendEmail,
    singleEmailWidget,
    runSingleEmailWidget,
    emailAllForm,
    runEmailAllForm,
    renderEmailAllForm,
    renderEmailRow
};
