// Generated by purs version 0.15.7
import * as API_Pagination from "../API.Pagination/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
import * as GraphQL_Compound from "../GraphQL.Compound/index.js";
import * as GraphQL_Query from "../GraphQL.Query/index.js";
import * as GraphQL_Shape from "../GraphQL.Shape/index.js";
import * as GraphQL_Type from "../GraphQL.Type/index.js";
import * as Widgets_BetterSelect from "../Widgets.BetterSelect/index.js";
var searchIsSymbol = {
    reflectSymbol: function () {
        return "search";
    }
};
var gDecodeJsonCons = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString));
var gDecodeJsonCons1 = /* #__PURE__ */ gDecodeJsonCons(Data_Argonaut_Decode_Class.gDecodeJsonNil);
var nameIsSymbol = {
    reflectSymbol: function () {
        return "name";
    }
};
var idIsSymbol = {
    reflectSymbol: function () {
        return "id";
    }
};
var decodeRecord = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ GraphQL_Type.decRNTY(Data_Argonaut_Decode_Class.decodeJsonString)))(/* #__PURE__ */ gDecodeJsonCons1(nameIsSymbol)()())(idIsSymbol)()())();
var decodeArray = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(decodeRecord);
var rlhsCons = /* #__PURE__ */ GraphQL_Shape.rlhsCons(GraphQL_Shape.rlhsNil);
var recShape = /* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(nameIsSymbol)(GraphQL_Shape.scalarShape)()())(idIsSymbol)(GraphQL_Shape.scalarShape)()())();
var arrayShape = /* #__PURE__ */ GraphQL_Shape.arrayShape(recShape);
var unpagedQR = /* #__PURE__ */ API_Pagination.unpagedQR(/* #__PURE__ */ GraphQL_Query.arrayQR(decodeArray)(arrayShape));
var buildQuery = /* #__PURE__ */ GraphQL_Query.buildQuery(/* #__PURE__ */ GraphQL_Query.rlTVars(/* #__PURE__ */ GraphQL_Query.rltvCons(GraphQL_Query.rltvNil)(searchIsSymbol)(GraphQL_Type.hqtStr)(Data_Argonaut_Encode_Class.encodeJsonJString)()())(/* #__PURE__ */ GraphQL_Query.rltvfConsOther(GraphQL_Query.rltvfNil)(searchIsSymbol)())())(unpagedQR);
var buildQuery1 = /* #__PURE__ */ GraphQL_Query.buildQuery(/* #__PURE__ */ GraphQL_Query.rlTVars(GraphQL_Query.rltvNil)(GraphQL_Query.rltvfNil)());
var semesterIsSymbol = {
    reflectSymbol: function () {
        return "semester";
    }
};
var decodeRecord1 = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons1(semesterIsSymbol)()())(nameIsSymbol)()())();
var recShape1 = /* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(semesterIsSymbol)(GraphQL_Shape.scalarShape)()())(nameIsSymbol)(GraphQL_Shape.scalarShape)()())();
var gDecodeJsonCons2 = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldMaybe(decodeRecord));
var teacherIsSymbol = {
    reflectSymbol: function () {
        return "teacher";
    }
};
var groupIsSymbol = {
    reflectSymbol: function () {
        return "group";
    }
};
var maybeShape = /* #__PURE__ */ GraphQL_Shape.maybeShape(recShape);
var fromFoldable = /* #__PURE__ */ Foreign_Object.fromFoldable(Data_Foldable.foldableArray);
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ GraphQL_Type.encRNTY(Data_Argonaut_Encode_Class.encodeJsonJString));
var encodeJson1 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeJsonJString);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Data_Maybe.functorMaybe);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var groupsIsSymbol = {
    reflectSymbol: function () {
        return "groups";
    }
};
var gDecodeJsonCons3 = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(decodeArray))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(groupsIsSymbol)()())())));
var teachersIsSymbol = {
    reflectSymbol: function () {
        return "teachers";
    }
};
var studentsIsSymbol = {
    reflectSymbol: function () {
        return "students";
    }
};
var arrayShape1 = /* #__PURE__ */ GraphQL_Shape.arrayShape(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ rlhsCons(groupsIsSymbol)(arrayShape)()())());
var SearchVars = /* #__PURE__ */ (function () {
    function SearchVars(value0) {
        this.value0 = value0;
    };
    SearchVars.create = function (value0) {
        return new SearchVars(value0);
    };
    return SearchVars;
})();
var teacherQuery = function (search) {
    return API_Pagination.unpaged(buildQuery("teachers")({
        search: search
    }));
};
var teacherSearchAff = /* #__PURE__ */ (function () {
    return new Widgets_BetterSelect.BackendSearch(function (search) {
        return Widgets_BetterSelect.wrapSelectOption(function (v) {
            return v.name;
        })(teacherQuery(search));
    });
})();
var semesterQuery = /* #__PURE__ */ buildQuery1(/* #__PURE__ */ GraphQL_Query.arrayQR(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeArray(decodeRecord1))(/* #__PURE__ */ GraphQL_Shape.arrayShape(recShape1)))("semesters")({});
var semesterSearchAff = /* #__PURE__ */ Widgets_BetterSelect.simpleFrontendSearch(function (v) {
    return v.label;
})(/* #__PURE__ */ Widgets_BetterSelect.wrapSelectOption(function (v) {
    return v.name;
})(semesterQuery));
var searchDefaultsQuery = /* #__PURE__ */ buildQuery1(/* #__PURE__ */ GraphQL_Query.jsonQR(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons2(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldMaybe(decodeRecord1))(/* #__PURE__ */ gDecodeJsonCons2(Data_Argonaut_Decode_Class.gDecodeJsonNil)(teacherIsSymbol)()())(semesterIsSymbol)()())(groupIsSymbol)()())())(/* #__PURE__ */ GraphQL_Shape.recShape(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ GraphQL_Shape.rlhsCons(/* #__PURE__ */ rlhsCons(teacherIsSymbol)(maybeShape)()())(semesterIsSymbol)(/* #__PURE__ */ GraphQL_Shape.maybeShape(recShape1))()())(groupIsSymbol)(maybeShape)()())()))("defaultSelections")({});
var queryVars = function (v) {
    return new SearchVars({
        teacher: v.teacher,
        semester: v.semester,
        search: v.search,
        "class": v["class"]
    });
};
var queryObj$prime = function (q) {
    return fromFoldable(Data_Array.catMaybes([ map(function (a) {
        return new Data_Tuple.Tuple("teacher_id", encodeJson(a));
    })(q.teacher), map(function (a) {
        return new Data_Tuple.Tuple("class_id", encodeJson(a));
    })(q["class"]), map(function (a) {
        return new Data_Tuple.Tuple("semester", encodeJson1(a));
    })(q.semester), map(function (a) {
        return new Data_Tuple.Tuple("search", encodeJson1(a));
    })(q.search) ]));
};
var toVarsSearchVars = {
    toVars: function (v) {
        return Data_Array.catMaybes([ voidRight(new GraphQL_Query.QueryVar("teacher_id", new GraphQL_Type.Nullable(new GraphQL_Type.ArrayOf(new GraphQL_Type.QType("ID")))))(v.value0.teacher), voidRight(new GraphQL_Query.QueryVar("class_id", new GraphQL_Type.QType("ID")))(v["value0"]["class"]), voidRight(new GraphQL_Query.QueryVar("semester", new GraphQL_Type.QType("String")))(v.value0.semester), voidRight(new GraphQL_Query.QueryVar("search", new GraphQL_Type.QType("String")))(v.value0.search) ]);
    },
    encodeVars: function (v) {
        return queryObj$prime(v.value0);
    },
    varFiles: function (v) {
        return [  ];
    }
};
var queryObj = function ($232) {
    return Data_Argonaut_Core.fromObject(queryObj$prime($232));
};
var queryFields = function (q) {
    return Data_Array.catMaybes([ voidRight(new Data_Tuple.Tuple("teacher_id", "[ID!]"))(q.teacher), voidRight(new Data_Tuple.Tuple("class_id", "ID!"))(q["class"]), voidRight(new Data_Tuple.Tuple("semester", "String!"))(q.semester), voidRight(new Data_Tuple.Tuple("search", "String!"))(q.search) ]);
};
var emptySearchDefaults = /* #__PURE__ */ (function () {
    return {
        teacher: Data_Maybe.Nothing.value,
        semester: Data_Maybe.Nothing.value,
        group: Data_Maybe.Nothing.value
    };
})();
var emptySearchData = {
    teachers: [  ],
    semesters: [  ],
    classes: [  ],
    defaults: emptySearchDefaults
};
var classesQuery = /* #__PURE__ */ (function () {
    var groups = Data_Array.concatMap(function (v) {
        return v.groups;
    });
    var process = function (v) {
        return append(groups(v.teachers))(groups(v.students));
    };
    return Data_Functor.map(GraphQL_Query.queryFunctor)(process)(buildQuery1(GraphQL_Query.jsonQR(Data_Argonaut_Decode_Class.decodeRecord(gDecodeJsonCons3(gDecodeJsonCons3(Data_Argonaut_Decode_Class.gDecodeJsonNil)(teachersIsSymbol)()())(studentsIsSymbol)()())())(GraphQL_Shape.recShape(GraphQL_Shape.rlhsCons(rlhsCons(teachersIsSymbol)(arrayShape1)()())(studentsIsSymbol)(arrayShape1)()())()))("me")({}));
})();
var searchDataQuery = /* #__PURE__ */ GraphQL_Compound.composeQueries()(/* #__PURE__ */ GraphQL_Compound.qcCons(/* #__PURE__ */ GraphQL_Compound.qcCons(/* #__PURE__ */ GraphQL_Compound.qcCons(/* #__PURE__ */ GraphQL_Compound.qcCons(GraphQL_Compound.cqNil)(teachersIsSymbol)()()()()(GraphQL_Compound.querySQ))({
    reflectSymbol: function () {
        return "semesters";
    }
})()()()()(GraphQL_Compound.querySQ))({
    reflectSymbol: function () {
        return "defaults";
    }
})()()()()(GraphQL_Compound.querySQ))({
    reflectSymbol: function () {
        return "classes";
    }
})()()()()(GraphQL_Compound.querySQ))("SearchData")({
    teachers: /* #__PURE__ */ API_Pagination.unpaged(/* #__PURE__ */ buildQuery1(unpagedQR)("teachers")({})),
    semesters: semesterQuery,
    classes: classesQuery,
    defaults: searchDefaultsQuery
});
var classSearchAff = /* #__PURE__ */ Widgets_BetterSelect.simpleFrontendSearch(function (v) {
    return v.label;
})(/* #__PURE__ */ Widgets_BetterSelect.wrapSelectOption(function (v) {
    return v.name;
})(classesQuery));
export {
    searchDataQuery,
    teacherQuery,
    teacherSearchAff,
    semesterQuery,
    semesterSearchAff,
    classesQuery,
    classSearchAff,
    searchDefaultsQuery,
    emptySearchData,
    emptySearchDefaults,
    queryFields,
    queryObj,
    queryObj$prime,
    queryVars,
    toVarsSearchVars
};
