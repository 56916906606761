// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
var EN = /* #__PURE__ */ (function () {
    function EN() {

    };
    EN.value = new EN();
    return EN;
})();
var setLanguageString = function (lang) {
    return $foreign.setLanguage("en");
};
export {
    lookupTranslation
} from "./foreign.js";
export {
    setLanguageString
};
