// Generated by purs version 0.15.7
import * as Concur_Core_FRP from "../Concur.Core.FRP/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as $$Error from "../Error/index.js";
import * as Steps_Route from "../Steps.Route/index.js";
import * as Steps_State from "../Steps.State/index.js";
var dyn = /* #__PURE__ */ Concur_Core_FRP.dyn(Concur_Core_Types.widgetMonad);
var stateLoopS = /* #__PURE__ */ Concur_Core_FRP.stateLoopS(Concur_Core_Types.widgetMonad)(/* #__PURE__ */ Concur_Core_Types.widgetAlternative(Data_Monoid.monoidArray));
var stepWidget = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.bindWidget)(/* #__PURE__ */ Control_Bind.bindFlipped(Concur_Core_Types.bindWidget)($$Error.wrapCritical)(Steps_State.loadStepStateWidget))(function (state) {
    return dyn(stateLoopS(state)(Steps_Route.routeSignal));
});
export {
    stepWidget
};
